import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Segmented,
  Table,
  Tooltip,
  Tag,
  Popconfirm,
} from 'antd';
import { SendOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';
import Subscription from '../components/Subscription';
import ProfileDeletion from '../components/ProfileDeletion';
import TeamInvitation from '../components/TeamInvitation';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import NotAuthorized from '../components/NotAuthorized'; // Import the NotAuthorized component

const toolTip = (value) => <span>{value}</span>;

function Settings() {
  const [activeSegment, setActiveSegment] = useState('Team');
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [teamData, setTeamData] = useState({ teamName: '', members: [] });
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { userDetails, teamDetails, organizationId, userId } = useSelector((state) => state.user);
  const privateAxios = usePrivateAxios();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await privateAxios.get(`/api/teams/${teamDetails?.teamId}`);
        setTeamData(response.data);
      } catch (error) {
        console.error('Error fetching team data:', error);
        // Handle error (e.g., show notification)
      }
    };

    if (organizationId) {
      fetchTeamData();
    }
  }, [organizationId, privateAxios, teamDetails]);

  const handleSegmentChange = (value) => {
    setActiveSegment(value);
  };

  const handleSend = (record) => {
    handleTeamInvite({ email: record.email, name: record.name, role: 'member' });
  };

  const handleTeamInvite = async (inviteData) => {
    if (!teamDetails?.teamId) {
      StatusNotification(
        'error',
        'Team information not found',
        'Make sure you have joined a team before sending invitations.'
      );
      return;
    }

    try {
      const response = await privateAxios.post(`/api/teams/${teamDetails.teamId}/invite`, {
        ...inviteData,
        inviter: userDetails?.name,
      });
      if (response.status === 200) {
        StatusNotification(
          'success',
          'Invitation Sent',
          `An invitation has been sent to ${inviteData.name} (${inviteData.email}).`
        );
        setTeamData((prevData) => ({
          ...prevData,
          members: [...prevData.members, { ...inviteData, status: 'pending' }],
        }));
      } else {
        StatusNotification(
          'error',
          'Invitation Failed',
          'Failed to send invitation. Please try again.'
        );
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
      StatusNotification(
        'error',
        'Invitation Error',
        'An unexpected error occurred while sending the invitation. Please try again.'
      );
    }
  };

  const handleDeleteClick = (userId) => {
    setDeletingUserId(userId);
  };

  const handleDeleteCancel = () => {
    setDeletingUserId(null);
  };

  const handleDeleteConfirm = async (record) => {
    setConfirmLoading(true);
    try {
      const response = await privateAxios.post(`/api/teams/${teamDetails.teamId}/remove`, {
        userId: record.userId,
      });

      if (response.status === 200) {
        StatusNotification(
          'success',
          'Team Member Removed',
          `${record.name} has been successfully removed from the team.`
        );
        setTeamData((prevData) => ({
          ...prevData,
          members: prevData.members.filter((member) => member.userId !== record.userId),
        }));
      } else {
        StatusNotification(
          'error',
          'Removal Failed',
          'Failed to remove team member. Please try again.'
        );
      }
    } catch (error) {
      console.error('Error removing team member:', error);
      StatusNotification(
        'error',
        'Removal Error',
        'An unexpected error occurred while removing the team member. Please try again.'
      );
    } finally {
      setConfirmLoading(false);
      setDeletingUserId(null);
    }
  };

  const handleAccountDeleteConfirm = async () => {
    setConfirmLoading(true);
    try {
      const response = await privateAxios.post(`/api/users/delete-account`, {
        userId,
        organizationId,
      });
      if (response.status === 200) {
        dispatch({ type: 'INITIATE_ACCOUNT_DELETION', data: response.data.deletionDate });
        StatusNotification(
          'success',
          'Account deletion initiated',
          'Your account will be deleted in 21 days.'
        );
      } else {
        StatusNotification(
          'error',
          'Account Deletion Failed',
          'Failed to delete account. Please try again.'
        );
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      StatusNotification(
        'error',
        'Account Deletion Failed',
        'An unexpected error occurred while deleting your account. Please try again.'
      );
    } finally {
      setConfirmLoading(false);
      setDeletingUserId(null);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => <Tag color='grey'>{text}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <span>
          <Tag color={text === 'active' ? 'green' : 'red'}>{text}</Tag>
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <span>
          {record.status !== 'active' && (
            <SendOutlined
              onClick={() => handleSend(record)}
              style={{ marginRight: 8, fontSize: 16 }}
            />
          )}
          {record.status === 'active' && record.role !== 'owner' && (
            <Popconfirm
              title='Are you sure you want to remove this team member?'
              open={deletingUserId === record.userId}
              onConfirm={() => handleDeleteConfirm(record)}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleDeleteCancel}
            >
              <DeleteOutlined
                onClick={() => handleDeleteClick(record.userId)}
                style={{ fontSize: 16 }}
              />
            </Popconfirm>
          )}
        </span>
      ),
    },
  ];

  // Check if user role is 'owner'
  if (teamDetails?.role !== 'owner') {
    return <NotAuthorized />;
  }

  return (
    <>
      <div className='profile-nav-bg'></div>

      <Card
        className='card-profile-head'
        bodyStyle={{ display: 'none' }}
        title={
          <Row justify='space-between' align='middle' gutter={[24, 0]}>
            <Col span={24} md={10} className='col-info'>
              <div className='avatar-info'>
                <h4 className='font-semibold m-0'>{`${userDetails?.name}`}</h4>
                <p>Owner</p>
              </div>
            </Col>
            <Col
              span={24}
              md={14}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Segmented
                options={['Team', 'Billing', 'Profile']}
                defaultValue='Team'
                value={activeSegment}
                onChange={handleSegmentChange}
                style={{ marginBottom: 20 }}
              />
            </Col>
          </Row>
        }
      ></Card>

      {/* Profile Section */}
      {activeSegment === 'Profile' && (
        <Row gutter={[24, 0]} justify='center'>
          <Col span={24} md={8} className='mb-24'>
            <Card
              bordered={false}
              title={<h6 className='font-semibold m-0'>Profile Information</h6>}
              className='header-solid h-full card-profile-information'
              bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Descriptions>
                <Descriptions.Item label='Name' span={3}>
                  {`${userDetails?.name} ${userDetails?.lastName}`}
                </Descriptions.Item>
                <Descriptions.Item label='Email' span={3}>
                  {userDetails?.email}
                </Descriptions.Item>
              </Descriptions>
              <Button
                type='primary'
                disabled={userDetails?.accountDeletionScheduled}
                danger
                style={{ marginTop: 16, marginBottom: 16 }}
                block
                onClick={() => setIsDeleteModalVisible(true)}
              >
                Delete my account
              </Button>
            </Card>
          </Col>
        </Row>
      )}

      {/* Team Section */}
      {activeSegment === 'Team' && (
        <Row gutter={[24, 0]}>
          <Col xs={24} md={{ push: 1, span: 2 }}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={toolTip('Add Team Member')}
            >
              <span className='icon' style={{ fontSize: 26 }}>
                <PlusOutlined
                  style={{ color: GlobalColors.mainPurple }}
                  onClick={() => setIsInviteModalVisible(true)}
                />
              </span>
            </Tooltip>
          </Col>
          <Col span={24} className='mb-24'>
            <Card
              bordered={false}
              className='header-solid h-full'
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <Table columns={columns} dataSource={teamData.members} pagination={false} />
            </Card>
          </Col>
        </Row>
      )}

      {/* Billing Section */}
      {activeSegment === 'Billing' && (
        <Row gutter={[24, 0]}>
          <Col span={24} className='mb-24'>
            <Subscription />
          </Col>
        </Row>
      )}

      <TeamInvitation
        isVisible={isInviteModalVisible}
        onCancel={() => setIsInviteModalVisible(false)}
        onOk={(values) => {
          handleTeamInvite(values);
          setIsInviteModalVisible(false);
        }}
      />

      <ProfileDeletion
        isVisible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        onConfirm={() => {
          handleAccountDeleteConfirm();
          setIsDeleteModalVisible(false);
        }}
        userEmail={userDetails?.email}
      />
    </>
  );
}

export default Settings;
