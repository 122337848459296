import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Map,
  Source,
  Layer,
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from 'react-map-gl';
import bbox from '@turf/bbox';
import { Row, Col, Grid, Tooltip } from 'antd';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import { SyncOutlined } from '@ant-design/icons';
import StatusNotification from '../StatusNotification';
import GlobalColors from '../../assets/colors/GlobalColors';
import GeocoderControl from './GeocoderControl';
import Legend from './Legend';

import getScreenSize from '../../utils/getScreenSize';

const { useBreakpoint } = Grid;

const toolTip = (value) => <span>{value}</span>;
const mapBoxToken = process.env.REACT_APP_MAPBOX_TOKEN;
const defaultViewport = {
  latitude: -29.0,
  longitude: 24.0,
  zoom: 4.8,
};

const MapBox = () => {
  const screenSize = getScreenSize(useBreakpoint());

  const [allData, setAllData] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [viewport, setViewport] = useState(defaultViewport);
  const mapRef = useRef();

  const axiosPrivate = usePrivateAxios();

  useEffect(() => {
    const fetchGeoJSON = async () => {
      try {
        const response = await axios.get(
          'https://raw.githubusercontent.com/RyzorBent/za-geojson/master/wards.json'
        );
        setAllData(response.data);
      } catch (error) {
        StatusNotification('error', 'GeoJSON Error', 'Could not load mapping data');
        console.error('Could not load data', error);
      }
    };

    fetchGeoJSON();
  }, []);

  const onClick = (event) => {
    const feature = event.features[0];
    if (feature) {
      // Calculate the bounding box of the feature
      const [minLng, minLat, maxLng, maxLat] = bbox(feature);

      mapRef.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: 40, duration: 1000 }
      );
    }
  };
  const mergeData = useCallback((newData) => {
    setAllData((prevAllData) => {
      // If there's no previous data, just return
      if (!prevAllData || !prevAllData.features || !Array.isArray(newData)) {
        return prevAllData;
      }

      // Deep copy the features array
      const updatedFeatures = prevAllData.features.map((feature) => ({
        ...feature,
        properties: { ...feature.properties },
      }));

      // Update the properties for each feature if a matching sentiment entry is found
      updatedFeatures.forEach((feature) => {
        const sentimentEntry = newData.find((entry) => entry.wardId === feature.properties.wardId);
        if (sentimentEntry) {
          feature.properties.sentimentScore = Math.round(sentimentEntry.sentimentScore);
        }
      });

      // Return the new data with the updated features
      return { ...prevAllData, features: updatedFeatures };
    });
  }, []);

  const fetchAllData = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const response = await axiosPrivate.get(`/api/sentiments`);
      const sentimentsData = response.data;
      mergeData(sentimentsData); // Use the mergeData function to combine the sentiment data with the GeoJSON
    } catch (error) {
      StatusNotification('error', 'Database Error', 'Could not fetch data from the database');
      console.error('Error fetching data:', error);
    } finally {
      setIsRefreshing(false);
    }
  }, [axiosPrivate, mergeData]);
  // Include mergeData in the dependency array

  // mergeData function remains the same as you previously defined
  const resetViewport = () => {
    setViewport(defaultViewport);
  };

  useEffect(() => {
    fetchAllData(); // Fetch all data when the component mounts
  }, []);

  // Function to fetch new sentiment data
  const fetchNewData = useCallback(async () => {
    try {
      const response = await axiosPrivate.get(`/api/sentiments`, {
        params: {
          lastUpdateTime: lastUpdateTime,
        },
      });
      const newData = response.data;
      mergeData(newData);
      setLastUpdateTime(Date.now());
    } catch (error) {
      console.error('Error fetching new data:', error);
    }
  }, [axiosPrivate, lastUpdateTime]);

  useEffect(() => {
    const interval = setInterval(fetchNewData, 500);
    return () => clearInterval(interval);
  }, [fetchNewData]);

  const onHover = useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];

    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  }, []);

  const wardLayer = {
    id: 'wards-layer',
    type: 'fill',
    paint: {
      'fill-color': [
        'case',
        ['==', ['typeof', ['get', 'sentimentScore']], 'number'], // Check if sentimentScore is a number
        [
          'interpolate',
          ['linear'],
          ['get', 'sentimentScore'],
          -10,
          '#EA2027', // very negative mood
          0,
          '#FFC312', // neutral mood
          10,
          '#009432', // very positive mood
        ],
        'rgba(0, 0, 0, 0)', // Default to transparent if sentimentScore is not a number
      ],
      'fill-opacity': 0.45,
    },
  };

  return (
    <div style={{ marginTop: screenSize !== 'xs' ? 30 : 0 }}>
      <Row style={{ height: '80vh', borderRadius: 9 }}>
        <Col style={{ height: '5%' }}>
          <Row>
            <Col>
              <Tooltip
                placement='top'
                color={GlobalColors.mainPurple}
                title={toolTip('Refresh Map Data')}
              >
                <SyncOutlined
                  spin={isRefreshing}
                  style={{ fontSize: 20, cursor: 'pointer' }}
                  onClick={() => fetchAllData()}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ height: '95%' }}>
          <Map
            initialViewState={{ ...viewport }}
            // viewState={viewport}.
            ref={mapRef}
            mapboxAccessToken={mapBoxToken}
            style={{ borderRadius: 9 }}
            mapStyle='mapbox://styles/mapbox/light-v9'
            // mapStyle='mapbox://styles/mapbox/dark-v9'
            onClick={onClick}
            onMouseMove={onHover}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            interactiveLayerIds={['sentiment-layer', 'wards-layer']}
            attributionControl={false}
          >
            {allData && (
              <Source type='geojson' data={allData}>
                <Layer {...wardLayer} />
              </Source>
            )}
            {hoverInfo && (
              <div className='tooltip' style={{ left: hoverInfo.x, top: hoverInfo.y }}>
                <div>Municipality: {hoverInfo.feature.properties.municipalityName}</div>
                <div>WardId: {hoverInfo.feature.properties.wardId}</div>
                <div>WardNo: {hoverInfo.feature.properties.wardNo}</div>
              </div>
            )}
            <GeocoderControl
              mapboxAccessToken={mapBoxToken}
              position='top-left'
              onResult={(evt) => {
                // Handle the result event
              }}
            />
            <FullscreenControl position='top-left' />
            <NavigationControl position='top-left' />
            <ScaleControl />
          </Map>
          <Legend />
        </Col>
        {/* {console.log({ viewport })}
        <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
          <Tooltip
            placement='top'
            color={GlobalColors.mainPurple}
            title={toolTip('Refresh Map Data')}
          >
            <SyncOutlined
              spin={isRefreshing}
              style={{ fontSize: 20, cursor: 'pointer' }}
              onClick={() => fetchAllData()}
            />
          </Tooltip>
        </Col> */}
      </Row>
    </div>
  );
};

export default MapBox;
