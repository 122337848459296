const FetchOrganizationCounters = async (axiosPrivate, dispatch, organizationId) => {
  try {
    const response = await axiosPrivate.get(`/api/organizations/counters/${organizationId}`);
    const counters = response.data.counters;
    // setOrganizationCounters(counters)
    dispatch({ type: 'SET_COUNTERS', data: counters });
  } catch (error) {
    console.error(error);
  }
};

export default FetchOrganizationCounters;
