import React, { useState, useEffect } from 'react';
import { Modal, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const ConsentMessage = () => {
  const [open, setOpen] = useState(false);

  const hasConsent = localStorage.getItem('hasConsent');

  useEffect(() => {
    if (!hasConsent)
    setTimeout(() => {
      setOpen(true);
    }, 8000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsent = () => {
    localStorage.setItem('hasConsent', 'true');
    setOpen(false);
  };
  return (
    <Modal
      title={<Title level={4}>We value your privacy</Title>}
      style={{ top: '60%' }}
      open={open}
      onOk={handleConsent}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText='Got It'
      width={1000}
    >
      <p>
        {' '}
        We use cookies to give you the best experience on our website. By using
        our site, you consent to our use of cookies. For more information,
        please check out our <Link to='/privacy'>Privacy Policy</Link>.
      </p>
    </Modal>
  );
};

export default ConsentMessage;
