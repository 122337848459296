const md5 = require('md5');

const verifySignature = (formData) => {
  const objFormData = {};

  for (var pair of formData.entries()) {
    objFormData[pair[0]] = pair[1];
  }
  const parms = Object.entries(objFormData).filter(([key, val]) => val !== '');
  const encodedParams = parms.map(([key, val]) => [
    key,
    encodeURIComponent(val.trim()).replace(/%20/g, '+'),
  ]);
  const concatedParams = encodedParams.reduce(
    (url, [key, val]) => url + key + '=' + val + '&',
    ''
  );
  //removing last ampersand
  const strippedParams =
    concatedParams.slice(0, concatedParams.length - 1) +
    '&passphrase=' +
    encodeURIComponent(
      process.env.REACT_APP_PAYFAST_PASSPHRASE.trim().replace(/%20/g, '+')
    );
  const signature = md5(strippedParams);

  return signature;
};

export default verifySignature;
