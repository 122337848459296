import React from 'react';
import Chart from 'react-apexcharts';
import GlobalColors from '../../assets/colors/GlobalColors';

const RadialBarChart = ({ value, task }) => {
  const options = {
    chart: {
      height: 240,
      type: 'radialBar',
    },
    series: [value],
    colors: [GlobalColors.mainPurple],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%',
          background: GlobalColors[task.toLowerCase()],
        },
        track: {
          strokeWidth: '280%',
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 2,
            opacity: 0.01,
          },
        },
        dataLabels: {
          name: {
            offsetY: -0,
            color: '#fff',
            fontSize: '40px',
          },
          value: {
            offsetY: -9,
            color: '#fff',
            fontSize: '17px',
            show: true,
            strokeWidth: 15,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: [GlobalColors.mainPurple],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: [''],
  };

  return <Chart options={options} series={options.series} type='radialBar' height={140} />;
};

export default RadialBarChart;