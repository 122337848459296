import { useState, useEffect } from 'react';
import axiosInstance from '../apis/backendAPI';

const useAxios = () => {

  const [loading, setLoading] = useState(false); //different!
  const [controller, setController] = useState();

  const axiosFetch = (configObj) => {
    const { method, url, requestConfig = {} } = configObj;
    return new Promise(async (resolve, reject) => {
      try {
        const ctrl = new AbortController();
        setController(ctrl);
        setLoading(true);
        const res = await axiosInstance[method.toLowerCase()](url, {
          ...requestConfig,
          signal: ctrl.signal,
        });
        resolve(res.data);
      } catch (err) {
        reject(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    // useEffect cleanup function
    return () => controller && controller.abort();
  }, [controller]);

  return [loading, axiosFetch];
};

export default useAxios;
