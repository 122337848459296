// src/components/TeamInvitation.js
import { Modal, Form, Input, Select } from 'antd';

const TeamInvitation = ({ isVisible, onCancel, onOk }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onOk(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal title='Invite Organization Member' open={isVisible} onOk={handleOk} onCancel={onCancel}>
      <Form form={form} layout='vertical'>
        <Form.Item
          name='name'
          label='Name'
          rules={[{ required: true, message: 'Please enter team member name!' }]}
        >
          <Input placeholder='e.g. Sizwe' />
        </Form.Item>
        <Form.Item
          name='email'
          label='Email Address'
          rules={[{ required: true, message: 'Please input the email address!' }]}
        >
          <Input placeholder='sizwe@codegarden.co.za' />
        </Form.Item>
        <Form.Item
          name='role'
          label='Role'
          rules={[{ required: true, message: 'Please select a role!' }]}
        >
          <Select placeholder='Select a role'>
            <Select.Option value='viewer'>Viewer</Select.Option>
            <Select.Option value='member'>Member</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TeamInvitation;