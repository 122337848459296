/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Typography } from 'antd';

import barChartConfigs from './configs/barChart';

const { options: barOptions, series: barSeries } = barChartConfigs;
// const items = [
//   {
//     Title: '1,2K',
//     user: 'Users',
//   },
//   {
//     Title: '800',
//     user: 'Responses',
//   },
//   {
//     Title: '90%',
//     user: 'Approval',
//   },
//   {
//     Title: '12%',
//     user: 'Pending',
//   },
// ];

function BarChart({ barChartSeries = [], barChartOptions = barOptions }) {
  return (
    <>
      <div id='chart'>
        <ReactApexChart
          className='bar-chart'
          series={barChartSeries}
          options={barChartOptions}
          type='bar'
          // height={220}
        />
      </div>
      {/* <div className='chart-vistior'>
        <Title level={5}>Active Users</Title>
        <Paragraph className='lastweek'>
          than last week <span className='bnb2'>+30%</span>
        </Paragraph>
        <Paragraph className='lastweek'>
          This is how things are looking for the past month.
        </Paragraph>
        <Row gutter>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className='chart-visitor-count'>
                <Title level={4}>{v.Title}</Title>
                <span>{v.user}</span>
              </div>
            </Col>
          ))}
        </Row>
      </div> */}
    </>
  );
}

export default BarChart;
