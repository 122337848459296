// FreeTextResponsesSkeleton.jsx
import React from 'react';
import { Skeleton, Row, Col, Card } from 'antd';

const FreeTextResponsesSkeleton = () => {
  return (
    <Card bordered={false} className='criclebox tablespace mb-24'>
      <Skeleton.Button active style={{ width: 450, marginBottom: 16, marginTop: 36 }} />
      {Array.from({ length: 6 }).map((_, index) => (
        <div key={index} style={{ marginBottom: 16 }}>
          <Skeleton.Button active style={{ width: 450, marginBottom: 16 }} />
        </div>
      ))}
    </Card>
  );
};

export default FreeTextResponsesSkeleton;
