import React from 'react';
import { Field } from 'rc-field-form';
import { Input, Button, Space } from 'antd';
import { ShrinkOutlined } from '@ant-design/icons';
import StatusNotification from './StatusNotification';
import shortenUrl from '../utils/shortenUrl';
import isValidUrl from '../utils/isValidUrl';
import usePrivateAxios from '../hooks/usePrivateAxios';

const ValidatePlaceholderInputs = ({
  valueSetter,
  type,
  text,
  form,
  placeholderType,
  setDynamicUrlInput,
}) => {
  const placeholderRegex = /{{\d+}}/g;
  const urlPlaceholderRegex = /{{url}}/g;
  const placeholders = text.match(placeholderRegex) || [];
  const urlPlaceholders = (placeholderType === 'SMS' && text.match(urlPlaceholderRegex)) || [];

  const axios = usePrivateAxios();

  const handleShortenUrl = async (index) => {
    const fieldName = `URL_example_${index}`;
    // const urlValue = form.getFieldValue(fieldName);
    const urlValue = 'https://link.surveyr.africa/template';
    try {
      const { isSuccess, payload, urlId } = await shortenUrl(urlValue, axios);
      if (isSuccess) {
        form.setFieldsValue({
          [fieldName]: payload,
        });
        setDynamicUrlInput(payload);
        // StatusNotification('success', 'URL shortened successfully');
      } else {
        StatusNotification(
          'error',
          'Error shortening URL',
          'Please try again, if it fails again, contact support'
        );
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Error shortening URL',
        'Please try again, if it fails again, contact support'
      );
    }
  };

  return (
    <>
      {placeholders.map((placeholder, index) => {
        const adjustedIndex = parseInt(placeholder.replace(/[{{}}]/g, ''), 10) - 1;
        const fieldName = `${type}_example_${adjustedIndex}`;

        return (
          <div key={fieldName} style={{ marginBottom: '10px' }}>
            <Field
              name={fieldName}
              rules={[
                {
                  required: true,
                  message: `Example text for ${placeholder} is required!`,
                },
              ]}
            >
              {(control, meta) => {
                return (
                  <div>
                    <Input
                      {...control}
                      placeholder={`Example text for placeholder ${placeholder}`}
                    />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                );
              }}
            </Field>
          </div>
        );
      })}
      {urlPlaceholders.map((placeholder, index) => {
        const fieldName = `URL_example_${index}`;

        return (
          <div key={fieldName} style={{ marginBottom: '10px' }}>
            <Field
              name={fieldName}
              rules={[
                {
                  required: true,
                  message: 'Example URL is required!',
                },
                {
                  type: 'url',
                  message: 'Please insert a valid URL!',
                },
              ]}
            >
              {(control, meta) => {
                return (
                  <div>
                    <Space.Compact
                      style={{
                        width: '100%',
                      }}
                    >
                      <Input
                        {...control}
                        placeholder='Enter example URL for {{url}}'
                        size='small'
                      />
                      <Button
                        onClick={() => handleShortenUrl(index)}
                        disabled={!isValidUrl(form.getFieldValue(fieldName))}
                        style={{ height: 40 }}
                      >
                        <ShrinkOutlined
                          style={{
                            cursor: !isValidUrl(form.getFieldValue(fieldName))
                              ? 'not-allowed'
                              : 'pointer',
                            fontSize: 19,
                            paddingTop: 6,
                            paddingLeft: 4,
                          }}
                        />
                      </Button>
                    </Space.Compact>
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                );
              }}
            </Field>
          </div>
        );
      })}
    </>
  );
};

export default ValidatePlaceholderInputs;
