import * as Sentry from '@sentry/react';
import { notification } from 'antd';
import { FrownTwoTone, SmileTwoTone, WarningTwoTone } from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';
import isObjectEmpty from '../utils/isObjectEmpty';

const StatusNotification = (type, title, message, forSentry = {}) => {
  console.log(title.toLowerCase().includes('deletion'));
  let icn;
  if (type === 'success' && !title.toLowerCase().includes('deletion'))
    icn = <SmileTwoTone twoToneColor={GlobalColors.lightPurple} />;
  else if (type === 'warning') icn = <WarningTwoTone twoToneColor={GlobalColors.lightPurple} />;
  else icn = <FrownTwoTone twoToneColor={GlobalColors.lightPurple} />;

  let duration = 6.5;

  if (title.toLowerCase().includes('summary') || title.toLowerCase().includes('deletion')) {
    duration = 0;
  }

  // if (typeof type === 'string')
  notification[type]({
    icon: icn,
    message: title,
    description: message,
    duration,
    style: {
      borderRadius: '6px',
      backgroundColor: '#f5f5f5',
    },
  });

  if (type === 'error' && !isObjectEmpty)
    Sentry.captureException(forSentry.error, forSentry.options);
  else if (type === 'error' && isObjectEmpty(forSentry))
    Sentry.captureException(new Error(`${title}: ${message}`));
};

export default StatusNotification;
