import React from 'react';
import { Row, Col, Card, Avatar, Typography, Carousel, Grid } from 'antd';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import SectionHeader from './SectionHeader';
import getScreenSize from '../utils/getScreenSize';
import muzi from '../assets/images/muzi-avatar.jpg';
import rev from '../assets/images/rev-molo-avatar.png';
import collen from '../assets/images/collen-avatar.png';
import rosalie from '../assets/images/rosalie-avatar.png';
import GlobalColors from '../assets/colors/GlobalColors';

const { Text, Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const testimonials = [
  {
    name: 'Dr Rosalie Molatela Nkhumishe',
    position: 'Founder, Oracare Dental Clinic',
    quote: (
      <>
        Oracare used Surveyr during the dental awareness month in 2023, It helped us{' '}
        <Text mark strong style={{ fontSize: 18 }}>
          understand
        </Text>{' '}
        our community's needs better
      </>
    ),
    avatar: rosalie,
  },
  {
    name: 'Rev. Mzwandile Molo',
    position: 'Secretary General, SACC',
    quote: (
      <>
        It has been a pleasure to work with Surveyr. The platform allows us to{' '}
        <Text mark strong style={{ fontSize: 18 }}>
          quickly and easily conduct surveys
        </Text>{' '}
        without any hassle.
      </>
    ),
    avatar: rev,
  },
  {
    name: 'Muzi Masilela',
    position: 'Co Founder, Muluma Consulting',
    quote: (
      <>
        Surveyr is our go-to tool for conducting survey research. It has been a{' '}
        <Text mark strong style={{ fontSize: 18 }}>
          game-changer
        </Text>{' '}
        for our business. Highly recommended!
      </>
    ),
    avatar: muzi,
  },
  {
    name: 'Collen Mashawana',
    position: 'Founder, The Collen Mashawana Foundation',
    quote: (
      <>
        Surveyr chatbot was instrumental during our 2024 fundraising gallar dinner, Our dellegates
        found it{' '}
        <Text mark strong style={{ fontSize: 18 }}>
          easy to use
        </Text>{' '}
      </>
    ),
    avatar: collen,
  },
];

const Testimonial = () => {
  const carouselRef = React.useRef();
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const renderTestimonialCard = (testimonial) => (
    <Card
      bordered={false}
      style={{
        height: '250px',
        margin: '0 8px',
        backgroundColor: GlobalColors.lightGray,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Avatar size={68} src={testimonial.avatar} />
        <div style={{ marginLeft: 16 }}>
          <Title level={5} style={{ margin: 0 }} className='featuresTitle'>
            {testimonial.name}
          </Title>
          <Text type='secondary' style={{ fontSize: 14 }}>
            {testimonial.position}
          </Text>
        </div>
      </div>
      <Paragraph type='secondary' style={{ fontSize: 18 }}>
        {testimonial.quote}
      </Paragraph>
    </Card>
  );

  const nextSlide = () => {
    carouselRef.current.next();
  };

  const prevSlide = () => {
    carouselRef.current.prev();
  };

  const renderCarouselContent = () => {
    if (screenSize === 'xs') {
      // Render one testimonial per slide for small screens
      return testimonials.map((testimonial, index) => (
        <div key={index}>
          <Row gutter={[8, 0]}>
            <Col span={24}>{renderTestimonialCard(testimonial)}</Col>
          </Row>
        </div>
      ));
    } else {
      // Render three testimonials per slide for larger screens
      return [0, 3].map((startIndex) => (
        <div key={startIndex}>
          <Row gutter={[8, 0]}>
            {testimonials.slice(startIndex, startIndex + 3).map((testimonial, index) => (
              <Col span={8} key={index}>
                {renderTestimonialCard(testimonial)}
              </Col>
            ))}
          </Row>
        </div>
      ));
    }
  };

  return (
    <div
      id='testimonials'
      className='container containerRadius'
      style={{ backgroundColor: 'white', position: 'relative' }}
    >
      <Row gutter={[24, 24]} style={{ padding: '48px 0 64px' }}>
        <Col span={24}>
          <SectionHeader
            title='Testimonials'
            isFront={true}
            subtitle='What others are saying about us'
          />
        </Col>
        <Col span={24}>
          <Carousel ref={carouselRef} dots={false}>
            {renderCarouselContent()}
          </Carousel>
        </Col>
      </Row>
      <LeftCircleFilled
        className='carousel-arrow carousel-arrow-left'
        onClick={prevSlide}
        style={{
          position: 'absolute',
          top: '60%',
          left: '10px',
          fontSize: '24px',
          cursor: 'pointer',
          color: GlobalColors.lightPurple,
        }}
      />
      <RightCircleFilled
        className='carousel-arrow carousel-arrow-right'
        onClick={nextSlide}
        style={{
          position: 'absolute',
          top: '60%',
          right: '10px',
          fontSize: '24px',
          cursor: 'pointer',
          color: GlobalColors.lightPurple,
        }}
      />
    </div>
  );
};

export default Testimonial;
