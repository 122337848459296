import React, { useState, useEffect } from 'react';
import { Slider, Row, Col, Tag, Segmented } from 'antd';
import GlobalColors from '../assets/colors/GlobalColors';

// Constants
const MIN_MESSAGES = 50;
const MAX_MESSAGES = 5000;
const WHATSAPP_RATE = process.env.REACT_APP_RATE_PER_WHATSAPP_MESSAGE || 0.0363; // Assuming a rate of $0.0168 per message
const SMS_RATE = process.env.REACT_APP_RATE_PER_SMS_MESSAGE || 0.322; //smsportal rate of 0.22 + 15% vat, selling at 0.32 vat included, profit margin 20%

const MessagesPriceSlider = ({ setTopUpAmount, currency = 'ZAR', exchangeRate }) => {
  const rate = currency === 'USD' ? 1 : exchangeRate;

  const [messages, setMessages] = useState(MIN_MESSAGES);
  const [price, setPrice] = useState(0);
  const [messageType, setMessageType] = useState('SMS');

  const getRate = () => (messageType === 'SMS' ? SMS_RATE : WHATSAPP_RATE);
  const whatsAppCost = (getRate() * rate).toFixed(2);
  const smsCost = (getRate() * rate).toFixed(2);

  // Effect to calculate price when messages change
  useEffect(() => {
    setPrice((messages * getRate() * rate).toFixed(2));
    setTopUpAmount({ price: parseFloat(price), numberOfMessages: messages });
    console.log({ rate, price });
  }, [messages, price, rate, setTopUpAmount]);

  const handleChange = (value) => {
    const newPrice = (value * getRate() * rate).toFixed(2);
    setMessages(value);
    setPrice(newPrice);
    setTopUpAmount({ price: parseFloat(newPrice), numberOfMessages: value });
    console.log({ rate, price });
  };

    const handleTypeChange = (value) => {
      setMessageType(value);
    };

  return (
    <div style={{ marginBottom: 8 }}>
      <Row gutter={[24, 8]} align='middle' style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Segmented
            options={['SMS', 'WhatsApp']}
            value={messageType}
            onChange={handleTypeChange}
          />
        </Col>
      </Row>
      <Slider
        step={50}
        defaultValue={MIN_MESSAGES}
        value={messages}
        min={MIN_MESSAGES}
        max={MAX_MESSAGES}
        onChange={handleChange}
      />
      <Row gutter={[24, 8]}>
        <Col span={24}>
          <Row justify='space-between'>
            <Col span={8}>
              <Tag color={GlobalColors.whiteGray} style={{ color: 'black' }}>
                Cost: {currency === 'USD' ? `$ ${whatsAppCost}` : `R ${smsCost}`} per message
              </Tag>
            </Col>
            <Col span={6}>
              <Tag color={GlobalColors.whiteGray} style={{ color: 'black' }}>
                Messages: {messages}
              </Tag>
            </Col>
            <Col span={6}>
              <Tag color={GlobalColors.darkGray} style={{ color: 'white' }}>
                Price: {currency === 'USD' ? '$' : 'R'}
                {price}
              </Tag>
            </Col>
          </Row>
        </Col>
        <Col span={2}></Col>
      </Row>
    </div>
  );
};

export default MessagesPriceSlider;
