import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

const { Option } = Select;

const LastSeenFilter = () => {
  const dispatch = useDispatch();
  const { lastSeenHumanReadable } = useSelector((state) => state?.lastSeenFilter);

  const dateToHumanReadable = {
    'A day': 'day',
    '3 days': '3 days',
    'A week': 'week',
    'A month': 'month',
    '3 months': '3 months',
    'Six months': 'six months',
  };

  const convertToDateFormat = (value) => {
    const today = dayjs();
    switch (value) {
      case 'day':
        return today.subtract(1, 'day').format('YYYY-MM-DD');
      case '3 days':
        return today.subtract(3, 'day').format('YYYY-MM-DD');
      case 'week':
        return today.subtract(7, 'day').format('YYYY-MM-DD');
      case 'month':
        return today.subtract(1, 'month').format('YYYY-MM-DD');
      case '3 months':
        return today.subtract(3, 'month').format('YYYY-MM-DD');
      case 'six months':
        return today.subtract(6, 'month').format('YYYY-MM-DD');
      default:
        return null;
    }
  };

  const handleChange = (humanReadableValue) => {
    const dateValue = dateToHumanReadable[humanReadableValue];
    const date = convertToDateFormat(dateValue);
    dispatch({ type: 'SET_LAST_SEEN', data: { date, humanReadableValue } });
  };

  return (
    <div>
      <div>Last seen within</div>
      <Select
        style={{ width: '100%' }}
        size='middle'
        placeholder='Select last interaction'
        value={lastSeenHumanReadable || null}
        onChange={handleChange}
      >
        <Option value='A day'>A day</Option>
        <Option value='3 days'>3 days</Option>
        <Option value='A week'>A week</Option>
        <Option value='A month'>A month</Option>
        <Option value='3 months'>3 months</Option>
        <Option value='Six months'>Six months</Option>
      </Select>
    </div>
  );
};

export default LastSeenFilter;
