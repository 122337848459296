import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  Row,
  Col,
  Grid,
  Steps,
  Button,
  message,
  Collapse,
  Typography,
  Tag,
} from 'antd'
import getScreenSize from '../utils/getScreenSize'
import TemplateThumbnail from './TemplateThumbnail'
import PdfTemplateExplorer from './PdfTemplateExplorer'
import usePrivateAxios from '../hooks/usePrivateAxios'
import StatusNotification from './StatusNotification'

import { generateAndDownloadPdf } from '../utils/cloudinaryServices'
import LoadingIcon from './LoadingIcon'
import GlobalColors from '../assets/colors/GlobalColors'
import PromptBox from './PromptBox'
import fetchAndSetTemplate from '../utils/fetchAndSetTemplate'

const { useBreakpoint } = Grid
const { Panel } = Collapse
const { Title } = Typography

const HandleReport = () => {
  const { id: surveyId, islinked } = useParams()
  const [current, setCurrent] = useState(0)
  const {
    pdfmeTemplate: { selectedPdfMeTemplate, threadId },
    user: { organizationId },
  } = useSelector((state) => state)

  const [isLoading, setIsLoading] = useState(false)

  const contentGroups = selectedPdfMeTemplate?.contentGroups
    ? selectedPdfMeTemplate.contentGroups
    : []
  const thumbnails = selectedPdfMeTemplate?.thumbnails
    ? selectedPdfMeTemplate.thumbnails
    : []
  const templateName = selectedPdfMeTemplate?.name
    ? selectedPdfMeTemplate.name
    : ''

  const isTemplateLinked = islinked === 'true'

  const currentGroup = contentGroups?.length > 0 ? contentGroups[current] : null
  const currentThumbnail = thumbnails[current] || ''
  const currentGroupTitle = currentGroup ? `Group ${currentGroup.group}` : ''

  const screenSize = getScreenSize(useBreakpoint())
  const dispatch = useDispatch()
  const axiosPrivate = usePrivateAxios()
  const history = useHistory()

  useEffect(() => {
    if (isTemplateLinked) {
      fetchAndSetTemplate(axiosPrivate, organizationId, threadId).then(
        ({ mergedTemplate, error, type }) => {
          if (error) {
            if (type === 'parametersError') {
              // StatusNotification("error", "Error", "ThreadId cannot be empty");
              history.push(`/surveys/report/${surveyId}/false`)
              // return <Redirect to={`surveys/report/${surveyId}/false`} />;
            }
            if (type === 'templateError') {
              // StatusNotification(
              //   "error",
              //   "Error",
              //   "This linked template is not available anymore",
              // );
              // return <Redirect to={`surveys/report/${surveyId}/false`} />;
              history.push(`/surveys/report/${surveyId}/false`)
            } else if (type === 'surveyError') {
              StatusNotification(
                'error',
                'Error',
                'Looks like this survey is not linked to a template'
              )
              history.push(`/surveys/report/${surveyId}/false`)
            }
            return
          }
          dispatch({
            type: 'SET_SELECTED_PDFME_TEMPLATE',
            data: mergedTemplate,
          })
        }
      )
    }
  }, [
    isTemplateLinked,
    axiosPrivate,
    dispatch,
    organizationId,
    surveyId,
    threadId,
    history,
  ])

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleDone = async () => {
    try {
      setIsLoading(true)
      const response = await axiosPrivate.patch(
        `/api/surveys/mapped/${surveyId}`
      )
      StatusNotification('success', 'Template mapping completed')
      const surveyName = response.data.surveyName

      // Use the extracted function
      await generateAndDownloadPdf(
        axiosPrivate,
        surveyName,
        selectedPdfMeTemplate.id,
        organizationId,
        threadId
      )
    } catch (error) {
      console.error('error downloading pdf', error.message)
      StatusNotification('error', 'Download Failed', 'Please try again later')
    } finally {
      setIsLoading(false)
      history.push('/surveys')
    }
  }

  const collapseItems = currentGroup
    ? currentGroup.content.map((contentItem, index) => (
        <Panel
          header={
            <Row justify="space-between">
              <Col>{contentItem.title} </Col>
              <Col>
                <Tag
                  bordered={false}
                  color={
                    contentItem.type === 'text'
                      ? GlobalColors.darkGray
                      : 'volcano'
                  }
                  style={{ fontSize: 'smaller' }}
                >
                  {contentItem.type}
                </Tag>
                <Tag
                  bordered={false}
                  color={
                    contentItem.isMapped
                      ? GlobalColors.whatsappLime
                      : GlobalColors.darkGray
                  }
                >
                  {contentItem.isMapped ? 'Mapped' : 'Not Mapped'}
                </Tag>
              </Col>
            </Row>
          }
          key={contentItem._id || index}
        >
          <PromptBox
            type={contentItem.type}
            title={contentItem.title}
            message={contentItem.assistant?.message}
            isMapped={contentItem.isMapped}
            isManual={contentItem.assistant?.messageId === 'manual'}
            dispatch={dispatch}
            axiosPrivate={axiosPrivate}
            surveyId={surveyId}
            organizationId={organizationId}
            threadId={threadId}
            pdfmeTemplateId={selectedPdfMeTemplate.id}
          />
        </Panel>
      ))
    : []

  return (
    <div style={{ marginTop: screenSize !== 'xs' ? 30 : 0 }}>
      {isTemplateLinked ? (
        <Row gutter={[24, 24]} align="middle" justify="center">
          <Col span={20}>
            <Steps current={current} size="small">
              {contentGroups?.map((group, index) => (
                <Steps.Step
                  key={group._id || index}
                  title={`Group ${group.group}`}
                />
              ))}
            </Steps>
          </Col>
          <Col span={8}>
            {currentGroup && (
              <TemplateThumbnail
                surveyId={surveyId}
                title={currentGroupTitle}
                thumbnailUrl={currentThumbnail}
              />
            )}
          </Col>
          <Col span={14} style={{ marginTop: 16 }}>
            <Row justify="space-between">
              <Col span={24} style={{ textAlign: 'center' }}>
                <Title level={4} style={{ color: GlobalColors.darkGray }}>
                  {templateName}
                </Title>
              </Col>
              <Col span={24}>
                <Collapse defaultActiveKey={['0']} size="large">
                  {collapseItems}
                </Collapse>
              </Col>
              <Col span={24} style={{ marginTop: 16, textAlign: 'center' }}>
                {current < contentGroups?.length - 1 && (
                  <Button type="primary" onClick={next}>
                    Next
                  </Button>
                )}
                {current === contentGroups?.length - 1 && (
                  <Button type="primary" onClick={handleDone}>
                    {isLoading ? <LoadingIcon /> : 'Done'}
                  </Button>
                )}
                {current > 0 && (
                  <Button style={{ margin: '18px 18px' }} onClick={prev}>
                    Previous
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row style={{ height: '70vh' }}>
          <Col span={18}>
            <PdfTemplateExplorer />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default HandleReport
