const FetchDashboardData = (
  surveyId,
  organizationId,
  axiosPrivate,
  dispatch,
  isLiveEvent,
  tags = ['obsv']
) => {
  return new Promise((resolve, reject) => {
    const countResponsesURL = `/api/responses/${organizationId}/count`;
    const dashboardValuesURL = `/api/responses/dashboard`;

    const r1 = axiosPrivate.get(countResponsesURL);
    const r2 = axiosPrivate.post(dashboardValuesURL, {
      surveyId,
      organizationId,
      isLiveEvent,
      tags,
    });
    const r3 = surveyId
      ? axiosPrivate.post('/api/responses/charts', { surveyId, organizationId, isLiveEvent, tags })
      : Promise.resolve(null);

    Promise.all([r1, r2, r3])
      .then((response) => {
        let dashboardValues = response[1]?.data;
        // counters.responses = response[0]?.data;
        const chartsData = response[2]?.data;
        dispatch({ type: 'LOAD_DASHBOARD_VALUES', data: dashboardValues });
        console.log({ chartsData });
        if (chartsData) {
          dispatch({
            type: 'LOAD_THIRTY_DAYS_CHART_DATA',
            data: chartsData,
          });
        } else {
          dispatch({
            type: 'LOAD_THIRTY_DAYS_CHART_DATA',
            data: null,
          });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default FetchDashboardData;
