import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';

const ProfileDeletion = ({ isVisible, onCancel, onConfirm, userEmail }) => {
  const [confirmationEmail, setConfirmationEmail] = useState('');

  return (
    <Modal
      title='Delete account'
      open={isVisible}
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key='delete'
          type='primary'
          danger
          disabled={confirmationEmail !== userEmail}
          onClick={onConfirm}
        >
          Delete
        </Button>,
      ]}
    >
      <p>
        Before you proceed with deleting your account, please be aware of the following
        consequences:
      </p>
      <ul>
        <li>Permanent removal of all of your personal information and data.</li>
        <li>
          Since you are the sole member and admin of the organization, it will be permanently
          deleted along with all associated data.
        </li>
        <li>This action cannot be undone. If it's gone, it's gone.</li>
      </ul>
      <p>
        Please enter <strong>{userEmail}</strong> in the following field to confirm the definitive
        deletion of your account:
      </p>
      <Input
        value={confirmationEmail}
        onChange={(e) => setConfirmationEmail(e.target.value)}
        placeholder='Enter your email'
      />
    </Modal>
  );
};

export default ProfileDeletion;