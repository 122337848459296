import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space } from 'antd';
import { ProfileTwoTone } from '@ant-design/icons';
import NavBar from '../components/NavBar';

const { Paragraph, Text } = Typography;

const UpdatePasswordSuccess = () => {
  return (
    <>
      <NavBar />
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh', flexFlow: 'Column' }}
      >
        <Col span={8}></Col>
        <Col span={8} justify='center' align='middle'>
          <ProfileTwoTone twoToneColor='#966dad' style={{ fontSize: '80px' }} />
          <h2>Reset Your Password</h2>
          <Paragraph>
            <Text strong>Please check your email account for the password reset link</Text>
          </Paragraph>
          <Paragraph>
            Password reset email can take up to 30 minutes. If you have not received the reset email
            after 30 minutes, please check your spam folder, make sure you have entered the correct
            email address and, if needed, You can <Link to='/password/forgot'>resend password</Link>{' '}
            reset email.
          </Paragraph>
          <Space />
          <Paragraph>
            <Text type='secondary'>
              If you have still not received the confirmation email even after resending it and
              waiting for another 30 minutes, please contact our support :{' '}
              <Link href='mailto:support@surveyr.africa'>support@surveyr.africa</Link>
            </Text>
          </Paragraph>
          <Link to='/login'>Return to login</Link>
        </Col>
        <Col span={8}></Col>
      </Row>
    </>
  );
};

export default UpdatePasswordSuccess;
