import {
  FETCH_TAGS,
  SET_SELECTED_AND_TAGS,
  RESET_TAGS,
  SET_SELECTED_NOT_TAGS,
} from '../actions/types';

const initialTagFilterState = {
  allTags: [],
  andTags: [],
  notTags: [],
  availableNotTags: [], // Add a new state slice for tags available for NOT selection
};

const tagsFilterReducer = (state = initialTagFilterState, action) => {
  switch (action.type) {
    case FETCH_TAGS:
      return {
        ...state,
        allTags: action.data,
        availableNotTags: action.data, // Initialize availableNotTags with all tags
      };
    case SET_SELECTED_AND_TAGS: {
      const updatedAvailableNotTags = state.allTags.filter((tag) => !action.data.includes(tag));
      // Optionally, remove any notTags that are now in andTags
      const updatedNotTags = state.notTags.filter((tag) => !action.data.includes(tag));
      return {
        ...state,
        andTags: action.data,
        availableNotTags: updatedAvailableNotTags,
        notTags: updatedNotTags, // Update notTags to ensure it doesn't include any andTags
      };
    }
    case SET_SELECTED_NOT_TAGS:
      return {
        ...state,
        notTags: action.data,
      };
    case RESET_TAGS:
      return {
        ...state,
        andTags: [],
        notTags: [],
        availableNotTags: state.allTags, // Reset availableNotTags back to all tags
      };
    default:
      return state;
  }
};

export default tagsFilterReducer;