const formatScore = (score) => {
  if (score === null || score === undefined) {
    return 'N/A'; // Handling nulls and undefined inputs
  }

  // Check that the score is a valid number (this also implicitly checks for valid integers since all inputs are whole numbers)
  if (typeof score !== 'number' || isNaN(score)) {
    return 'Invalid'; // In case the score is not a number
  }

  if (score < 1000) {
    return score.toString(); // Return as is if less than 1000
  }

  const suffixes = ['', 'k', 'M', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(score) / 3); // Determine the suffix to use based on the number of digits
  const base = Math.pow(1000, suffixIndex); // Calculate the power of 1000 relevant to suffixIndex

  const formattedNumber = score / base;
  // Check if the formatted number is an integer. If not, format to one decimal place, otherwise no decimals
  const formattedScore =
    formattedNumber % 1 === 0 ? formattedNumber.toString() : formattedNumber.toFixed(1);

  return `${formattedScore}${suffixes[suffixIndex]}`;
};

export default formatScore;
