import axios from 'axios';


const BASE_URL =
  process.env.REACT_APP_ENV === 'local'
    ? process.env.REACT_APP_BACKEND_URL
    : process.env.REACT_APP_BASE_URL;


export default axios.create({
  baseURL: BASE_URL,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  cache: false,
  withCredentials: true,
});

export const axiosVanila = axios.create({
  baseURL: BASE_URL,
});
