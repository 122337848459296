/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import DashboardFilter from './components/tables/DashboardFilter';
import Surveys from './pages/Surveys';
import Broadcast from './pages/Broadcast';
import Contacts from './pages/Contacts';
import Templates from './pages/Templates';
import HandleSurvey from './components/HandleSurvey';
import HandleReport from './components/HandleReport';
import MapBox from './components/map/MapBox';
import LiveEvent from './components/event/LiveEvent';
import LiveEventPublic from './components/event/LiveEventPublic';
import HandleBroadcast from './components/HandleBroadcast';
import HandleContacts from './components/HandleContacts';
import HandleTemplate from './components/HandleTemplate';
import BroadcastStatusList from './components/BroadcastStatusList';
import BroadcastStatusStats from './components/BroadcastStatusStats';
import Billing from './pages/Billing';
import Rtl from './pages/Rtl';
import Settings from './pages/Settings';
import Integrations from './pages/Integrations';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ResendRegistrationConfirmation from './pages/ResendRegistrationConfirmation';
import ForgotPassword from './pages/ForgotPassword';
import UpdatePassword from './pages/UpdatePassword';
import Contact from './pages/Contact';
import PaymentNotification from './pages/PaymentNotification';
import Subscription from './components/Subscription';
import SubscriptionUpgrade from './components/SubscriptionUpgrade';
import UpdatePasswordSuccess from './pages/UpdatePasswordSuccess';
import Landing from './pages/Landing';
import RegistrationSuccess from './pages/RegistrationSuccess';
import Main from './components/layout/Main';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ProtectedRoute from './components/ProtectedRoute';
import HeroParticles from './components/HeroParticles';
import Floater from './components/Floater';
import ExitIntentModal from './components/ExitIntentModal';
import BlogList from './components/BlogList';
import Blog from './components/Blog';

import 'antd/dist/reset.css';
import './assets/styles/main.css';
import './assets/styles/responsive.css';
import './assets/styles/navbar.css';
import './assets/styles/landing.css';
import './assets/styles/mapbox.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store } from './store/store';
import checkLocalStoreStatus from './utils/checkLocalStoreStatus';
import { persistor, persistedStore } from './store/persistedStore';
import { Spin } from 'antd';
import TeamSignUp from './pages/TeamSignUp';

const isLocalStorageAvailable = checkLocalStoreStatus();

function App() {
  const loaderdiv = document.getElementById('loaderdiv');

  if (!isLocalStorageAvailable)
    console.error(':: redux localstorage disabled ::, app will not work properly');

  if (loaderdiv && !loaderdiv.hasAttribute('hidden')) {
    loaderdiv.setAttribute('hidden', 'true');
  }
  if (isLocalStorageAvailable) {
    return (
      <Provider store={persistedStore}>
        {/* <HeroParticles /> */}
        <Floater />
        <ExitIntentModal />
        <PersistGate loading={<Spin />} persistor={persistor}>
          <Router>
            <div className='App'>
              <Switch>
                <Route path='/signin' exact component={SignIn} />
                <Route path='/signin/new/:userId/:token' exact component={SignIn} />
                <Route path='/terms' exact component={TermsOfService} />
                <Route path='/privacy' exact component={PrivacyPolicy} />
                <Route path='/signup' exact component={SignUp} />
                <Route path='/accept-invite/:token' exact component={TeamSignUp} />
                <Route path='/success' exact component={RegistrationSuccess} />
                <Route path='/contact' exact component={Contact} />
                <Route exact path='/resend' component={ResendRegistrationConfirmation} />
                <Route exact path='/password/forgot' component={ForgotPassword} />
                <Route exact path='/password/success' component={UpdatePasswordSuccess} />
                <Route exact path='/password/reset/:userId/:token' component={UpdatePassword} />
                <Route
                  path='/public/live-event/:id/:surveyName/:organizationId'
                  exact
                  render={(props) => <LiveEventPublic {...props} type={'quiz'} />}
                />
                <Route path='/' exact component={Landing} />
                <Route
                  path='/public/ranking/:id/:surveyName/:organizationId/:rankableFieldIndexNumber'
                  exact
                  render={(props) => <LiveEventPublic {...props} type={'ranking'} />}
                />
                <Route path='/blogs/:_id' exact component={Blog} />
                <Route path='/blogs' exact component={BlogList} />
                <Route path='/' exact component={Landing} />
                <Main>
                  <ProtectedRoute>
                    <Route
                      path='/subscription-upgrade'
                      exact
                      render={(props) => <SubscriptionUpgrade {...props} />}
                    />
                  </ProtectedRoute>
                  <ProtectedRoute>
                    <Route
                      exact
                      //both cancel and complete will his the bellow slug and
                      //in the PaymentNotification component we just decided based on the slug
                      path='/subscription/:complete'
                      component={PaymentNotification}
                    />
                  </ProtectedRoute>

                  {/* <ProtectedRoute exact path='/subscription'>
                    <Subscription />
                  </ProtectedRoute> */}

                  {/* <Route exact path='/dashboard' component={Dashboard} /> */}
                  <ProtectedRoute exact path='/surveys'>
                    <Surveys />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/broadcasts'>
                    <Broadcast />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/contacts'>
                    <Contacts />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/templates'>
                    <Templates />
                  </ProtectedRoute>
                  <Route
                    path='/templates/create'
                    exact
                    render={(props) => <HandleTemplate {...props} action='create' />}
                  />
                  <Route
                    path='/surveys/create'
                    exact
                    render={(props) => <HandleSurvey {...props} action='create' />}
                  />
                  <Route
                    path='/surveys/edit'
                    exact
                    render={(props) => <HandleSurvey {...props} action='edit' />}
                  />
                  <Route
                    path='/surveys/report/:id/:islinked'
                    exact
                    render={(props) => <HandleReport {...props} action='create' />}
                  />
                  <Route
                    path='/surveys/mapbox/:id/:surveyName'
                    exact
                    render={(props) => <MapBox {...props} />}
                  />
                  <Route
                    path='/surveys/live-event/:id/:surveyName'
                    exact
                    render={(props) => <LiveEvent {...props} />}
                  />

                  <Route
                    path='/broadcasts/create'
                    exact
                    render={(props) => <HandleBroadcast {...props} action='create' />}
                  />
                  <Route
                    path='/broadcasts/status/sent'
                    exact
                    render={(props) => <BroadcastStatusList {...props} action='sent' />}
                  />
                  <Route
                    path='/broadcasts/status/delivered'
                    exact
                    render={(props) => <BroadcastStatusList {...props} action='delivered' />}
                  />
                  <Route
                    path='/broadcasts/status/read'
                    exact
                    render={(props) => <BroadcastStatusList {...props} action='read' />}
                  />
                  <Route
                    path='/broadcasts/status/stats'
                    exact
                    render={(props) => <BroadcastStatusStats {...props} action='stats' />}
                  />
                  <Route
                    path='/broadcasts/status/failed'
                    exact
                    render={(props) => <BroadcastStatusList {...props} action='failed' />}
                  />
                  <Route
                    path='/contacts/upload'
                    exact
                    render={(props) => <HandleContacts {...props} action='upload' />}
                  />
                  <Route
                    path='/contacts/create'
                    exact
                    render={(props) => <HandleContacts {...props} action='create' />}
                  />
                  <Route
                    path='/contacts/create'
                    exact
                    render={(props) => <HandleContacts {...props} action='edit' />}
                  />

                  <Route
                    path='/dashboard/filter'
                    exact
                    render={(props) => <DashboardFilter {...props} />}
                  />
                  <ProtectedRoute exact path='/billing'>
                    <Billing />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/settings'>
                    <Settings />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/integrations'>
                    <Integrations />
                  </ProtectedRoute>
                  <ProtectedRoute exact path='/dashboard'>
                    <Dashboard />
                  </ProtectedRoute>
                  {/* <Redirect from='*' to='/dashboard' /> */}
                  {/* <Route exact path='/survey' component={Surveys} /> */}
                  {/* <Route exact path='/billing' component={Billing} /> */}
                  {/* <Route exact path='/rtl' component={Rtl} /> */}
                  {/* <Route exact path='/profile' component={Profile} /> */}
                </Main>
              </Switch>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  } else {
    console.log('Store unavailable, ... no persistor will be used');
    return (
      <Provider store={store}>
        <div className='App'>
          <Switch>
            <Route path='/signup' exact component={SignUp} />
            <Route path='/signin' exact component={SignIn} />
            <Route path='/contact' exact component={Contact} />
            {/* <Route path='/signin/new/:userId/:token' exact component={SignIn} />
            <Route path='/success' exact component={RegistrationSuccess} />
            <Route exact path='/resend' component={ResendPassword} />
            <Route exact path='/password/forgot' component={ForgotPassword} /> */}
            <Route path='/privacy' exact component={PrivacyPolicy} />
            <Route path='/terms' exact component={TermsOfService} />
            <Route exact path='/password/success' component={UpdatePasswordSuccess} />
            <Route exact path='/password/reset/:userId/:token' component={UpdatePassword} />
            <Route
              exact
              strict
              path='/email/verify/:userId/:token'
              component={ResendRegistrationConfirmation}
            />
            <Main>
              <Route exact path='/dashboard' component={Dashboard} />
              <Route exact path='/surveys/create' component={HandleSurvey} />
              <Route exact path='/surveys/report' component={HandleReport} />
              <Route exact path='/surveys' component={Surveys} />
              <Route exact path='/billing' component={Billing} />
              <Route exact path='/rtl' component={Rtl} />
              <Route exact path='/settings' component={Settings} />
              <Route exact path='/integrations' component={Integrations} />
              {/* <Redirect from='*' to='/dashboard' /> */}
            </Main>
          </Switch>
        </div>
      </Provider>
    );
  }
}

export default App;
