import { NOTIFICATION } from '../actions/types';

const initialState = { data: {} }

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION:
            return {
                ...state,
                data: action.data
            };
        default:
            return state;
    }
}

export default notificationReducer;