// TableSkeleton.jsx
import React from 'react';
import { Skeleton } from 'antd';

const SurveyTableSkeleton = () => {
  return (
    <div>
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px 24px',
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <Skeleton.Input style={{ width: '50px', height: '20px' }} active />
          <Skeleton.Input style={{ width: '200px', height: '20px' }} active />
          <Skeleton.Button style={{ width: '100px', height: '20px' }} active />
          <Skeleton.Button style={{ width: '70px', height: '20px' }} active />
          <Skeleton.Input style={{ width: '100px', height: '20px' }} active />
          <Skeleton.Button style={{ width: '80px', height: '20px' }} active />
        </div>
      ))}
    </div>
  );
};

export default SurveyTableSkeleton;
