import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';

import Navbar from '../components/NavBar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Pricing from '../components/Pricing';
import UseCases from '../components/UseCases';
import Testimonials from '../components/Testimonials';
import Footer from '../components/layout/Footer';
import ConsentMessage from '../components/ConsentMessage';

const { Content } = Layout;

const Landing = () => {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) history.push('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <div className='layout-default  layout-sign-up'>
        <Navbar />
        <Content className='p-0'>
          <Row gutter={[0, 34]}>
            <Col span={24}>
              <Hero />
            </Col>
            <Col span={24}>
              <Testimonials />
            </Col>
            <Col span={24}>
              <Features />
            </Col>
            <Col span={24}>
              <Pricing isFront={true} />
            </Col>
            <Col span={24}>
              <UseCases />
            </Col>
            <Col span={24}>
              <ConsentMessage />
              <Footer />
            </Col>
          </Row>

          {/* <Row gutter={[16, 16]} style={{}} align='middle' justify='center'>
            <Col style={{ height: '290px' }}>
              <Card
                className='card-signup header-solid h-full ant-card pt-0'
                title={<h5></h5>}
                bordered='false'
                style={{ width: '300px', backgroundColor: 'palegreen' }}
              ></Card>
            </Col>
            <Col style={{ height: '290px' }}>
              <Card
                className='card-signup header-solid h-full ant-card pt-0'
                title={<h5></h5>}
                bordered='false'
                style={{ width: '300px', backgroundColor: 'palegreen' }}
              ></Card>
            </Col>
          </Row> */}
        </Content>
        {/* <Footer>
          <Menu mode='horizontal'>
            <Menu.Item>Company</Menu.Item>
            <Menu.Item>About Us</Menu.Item>
            <Menu.Item>Teams</Menu.Item>
            <Menu.Item>Products</Menu.Item>
            <Menu.Item>Blogs</Menu.Item>
            <Menu.Item>Pricing</Menu.Item>
          </Menu>
          <Menu mode='horizontal' className='menu-nav-social'>
            <Menu.Item>
              <Link to='#'>{<DribbbleOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='#'>{<TwitterOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='#'>{<InstagramOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='#'>
                <svg
                  width='18'
                  height='18'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 512 512'
                >
                  <path d='M496 256c0 137-111 248-248 248-25.6 0-50.2-3.9-73.4-11.1 10.1-16.5 25.2-43.5 30.8-65 3-11.6 15.4-59 15.4-59 8.1 15.4 31.7 28.5 56.8 28.5 74.8 0 128.7-68.8 128.7-154.3 0-81.9-66.9-143.2-152.9-143.2-107 0-163.9 71.8-163.9 150.1 0 36.4 19.4 81.7 50.3 96.1 4.7 2.2 7.2 1.2 8.3-3.3.8-3.4 5-20.3 6.9-28.1.6-2.5.3-4.7-1.7-7.1-10.1-12.5-18.3-35.3-18.3-56.6 0-54.7 41.4-107.6 112-107.6 60.9 0 103.6 41.5 103.6 100.9 0 67.1-33.9 113.6-78 113.6-24.3 0-42.6-20.1-36.7-44.8 7-29.5 20.5-61.3 20.5-82.6 0-19-10.2-34.9-31.4-34.9-24.9 0-44.9 25.7-44.9 60.2 0 22 7.4 36.8 7.4 36.8s-24.5 103.8-29 123.2c-5 21.4-3 51.6-.9 71.2C65.4 450.9 0 361.1 0 256 0 119 111 8 248 8s248 111 248 248z'></path>
                </svg>
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='#'>{<GithubOutlined />}</Link>
            </Menu.Item>
          </Menu>
          <p className='copyright'>
            {' '}
            Copyright © 2021 Muse by <a href='#pablo'>Creative Tim</a>.{' '}
          </p>
        </Footer> */}
      </div>
    </>
  );
};

export default Landing;
