const reformatValues = (values, mediaType, templateMediaUrl) => {
  const parametersByType = {};
  const buttonComponents = [];

  for (const [key, value] of Object.entries(values)) {
    if (key === 'template') continue;

    const [type, subtype, index] = key.split('_');
    const lowerType = type.toLowerCase();

    if (lowerType === 'buttons') {
      let buttonValue = value;
      const buttonType = value.startsWith('http') ? 'text' : 'payload';
      const subType = buttonType === 'text' ? 'url' : 'quick_reply';

      if (buttonType === 'text') {
        buttonValue = value.replace('https://link.surveyr.africa/', '');
      }

      buttonComponents.push({
        type: 'button',
        sub_type: subType,
        index: parseInt(index, 10),
        parameters: [
          {
            type: buttonType,
            [buttonType]: buttonValue,
          },
        ],
      });
      continue;
    }

    if (!parametersByType[lowerType]) parametersByType[lowerType] = [];

    if (subtype === 'fileName') {
      const existingMedia = parametersByType[lowerType].find((param) => param.type === mediaType);
      if (existingMedia) {
        existingMedia[mediaType].filename = value;
      } else {
        parametersByType[lowerType].push({
          type: mediaType,
          [mediaType]: {
            filename: value,
          },
        });
      }
    } else if (subtype === 'url') {
      parametersByType[lowerType].push({
        type: 'url',
        url: value,
      });
    } else {
      parametersByType[lowerType].push({
        type: 'text',
        text: value,
      });
    }
  }

  if (templateMediaUrl && mediaType) {
    const existingMedia =
      parametersByType['header'] &&
      parametersByType['header'].find((param) => param.type === mediaType);
    if (existingMedia) {
      existingMedia[mediaType].link = templateMediaUrl;
    } else {
      if (!parametersByType['header']) parametersByType['header'] = [];
      parametersByType['header'].push({
        type: mediaType,
        [mediaType]: {
          link: templateMediaUrl,
        },
      });
    }
  }

  const components = Object.entries(parametersByType).map(([type, parameters]) => ({
    type,
    parameters,
  }));

  return [...components, ...buttonComponents];
};

export default reformatValues;
