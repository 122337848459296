import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Col, Row, Typography, Grid, Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import LoadingIcon from '../components/LoadingIcon';
import BarChart from '../components/chart/BarChart';
import LineChart from '../components/chart/LineChart';
import PieChart from '../components/chart/PieChart';
import GlobalColors from '../assets/colors/GlobalColors';
import isObjectEmpty from '../utils/isObjectEmpty';
import SentimentBarChart from '../components/SentimentBarChart';
import Tour from '../components/Tour';
import usePrivateAxios from '../hooks/usePrivateAxios';
import FreeTextResponses from '../components/FreeTextResponses';
import {
  SelectOutlined,
  FileDoneOutlined,
  PauseOutlined,
  StopOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import GettingStartedVideo from '../components/GettingStartedVideo';
import getScreenSize from '../utils/getScreenSize';
import PieChartSkeleton from '../components/skeletons/PieChartSkeleton';

import tourYouTube from '../assets/images/tour-youtube.png';
import surveyrIcon from '../assets/images/surveyr-logo-icon.png';
import { Cursor } from 'mongoose';

const { useBreakpoint } = Grid;
const { Title } = Typography;

function Dashboard() {
  const [isPieChartAvailable, setIsPieChartAvailable] = useState(false);

  const location = useLocation();
  const isLiveEvent = location?.isLiveEvent;

  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();
  const history = useHistory();

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const {
    dashboard: {
      dashboardValues,
      chartsData: { pieChart, lineChart, barChart },
    },
    user: { organizationId },
    loading: { isLoading },
    surveysData: {
      activeSurvey: { surveyId },
      surveyName,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pieChart && !isObjectEmpty(pieChart?.data)) setIsPieChartAvailable(true);
  }, [pieChart]);

  useEffect(() => {
    dispatch({ type: 'SET_IS_LIVE_EVENT_IN_PROGRESS', data: false });
    dispatch({ type: 'SET_IS_QUIZ_IN_PROGRESS', data: false });
  }, [dispatch]);

  const handleCardClick = (path) => {
    history.push(path);
  };

  const count = [
    {
      today: 'Started',
      title: !isNaN(dashboardValues?.completed?.number + dashboardValues?.notCompleted?.inProgress)
        ? dashboardValues?.completed?.number + dashboardValues?.notCompleted?.inProgress
        : '',
      icon: <SelectOutlined />,
      bnb: 'bnb2',
      // path: '/dashboard', // Add path for navigation
    },
    {
      today: 'Completed',
      title: !isNaN(dashboardValues?.completed?.number) ? dashboardValues?.completed?.number : '',
      persent:
        typeof dashboardValues?.completed?.percentage === 'number'
          ? dashboardValues?.completed?.percentage + '%'
          : '',
      icon: <FileDoneOutlined />,
      bnb: 'bnb2',
      // path: '/dashboard', // Add path for navigation
    },
    {
      today: 'In Progress',
      title: dashboardValues?.notCompleted?.inProgress,
      icon: <PauseOutlined color={GlobalColors.darkGray} />,
      bnb: 'bnb2',
      path: '/dashboard/filter',
      cursor: 'pointer', // Add path for navigation
    },
    {
      today: 'Not Started',
      title: dashboardValues?.notCompleted?.number,
      icon: <StopOutlined color={GlobalColors.darkGray} />,
      bnb: 'bnb2',
      path: '/dashboard/filter',
      cursor: 'pointer', // Add path for navigation
    },
  ];

  return (
    <>
      <div className='layout-content' style={{ marginTop: 20 }}>
        <Row className='rowgap-vbox' gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
              <Card
                bordered={false}
                className='criclebox '
                onClick={() => handleCardClick(c.path)}
                style={{ cursor: c.cursor }}
              >
                <div className='number'>
                  <Row align='middle' gutter={[24, 0]}>
                    {isLoading && !dashboardValues ? (
                      <LoadingIcon />
                    ) : (
                      <>
                        <Col xs={18}>
                          <span>{c.today}</span>
                          <Title level={3}>
                            {c.title}
                            <small className={c.bnb}>{!isLoading && c.persent}</small>
                          </Title>
                        </Col>
                        <Col xs={6}>
                          <div className='icon-box'>{c.icon}</div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[12, 0]}>
          <Col xs={24} sm={24} md={12} className='mb-24'>
            <Card bordered={false} className='criclebox h-full' style={{ textAlign: 'center' }}>
              {isLoading && !isPieChartAvailable ? (
                <PieChartSkeleton />
              ) : isPieChartAvailable ? (
                <PieChart />
              ) : (
                <Row
                  justify='center'
                  align='middle'
                  style={{
                    height: '55vh',
                  }}
                  id={screenSize === 'xs' ? 'startingPoint' : 'NotStartingPoint'}
                >
                  <Col>
                    <Title level={4} style={{ color: GlobalColors.darkGray }}>
                      You must first create a survey to see relevant data,{' '}
                      <GettingStartedVideo screenSize={screenSize} />
                    </Title>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} className='mb-24'>
            <Card bordered={false} className='criclebox h-full' style={{ textAlign: 'center' }}>
              {isLoading && isObjectEmpty(lineChart) ? (
                <LoadingIcon color={GlobalColors.darkGray} />
              ) : (
                <FreeTextResponses
                  screenSize={screenSize}
                  surveyId={surveyId}
                  organizationId={organizationId}
                  surveyName={surveyName}
                />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} className='mb-24'>
            <Card bordered={false} className='criclebox h-full' style={{ textAlign: 'center' }}>
              {isLoading && isObjectEmpty(lineChart) ? (
                <LoadingIcon color={GlobalColors.darkGray} />
              ) : (
                <LineChart />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} className='mb-24'>
            <Card bordered={false} className='criclebox h-full' style={{ textAlign: 'center' }}>
              {isLoading && isObjectEmpty(barChart) ? (
                <LoadingIcon color={GlobalColors.darkGray} />
              ) : (
                <BarChart />
              )}
            </Card>
          </Col>
        </Row>
      </div>
      <Tour />
    </>
  );
}

export default Dashboard;
