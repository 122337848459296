import ChartColors from '../../../assets/colors/ChartColors';

const pieChart = {
  series: [],

  options: {
    chart: {
      width: '100%',
      type: 'pie',
    },
    labels: [],
    colors: ChartColors,
    tooltip: {},
    responsive: [
      {
        breakpoint: 320,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 375,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 425,
        options: {
          chart: {
            height: 400,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 600,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -25,
        },
      },
    },
    dataLabels: {
      // offsetY: -10,
      formatter(val, opts) {
        // const name = opts.w.globals.labels[opts.seriesIndex];
        // const total = opts.w.globals.seriesTotals[opts.seriesIndex];
        return [val.toFixed(1) + '%'];
      },
      textAnchor: 'middle',

      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 700,
        colors: ['#f4f4f4', '#898989', '#898989', '#f4f4f4', '#f4f4f4', '#5c5b5b', '#5c5b5b', '#898989'],
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.15,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      fontSize: '24px',
    },
  },
};

export default pieChart;
