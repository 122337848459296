const Emojis = {
  "grinning": "😀",
  "grin": "😁",
  "joy": "😂",
  "smiley": "😃",
  "smile": "😄",
  "sweat_smile": "😅",
  "laughing": "😆",
  "satisfied": "😆",
  "innocent": "😇",
  "smiling_imp": "😈",
  "wink": "😉",
  "blush": "😊",
  "yum": "😋",
  "relieved": "😌",
  "heart_eyes": "😍",
  "sunglasses": "😎",
  "smirk": "😏",
  "neutral_face": "😐",
  "expressionless": "😑",
  "unamused": "😒",
  "sweat": "😓",
  "pensive": "😔",
  "confused": "😕",
  "confounded": "😖",
  "kissing": "😗",
  "kissing_heart": "😘",
  "kissing_smiling_eyes": "😙",
  "kissing_closed_eyes": "😚",
  "stuck_out_tongue": "😛",
  "stuck_out_tongue_winking_eye": "😜",
  "stuck_out_tongue_closed_eyes": "😝",
  "disappointed": "😞",
  "worried": "😟",
  "angry": "😠",
  "rage": "😡",
  "cry": "😢",
  "persevere": "😣",
  "triumph": "😤",
  "disappointed_relieved": "😥",
  "frowning": "😦",
  "anguished": "😧",
  "fearful": "😨",
  "weary": "😩",
  "sleepy": "😪",
  "tired_face": "😫",
  "grimacing": "😬",
  "sob": "😭",
  "face_exhaling": "😮‍💨",
  "open_mouth": "😮",
  "hushed": "😯",
  "cold_sweat": "😰",
  "scream": "😱",
  "astonished": "😲",
  "flushed": "😳",
  "sleeping": "😴",
  "face_with_spiral_eyes": "😵‍💫",
  "dizzy_face": "😵",
  "face_in_clouds": "😶‍🌫️",
  "no_mouth": "😶",
  "mask": "😷",
  "smile_cat": "😸",
  "joy_cat": "😹",
  "smiley_cat": "😺",
  "heart_eyes_cat": "😻",
  "smirk_cat": "😼",
  "kissing_cat": "😽",
  "pouting_cat": "😾",
  "crying_cat_face": "😿",
  "scream_cat": "🙀",
  "slightly_frowning_face": "🙁",
  "slightly_smiling_face": "🙂",
  "upside_down_face": "🙃",
  "face_with_rolling_eyes": "🙄",
  "woman-gesturing-no": "🙅‍♀️",
  "no_good": "🙅‍♀️",
  "man-gesturing-no": "🙅‍♂️",
  "woman-gesturing-ok": "🙆‍♀️",
  "ok_woman": "🙆‍♀️",
  "man-gesturing-ok": "🙆‍♂️",
  "woman-bowing": "🙇‍♀️",
  "man-bowing": "🙇‍♂️",
  "bow": "🙇‍♂️",
  "see_no_evil": "🙈",
  "hear_no_evil": "🙉",
  "speak_no_evil": "🙊",
  "woman-raising-hand": "🙋‍♀️",
  "raising_hand": "🙋‍♀️",
  "man-raising-hand": "🙋‍♂️",
  "raised_hands": "🙌",
  "woman-frowning": "🙍‍♀️",
  "person_frowning": "🙍‍♀️",
  "man-frowning": "🙍‍♂️",
  "woman-pouting": "🙎‍♀️",
  "person_with_pouting_face": "🙎‍♀️",
  "man-pouting": "🙎‍♂️",
  "pray": "🙏",
  "rocket": "🚀",
  "helicopter": "🚁",
  "steam_locomotive": "🚂",
  "railway_car": "🚃"
}

export default Emojis;