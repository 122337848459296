import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Space } from 'antd';
import { Field } from 'rc-field-form';
import Media from './Media';
import shortenUrl from '../utils/shortenUrl';
import isValidUrl from '../utils/isValidUrl';
import StatusNotification from './StatusNotification';
import { ShrinkOutlined } from '@ant-design/icons';
import ValidatePlaceholderInputs from './ValidatePlaceholderInputs';
import usePrivateAxios from '../hooks/usePrivateAxios';

const GetPlaceholderInputs = ({ component, onInputChange, templateName, form }) => {
  const { type, format, text, buttons, example } = component;
  const {
    // template: { selectedTemplate },
    user: { organizationId },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const axios = usePrivateAxios();
  useEffect(() => {
    if (buttons) {
      buttons.forEach((button, index) => {
        if (button.type === 'QUICK_REPLY' && /Stop|Mute/i.test(button.text)) {
          const fieldName = `${type}_button_${index}`;
          form.setFieldsValue({ [fieldName]: button.text });
        }
      });
    }
  }, [buttons, type, form]);

  const handleShortenUrl = async (index) => {
    const fieldName = `BODY_url_${index}`;
    const urlValue = form.getFieldValue(fieldName);
    try {
      const { isSuccess, payload, urlId } = await shortenUrl(urlValue, axios);
      if (isSuccess) {
        form.setFieldsValue({
          [fieldName]: payload,
        });
        onInputChange(type, `url_${index}`, payload);
        dispatch({ type: 'SET_SHORT_URL_ID', data: urlId });
        StatusNotification('success', 'URL shortened successfully');
      } else {
        StatusNotification(
          'error',
          'Error shortening URL',
          'Please try again, if it fails again, contact support'
        );
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Error shortening URL',
        'Please try again, if it fails again, contact support'
      );
    }
  };

  if (type === 'HEADER') {
    if (format === 'DOCUMENT') {
      return (
        <>
          {format === 'DOCUMENT' && (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Field
                  name={`${type}_fileName`}
                  rules={[
                    {
                      pattern: /^(?![^\s]+\.\w+$)(?!.*\.$).+$/,
                      message: 'Do not include file extension or end with a dot!',
                    },
                    { required: true, message: 'file name is required!' },
                  ]}
                >
                  {(control, meta) => (
                    <div>
                      <Input
                        {...control}
                        placeholder='File Name'
                        onChange={(e) => {
                          form.setFieldsValue({
                            [`${type}_fileName`]: e.target.value,
                          });
                          onInputChange(type, 'fileName', e.target.value);
                        }}
                      />
                      {meta.errors.length > 0 && (
                        <div style={{ color: 'red' }}>{meta.errors[0]}</div>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </>
          )}
          <Media fileName={templateName} organizationId={organizationId} usage={'Template'} />
        </>
      );
    } else if (format === 'IMAGE') {
      return <Media fileName={templateName} organizationId={organizationId} usage={'Template'} />;
    } else if (format === 'TEXT' && text) {
      const placeholderRegex = /{{\d+}}/g;
      const placeholders = text.match(placeholderRegex) || [];
      return placeholders.map((placeholder, index) => {
        const adjustedIndex = parseInt(placeholder.replace(/[{{}}]/g, ''), 10) - 1;
        const fieldName = `${type}_${adjustedIndex}`;
        return (
          <div style={{ marginBottom: '10px' }}>
            <Field
              key={adjustedIndex}
              name={fieldName}
              rules={[{ required: true, message: 'This field is very required!' }]}
            >
              {(control, meta) => {
                return (
                  <div>
                    <Input
                      {...control}
                      placeholder={placeholder}
                      onChange={(e) => {
                        form.setFieldsValue({ [fieldName]: e.target.value });
                        onInputChange(type, adjustedIndex, e.target.value);
                      }}
                    />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                );
              }}
            </Field>
          </div>
        );
      });
    }
  }

  if (type === 'BODY' && text) {
    const placeholderRegex = /{{\d+}}/g;
    const urlPlaceholderRegex = /{{url}}/g; // Define the regex for {{url}}
    const placeholders = text.match(placeholderRegex) || [];
    const urlPlaceholders = text.match(urlPlaceholderRegex) || []; // Match {{url}} placeholder

    return (
      <>
        {placeholders.map((placeholder, index) => {
          const adjustedIndex = parseInt(placeholder.replace(/[{{}}]/g, ''), 10) - 1;
          const fieldName = `${type}_${adjustedIndex}`;
          return (
            <div key={fieldName} style={{ marginBottom: '10px' }}>
              <Field
                name={fieldName}
                rules={[{ required: true, message: 'This field is very required!' }]}
              >
                {(control, meta) => (
                  <div>
                    <Input
                      {...control}
                      placeholder={placeholder}
                      onChange={(e) => {
                        form.setFieldsValue({ [fieldName]: e.target.value });
                        onInputChange(type, adjustedIndex, e.target.value);
                      }}
                    />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                )}
              </Field>
            </div>
          );
        })}

        {urlPlaceholders.map((placeholder, index) => {
          const fieldName = `${type}_url_${index}`;
          console.log({ urlPlaceholders, fieldName });
          return (
            <div key={fieldName} style={{ marginBottom: '10px' }}>
              <Field
                name={fieldName}
                rules={[
                  {
                    required: true,
                    message: 'Example URL is required!',
                  },
                  {
                    type: 'url',
                    message: 'Please insert a valid URL!',
                  },
                ]}
              >
                {(control, meta) => {
                  return (
                    <div>
                      <Space.Compact
                        style={{
                          width: '100%',
                        }}
                      >
                        <Input
                          {...control}
                          placeholder='Enter example URL for {{url}}'
                          size='small'
                        />
                        <Button
                          onClick={() => handleShortenUrl(index)}
                          disabled={!isValidUrl(form.getFieldValue(fieldName))}
                          style={{ height: 40 }}
                        >
                          <ShrinkOutlined
                            style={{
                              cursor: !isValidUrl(form.getFieldValue(fieldName))
                                ? 'not-allowed'
                                : 'pointer',
                              fontSize: 19,
                              paddingTop: 6,
                              paddingLeft: 4,
                            }}
                          />
                        </Button>
                      </Space.Compact>
                      {meta.errors.length > 0 && (
                        <div style={{ color: 'red' }}>{meta.errors[0]}</div>
                      )}
                    </div>
                  );
                }}
              </Field>
            </div>
          );
        })}
      </>
    );
  }

  if (buttons) {
    return buttons.map((button, index) => {
      let message = '';

      if (button.type === 'URL') {
        message = 'URL must be provided';
      } else {
        message = `Quick Reply response text for [ ${button.text} ] is required`;
      }

      if (button.type === 'QUICK_REPLY') {
        const fieldName = `${type}_button_${index}`;
        if (!/Stop|Mute/i.test(button.text)) {
          const placeholderText = `Please provide response message to be sent to the respondent when ${button.text} is pressed`;
          return (
            <div style={{ marginBottom: '10px' }}>
              <Field key={index} name={fieldName} rules={[{ required: true, message }]}>
                {(control, meta) => (
                  <div>
                    <Input.TextArea
                      {...control}
                      placeholder={placeholderText}
                      maxLength={100}
                      showCount={true}
                    />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                )}
              </Field>
            </div>
          );
        }
        return null;
      }

      console.log(button);
      if (button?.type === 'URL')
        if (button?.url?.includes('{{1}}')) {
          return (
            <div style={{ marginBottom: '10px', marginTop: 24 }}>
              <Field
                key={index}
                name={`${type}_button_${index}`}
                rules={[
                  { required: true, message },
                  {
                    type: 'url',
                    message: 'Please insert a valid URL!',
                  },
                ]}
              >
                {(control, meta) => (
                  <div>
                    <Input {...control} placeholder={'https://link.surveyr.africa/{{1}}'} />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </div>
                )}
              </Field>
            </div>
          );
        }
    });
  }

  if (example && example.header_handle) {
    return (
      <div style={{ marginBottom: '10px' }}>
        <Field
          name={`${type}_image`}
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          {(control, meta) => (
            <div>
              <Input {...control} placeholder='Image URL' />
              {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
            </div>
          )}
        </Field>
      </div>
    );
  }

  return null;
};

export default GetPlaceholderInputs;
