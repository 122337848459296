import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';

// const initialState = {};
const middleware = [thunk]; //array, for other middleware like logger

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const persistedStore = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
  
);

const persistor = persistStore(persistedStore);
export { persistedStore, persistor };
