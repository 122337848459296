import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Card, Tag, Table, Grid, Tooltip, Input, Select, Space } from 'antd';
import { UserOutlined, DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';

import GlobalColors from '../../assets/colors/GlobalColors';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import StatusNotification from '../../components/StatusNotification';
import getScreenSize from '../../utils/getScreenSize';
import FetchDashboardData from '../../components/FetchDashboardData';

const { useBreakpoint } = Grid;

const DashboardFilter = () => {
  const axiosPrivate = usePrivateAxios();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchedTag, setSearchedTag] = useState(['obsv']);
  const [searchedName, setSearchedName] = useState('');

  const {
    user: { organizationId },
    surveysData: { activeSurvey: {surveyId} },
    dashboard: {
      dashboardValues,
      chartsData: { tags },
    },
  } = useSelector((state) => state);

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const fetchData = (tags) => {
    FetchDashboardData(surveyId, organizationId, axiosPrivate, dispatch, false, tags)
      .then((response) => {
        if (response[1]?.data?.tags) {
          let dashboardValues = response[1]?.data;
          dispatch({
            type: 'LOAD_DASHBOARD_VALUES',
            data: dashboardValues,
          });
        }
      })
      .catch((error) => {
        StatusNotification('error', 'Fetch Failed', error?.response?.data?.message);
      });
  };

  const handleTagChange = (value) => {
    // Ensure 'obsv' is always included
    if (!value.includes('obsv')) {
      value.push('obsv');
    }
    setSearchedTag(value);
    fetchData(value);
  };

  const filteredRespondents = dashboardValues?.notCompleted?.details
    ? dashboardValues?.notCompleted?.details.filter((respondent) => {
        if (searchedName) {
          const lowercasedName = searchedName.toLowerCase();
          if (!respondent.name.toLowerCase().includes(lowercasedName)) {
            return false;
          }
        }
        return true;
      })
    : [];

  const columns = [
    {
      title: '#',
      key: 'counter',
      width: '4%',
      responsive: ['lg'],
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '16%',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cell Number',
      dataIndex: 'cellnumber',
      key: 'cellnumber',
      width: '16%',
      align: 'center',
      sorter: (a, b) => a.cellnumber.localeCompare(b.cellnumber),
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const downloadCsv = () => {
    const csvData = filteredRespondents.map((respondent) => ({
      name: respondent.name,
      cellnumber: respondent.cellnumber,
    }));

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'respondents.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  console.log({ tags });
  return (
    <div style={{ marginBottom: 10, marginTop: screenSize !== 'xs' ? 78 : 0 }}>
      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            className='criclebox tablespace mb-24'
            style={{ padding: 12 }}
            title={
              <Row justify='space-between'>
                <Col push={1} span={24}>
                  <Row justify='space-between'>
                    <Col span={12}>
                      <UserOutlined
                        style={{
                          fontSize: 24,
                          color: GlobalColors.lightPurple,
                          paddingRight: 8,
                        }}
                      />
                      <Tag
                        color={GlobalColors.lightPurple}
                        style={{ cursor: 'pointer', fontSize: 18 }}
                      >
                        {filteredRespondents.length}
                      </Tag>
                    </Col>
                    <Col pull={2} span={12} style={{ textAlign: 'right' }}>
                      <Space>
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={'download list'}
                        >
                          <span
                            className='icon'
                            style={{ cursor: 'pointer' }}
                            onClick={downloadCsv}
                          >
                            <DownloadOutlined
                              style={{ fontSize: 24, color: GlobalColors.lightPurple }}
                            />
                          </span>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={22}>
                  <Row justify='center'>
                    <Col xs={24} md={6}>
                      <Input
                        type='text'
                        placeholder='Search by name'
                        className='field-item form-field'
                        style={{ lineHeight: 10, height: 32 }}
                        value={searchedName}
                        onChange={(e) => setSearchedName(e.target.value)}
                      />
                    </Col>
                    <Col xs={24} md={6}>
                      <Select
                        mode='tags'
                        size='middle'
                        style={{
                          width: '100%',
                          borderRadius: '6px',
                        }}
                        placeholder='Select tags'
                        className='table-search'
                        value={searchedTag}
                        onChange={handleTagChange}
                      >
                        {dashboardValues?.completed?.tags?.map((tag) => (
                          <Select.Option key={tag} value={tag} disabled={tag === 'obsv'}>
                            {tag}
                          </Select.Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          >
            <Table
              rowKey={(record) => record.respondentId}
              columns={columns}
              dataSource={filteredRespondents}
              pagination={{
                size: 'small',
                position: ['bottomCenter'],
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardFilter;
