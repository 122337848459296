import React from 'react';
import { Row, Col, Typography, Image } from 'antd';
import SectionHeader from './SectionHeader';
import { ClipboardList, Megaphone, BarChart2, Bot, Users, Radio } from 'lucide-react';
import GlobalColors from '../assets/colors/GlobalColors';
import FeaturesVideo from './FeaturesVideo';

const { Title, Text } = Typography;

const Features = () => {
  const features = [
    {
      icon: <ClipboardList size={40} color={GlobalColors.lightPurple} />,
      title: 'Intuitive Survey Builder',
      description:
        'The survey builder is very easy to use and intuitive, supporting rich text and emojis. 😀',
    },
    {
      icon: <Radio size={40} color={GlobalColors.lightPurple} />,
      title: 'Boosting Live Events',
      description:
        'Enhance live events with interactive quizzes and engagements for a dynamic audience experience.',
    },
    {
      icon: <Megaphone size={40} color={GlobalColors.lightPurple} />,
      title: 'WhatsApp Broadcast',
      description:
        'Reach both small and large crowds with WhatsApp broadcast messages, ensuring broad communication coverage.',
    },
    {
      icon: <BarChart2 size={40} color={GlobalColors.lightPurple} />,
      title: 'Sentiment Analysis',
      description:
        'Gain insights into audience reactions with sentiment analysis, understanding the emotional undertones of responses.',
    },
    {
      icon: <Bot size={40} color={GlobalColors.lightPurple} />,
      title: 'ChatGPT Report Authoring',
      description:
        'Import beautifully designed Canva templates to create stunning reports, simplifying the documentation and analysis workflow.',
    },
    {
      icon: <Users size={40} color={GlobalColors.lightPurple} />,
      title: 'TeamBox',
      description:
        'Manage large volumes of interactions with TeamBox, where human agents provide personalized responses.',
    },
  ];

  return (
    <div id='features' className='containerRadius container' style={{ backgroundColor: '#dfdfdf' }}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SectionHeader
            title='Features'
            isFront={true}
            subtitle='Surveyr is WhatsApp driven, Making it an easy and efficient way to engage with your audience'
          />
        </Col>
        <Col span={24}>
          <FeaturesVideo />
        </Col>
        <Col span={24} style={{ marginBottom: 28 }}>
          <Row gutter={[32, 32]} justify='space-between'>
            {features.map((feature, index) => (
              <Col xs={24} md={7} className='blockStyle' key={index}>
                <Row style={{ padding: 12 }}>
                  <Col span={24}>{feature.icon}</Col>
                  <Col span={24}>
                    <Title level={3} className='featuresTitle'>
                      {feature.title}
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {feature.description.includes('rich text and emojis') ? (
                        <>
                          {feature.description.split('rich text and emojis')[0]}
                          <Text mark>rich text and emojis</Text>
                          {feature.description.split('rich text and emojis')[1]}
                        </>
                      ) : (
                        feature.description
                      )}
                    </Text>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Features;
