import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { scaleLinear } from 'd3-scale';
import ReactWordcloud from 'react-wordcloud-fork';
// import 'tippy.js/dist/tippy.css'; // tooltip library styles
import '../../assets/styles/leaderboard.css'; // Ensure the correct path to your CSS
import GlobalColors from '../../assets/colors/GlobalColors';

const colorRange = ['#624e9a', '#eac435']; // Gradient from dark purple to light yellow

const calculateColor = (value, maxValue) => {
  const colorScale = scaleLinear().domain([1, maxValue]).range(colorRange);
  return colorScale(value);
};

const WordCloud = ({ setIsStillActive, surveyId, wordCloudAnswerIndex }) => {
  const [words, setWords] = useState([]);

  useEffect(() => {
    let intervalId;

    const fetchWords = async () => {
      try {
        const response = await axios.get(`/api/leaderboard/${surveyId}/${wordCloudAnswerIndex}`);
        console.log('Fetched words:', response.data);
        setWords(
          response.data.map((word) => ({
            text: word.text,
            value: word.value,
            color: calculateColor(word.value, Math.max(...response.data.map((w) => w.value), 1)),
          }))
        ); // Map words to include color based on value
      } catch (error) {
        const { status } = error?.response;
        console.error('Error fetching words data:', status);
        if (status === 409) {
          setIsStillActive(false);
        }
      }
    };

    fetchWords(); // Initial fetch regardless of polling state

    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(fetchWords, 5000); // Adjust the interval as needed

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [surveyId, wordCloudAnswerIndex, setIsStillActive]);

  const options = {
    colors: ['#3b2b52', '#533b73', '#6a4c93', '#8b73ab', '#bdb0cf'],
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [20, 60],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 1,
    rotations: 3,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 2000,
  };

  return (
    <div className='word-cloud'>
      <ReactWordcloud words={words} options={options} />
    </div>
  );
};

export default WordCloud;
