/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState } from 'react';
import { Layout, Button, Typography, Card, Form, Input, Select, Result } from 'antd';

import StatusNotification from '../components/StatusNotification';
import useAxios from '../hooks/useAxios';
import NavBar from '../components/NavBar';
import LoadingIcon from '../components/LoadingIcon';

const { Option } = Select;
const { Title } = Typography;

//change it to functional component
const Contact = () => {
  const [loading, axiosFetch] = useAxios();
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);



  const apiURL = '/api/contact';

  const onSubjectChange = (value) => {
    setSubject(value);
  };

  const onFinish = (values) => {
    setName(values.name);
    axiosFetch({
      method: 'post',
      url: apiURL,
      requestConfig: { ...values, subject },
    })
      .then((response) => {
        setSubmitSuccess(true);
        StatusNotification('success', 'Submitted successfully');
      })
      .catch((error) => {
        setSubmitSuccess(false);
        StatusNotification('error', 'Message not submitted', error, {
          error,
          options: {
            level: 'error',
            tags: { section: 'Contact submit form' },
            extra: {
              additionalInfo: 'error submitting contact form to the backend for emailing',
            },
          },
        });
      });
  };
  const onFinishFailed = (errorInfo) => {
    setSubmitSuccess(false);
  };

  return (
    <>
      <Layout className='layout-default layout-signin'>
        <NavBar />
        <Card
          className='card-signup header-solid h-full ant-card '
          title={
            !submitSuccess && (
              <Title className='mb-15' style={{ textAlign: 'center' }}>
                Get in touch
              </Title>
            )
          }
          bordered='false'
        >
          {submitSuccess ? (
            <Result
              status='success'
              title={<>Thank you {name},</>}
              subTitle='We will feedback as soon as possible'
            />
          ) : (
            <Form
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className='row-col'
            >
              <Form.Item
                name='name'
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input placeholder='Name' />
              </Form.Item>
              <Form.Item
                name='surname'
                rules={[{ required: true, message: 'Please input your surname!' }]}
              >
                <Input placeholder='Surname' />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  { required: true, message: 'Please input your email!' },
                ]}
              >
                <Input placeholder='Organization Email' />
              </Form.Item>

              <Form.Item>
                <Select placeholder='Subject' onChange={onSubjectChange} allowClear>
                  {[
                    'Sales inquiry',
                    'Technical support',
                    'Media inquiry',
                    'Partnership inquiry',
                    'Other',
                  ].map((subject, index) => (
                    <Option value={subject} key={index}>
                      {subject}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name='message'
                placeholder='Message'
                rules={[
                  {
                    required: true,
                    message: 'Message is required',
                  },
                ]}
              >
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>
              <Form.Item>
                <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
                  {loading ? <LoadingIcon /> : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </Layout>
    </>
  );
};

export default Contact;
