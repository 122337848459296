import React from 'react';
import { Row, Col } from 'antd';

const Legend = () => {
  const legendStyle = {
    display: 'flex',
    alignItems: 'stretch',
    marginTop: '20px',
  };

  const legendItemStyle = (color) => ({
    backgroundColor: color,
    flexGrow: 1,
    height: '10px',
  });

  return (
    <Row style={{ justifyContent: 'center' }}>
      <Col>
        <div style={legendStyle}>
          <div style={legendItemStyle('#EA2027')} title='-10: Very negative mood' />
          <div style={legendItemStyle('#FFC312')} title='0: Neutral mood' />
          <div style={legendItemStyle('#009432')} title='10: Very positive mood' />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '12px' }}>
          <span>-10</span>
          <span>0</span>
          <span>10</span>
        </div>
      </Col>
    </Row>
  );
};

export default Legend;
