import axiosInstance from '../apis/backendAPI';
import { useDispatch } from 'react-redux';

const useRefreshToken = () => {
  const dispatch = useDispatch();

  const refresh = async () => {
    try {
      const response = await axiosInstance.get('/api/refresh');
      dispatch({
        type: 'REFRESH_TOKEN',
        data: response.data.accessToken,
      });
      return response.data.accessToken;
    } catch (error) {
      console.log('Error refreshing token', error?.response);
      throw error; 
    }
  };
  return refresh;
};

export default useRefreshToken;
