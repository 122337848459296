import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Grid, Badge, Button, Switch, Input, Tag, Skeleton } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import usePrivateAxios from '../hooks/usePrivateAxios';
import duration from 'dayjs/plugin/duration';
import SectionHeader from './SectionHeader';
import Offerings from '../components/Offerings';
import getScreenSize from '../utils/getScreenSize';
import removePercent from '../utils/removePercent';
import StatusNotification from '../components/StatusNotification';
import PayfastButtonBasic from './PayfastButtonBasic';
import PayfastButtonPremium from './PayfastButtonPremium';
import PayfastButtonEnterprise from './PayfastButtonEnterprise';
import PayfastButtonFree from './PayfastButtonFree';
import GlobalColors from '../assets/colors/GlobalColors';
import FAQ from './FAQ';

dayjs.extend(relativeTime);
dayjs.extend(duration);
const basicAmount = parseInt(process.env.REACT_APP_SUBSCRIPTION_BASIC);
const premiumAmount = parseInt(process.env.REACT_APP_SUBSCRIPTION_PREMIUM);
const topUpAmount = 300;

const { Title, Paragraph, Text } = Typography;
const { useBreakpoint } = Grid;
const btnStyle = {
  fontWeight: 600,
  width: 102,
  fontSize: 12,
  // backgroundColor: '#7A7774',
  color: 'black',
  padding: 4,
  borderRadius: 4,
};

const amountStyle = {
  fontSize: 19,
  fontWeight: 'bold',
  color: GlobalColors.darkGray,
};

const Pricing = (props) => {
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);
  const [amounts, setAmounts] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [annualAmounts, setAnnualAmounts] = useState({});
  const [savedAmount, setSavedAmount] = useState({});
  const [isError, setIsError] = useState(true);
  const [isYearly, setIsYearly] = useState(true);
  const [whichButton, setWhichButton] = useState('');
  const [nextBillingDate, setNextBillingDate] = useState(undefined);
  const [exchangeRate, setExchangeRate] = useState(null);
  const [currency, setCurrency] = useState('ZAR');
  const axiosPrivate = usePrivateAxios();

  const priceURL = props.isFront ? '/signup' : '';
  const apiURL = '/api/exchange-rates';

  useEffect(() => {
    togglePercentOff(true);
  }, []);

  const fetchExchangeRate = async () => {
    try {
      setIsloading(true);
      const { data } = await axiosPrivate.get(apiURL);
      const roundedRate = Math.round(data.rate);
      console.log({ inRate: amounts, roundedRate });
      setExchangeRate(roundedRate);

      // convertInitialAmountsToZAR(roundedRate);
    } catch (error) {
      console.error('Error fetching exchange rate', error);

      // StatusNotification(
      //   "error",
      //   "Error fetching exchange rate",
      //   " Support have been notified",
      // );
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (exchangeRate) {
      recalculatePricing(currency);
    }
  }, [exchangeRate]);
  const convertToZAR = (amount) => {
    return Math.round(basicAmount * exchangeRate);
  };

  const handleCurrencyToggle = (checked) => {
    const newCurrency = checked ? 'USD' : 'ZAR';
    setCurrency(newCurrency);

    // Immediately apply the correct conversion based on the new currency selection
    recalculatePricing(newCurrency);
  };

  const recalculatePricing = (currency) => {
    if (!exchangeRate || !basicAmount || !premiumAmount) return;

    const conversionRate = currency === 'ZAR' ? exchangeRate : 1;
    // Apply conversion rate
    const convertedBasicAmount = Math.round(basicAmount * conversionRate);
    const convertedPremiumAmount = Math.round(premiumAmount * conversionRate);

    console.log({ isYearly });
    if (isYearly) {
      console.log({
        recalculate: currency,
        amounts,
        conversionRate,
        convertedBasicAmount,
        convertedPremiumAmount,
      });
      applyYearlyDiscounts(convertedBasicAmount, convertedPremiumAmount, currency);
    } else {
      // If not yearly, just update the amounts normally
      setAmounts({
        basicAmount: convertedBasicAmount,
        premiumAmount: convertedPremiumAmount,
      });
    }
  };

  const togglePercentOff = (checked) => {
    setIsYearly(checked);

    if (!checked) {
      resetAmounts();
      setNextBillingDate(
        dayjs()
          .add(dayjs.duration({ months: 1 }))
          .format('YYYY-MM-DD')
      );
    }

    if (checked) {
      setNextBillingDate(
        dayjs()
          .add(dayjs.duration({ years: 1 }))
          .format('YYYY-MM-DD')
      );
      applyYearlyDiscounts(amounts.basicAmount, amounts.premiumAmount);
    }
  };

  const resetAmounts = () => {
    setAmounts({
      basicAmount: currency === 'ZAR' ? basicAmount * exchangeRate : basicAmount,
      premiumAmount: currency === 'ZAR' ? premiumAmount * exchangeRate : premiumAmount,
    });
    setSavedAmount({
      basicAmount: 0,
      premiumAmount: 0,
    });
  };

  const applyYearlyDiscounts = (basic, premium) => {
    const removedBasicPercent = Math.round(removePercent(basic, 20));
    const removedPremiumPercent = Math.round(removePercent(premium, 20));

    const annualBasic = Math.round(basic * 12);
    const annualPremium = Math.round(premium * 12);

    setSavedAmount({
      basicAmount: removedBasicPercent,
      premiumAmount: removedPremiumPercent,
    });
    setAmounts({
      basicAmount: basic - removedBasicPercent,
      premiumAmount: premium - removedPremiumPercent,
    });
    setAnnualAmounts({
      annualBasicAmount: annualBasic,
      annualPremiumAmount: annualPremium,
    });
  };

  const handleEnterpriseAmount = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value) && value) {
      setIsError(false);
    } else setIsError(true);
  };

  const bookCalender = () => {
    window.open('https://www.cal.com/thabo', '_blank');
  };

  console.log({ amounts });
  return (
    <div
      id='pricing'
      className={props.isFront ? 'containerRadius container' : 'containerRadius'}
      style={{ backgroundColor: '#EDEDED' }}
    >
      <Row gutter={[24, 24]}>
        {props.isFront && (
          <Col span={24}>
            <SectionHeader
              title='Pricing'
              subtitle='7-day trial to test drive all features the platform'
              isFront={props.isFront}
            />
          </Col>
        )}
        <Col
          span={24}
          style={props.isFront ? { textAlign: 'center' } : { textAlign: 'center', marginTop: 22 }}
        >
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Row justify='center'>
                {/* <Col span={11} style={{ textAlign: 'end' }}>
                  <span style={{ fontSize: 16 }}>
                    <Text mark strong style={{ fontSize: 18 }}>
                      20% off
                    </Text>{' '}
                    annual pricing{' '}
                  </span>
                  <Switch
                    defaultChecked
                    checkedChildren='YES'
                    unCheckedChildren='NO'
                    onChange={togglePercentOff}
                  />
                </Col> */}
                {/* <Col span={2}></Col> */}
                <Col span={24} style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: 16 }}>Currency</span>{' '}
                  <Switch
                    // defaultChecked
                    checkedChildren='USD'
                    unCheckedChildren='ZAR'
                    onChange={handleCurrencyToggle}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: 4 }}>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={6}>
              <Row style={screenSize !== 'xs' ? { width: '100%', float: 'right' } : {}}>
                <Col span={24}>
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Title
                        level={5}
                        className='typographyBrown'
                        style={{
                          display: 'flex',
                          // justifyContent: 'space-around',
                        }}
                      >
                        <span style={{ ...btnStyle, backgroundColor: 'white' }}>Free</span>
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph>
                        {' '}
                        <Text
                          style={{
                            color: GlobalColors.darkGray,
                          }}
                          strong
                        >
                          Pay as you go
                        </Text>{' '}
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Offerings subscriptionStatus={'active'} planType={'free'} isFront={true} />
                </Col>
                <Col span={24}>
                  {props.isFront ? (
                    <Link to={priceURL}>
                      <Button block type='primary'>
                        Start For Free
                      </Button>
                    </Link>
                  ) : (
                    <span
                      onClick={() => {
                        setWhichButton('free');
                      }}
                    >
                      {/* {props.planType !== 'free' && (
                        <PayfastButtonFree
                          key={'btn-free'}
                          {...props}
                          amount={topUpAmount}
                          discountAmount={0}
                          currency={currency}
                          exchangeRate={exchangeRate}
                          isError={false}
                          btn={whichButton}
                        />
                      )} */}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={6}>
              <Row style={screenSize !== 'xs' ? { width: '100%', float: 'right' } : {}}>
                <Col span={24}>
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Title
                        level={5}
                        className='typographyBrown'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span style={{ ...btnStyle, backgroundColor: 'white' }}>Basic</span>
                        {isLoading ? (
                          <Skeleton.Button active size='small' />
                        ) : (
                          amounts?.basicAmount && (
                            <span style={amountStyle}>
                              {' '}
                              {currency === 'USD' ? '$ ' : 'R '}
                              {amounts.basicAmount}{' '}
                              <span style={{ fontSize: 12, marginRight: 12 }}>{'p/m'}</span>
                            </span>
                          )
                        )}
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph>
                        <Row justify='space-between'>
                          <Col>
                            <Text
                              style={{
                                color: GlobalColors.darkGray,
                              }}
                              strong
                            >
                              Getting started{' '}
                            </Text>
                          </Col>
                          <Col>
                            {isYearly && (
                              <Tag color={GlobalColors.darkGray}>
                                {/* Save {currency === 'USD' ? '$' : 'R' }
                                {savedAmount.basicAmount} <span style={{ fontSize: 9 }}>p/m</span> */}
                                Charged yearly
                              </Tag>
                            )}
                          </Col>
                        </Row>
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Offerings subscriptionStatus={'active'} planType={'basic'} isFront={true} />
                </Col>
                <Col span={24}>
                  {props.isFront ? (
                    <Link to={priceURL}>
                      <Button block type='primary'>
                        Buy Now
                      </Button>
                    </Link>
                  ) : (
                    <span
                      onClick={() => {
                        setWhichButton('basic');
                      }}
                    >
                      {props.planType !== 'basic' && (
                        <PayfastButtonBasic
                          key={'btn-basic'}
                          {...props}
                          amount={isYearly ? annualAmounts.annualBasicAmount : amounts.basicAmount}
                          discountAmount={savedAmount.basicAmount}
                          nextBillingDate={nextBillingDate}
                          currency={currency}
                          exchangeRate={exchangeRate}
                          isError={false}
                          btn={whichButton}
                          isYearly={isYearly}
                        />
                      )}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={6}>
              <Row
              // style={screenSize !== 'xsx' ? { width: '50%' } : {}}
              >
                <Col span={24}>
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Title
                        level={5}
                        className='typographyBrown'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            ...btnStyle,
                            backgroundColor: 'white',
                            // color: 'white',
                          }}
                        >
                          Premium
                        </span>
                        {isLoading ? (
                          <Skeleton.Button active size='small' />
                        ) : (
                          amounts?.premiumAmount && (
                            <span style={amountStyle}>
                              {' '}
                              {currency === 'USD' ? '$ ' : 'R '}
                              {amounts.premiumAmount}{' '}
                              <span style={{ fontSize: 12, marginRight: 12 }}> {'p/m'}</span>
                            </span>
                          )
                        )}
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph>
                        <Row justify='space-between'>
                          <Col>
                            {' '}
                            <Text
                              style={{
                                color: GlobalColors.darkGray,
                              }}
                              strong
                            >
                              Growing{' '}
                            </Text>
                          </Col>
                          <Col>
                            {isYearly && (
                              <Tag color={GlobalColors.darkGray}>
                                {/* Save {currency === 'USD' ? '$' : 'R'}
                                {savedAmount.premiumAmount} <span style={{ fontSize: 9 }}>p/m</span> */}
                                Charged yearly
                              </Tag>
                            )}
                          </Col>
                        </Row>
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Badge.Ribbon text={'Popular'} color={GlobalColors.mainPurple} className='ribbon'>
                    <Offerings subscriptionStatus={'active'} planType={'premium'} isFront={true} />
                  </Badge.Ribbon>
                </Col>
                <Col span={24}>
                  {props.isFront ? (
                    <Link to={priceURL}>
                      <Button block type='primary'>
                        Buy Now
                      </Button>
                    </Link>
                  ) : (
                    <span onClick={() => setWhichButton('standard')}>
                      {props.planType !== 'premium' && (
                        <PayfastButtonPremium
                          key={'btn-premium'}
                          {...props}
                          amount={
                            isYearly ? annualAmounts.annualPremiumAmount : amounts.premiumAmount
                          }
                          discountAmount={savedAmount.premiumAmount}
                          exchangeRate={exchangeRate}
                          currency={currency}
                          nextBillingDate={nextBillingDate}
                          btn={whichButton}
                          isError={false}
                          isYearly={isYearly}
                        />
                      )}
                    </span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={6}>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Title
                        level={5}
                        className='typographyBrown'
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span
                          style={{
                            ...btnStyle,
                            backgroundColor: 'white',
                            // color: 'white',
                          }}
                        >
                          Enterprise
                        </span>
                        <span style={{ ...amountStyle, marginRight: 12 }}>On request</span>
                      </Title>
                    </Col>
                    <Col span={24}>
                      <Paragraph>
                        {' '}
                        <Text
                          style={{
                            color: GlobalColors.darkGray,
                          }}
                          strong
                        >
                          Customized solution
                        </Text>
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Offerings subscriptionStatus={'active'} planType={'enterprise'} isFront={true} />
                </Col>
                <Col span={24}>
                  <Button block type='primary' onClick={bookCalender}>
                    Contact Us
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={24}>
          <FAQ />
        </Col>
      </Row>
    </div>
  );
};

export default Pricing;
