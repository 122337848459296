import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import usePrivateAxios from '../hooks/usePrivateAxios'
import StatusNotification from '../components/StatusNotification'

const apiURL = '/api/users/extradetails'
const PaymentNotification = () => {
  let { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const axiosPrivate = usePrivateAxios()
  const { userId } = useSelector((state) => {
    return state.user
  })

  pathname = pathname.split('/')[2]

  useEffect(() => {
    if (pathname === 'complete') {
      refreshSubscription()
      history.replace('/subscription', { from: pathname })
    } else {
      StatusNotification(
        'warning',
        'Subscription cancelled',
        'Hit the subscribe button when ready to do so'
      )
      history.replace('/subscription', { from: pathname })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const refreshSubscription = async () => {
    try {
      const response = await axiosPrivate.post(apiURL, { userId })
      dispatch({ type: 'REFRESH_SUBSCRIPTION', data: response.data })
      StatusNotification(
        'success',
        'Subscription Confirmed',
        'Enjoy the platform'
      )
    } catch (error) {
      StatusNotification(
        'warning',
        'Subscription processed',
        'But update did not completed, please inform support@surveyr.africa'
      )
    }
  }

  return (
    <>{pathname === 'complete' ? <div>Succesful</div> : <div>Cancelled</div>}</>
  )
}

export default PaymentNotification
