import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Papa from 'papaparse';
import { Card, Row, Col, Space, Upload, Table, Button, Tag } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from './StatusNotification';
import GlobalColors from '../assets/colors/GlobalColors';
import { HappyProvider } from '@ant-design/happy-work-theme';
import LoadingIcon from './LoadingIcon';

const phoneUtil = PhoneNumberUtil.getInstance();

const columns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'CellNumber', dataIndex: 'cellnumber', key: 'cellnumber' },
  { title: 'CountryCode', dataIndex: 'countrycode', key: 'countrycode' },
  { title: 'Tags', dataIndex: 'tags', key: 'tags' },
];

const formatPhoneNumberForWhatsApp = (number, countrycode) => {
  try {
    const phoneNumber = phoneUtil.parseAndKeepRawInput(number, countrycode);
    const formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
    return formattedNumber.slice(1);
  } catch (error) {
    console.error('Error formatting number:', error);
    return number;
  }
};

const HandleContacts = () => {
  const axiosPrivate = usePrivateAxios();
  const { organizationId } = useSelector((state) => state.user);
  const [parsedData, setParsedData] = useState([]);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canUpload, setCanUpload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const history = useHistory();

  const handleUpload = (uploadedFile) => {
    setFile(uploadedFile);
    Papa.parse(uploadedFile, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data && result.data.length > 0 && isValidCSV(result.data)) {
          const validData = result.data
            .filter((row) => row.name && row.cellnumber && row.countrycode && row.name.length > 2)
            .map((row) => ({
              ...row,
              cellnumber: formatPhoneNumberForWhatsApp(row.cellnumber, row.countrycode),
            }));
          setAccumulatedData((prev) => [...prev, ...validData]);
          setCanUpload(true);
          StatusNotification('success', 'The data is all correct and ready for database upload');
        } else {
          StatusNotification('error', 'Invalid CSV format, compare with the sample csv file and try again');
        }
      },
      error: () => {
        StatusNotification('error', 'Error parsing CSV.');
      },
    });
    return false;
  };

  const isValidCSV = (data) => {
    const sampleKeys = ['name', 'cellnumber', 'countrycode', 'tags'];
    return sampleKeys.every((key) => Object.keys(data[0]).includes(key));
  };

  const uploadData = async () => {
    setIsLoading(true);
    try {
      await axiosPrivate.post(
        `api/contacts/upload/${organizationId}`,
        { contacts: accumulatedData },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setAccumulatedData([]);
      setIsLoading(false);
      StatusNotification('success', 'Contacts uploaded successfully.');
      history.push('/contacts');
    } catch (error) {
      setIsLoading(false);
      StatusNotification('error', error.response?.data?.message || 'Error uploading contacts.');
    }
  };

  const downloadSampleCSV = () => {
    const csv = 'name,cellnumber,countrycode,tags\nLesedi,+27801234567,ZA,"contact,vip,new-client"';
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sample.csv';
    link.click();
  };

  const resetAccumulatedData = () => {
    setAccumulatedData([]);
    setCanUpload(false);
  };

  return (
    <div style={{ marginBottom: 10, marginTop: 78 }}>
      <Row justify='space-between'>
        <Col push={1}>
          <Upload showUploadList={false} customRequest={({ file }) => handleUpload(file)}>
            <span className='icon' style={{ fontSize: 26 }}>
              <UploadOutlined style={{ color: GlobalColors.mainPurple, cursor: 'pointer' }} />
            </span>
          </Upload>
        </Col>
        <Col pull={1}>
          <Space align='center'>
            Download Sample CSV
            <span className='icon' style={{ fontSize: 26 }}>
              <DownloadOutlined
                onClick={downloadSampleCSV}
                style={{ color: GlobalColors.mainPurple }}
              />
            </span>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            className='criclebox tablespace mb-24'
            title={
              accumulatedData.length === 0 ? (
                'No Contacts'
              ) : (
                <>
                  <Tag color={GlobalColors.lightPurple}>{accumulatedData.length}</Tag> Contacts
                </>
              )
            }
            style={{ padding: 12 }}
          >
            <Table
              dataSource={accumulatedData}
              columns={columns}
              rowKey='cellnumber'
              pagination={{
                size: 'small',
                position: ['bottomCenter'],
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row justify='space-between'>
        <Col span={12}>
          <HappyProvider>
            <Button type='primary' onClick={uploadData} disabled={!canUpload}>
              {isLoading ? <LoadingIcon /> : 'Upload'}
            </Button>
          </HappyProvider>
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <HappyProvider>
            <Button type='primary' onClick={resetAccumulatedData} disabled={!canUpload}>
              {isLoading ? <LoadingIcon /> : 'Clear All'}
            </Button>
          </HappyProvider>
        </Col>
      </Row>
    </div>
  );
};

export default HandleContacts;
