import {
  SIGN_IN_USER,
  SIGN_UP_USER,
  LOGOUT_USER,
  REFRESH_TOKEN,
  CHANGE_PLAN_TYPE,
  REFRESH_SUBSCRIPTION,
  SET_COUNTERS,
  SET_USER_MARKETING_EMAIL_STATUS,
  CANCEL_ACCOUNT_DELETION,
  INITIATE_ACCOUNT_DELETION,
} from '../actions/types';

const DEFAULT_STATE = {
  isAuthenticated: false, // hopefully be true, when logged in
  isSusbscribedToMarketing: false,
  accessToken: '',
  organizationId: '',
  hasOwnBot: false,
  discount: {},
  userId: '', // all the user info when logged in
  planType: '',
  subscriptionStatus: '',
  registrationDate: '',
  counters: {},
  userDetails: {},
  teamDetails: {},
};

const userReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SIGN_IN_USER:
      console.log({ action });
      return {
        // turn empty object into false or if there are keys, true
        ...state,
        isAuthenticated: !!Object.keys(action.data).length,
        accessToken: action.data.accessToken,
        organizationId: action.data.organizationId,
        hasOwnBot: action.data.hasOwnBot,
        discount: action.data.discount,
        userId: action.data.userId,
        planType: action.data.planType,
        subscriptionStatus: action.data.subscriptionStatus,
        registrationDate: action.data.registrationDate,
        isSusbscribedToMarketing: action?.data?.isSubscribedToMarketing,
        userDetails: action.data.userDetails,
        teamDetails: action.data.teamDetails,
      };

    case SIGN_UP_USER:
      //no need to update state for this type, client status will remain pending
      return {
        isAuthenticated: !!Object.keys(action.data).length,
        id: action.data.id,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        isAuthenticated: !!Object.keys(action.data).length,
        accessToken: action.data,
      };
    case CHANGE_PLAN_TYPE:
      return {
        ...state,
        planType: action.data,
      };
    case SET_COUNTERS:
      return {
        ...state,
        counters: { ...state.counters, ...action.data },
      };
    case SET_USER_MARKETING_EMAIL_STATUS:
      return {
        ...state,
        isSusbscribedToMarketing: action.data,
      };
    case REFRESH_SUBSCRIPTION:
      return {
        ...state,
        planType: action.data.planType,
        discount: action.data.discount,
        subscriptionStatus: action.data.subscriptionStatus,
      };
    case INITIATE_ACCOUNT_DELETION:
      console.log({ deletionDate: action.data });
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          accountDeletionScheduled: action.data,
        },
      };
    case CANCEL_ACCOUNT_DELETION:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          accountDeletionScheduled: null,
        },
      };
    case LOGOUT_USER:
      const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
      delete persistRoot.user;
      localStorage.setItem('persist:root', JSON.stringify(persistRoot));
      return {
        ...state,
        isAuthenticated: false,
        accessToken: '',
        organizationId: '',
        hasOwnBot: false,
        discount: {},
        id: '',
        planType: '',
        subscriptionStatus: '',
        registrationDate: '',
        counters: {},
        userDetails: {},
        teamDetails: {},
      };
    default:
      return state;
  }
};

export default userReducer;
