const fetchAndSetTemplate = async (axiosPrivate, organizationId, threadId) => {
  if (!threadId) {
    return { error: "ThreadId cannot be empty", type: "parametersError" };
  }
  try {
    const response = await axiosPrivate.get(
      `/api/ai/thread/messages/${organizationId}/${threadId}`,
    );
    try {
      const templateResponse = await axiosPrivate.get(
        `/api/pdfme/${response.data.pdfmeTemplateId}`,
      );
      const mergedTemplate = {
        ...templateResponse.data,
        contentGroups: response.data.contentGroups,
      };
      return { mergedTemplate };
    } catch (error) {
      return { error, type: "templateError" };
    }
  } catch (error) {
    return { error, type: "surveyError" };
  }
};

export default fetchAndSetTemplate;
