import GlobalColors from "../../../assets/colors/GlobalColors";

const lineChart = {
  series: [
    {
      name: 'Same day',
      data: [8, 4, 8, 9, 0, 0, 0],
      offsetY: 0,
    },
    {
      name: 'Later',
      data: [5, 12, 23, 60, 12, 8, 18],
      offsetY: 0,
    },
  ],

  options: {
    chart: {
      width: '100%',
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
    },
    animations: {
      enabled: true,
      easing: 'easeout',
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 250,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },

    legend: {
      show: true,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 4,
      curve: 'smooth',
      colors: [GlobalColors.darkGray],
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        gradientToColors: [GlobalColors.lightPurple],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 0.4,
        opacityTo: 0.7,
        stops: [0, 100, 100, 100],
      },
    },

    yaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: ['#8c8c8c'],
        },
      },
    },

    xaxis: {
      labels: {
        style: {
          fontSize: '14px',
          fontWeight: 600,
          colors: [
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
            '#8c8c8c',
          ],
        },
      },
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  },
};

export default lineChart;
