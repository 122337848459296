import React from 'react';
import { Typography, Row, Col } from 'antd';
import muluma from '../assets/images/muluma-logo.jpeg';
import nfdi from '../assets/images/nfdi-logo.jpg';
import origins from '../assets/images/origins-logo.png';
import sacc from '../assets/images/sacc-logo.jpg';
import chefsonapp from '../assets/images/chefsonapp-logo.png';

const { Title } = Typography;

const CompanyTrustBar = () => {
  const logos = [
    { name: 'Muluma', url: muluma },
    { name: 'NFDI', url: nfdi },
    { name: 'Origins', url: origins },
    { name: 'SACC', url: sacc },
    { name: 'ChefsOnApp', url: chefsonapp },
  ];

  return (
    <Row
      style={{
        background: 'white',
        padding: '24px 0',
        borderRadius: '9px',
      }}
    >
      <Col span={24}>
        <div style={{ textAlign: 'center', marginBottom: '16px' }}>
          <Title level={3} className='trust typographyBrown'>
            Trusted by these organizations
          </Title>
        </div>
        <Row gutter={[16, 16]} justify='space-around' align='middle'>
          {logos.map((logo, index) => (
            <Col key={index} xs={12} sm={8} md={4} lg={4} xl={4}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60px',
                }}
              >
                <img
                  src={logo.url}
                  alt={`${logo.name} logo`}
                  style={{
                    maxHeight: '40px',
                    maxWidth: '100%',
                    objectFit: 'contain',
                    filter: 'grayscale(78%)',
                    opacity: 0.7,
                    transition: 'filter 0.3s ease, opacity 0.3s ease',
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default CompanyTrustBar;
