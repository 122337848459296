import React from 'react';
import { Link } from 'react-router-dom';
import {
  Layout,
  Typography,
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import useAxios from '../hooks/useAxios';
import NavBar from '../components/NavBar';
import StatusNotification from '../components/StatusNotification';
import LoadingIcon from '../components/LoadingIcon'


const { Title } = Typography;
const { Content } = Layout;

const ResendRegistrationConfirmation = ({ history }) => {
  const [loading, axiosFetch] = useAxios();

  const apiURL = '/api/users/signup/resend';

  const onFinish = (email) => {
    axiosFetch({
      method: 'post',
      url: apiURL,
      requestConfig: email,
    })
      .then((response) => {
        // eslint-disable-next-line default-case
        switch (response.Type) {
          case 'resent':
            StatusNotification(
              'success',
              'Confirmation email sent',
              `confirmation email resent to ${email.email}`
            );
            history.push('/success');
            break;
          case 'again':
            StatusNotification(
              'success',
              'Confirmation email sent again',
              `Please check your email including the spam folder`
            );
            break;
        }
      })
      .catch((error) => {

        if (error.includes('activated'))
          StatusNotification('warning', 'Account Already Active', error);
        else StatusNotification('error', 'Error resubmiting', error);
      });
  };

  return (
    <>
      <Layout className='layout-default layout-signin'>
        <NavBar />
        <Content>
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ minHeight: '50vh', flexFlow: 'Column' }}
          >
            <Title className='mb-15' style={{ textAlign: 'center' }}>
              Resend Verification Email
            </Title>
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='email'
                  allowClear='true'
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  htmlType='submit'
                >
                  {loading ? (
                  <LoadingIcon />
                  ) : (
                    'Resend Confirmation Email'
                  )}
                </Button>

                <Col justify='center' align='middle' span={24}>
                  <Space />
                  <Link to='/signin' id='validating'>
                    Return to Sign In
                  </Link>
                </Col>
              </Form.Item>
            </Form>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default ResendRegistrationConfirmation;
