import React from 'react';
import { Row, Col, Result, Button } from 'antd';
import { LockTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import GlobalColors from '../assets/colors/GlobalColors';
import { HappyProvider } from '@ant-design/happy-work-theme';

const NotAuthorized = () => {
  const unAuthorizedText = 'You are not authorized to view this page.';
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Row justify='center'>
      <Col xs={24} md={12}>
        <Result
          status='403'
          title={
            <span
              style={{
                fontFamily: 'Alfa Slab One',
                color: GlobalColors.darkGray,
                fontWeight: 100,
              }}
            >
              {unAuthorizedText}
            </span>
          }
          subTitle='Only the organization owner can access this page.'
          icon={<LockTwoTone twoToneColor={GlobalColors.lightPurple} />}
          extra={
            <HappyProvider>
              <Button type='primary' onClick={handleGoBack}>
                Go Back
              </Button>
            </HappyProvider>
          }
        />
      </Col>
    </Row>
  );
};

export default NotAuthorized;
