import { SET_EXCHANGE_RATE } from '../actions/types'

const initialExchangeRateState = {
  rate: 1,
}

const exchangeRateReducer = (state = initialExchangeRateState, action) => {
  switch (action.type) {
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        rate: action.data,
      }
    default:
      return state
  }
}

export default exchangeRateReducer
