import {
  LOAD_DASHBOARD_VALUES,
  LOAD_CHARTS_DATA,
  LOAD_THIRTY_DAYS_CHART_DATA,
  REMOVE_THIRTY_DAYS_CHART_DATA,
  DASHBOARD_SET_SEARCHED_TAGS,
  DASHBOARD_SET_ALL_TAGS,
} from '../actions/types';

const initialState = { dashboardValues: {}, chartsData: {}, searchedTags: [], allTags: [] };

const dashboardReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case LOAD_DASHBOARD_VALUES:
      if (!data) return;
      return { ...state, dashboardValues: { ...data } };

    case LOAD_THIRTY_DAYS_CHART_DATA:
      if (!data) return;
      return {
        ...state,
        //loading only line and pie charts, because bar chart data is HUGE
        chartsData: {
          ...state.chartsData,
          lineChart: data?.barAndLineCharts?.lineChart,
          pieChart: data?.pieChart || {},
        },
      };

    case DASHBOARD_SET_SEARCHED_TAGS:
      if (!data) return;
      return { ...state, searchedTags: data };

    case DASHBOARD_SET_ALL_TAGS:
      if (!data) return;
      return { ...state, allTags: data };

    case LOAD_CHARTS_DATA:
      return { ...state, chartsData: data };

    case REMOVE_THIRTY_DAYS_CHART_DATA:
      return { ...state, chartsData: {} };

    default:
      return state;
  }
};

export default dashboardReducer;
