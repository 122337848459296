import { IS_LOADING } from '../actions/types';

const setIsLoadingReducer = (state = { isLoading: false }, action) => {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, isLoading: action.data };
        default:
            return state;
    }
};

export default setIsLoadingReducer;