import {
  CREATE_BROADCAST_INPUT_DATA,
  CREATE_BROADCAST_ID,
  CREATE_BROADCAST_CLEAR,
  FETCH_BROADCASTS,
  SET_SELECTED_BROADCAST,
  SET_SELECTED_BROADCAST_SHORT_STATS,
  CLEAR_SELECTED_BROADCAST,
  FETCH_BATCH_SIZE,
  FETCH_BROADCASTS_RESPONDENTS,
} from '../actions/types';

const initialState = {
  selectedBroadcast: null,
  selectedBroadcastShortStats: {},
  broadcasts: [],
  broadcastRespondents: [],
  batchSize: 0,
  create: {
    broadcastInputData: {
      organizationId: '',
      broadcastName: '',
      startingDateTime: '',
      options: {},
    },
    broadcastId: '',
    isDraft: true,
  },
};

const broadcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BROADCASTS:
      return {
        ...state,
        broadcasts: action.data,
      };
    case FETCH_BROADCASTS_RESPONDENTS:
      return {
        ...state,
        broadcastRespondents: action.data,
      };
    case CREATE_BROADCAST_INPUT_DATA:
      return {
        ...state,
        create: {
          ...state.create,
          broadcastInputData: { ...state.create.broadcastInputData, ...action.data },
        },
      };
    case CREATE_BROADCAST_ID:
      return {
        ...state,
        create: { ...state.create, broadcastId: action.data },
      };
    case SET_SELECTED_BROADCAST:
      return {
        ...state,
        selectedBroadcast: action.data,
      };
    case SET_SELECTED_BROADCAST_SHORT_STATS:
      return {
        ...state,
        selectedBroadcastShortStats: action.data,
      };
    case FETCH_BATCH_SIZE:
      return {
        ...state,
        batchSize: action.data,
      };
    case CLEAR_SELECTED_BROADCAST:
      return {
        ...state,
        selectedBroadcast: null,
      };
    case CREATE_BROADCAST_CLEAR:
      return {
        ...state,
        batchSize: 0,
        selectedBroadcast: null,
        broadcasts: [],
        broadcastRespondents: [],
        create: {
          ...state.create,
          broadcastId: '',
          isDraft: true,
          broadcastInputData: {
            organizationId: '',
            broadcastName: '',
            startingDateTime: '',
            options: {},
          },
        },
      };
    default:
      return state;
  }
};

export default broadcastReducer;
