import React from 'react'
import { Card } from 'antd';

const { Meta } = Card;
const TemplateThumbnail = ({ surveyId, title, thumbnailUrl }) => {
  return (
    <Card
      hoverable
      style={{
        width: 340,
      }}
      cover={<img alt='example' src={thumbnailUrl} />}
    >
      <Meta title={title} description={surveyId} />
    </Card>
  );
};

export default TemplateThumbnail