import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useHistory, NavLink, useLocation, Redirect } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Tag,
  Typography,
  Grid,
  Space,
  Tooltip,
  Table,
  Image,
  Button,
  Popconfirm,
} from 'antd';
import GlobalColors from '../assets/colors/GlobalColors';
import usePrivateAxios from '../hooks/usePrivateAxios';
import LoadingIcon from '../components/LoadingIcon';
import StatusNotification from '../components/StatusNotification';
import RadialBarChart from '../components/chart/RadialBarChart';
import {
  CheckCircleOutlined,
  PlusOutlined,
  EyeFilled,
  DeleteFilled,
  SyncOutlined,
  RedoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import getScreenSize from '../utils/getScreenSize';
import canResend from '../utils/canResend';

import StatusCompleted from '../assets/images/status-completed.svg';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Broadcast = () => {
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();
  const screens = useBreakpoint();
  const history = useHistory();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [resendRecordId, setResendRecordId] = useState(null);

  const apiURL = '/api/broadcasts/';
  const templatesURL = '/api/templates/';
  const shortURL = '/api/short/';

  const screenSize = getScreenSize(screens);
  const toolTip = (value) => <span>{value}</span>;

  const {
    organizationId,
    teamDetails: { role },
  } = useSelector((state) => state.user);
  const { broadcasts, selectedBroadcast } = useSelector((state) => state.broadcastData);

useEffect(() => {
  let intervalId;

  const controller = new AbortController();

  const getBroadcasts = async () => {
    try {
      const response = await axiosPrivate.get(apiURL + organizationId, {
        signal: controller.signal,
      });
      dispatch({ type: 'FETCH_BROADCASTS', data: response.data });

      if (response.data && response.data.length > 0) {
        const previousSelectedId = selectedBroadcast?._id;
        const selectedBroadcastId = previousSelectedId || response.data[0]?._id;

        const selected =
          response.data.find((b) => b._id === selectedBroadcastId) || response.data[0];
        handleView(selected);
        if (selected?.type === 'whatsapp') getRespondents(selectedBroadcastId);
      }
    } catch (error) {
      // Error handling...
    }
  };

  const isSelectedBroadcastActive = () => {
    return selectedBroadcast && selectedBroadcast.status === 'active';
  };

  const initializeAndPollBroadcasts = async () => {
    await getBroadcasts();

    if (isSelectedBroadcastActive()) {
      intervalId = setInterval(() => {
        if (isSelectedBroadcastActive()) {
          getBroadcasts();
        } else {
          clearInterval(intervalId);
        }
      }, 150000);
    }
  };

  initializeAndPollBroadcasts();

  return () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
    controller.abort();
  };
}, [axiosPrivate, apiURL, organizationId, dispatch]);// Ensure dependencies are correct

  useEffect(() => {
    const getTemplates = async () => {
      try {
        const response = await axiosPrivate.get(templatesURL + organizationId);
        dispatch({ type: 'FETCH_TEMPLATES', data: response.data });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          dispatch({ type: 'FETCH_TEMPLATES', data: [] });
        } else {
          // Handle other errors or redirect as needed
          // return <Redirect to={state?.from || '/signin'} />
        }
      }
    };
    getTemplates();
  }, [axiosPrivate, dispatch, organizationId, templatesURL]);

  const getRespondents = async (broadcastId) => {
    try {
      const response = await axiosPrivate.get(`/api/broadcasts/respondents/${broadcastId}`);
      dispatch({ type: 'FETCH_BROADCASTS_RESPONDENTS', data: response.data });
    } catch (error) {
      // StatusNotification(
      //   'error',
      //   'Refresh Failed',
      //   error?.response?.data?.message || 'An error occurred while refreshing the broadcasts.'
      // );
    }
  };

const handleView = async (record) => {
  setIsLoading({ [record._id]: 'view' });

  try {
    // First, update the selected broadcast
     dispatch({ type: 'SET_SELECTED_BROADCAST', data: record });

    const { _id: broadcastId, type } = record;

    // Then, fetch respondents if it's a WhatsApp broadcast
    if (type === 'whatsapp') {
      await getRespondents(broadcastId);
    }

    // Finally, refresh the active broadcast
    // await refreshActiveBroadcast(type);
  } catch (error) {
    console.error('Error in handleView:', error);
    // Handle the error appropriately
  } finally {
    setIsLoading({ [record._id]: false });
  }
};

  const handleResendClick = (recordId) => {
    setResendRecordId(recordId);
  };

  const handleResend = async (recordId) => {
    setIsLoading({ [recordId]: 'resend' });

    try {
      const response = await axiosPrivate.post(apiURL + 'resend', { broadcastId: recordId });
      dispatch({ type: 'CREATE_BROADCAST_ID', data: response.data.id });
      StatusNotification(
        'success',
        'Broadcast re-submitted',
        'Keep refreshing the table to get progress status'
      );
    } catch (error) {
      const { message } = error?.response?.data;
      StatusNotification('error', 'Resending not allowed', message);
    } finally {
      setIsLoading({ [recordId]: false });
    }
  };

  const handleConfirmResend = async () => {
    if (!resendRecordId) return;

    setConfirmLoading(true);
    // Perform the resend operation using resendRecordId
    await handleResend(resendRecordId);
    // Reset state after operation
    setConfirmLoading(false);
    setResendRecordId(null);
  };

  const handleCancelResend = () => {
    setResendRecordId(null);
  };

  const handleEdit = (broadcastId) => {
    setIsLoading({ [broadcastId]: 'edit' });
    // Edit logic here
  };

  const handleDelete = async (broadcastId) => {
    setIsLoading({ [broadcastId]: 'delete' });
    try {
      const response = await axiosPrivate.delete(apiURL + 'delete/' + broadcastId);
      if (response.status === 200) {
        const updatedBroadcasts = broadcasts.filter((b) => b._id !== broadcastId);
        dispatch({ type: 'FETCH_BROADCASTS', data: updatedBroadcasts });

        StatusNotification(
          'success',
          'Broadcast Deleted',
          'The broadcast was successfully deleted.'
        );
      } else {
        StatusNotification(
          'error',
          'Delete Failed',
          'Failed to delete the broadcast. Please try again.'
        );
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Delete Failed',
        error?.response?.data?.message || 'An error occurred while deleting the broadcast.'
      );
    } finally {
      setIsLoading({ [broadcastId]: false });
    }
  };

  const handleActivate = async (broadcastId, type) => {
    try {
      const response = await axiosPrivate.post(apiURL + '/job/activate', {
        broadcastId,
      });
      if (response.status === 200) {
        StatusNotification(
          'success',
          'Broadcast Activated',
          'The broadcast was successfully activated.'
        );
        // Refresh the broadcasts list after activation
        await refreshActiveBroadcast(type);
      } else {
        StatusNotification(
          'error',
          'Activation Failed',
          'Failed to activate the broadcast. Please try again.'
        );
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Activation Failed',
        error?.response?.data?.message || 'An error occurred while activating the broadcast.'
      );
    }
  };

const refreshActiveBroadcast = async (type) => {
  if (!selectedBroadcast?._id) return;
  setIsRefreshing(true);
  try {
    const _id = selectedBroadcast?._id;
    const shortUrlId = selectedBroadcast?.sms?.shortUrlId;

    console.log({ type });
    const response =
      shortUrlId && type === 'sms'
        ? await axiosPrivate.get(`${shortURL}${_id}/${shortUrlId}`)
        : await axiosPrivate.get(`${apiURL}/current/${_id}`);
    const { updatedBroadcast, stats } = response.data;

    console.log({ updatedBroadcast, stats, data: response.data });
    dispatch({ type: 'SET_SELECTED_BROADCAST', data: updatedBroadcast });
    dispatch({ type: 'SET_SELECTED_BROADCAST_SHORT_STATS', data: stats });
  } catch (error) {
    console.log({ error });
    StatusNotification(
      'error',
      'Refresh Failed',
      error?.response?.data?.message || 'An error occurred while refreshing the broadcast.'
    );
  } finally {
    setIsRefreshing(false);
  }
};

  const count =
    selectedBroadcast && broadcasts.length > 0
      ? selectedBroadcast.type === 'sms'
        ? [
            { task: 'Sent', title: selectedBroadcast?.sms?.sent },
            { task: 'Delivered', title: selectedBroadcast?.sms?.delivered },
            { task: 'Stats', title: selectedBroadcast?.sms?.read || 0 },
            { task: 'Failed', title: selectedBroadcast?.sms?.failed },
          ]
        : selectedBroadcast.type === 'whatsapp'
        ? [
            { task: 'Sent', title: selectedBroadcast?.whatsApp?.sent },
            { task: 'Delivered', title: selectedBroadcast?.whatsApp?.delivered },
            { task: 'Read', title: selectedBroadcast?.whatsApp?.read },
            { task: 'Failed', title: selectedBroadcast?.whatsApp?.failed },
          ]
        : []
      : [];

  let icon = <CheckCircleOutlined />;
  // let color = 'default';
  let tagStyle = { width: '92px' };
  let mobileStatusIcon = StatusCompleted;

  const columns = [
    {
      title: '#',
      key: 'counter',
      width: '4%',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      align: 'center',
    },
    {
      title: 'Broadcast Name',
      dataIndex: 'broadcastName',
      key: 'broadcastName',
      width: '16%',
      align: 'center',
      sorter: (a, b) => a.broadcastName.localeCompare(b.broadcastName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (status, record) => (
        <Row justify='center'>
          <Col span={24} style={{ textAlign: screenSize === 'xs' ? 'center' : 'left' }}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={
                status === 'scheduled'
                  ? toolTip('activate')
                  : status === 'failed'
                  ? toolTip(record?.failureReason)
                  : null
              }
            >
              <span>
                {screenSize === 'xs' ? (
                  <Image height={12} src={mobileStatusIcon} preview={false} />
                ) : (
                  <Tag
                    icon={icon}
                    color={
                      status === 'scheduled'
                        ? 'warning'
                        : status === 'active'
                        ? 'processing'
                        : status === 'failed'
                        ? 'error'
                        : status === 'completed'
                        ? 'grey'
                        : 'default'
                    }
                    style={
                      status === 'scheduled' ? { ...tagStyle, cursor: 'pointer' } : { ...tagStyle }
                    }
                    onClick={() => {
                      if (status === 'scheduled') {
                        handleActivate(record._id, record.type);
                      }
                    }}
                  >
                    {status}
                  </Tag>
                )}
              </span>
            </Tooltip>
          </Col>
        </Row>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Date',
      dataIndex: 'startingDateTime',
      key: 'createdAt',
      align: 'center',
      width: '8%',
      render: (date, record) => dayjs(date).fromNow(),
      sorter: (a, b) => {
        if (a.createdAt && b.createdAt) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '8%',
      render: (date, record) => {
        let typeColor = 'success';

        if (record.type === 'sms') {
          typeColor = 'processing';
        }
        return (
          <>
            <Tag color={typeColor}>{record.type}</Tag>
          </>
        );
      },
    },
    {
      title: 'Batch Size',
      key: 'batchSize',
      dataIndex: 'batchSize',
      responsive: ['lg'],
      width: '2%',
      align: 'center',
      sorter: (a, b) => a.batchSize - b.batchSize,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Dispatched',
      key: 'dispatched',
      dataIndex: 'bullMQ',
      render: (bullMQ) => bullMQ?.dispatched,
      responsive: ['lg'],
      align: 'center',
      sorter: (a, b) => a.bullMQ.dispatched - b.bullMQ.dispatched,
      sortDirections: ['descend', 'ascend'],
    },
    // ... (other columns)
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      dataIndex: 'action',
      render: (text, record) => {
        return (
          <Space>
            {isLoading[record._id] === 'view' ? (
              <LoadingIcon color={GlobalColors.darkGray} />
            ) : canResend(record, role) ? (
              <Tooltip placement='top' color={GlobalColors.mainPurple} title='Resend Broadcast'>
                <RedoOutlined onClick={() => handleResendClick(record._id)} />
                {record._id === resendRecordId && (
                  <Popconfirm
                    title='Are you sure you want to resend?'
                    open={resendRecordId !== null}
                    onConfirm={handleConfirmResend}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancelResend}
                  >
                    <Button type='primary' hidden>
                      Confirm Resend
                    </Button>{' '}
                    {/* Hidden button as a placeholder for focusing */}
                  </Popconfirm>
                )}
              </Tooltip>
            ) : (
              <Tooltip placement='top' color={GlobalColors.mainPurple} title='View Broadcast'>
                <EyeFilled onClick={() => handleView(record)} />
              </Tooltip>
            )}
            {/* {record.status !== 'completed' && (
              <Tooltip placement='top' color={GlobalColors.mainPurple} title='Edit Broadcast'>
                {isLoading[record._id] === 'edit' ? (
                  <LoadingIcon color={GlobalColors.darkGray} />
                ) : (
                  <EditFilled onClick={() => handleEdit(record._id)} />
                )}
              </Tooltip>
            )} */}
            {record.status === 'scheduled' && role !== 'viewer' && (
              <Tooltip placement='top' color={GlobalColors.mainPurple} title='Delete Broadcast'>
                {isLoading[record._id] === 'delete' ? (
                  <LoadingIcon color={GlobalColors.darkGray} />
                ) : (
                  <DeleteFilled onClick={() => handleDelete(record._id)} />
                )}
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className='tabled' style={{ marginBottom: 10, marginTop: screenSize !== 'xs' ? 78 : 0 }}>
        {selectedBroadcast && (
          <>
            {' '}
            <Title
              level={3}
              className='sectionHeader'
              style={screenSize !== 'xs' ? {} : { display: 'flex', justifyContent: 'center' }}
            >
              {selectedBroadcast.broadcastName}
            </Title>
            <Row className='rowgap-vbox' gutter={[24, 0]}>
              {count.map((c, index) => (
                <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
                  <Card bordered={false} className='criclebox'>
                    <div className='number'>
                      <Row align='middle' gutter={[24, 0]}>
                        <>
                          <Col xs={10}>
                            <span>{c.task}</span>
                            <Title level={3}>
                              {c.title}
                              <small>{c.persent}</small>
                            </Title>
                          </Col>
                          <Col xs={14}>
                            <div>
                              <NavLink to={`/broadcasts/status/${c.task.toLowerCase()}`}>
                                <div style={{ cursor: 'pointer' }}>
                                  <RadialBarChart
                                    value={Math.min(
                                      Math.round((c.title / selectedBroadcast?.batchSize) * 100),
                                      100
                                    )}
                                    task={c.task}
                                  />
                                </div>
                              </NavLink>
                            </div>
                          </Col>
                        </>
                      </Row>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
        <Row>
          {role !== 'viewer' && (
            <Col push={1} span={24}>
              <Row justify='space-between'>
                <Col span={12}>
                  <NavLink to='broadcasts/create'>
                    <Tooltip
                      placement='top'
                      color={GlobalColors.mainPurple}
                      title={toolTip('Create Broadcast')}
                    >
                      <span className='icon' style={{ fontSize: 26 }}>
                        <PlusOutlined style={{ color: GlobalColors.mainPurple }} />
                      </span>
                    </Tooltip>
                  </NavLink>
                </Col>

                <Col span={12} style={{ textAlign: 'end' }} pull={2}>
                  <NavLink to='templates'>
                    <Tooltip
                      placement='top'
                      color={GlobalColors.mainPurple}
                      title={toolTip('WhatsApp Templates')}
                    >
                      <span className='icon' style={{ fontSize: 26 }}>
                        <UnorderedListOutlined style={{ color: GlobalColors.mainPurple }} />
                      </span>
                    </Tooltip>
                  </NavLink>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Card
              bordered={false}
              className='criclebox tablespace mb-24'
              title={
                <Row justify='space-between'>
                  <Col>Broadcast</Col>
                  <Col>
                    <span
                      className='icon'
                      onClick={() => refreshActiveBroadcast(selectedBroadcast?.type)}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectedBroadcast && (
                        <SyncOutlined spin={isRefreshing} style={{ fontSize: 20 }} />
                      )}
                    </span>
                  </Col>
                </Row>
              }
            >
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={broadcasts}
                pagination={{
                  position: ['bottomCenter'],
                  current: currentPage,
                  size: 'small',
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  },
                }}
                className='ant-border-space'
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Broadcast;
