import React from 'react';
import { Row, Col, Typography, Button, Image } from 'antd';
import { Church, Building, School, Calendar, Hospital, ShoppingBag } from 'lucide-react';
import { Link } from 'react-router-dom';
import SectionHeader from './SectionHeader';
import GlobalColors from '../assets/colors/GlobalColors';
import elections from '../assets/images/elections.png';

const { Title, Text } = Typography;

const UseCases = () => {
  const useCases = [
    {
      icon: <Church size={40} color={GlobalColors.lightPurple} />,
      title: 'Churches',
      description:
        'Churches can use our platform to engage their congregation, send daily devotionals, and organize community events effortlessly.',
    },
    {
      icon: <Building size={40} color={GlobalColors.lightPurple} />,
      title: 'Small Businesses',
      description:
        'Small businesses can leverage our chatbot to provide instant customer support, schedule appointments, and send personalized promotions.',
    },
    {
      icon: <School size={40} color={GlobalColors.lightPurple} />,
      title: 'Schools',
      description:
        'Schools can streamline communication with parents, send homework reminders, and conduct quick surveys for feedback on school initiatives.',
    },
    {
      icon: <Calendar size={40} color={GlobalColors.lightPurple} />,
      title: 'Event Companies',
      description:
        'Event companies can manage RSVPs, send event updates, and gather post-event feedback seamlessly through our WhatsApp platform.',
    },
    {
      icon: <Hospital size={40} color={GlobalColors.lightPurple} />,
      title: 'Healthcare Providers',
      description:
        'Healthcare providers can use our chatbot for appointment reminders, health tips distribution, and quick patient follow-ups.',
    },
    {
      icon: <ShoppingBag size={40} color={GlobalColors.lightPurple} />,
      title: 'Retail Stores',
      description:
        'Retail stores can enhance customer experience with order updates, personalized recommendations, and instant query resolutions.',
    },
  ];

  return (
    <div
      id='use-cases'
      className='containerRadius container'
      style={{ backgroundColor: '#dfdfdf' }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SectionHeader
            title='Use Cases'
            isFront={true}
            subtitle='Discover how various industries are already benefiting from our WhatsApp chatbot platform'
          />
        </Col>
        <Col
          xs={24}
          md={12}
          style={{ padding: '32px', backgroundColor: GlobalColors.darkPurple, borderRadius: 6 }}
        >
          <div style={{ marginBottom: '8px' }}>
            <Text style={{ color: '#ffffff', fontSize: '14px', fontWeight: 'bold' }}>
              South Africa, 2024
            </Text>
          </div>
          <Title level={2} style={{ color: '#ffffff', marginBottom: '16px' }}>
            Discover how Surveyr was successfully used by the SACC election observers across South
            Africa for the 2024 elections.
          </Title>
          <Button type='primary'>
            <Link to='/blogs'>Read their story</Link>
          </Button>
        </Col>
        <Col xs={24} md={12} style={{ borderRadius: 6 }}>
          <Image src={elections} alt='Use Cases' preview={false} style={{ borderRadius: 6 }} />
        </Col>
        <Col span={24} style={{ marginBottom: 28 }}>
          <Row gutter={[32, 32]} justify='space-between'>
            {useCases.map((useCase, index) => (
              <Col xs={24} md={7} className='blockStyle' key={index}>
                <Row style={{ padding: 12 }}>
                  <Col span={24} style={{ marginBottom: 16, color: '#1890ff' }}>
                    {useCase.icon}
                  </Col>
                  <Col span={24}>
                    <Title level={3} className='featuresTitle'>
                      {useCase.title}
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Text>{useCase.description}</Text>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default UseCases;
