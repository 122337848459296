import React, { useState } from 'react'
import {
  Row,
  Col,
  Select,
  Input,
  Button,
  Typography,
  Menu,
  Dropdown,
  Form,
  Space,
  Card,
  Tag,
} from 'antd'
import { Field } from 'rc-field-form'
import {
  PlusOutlined,
  BulbOutlined,
  DeleteFilled,
  ShrinkOutlined,
} from '@ant-design/icons'
import { HappyProvider } from '@ant-design/happy-work-theme'

import GlobalColors from '../assets/colors/GlobalColors'
import getScreenSize from '../utils/getScreenSize'
import StatusNotification from './StatusNotification'
import isValidUrl from '../utils/isValidUrl'
import shortenUrl from '../utils/shortenUrl'
import usePrivateAxios from '../hooks/usePrivateAxios'

const { Option } = Select
const { TextArea } = Input
const { Title, Text } = Typography

const TemplateButtons = ({ form, screenSize, handleValuesChange }) => {
  const [buttons, setButtons] = useState([])
  const [urlType, setUrlType] = useState('static')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const axios = usePrivateAxios()

  const isGroupingValid = (newButtons) => {
    const types = newButtons.map((button) => button.type)
    if (types.length === 3) {
      // Valid: Q, Q, U or U, Q, Q
      return (
        (types[0] === 'custom' &&
          types[1] === 'custom' &&
          types[2] === 'website') ||
        (types[0] === 'website' &&
          types[1] === 'custom' &&
          types[2] === 'custom')
      )
    }
    // Valid for 1 or 2 buttons
    return true
  }

  const addCustomButton = () => {
    const newButtons = [
      ...buttons,
      { type: 'custom', text: '', urlType: '', url: '', isShortened: false },
    ]

    if (buttons.length < 3 && isGroupingValid(newButtons)) {
      setButtons(newButtons)
    } else {
      StatusNotification(
        'error',
        'Invalid grouping or maximum of 3 buttons reached'
      )
    }
  }

  const addVisitWebsiteButton = () => {
    const newButtons = [
      ...buttons,
      {
        type: 'website',
        text: '',
        urlType: 'static',
        url: '',
        isShortened: false,
      },
    ]

    if (buttons.length < 3 && isGroupingValid(newButtons)) {
      setButtons(newButtons)
    } else {
      StatusNotification(
        'error',
        'Invalid grouping or maximum of 3 buttons reached'
      )
    }
  }

  const handleMenuClick = (e) => {
    if (e.key === 'custom') {
      addCustomButton()
    } else if (e.key === 'visitWebsite') {
      addVisitWebsiteButton()
    }
  }
  const checkForErrors = () => {
    // Assuming you have a way to identify your dynamic URL fields, for example by name
    const errors = form.getFieldsError().filter(({ errors }) => {
      return errors.length > 0
    })
    const hasUrlErrors = errors.some((error) => {
      return error.name.join('.').includes('URL_EXAMPLE')
    })
    setIsButtonDisabled(hasUrlErrors)
  }
  const handleShortenUrl = async (index) => {
    const fieldName = `URL_EXAMPLE_${index}`
    const buttonValue = form.getFieldValue(fieldName)
    try {
      const { isSuccess, payload, error } = await shortenUrl(buttonValue, axios)
      if (isSuccess) {
        console.log({ fieldName })
        form.setFieldsValue({
          [fieldName]: payload,
        })
        const updatedButtons = buttons.map((btn, btnIndex) =>
          btnIndex === index ? { ...btn, isShortened: true } : btn
        )
        setButtons(updatedButtons)
      } else {
        StatusNotification(
          'error',
          'Error shortening URL',
          'Please try again, if it fails again, contact support'
        )
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Error shortening URL',
        'Please try again, if it fails again, contact support'
      )
    }
  }
  const handleRemoveButton = (index) => {
    const newButtons = [...buttons]
    newButtons.splice(index, 1)
    setButtons(newButtons)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup title="Quick reply buttons">
        <Menu.Item key="custom">Custom</Menu.Item>
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.ItemGroup title="Call-to-action buttons">
        <Menu.Item key="visitWebsite">
          Visit website (2 buttons maximum)
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  )

  const handleUrlTypeChange = (newUrlType, buttonIndex) => {
    const updatedButtons = buttons.map((button, index) => {
      if (index === buttonIndex) {
        return { ...button, urlType: newUrlType }
      }
      return button
    })
    setButtons(updatedButtons)
  }

  const handleDelete = (button, index) => {
    const textFieldName =
      button.type === 'custom'
        ? `BUTTONS_QUICK_REPLY_text_${index}`
        : `BUTTONS_URL_text_${index}`

    const urlFieldName =
      button.urlType === 'dynamic' ? `URL_EXAMPLE_${index}` : null

    const fieldName = `BUTTONS_URL_${index}`
    form.setFieldsValue({ [textFieldName]: undefined })
    form.setFieldsValue({ [fieldName]: undefined })
    if (urlFieldName) form.setFieldsValue({ [urlFieldName]: undefined })

    handleRemoveButton(index, textFieldName)

    const allValues = form.getFieldsValue()
    handleValuesChange({}, allValues)
  }

  const handleUrlChange = (e, fieldName) => {
    let value = e.target.value
    // if (urlType === 'dynamic' && isValidUrl(value) && !value.endsWith('/')) {
    //   value += '/'
    // }
    form.setFieldsValue({ [fieldName]: value })
  }

  return (
    <div style={{ paddingTop: 8 }}>
      <Row gutter={[0, 12]}>
        <Col span={24}>
          {buttons.map((button, index) => (
            <Row gutter={[8, 12]} key={index}>
              <Col
                span={24}
                style={{
                  borderRadius: 6,
                  marginBottom: 8,
                  backgroundColor: GlobalColors.whitePurple,
                  padding: 8,
                }}
              >
                <Row gutter={[0, 8]} justify="space-between" align="middle">
                  <Col span={24}>
                    <Tag>
                      {button.type !== 'website'
                        ? 'Quick reply'
                        : 'Call to action'}
                    </Tag>
                  </Col>
                  <Col span={22}>
                    <Row gutter={[8, 4]} justify="center">
                      {button.type === 'custom' && (
                        <Col span={24}>
                          <Field
                            key={index}
                            name={`BUTTONS_QUICK_REPLY_text_${index}`}
                            rules={[
                              {
                                required: true,
                                message: 'Custom button text is required!',
                              },
                            ]}
                          >
                            {(control, meta) => {
                              return (
                                <>
                                  <Input
                                    {...control}
                                    placeholder="Enter button text"
                                    showCount
                                    size="small"
                                    maxLength={25}
                                  />
                                  {meta.errors.length > 0 && (
                                    <div style={{ color: 'red' }}>
                                      {meta.errors[0]}
                                    </div>
                                  )}
                                </>
                              )
                            }}
                          </Field>
                        </Col>
                      )}
                      {button.type === 'website' && (
                        <>
                          <Col span={24}>
                            <Field
                              key={index}
                              name={`BUTTONS_URL_text_${index}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Website button text is required!',
                                },
                              ]}
                            >
                              {(control, meta) => {
                                return (
                                  <>
                                    <Input
                                      {...control}
                                      placeholder="Enter button text"
                                      showCount
                                      size="small"
                                      maxLength={25}
                                    />
                                    {meta.errors.length > 0 && (
                                      <div style={{ color: 'red' }}>
                                        {meta.errors[0]}
                                      </div>
                                    )}
                                  </>
                                )
                              }}
                            </Field>
                          </Col>
                          <Col xs={24} md={6}>
                            <Select
                              value={button.urlType}
                              onChange={(value) =>
                                handleUrlTypeChange(value, index)
                              }
                            >
                              <Option value="static">Static</Option>
                              <Option value="dynamic">Dynamic</Option>
                            </Select>
                          </Col>
                          {button.urlType === 'static' && (
                            <Col xs={24} md={18}>
                              <Field
                                key={index}
                                name={`BUTTONS_URL_${index}`}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Website URL is required!',
                                  },
                                  {
                                    type: 'url',
                                    message: 'Please insert a valid URL!',
                                  },
                                ]}
                              >
                                {(control, meta) => {
                                  return (
                                    <>
                                      <Input
                                        {...control}
                                        placeholder="https://www.example.com/"
                                        showCount
                                        size="small"
                                        maxLength={2000}
                                        addonAfter={
                                          button.urlType === 'dynamic'
                                            ? '{{1}}'
                                            : ''
                                        }
                                      />
                                      {meta.errors.length > 0 && (
                                        <div style={{ color: 'red' }}>
                                          {meta.errors[0]}
                                        </div>
                                      )}
                                    </>
                                  )
                                }}
                              </Field>
                            </Col>
                          )}
                          {button.urlType === 'dynamic' && (
                            <Col xs={24} md={18}>
                              <Space.Compact
                                style={{
                                  width: '100%',
                                }}
                              >
                                <Field
                                  key={index}
                                  name={`URL_EXAMPLE_${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        'Example website URL is required!',
                                    },
                                    {
                                      type: 'url',
                                      message: 'Please insert a valid URL!',
                                    },
                                  ]}
                                >
                                  {(control, meta) => {
                                    checkForErrors()
                                    return (
                                      <>
                                        <Input
                                          {...control}
                                          disabled={buttons[index].isShortened}
                                          placeholder="Enter full URL for https://www.example.com/{{1}}"
                                          size="small"
                                        />
                                      </>
                                    )
                                  }}
                                </Field>
                                <HappyProvider>
                                  <Button
                                    onClick={() => handleShortenUrl(index)}
                                    disabled={
                                      isButtonDisabled ||
                                      buttons[index].isShortened
                                    }
                                    style={{  height: 40 }}
                                  >
                                    <ShrinkOutlined
                                      style={{
                                        cursor: isButtonDisabled
                                          ? 'not-allowed'
                                          : 'pointer',
                                        fontSize: 19,
                                        paddingTop: 6,
                                        paddingLeft: 4,
                                
                                      }}
                                    />
                                  </Button>
                                </HappyProvider>
                              </Space.Compact>
                              {isButtonDisabled && (
                                <div style={{ color: 'red' }}>
                                  {'Please insert valid URL'}
                                </div>
                              )}
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col span={1} order={4}>
                    <DeleteFilled
                      onClick={() => {
                        handleDelete(button, index)
                      }}
                      style={{ color: GlobalColors.darkGray, fontSize: 16 }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
        <Col span={24}>
          <Text style={{ color: GlobalColors.darkGray }}>
            <BulbOutlined /> Only three buttons allowed, valid groupings [Quick
            Reply, Quick Reply] or [Quick Reply, Quick Reply, URL] or [URL,
            Quick Reply, Quick Reply].
          </Text>
        </Col>
        <Col span={24}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button icon={<PlusOutlined />}  block>
              Add a button
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </div>
  )
}

export default TemplateButtons
