import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  Grid,
  Badge,
  Statistic,
  Skeleton,
  Typography,
  Space,
  Tag,
  Switch,
} from 'antd';
import Confetti from 'react-confetti';
import { SyncOutlined } from '@ant-design/icons';
import { HappyProvider } from '@ant-design/happy-work-theme';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from './StatusNotification';
import LoadingIcon from './LoadingIcon';
import getScreenSize from '../utils/getScreenSize';

import PayfastButtonFree from './PayfastButtonFree';
import MessagesPriceSlider from './MessagesPriceSlider';

import GlobalColors from '../assets/colors/GlobalColors';

const basicAmount = process.env.REACT_APP_SUBSCRIPTION_BASIC;
const premiumAmount = process.env.REACT_APP_SUBSCRIPTION_PREMIUM;

const cancelURL = '/api/users/subscription/cancel';
const extraDetailsURL = '/api/users/extradetails';

const { useBreakpoint } = Grid;
const { Text } = Typography;

const Subscription = () => {
  let signature = '';
  let values = {};

  const subscriberOption = 'Standard';
  const {
    user: {
      organizationId,
      userId,
      discount,
      planType,
      subscriptionStatus,
      counters: organizationCounters,
    },
    exchangeRate: { rate },
  } = useSelector((state) => state);
  const itemDescription = 'Standard subscription, with 1000 free messages';

  const screenSize = getScreenSize(useBreakpoint());
  const history = useHistory();
  const axiosPrivate = usePrivateAxios();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [extraDetails, setExtraDetails] = useState(undefined);
  const [topUpAmount, setTopUpAmount] = useState({});
  const [currency, setCurrency] = useState('ZAR');

  const previousLocation = history.location.state?.from;
  const [showConfetti, setShowConfetti] = useState(previousLocation === '/subscription/complete');

  useEffect(() => {
    if (history.action === 'PUSH') setIsLoading(false);
  }, [history]);

  useEffect(() => {
    let confettiTimeout;
    if (showConfetti) {
      confettiTimeout = setTimeout(() => setShowConfetti(false), 10000);
    }
    return () => clearTimeout(confettiTimeout);
  }, [showConfetti]);

  useEffect(() => {
    if (extraDetails) return;
    getUserDetails();
    getOrganizationCounters();
  }, [extraDetails]);

  const getUserDetails = async () => {
    try {
      const response = await axiosPrivate.post(extraDetailsURL, { userId });
      const { name: firstName, lastName, email: emailAddress } = response.data;
      setExtraDetails({ firstName, lastName, emailAddress });
    } catch (error) {
      console.log(error);
    }
  };

  const cancelSubscription = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(cancelURL, { userId });
      if (response.status === 200) {
        StatusNotification(
          'success',
          'Subscription Cancelled',
          'Your subscription has been successfully cancelled'
        );
        refreshSubscription();
      }
    } catch (error) {
      console.error(error);
      StatusNotification(
        'error',
        'Problem cancelling subscription',
        'Support has been alerted, and will look into the issue'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const refreshSubscription = async () => {
    try {
      const response = await axiosPrivate.post(extraDetailsURL, { userId });
      dispatch({ type: 'REFRESH_SUBSCRIPTION', data: response.data });
    } catch (error) {
      console.error(error);
    }
  };

  const updateView = async () => {
    getOrganizationCounters();
    refreshSubscription();
  };

  const getOrganizationCounters = async () => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.get(`/api/organizations/counters/${organizationId}`);
      const counters = response.data.counters;
      dispatch({ type: 'SET_COUNTERS', data: counters });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgrade = () => {
    setTimeout(() => {
      history.push('/subscription-upgrade');
    }, 700);
  };

  const handleCurrencyChange = (checked) => {
    setCurrency(checked ? 'ZAR' : 'USD');
  };

  const getCurrencySymbol = () => (currency === 'ZAR' ? 'R' : '$');

  const calculatePlanAmount = () => {
    let amount = planType === 'basic' ? parseFloat(basicAmount) : parseFloat(premiumAmount);
    if (isNaN(amount)) {
      console.error('Invalid plan amount');
      return 'N/A';
    }

    // Apply 20% discount
    const discountAmount = amount * 0.2;
    let finalAmount = amount - discountAmount;

    if (currency === 'ZAR') {
      finalAmount *= rate;
    }

    // Round to nearest whole number
    finalAmount = Math.round(finalAmount);

    return `${getCurrencySymbol()}${finalAmount}`;
  };

  values = {
    subscriberOption,
    itemDescription,
    userId,
    planType,
  };

  let ribbonText;
  let ribbonColor;
  let buttonText;
  let planAmount = calculatePlanAmount();

  if (planType === 'free') {
    ribbonText = 'Free Plan';
    ribbonColor = 'gray';
    buttonText = 'Top Up';
  } else if (planType === 'basic') {
    ribbonText = 'Basic Plan  ';
    ribbonColor = 'cyan';
    buttonText = 'Upgrade To Premium';
  } else if (planType === 'premium') {
    ribbonText = 'Premium Plan';
    ribbonColor = 'volcano';
    buttonText = 'Downgrade To Basic';
  } else {
    ribbonText = 'Enterprise Plan';
    ribbonColor = GlobalColors.mainPurple;
  }

  const getDiscountDisplay = () => {
    if (!discount?.amount) return null;

    let discountAmount = parseFloat(discount.amount);
    if (isNaN(discountAmount)) return null;

    if (currency === 'ZAR') {
      discountAmount *= rate;
    }

    return `${getCurrencySymbol()}${discountAmount.toFixed(2)}`;
  };

  console.log({ rate });

  return (
    <div style={{ marginTop: screenSize !== 'xs' ? 0 : 0 }}>
      {showConfetti && (
        <div className='confetti-wrapper'>
          <Confetti numberOfPieces={200} />
        </div>
      )}
      <Row gutter={[24, 24]} justify='center'>
        <Col xs={24} md={12}>
          <Badge.Ribbon
            text={
              <>
                <SyncOutlined spin={isLoading} onClick={updateView} /> {ribbonText}
              </>
            }
            color={ribbonColor}
            size='large'
          >
            <Card
              bordered={false}
              className='header-solid ant-invoice-card'
              title={[
                <Row
                  key='subscription-row'
                  gutter={[24, 0]}
                  className='ant-row-flex ant-row-flex-middle'
                >
                  <Col xs={12} md={7}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '8px' }}>USD</span>
                      <Switch checked={currency === 'ZAR'} onChange={handleCurrencyChange} />
                      <span style={{ marginLeft: '8px' }}>ZAR</span>
                    </div>
                  </Col>
                  {planType !== 'free' && (
                    <Col xs={24} md={6}>
                      {subscriptionStatus !== 'active' ? null : (
                        <Space>
                          <h6 className='m-0 font-semibold'>
                            {planAmount} <span style={{ fontSize: 10 }}>p/m</span>
                          </h6>
                          <Text style={{ color: '#b4b4b4', fontSize: 10 }}>
                            <Tag color={GlobalColors.lightGray} style={{ color: 'black' }}>
                              {discount?.amount
                                ? `20% disccount, yearly plan`
                                : 'Monthly Subscription'}
                            </Tag>
                          </Text>
                        </Space>
                      )}
                    </Col>
                  )}
                </Row>,
              ]}
            >
              <Row>
                <Col span={24} md={24} className='mb-24'>
                  <Skeleton loading={isLoading}>
                    <Col span={24}>
                      {Object.keys(organizationCounters).length > 0 && (
                        <Col span={24}>
                          <Row gutter={16} justify='center'>
                            <Col span={12} style={{ textAlign: 'center' }}>
                              <Statistic
                                title='Purchased Message Bundles'
                                value={organizationCounters.topUpCredit}
                              />
                            </Col>
                            <Col span={12} style={{ textAlign: 'center' }}>
                              <Statistic
                                title='System Message Bundles'
                                value={organizationCounters.monthlyFreeBundles}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )}
                    </Col>
                  </Skeleton>
                </Col>
                <Col span={24} md={24}>
                  <MessagesPriceSlider
                    setTopUpAmount={setTopUpAmount}
                    currency={currency}
                    exchangeRate={rate}
                  />
                </Col>
              </Row>
              <Row gutter={[24, 0]} className='ant-row-flex ant-row-flex-middle'>
                <Col xs={24}>
                  <Row gutter={[8, 0]}>
                    <Col span={12}>
                      {extraDetails && (
                        <PayfastButtonFree
                          topUpAmount={topUpAmount}
                          exchangeRate={rate}
                          values={values}
                          extraDetails={extraDetails}
                          signature={signature}
                          setIsLoading={setIsLoading}
                          isLoading={isLoading}
                          btn={planType}
                        />
                      )}
                    </Col>
                    {extraDetails && (
                      <Col span={12}>
                        <HappyProvider>
                          <Button type='primary' block onClick={handleUpgrade}>
                            Change Plan
                          </Button>
                        </HappyProvider>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Badge.Ribbon>
          <Row>
            <Col
              span={24}
              style={{
                textAlign: 'right',
                paddingTop: 8,
                cursor: 'pointer',
              }}
            >
              {planType !== 'free' && !discount?.amount ? (
                <Tag onClick={cancelSubscription}>Cancel Plan</Tag>
              ) : (
                planType !== 'free' && <Tag>Contact support to cancel yearly plan</Tag>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Subscription;
