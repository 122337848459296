import {
  SET_SELECTED_PDFME_TEMPLATE,
  SET_THREAD_ID,
  UPDATE_CONTENT_GROUPS,
} from '../actions/types';

const initialState = {
  threadId: '',
  selectedPdfMeTemplate: {},
};

const pdfmeTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_PDFME_TEMPLATE:
      return {
        ...state,
        selectedPdfMeTemplate: action.data,
      };
    case UPDATE_CONTENT_GROUPS:
      return {
        ...state,
        selectedPdfMeTemplate: {
          ...state.selectedPdfMeTemplate,
          contentGroups: action.data,
        },
      };
    case SET_THREAD_ID:
      return {
        ...state,
        threadId: action.data,
      };
    default:
      return state;
  }
};

export default pdfmeTemplateReducer;
