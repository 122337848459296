import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Tooltip, Typography, Segmented, ConfigProvider, Image } from 'antd';
import { RedoOutlined, SaveOutlined, SendOutlined, EditOutlined } from '@ant-design/icons';
// import usePrivateAxios from '../hooks/usePrivateAxios';
import GlobalColors from '../assets/colors/GlobalColors';
import LoadingIcon from './LoadingIcon';
import StatusNotification from './StatusNotification';
import magic from '../assets/images/magic.svg';

const { TextArea } = Input;
const { Paragraph } = Typography;

const PromptBox = ({
  dispatch,
  axiosPrivate,
  type,
  title,
  message,
  isMapped,
  isManual,
  organizationId,
  surveyId,
  threadId,
  pdfmeTemplateId,
}) => {
  const [userInput, setUserInput] = useState('');
  const [messageResponse, setMessageResponse] = useState(
    isMapped ? { content: [{ type: type, [type]: { value: message } }] } : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [inputMode, setInputMode] = useState(isManual ? 'Manual' : 'AI');

  useEffect(() => {
    if (isMapped && message) {
      setMessageResponse({ content: [{ type: type, [type]: { value: message } }] });
    }
  }, [message, isMapped, type]);

  const handleSend = async () => {
    if (userInput === '') return;

    setIsLoading(true);
    setUserInput('');
    try {
      const response = await axiosPrivate.post('/api/ai/thread/run', {
        organizationId: organizationId,
        surveyId: surveyId,
        userMessage: userInput,
      });
      const message = await checkResponse(response.data);
      setMessageResponse(message);
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error (e.g., show notification to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const checkResponse = async (response) => {
    if (
      response &&
      response.content &&
      response.content[0] &&
      response.content[0].type === 'text'
    ) {
      return response;
    }
    if (
      response &&
      response.content &&
      response.content[0] &&
      response.content[0].type === 'image_file'
    ) {
      const fileId = response.content[0].image_file.file_id;
      const uploaded = await axiosPrivate.post('/api/ai/thread/messages/content/upload', {
        fileId,
        pdfmeTemplateId,
      });

      return {
        id: 'cloudinary',
        content: [{ type: 'image', image: { value: uploaded.data.imageUrl } }],
      };
    }
  };

  const handleChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleTryAgain = () => {
    setUserInput('Please try again');
  };

  const handleModeChange = (newMode) => {
    setInputMode(newMode);
    // Additional logic based on mode change, if necessary
  };

  const handleSave = async () => {
    if (userInput === '' && inputMode !== 'AI') return;
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post('/api/ai/thread/message/save', {
        organizationId: organizationId,
        threadId: threadId,
        payload: {
          title: title,
          prompt: inputMode === 'AI' ? userInput : '',
          message:
            inputMode === 'AI'
              ? messageResponse
              : { id: 'manual', content: [{ type: type, [type]: { value: userInput } }] },
        },
      });
      dispatch({ type: 'UPDATE_CONTENT_GROUPS', data: response.data.contentGroups });
      StatusNotification('success', 'Success', 'Message saved');
      setUserInput('');
    } catch (error) {
      console.error('Error saving message:', error);
      // Handle error (e.g., show notification)
    } finally {
      setIsLoading(false);
    }
  };
  const renderResponseContent = () => {
    if (isLoading) {
      return <LoadingIcon color={GlobalColors.darkGray} fontSize={28} style={{ paddingTop: 18 }} />;
    }

    if (!messageResponse || !messageResponse.content || messageResponse.content.length === 0) {
      if (inputMode === 'AI') {
        return 'Response will appear here...';
      }
      return '';
    }

    return messageResponse.content.map((item, index) => {
      if (item.type === 'text') {
        return (
          <Paragraph key={index} style={{ color: GlobalColors.darkGray }}>
            {item.text.value}
          </Paragraph>
        );
      } else if (item.type === 'image') {
        return (
          <Image
            key={index}
            src={item.image.value}
            alt='Response Image'
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        );
      }
      return null; // For other types not handled
    });
  };

  return (
    <div style={{ background: '#dad2e4', padding: '16px', borderRadius: '8px' }}>
      <Row gutter={[0, 16]}>
        <Col
          span={24}
          style={isLoading ? { textAlign: 'center', minHeight: 50 } : { minHeight: 50 }}
        >
          {renderResponseContent()}
        </Col>
        <Col span={24}>
          <TextArea
            aria-label='Message input'
            placeholder={`${inputMode === 'AI' ? 'Prompt for' : 'Enter'} [ ${title} ]`}
            autoSize={{ minRows: 2 }}
            value={userInput}
            onChange={handleChange}
          />
        </Col>
        <Col span={4} style={{ paddingLeft: 2 }}>
          {/* <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  itemSelectedBg: GlobalColors.lightPurple,
                },
              },
            }}
          > */}
            <Segmented
              options={[
                {
                  label: (
                    <Tooltip title='AI Mode'>
                      <img src={magic} alt='AI' style={{ width: 20 }} />
                    </Tooltip>
                  ),
                  value: 'AI',
                },
                {
                  label: (
                    <Tooltip title='Manual Mode'>
                      <EditOutlined style={{ fontSize: '18px' }} />
                    </Tooltip>
                  ),
                  value: 'Manual',
                },
              ]}
              value={inputMode}
              onChange={handleModeChange}
            />
          {/* </ConfigProvider> */}
        </Col>
        <Col span={20}>
          <Row justify='end'>
            <Col
              style={{
                height: 32,
                paddingTop: 7,
                paddingBottom: 5,
                marginRight: 8,
                backgroundColor: 'white',
                borderRadius: 6,
              }}
            >
              {inputMode === 'AI' && !isLoading && (
                <Tooltip title='Send to AI'>
                  <SendOutlined
                    onClick={handleSend}
                    style={{
                      fontSize: '18px',
                      color: GlobalColors.darkGray,
                      margin: '0 12px',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </Col>
            <Col
              style={{
                height: 32,
                paddingTop: 7,
                paddingBottom: 5,
                marginRight: 8,
                backgroundColor: 'white',
                borderRadius: 6,
              }}
            >
              {inputMode === 'AI' && !isLoading && (
                <Tooltip title='Try Again'>
                  <RedoOutlined
                    onClick={handleTryAgain}
                    style={{
                      fontSize: '18px',
                      color: GlobalColors.darkGray,
                      margin: '0 12px',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </Col>
            <Col
              style={{
                height: 32,
                paddingTop: 7,
                paddingBottom: 5,
                backgroundColor: 'white',
                borderRadius: 6,
              }}
            >
              {((messageResponse && inputMode === 'AI') || inputMode === 'Manual') && (
                <Tooltip title='Save'>
                  <SaveOutlined
                    onClick={handleSave}
                    style={{
                      fontSize: '18px',
                      color: GlobalColors.darkGray,
                      margin: '0 12px',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PromptBox;
