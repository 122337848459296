import {
  ADD_SURVEY,
  REMOVE_SURVEY,
  PROCESS_SURVEY,
  RECEIVE_AI_GENERATED_SURVEY,
  FETCH_SURVEYS,
  ACTIVATE_STATUS,
  SET_ACTIVE_SURVEY,
  SET_IS_LIVE_EVENT,
  SET_IS_LIVE_EVENT_IN_PROGRESS,
  SET_IS_RANKING_EVENT_IN_PROGRESS,
  SET_IS_QUIZ_IN_PROGRESS,
  CREATE_SURVEY_CLEAR,
  CREATE_SURVEY_INPUT_DATA,
  CREATE_SURVEY_NUMBER_OF_QUESTIONS,
  CREATE_SURVEY_NAME,
  CREATE_SURVEY_COMPLETED,
  CREATE_SURVEY_START_DATE,
  CREATE_SURVEY_END_DATE,
  CREATE_SURVEY_SET_EMOJIS,
  CREATE_SURVEY_WELCOME_MEDIA,
  CREATE_SURVEY_CLOSE_MEDIA,
  CREATE_SURVEY_CLOSE_MEDIA_MESSAGE,
  CREATE_SURVEY_SET_OPEN_ENDEDS,
  CREATE_SURVEY_SET_FORM_TYPE,
  CREATE_SURVEY_SET_MARKETING_OPT_IN_MESSAGE,
  CREATE_SURVEY_SET_TESTIMONIAL_MESSAGE,
  CREATE_SURVEY_SET_IS_OPT_IN,
  CREATE_SURVEY_SET_IS_WELCOME_MESSAGE,
  CREATE_SURVEY_SET_IS_CLOSING_MESSAGE,
  CREATE_SURVEY_SET_WELCOME_MESSAGE,
  CREATE_SURVEY_SET_CLOSING_MESSAGE,
  CREATE_SURVEY_SET_IS_TESTIMONIAL,
  SET_OPTION_MEDIA_URL,
  CREATE_SURVEY_SET_IS_REPEATABLE,
  CREATE_SURVEY_SET_RESPONSE_SIZE,
  CREATE_SURVEY_SET_FORM_BRICKS_URL,
} from '../actions/types';
import injectDateIdsIntoOptions from '../../utils/injectDateIdsIntoOptions';

const initialState = {
  surveys: [],
  activeSurvey: { surveyId: '', surveyName: '' },
  activeSurveyQuestions: {},
  isLiveEventInProgress: false,
  isRankingEventInProgress: false,
  isQuizInProgress: false,
  create: {
    surveyId: '',
    surveyInputData: {},
    surveyQuestionsMediaURLs: {},
    formType: 'basic',
    isLiveEvent: false,
    isQuiz: false,
    surveyName: '',
    open: ['1'],
    numberOfQuestions: 2,
    completed: {},
    startDateNew: '',
    endDateNew: '',
    emojis: {},
    openEndeds: {},
    testimonialMessage: '',
    optInMessage: '',
    isOptIn: false,
    isTestimonial: false,
    welcomeMessage: '',
    closingMessage: '',
    isWelcomeMessage: false,
    isClosingMessage: false,
    settings: {
      isRepeatable: false,
      responseSize: -1,
      formBricksUrl: '',
    },
    media: {
      welcomeURL: '',
      closeURL: '',
      closingMessage: '',
      welcomeFileType: '',
      closingFileType: '',
    },
  },
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SURVEYS:
      return {
        ...state,
        surveys: action.data,
      };
    case ADD_SURVEY:
      return { ...state, surveys: [...state.surveys, action.data] };
    case REMOVE_SURVEY:
      return {
        ...state,
        surveys: state.surveys.filter((survey) => survey._id !== action.data._id),
      };
    case RECEIVE_AI_GENERATED_SURVEY:
      const { survey: aiSurvey } = action.data;
      let createCompleted = {};
      let createEmojis = {};
      let createOpenEndeds = {};
      let createSurveyInputData = {};

      const surveyToCreate = aiSurvey.map((question, index) => {
        let { mainQuestion, options, subHeading, type } = question;
        const id = index + 1;
        createCompleted[index + 1] = false;
        if (type === 'openEnded') {
          createOpenEndeds[index + 1] = true;
        } else {
          createOpenEndeds[index + 1] = false;
          options =
            options?.map((option) => {
              delete option._id;
              if (option.hasOwnProperty('emojiType')) {
                createEmojis[index + 1] = true;
              } else {
                createEmojis[index + 1] = false;
              }
              return option;
            }) || [];
        }
        return { id, mainQuestion, options, subHeading, type };
      });

      surveyToCreate.forEach((question, index) => {
        createSurveyInputData[index + 1] = question;
      });

      createSurveyInputData = injectDateIdsIntoOptions(createSurveyInputData);

      console.log({ createSurveyInputData });
      return {
        ...state,
        create: {
          surveyInputData: createSurveyInputData,
          numberOfQuestions: aiSurvey.length,
          completed: createCompleted,
          emojis: createEmojis,
          openEndeds: createOpenEndeds,
          formType: 'basic',
        },
      };
    case PROCESS_SURVEY:
      const {
        _id: surveyId,
        name,
        survey,
        start: startDateNew,
        end: endDateNew,
        formType,
        isLiveEvent,
        isQuiz,
        optInMessage,
        testimonialMessage,
        welcomeMessage,
        closingMessage,
        media = {},
        settings = {},
      } = action.data;
      let completed = {};
      let emojis = {};
      let openEndeds = {};
      let surveyInputData = {};

      const surveyToEdit = survey.map((question, index) => {
        let {
          id = Date.now().toString(),
          mainQuestion,
          options,
          subHeading,
          type,
          correctAnswerOption,
          isValidateInput,
        } = question;
        completed[index + 1] = false;
        if (type === 'openEnded') {
          openEndeds[index + 1] = true;
        } else {
          openEndeds[index + 1] = false;
          options =
            options?.map((option, optionIndex) => {
              delete option._id;
              if (option.hasOwnProperty('emojiType')) {
                emojis[index + 1] = true;
              } else {
                emojis[index + 1] = false;
              }
              return option;
            }) || [];
        }
        return {
          id,
          mainQuestion,
          options,
          subHeading,
          type,
          correctAnswerOption,
          isValidateInput,
        };
      });

      surveyToEdit.forEach((question, index) => {
        surveyInputData[index + 1] = question;
      });

      return {
        ...state,
        create: {
          surveyId,
          surveyName: name,
          surveyInputData,
          startDateNew,
          endDateNew,
          numberOfQuestions: survey.length,
          completed,
          emojis,
          openEndeds,
          formType,
          isLiveEvent,
          isQuiz,
          testimonialMessage,
          optInMessage,
          welcomeMessage,
          closingMessage,
          isWelcomeMessage: welcomeMessage ? true : false,
          isClosingMessage: closingMessage ? true : false,
          isOptIn: optInMessage ? true : false,
          isTestimonial: testimonialMessage ? true : false,
          media,
          settings,
        },
      };

    case ACTIVATE_STATUS:
      return {
        ...state,
        surveys: state.surveys.map((survey) =>
          survey._id === action.data._id ? action.data : survey
        ),
      };
    case SET_ACTIVE_SURVEY:
      const questions = action.data.survey.reduce((acc, question, index) => {
        acc[index] = question.mainQuestion;
        return acc;
      }, {});
      return {
        ...state,
        activeSurvey: { surveyId: action.data._id, surveyName: action.data.surveyName },
        activeSurveyQuestions: questions,
      };
    case SET_IS_LIVE_EVENT:
      return {
        ...state,
        create: { ...state.create, isLiveEvent: action.data.liveEvent, isQuiz: action.data.isQuiz },
      };
    case SET_OPTION_MEDIA_URL:
      const { surveyNumber, optionIndex, optionUrl, mediaType } = action.data;
      const existingOptions = state.surveyQuestionsMediaURLs[surveyNumber] || {};

      const updatedOptions = {
        ...existingOptions,
        [optionIndex]: { optionUrl, mediaType },
      };
      return {
        ...state,
        surveyQuestionsMediaURLs: {
          ...state.surveyQuestionsMediaURLs,
          [surveyNumber]: updatedOptions,
        },
      };
    case SET_IS_LIVE_EVENT_IN_PROGRESS:
      return { ...state, isLiveEventInProgress: action.data };
    case SET_IS_RANKING_EVENT_IN_PROGRESS:
      return { ...state, isRankingEventInProgress: action.data };
    case SET_IS_QUIZ_IN_PROGRESS:
      return { ...state, isQuizInProgress: action.data };
    case CREATE_SURVEY_INPUT_DATA:
      console.log(action.data);
      return {
        ...state,
        create: { ...state.create, surveyInputData: { ...action.data } },
      };
    case CREATE_SURVEY_COMPLETED:
      return {
        ...state,
        create: { ...state.create, completed: { ...action.data } },
      };
    case CREATE_SURVEY_NAME:
      return { ...state, create: { ...state.create, surveyName: action.data } };
    case CREATE_SURVEY_NUMBER_OF_QUESTIONS:
      return {
        ...state,
        create: { ...state.create, numberOfQuestions: parseInt(action.data) },
      };
    case CREATE_SURVEY_SET_FORM_TYPE:
      return {
        ...state,
        create: { ...state.create, formType: action.data },
      };
    case CREATE_SURVEY_START_DATE:
      return {
        ...state,
        create: { ...state.create, startDateNew: action.data },
      };
    case CREATE_SURVEY_END_DATE:
      return {
        ...state,
        create: { ...state.create, endDateNew: action.data },
      };
    case CREATE_SURVEY_SET_EMOJIS:
      return {
        ...state,
        create: { ...state.create, emojis: { ...action.data } },
      };

    case CREATE_SURVEY_SET_OPEN_ENDEDS:
      return {
        ...state,
        create: { ...state.create, openEndeds: { ...action.data } },
      };
    case CREATE_SURVEY_SET_IS_WELCOME_MESSAGE:
      return {
        ...state,
        create: { ...state.create, isWelcomeMessage: action.data },
      };
    case CREATE_SURVEY_SET_IS_CLOSING_MESSAGE:
      return {
        ...state,
        create: { ...state.create, isClosingMessage: action.data },
      };
    case CREATE_SURVEY_SET_WELCOME_MESSAGE:
      return {
        ...state,
        create: { ...state.create, welcomeMessage: action.data },
      };
    case CREATE_SURVEY_SET_CLOSING_MESSAGE:
      return {
        ...state,
        create: { ...state.create, closingMessage: action.data },
      };
    case CREATE_SURVEY_SET_MARKETING_OPT_IN_MESSAGE:
      return {
        ...state,
        create: { ...state.create, optInMessage: action.data },
      };
    case CREATE_SURVEY_SET_TESTIMONIAL_MESSAGE:
      return {
        ...state,
        create: { ...state.create, testimonialMessage: action.data },
      };
    case CREATE_SURVEY_SET_IS_OPT_IN:
      return {
        ...state,
        create: { ...state.create, isOptIn: action.data },
      };
    case CREATE_SURVEY_SET_IS_TESTIMONIAL:
      return {
        ...state,
        create: { ...state.create, isTestimonial: action.data },
      };
    case CREATE_SURVEY_WELCOME_MEDIA:
      return {
        ...state,
        create: {
          ...state.create,
          media: {
            ...state.create.media,
            welcomeURL: action.data.url,
            welcomeFileType: action.data.welcomeFileType,
          },
        },
      };
    case CREATE_SURVEY_CLOSE_MEDIA:
      return {
        ...state,
        create: {
          ...state.create,
          media: {
            ...state.create.media,
            closeURL: action.data.url,
            closingFileType: action.data.closingFileType,
          },
        },
      };
    case CREATE_SURVEY_CLOSE_MEDIA_MESSAGE:
      return {
        ...state,
        create: {
          ...state.create,
          media: { ...state.create.media, closingMessage: action.data },
        },
      };
    case CREATE_SURVEY_SET_IS_REPEATABLE:
      console.log({ isRepeatable: action.data });
      return {
        ...state,
        create: {
          ...state.create,
          settings: { ...state.create.settings, isRepeatable: action.data },
        },
      };
    case CREATE_SURVEY_SET_RESPONSE_SIZE:
      console.log({ responseSize: action.data });
      return {
        ...state,
        create: {
          ...state.create,
          settings: { ...state.create.settings, responseSize: action.data },
        },
      };
    case CREATE_SURVEY_SET_FORM_BRICKS_URL:
      console.log({ formBricksUrl: action.data });
      return {
        ...state,
        create: {
          ...state.create,
          settings: { ...state.create.settings, formBricksUrl: action.data },
        },
      };
    case CREATE_SURVEY_CLEAR:
      return {
        ...state,
        create: {
          formType: 'basic',
          isLiveEvent: false,
          isQuiz: false,
          surveyInputData: {},
          surveyName: '',
          // open: 5,
          numberOfQuestions: 2,
          completed: {},
          startDateNew: '',
          endDateNew: '',
          emojis: {},
          openEndeds: {},
          openEnded: {},
          surveyId: '',
          isWelcomeMessage: false,
          isClosingMessage: false,
          welcomeMessage: '',
          closingMessage: '',
          testimonialMessage: '',
          optInMessage: '',
          isOptIn: false,
          isTestimonial: false,

          media: {
            welcomeURL: '',
            closeURL: '',
            closingMessage: '',
            welcomeFileType: '',
            closingFileType: '',
          },
          settings: {
            isRepeatable: false,
            responseSize: -1,
            formBricksUrl: '',
          },
        },
      };
    default:
      return state;
  }
};

export default surveyReducer;
