import { SET_LAST_SEEN, CLEAR_LAST_SEEN } from '../actions/types';

const initialLastSeenFilterState = {
  lastSeen: '',
  lastSeenHumanReadable: '',
};

const lastSeenFilterReducer = (state = initialLastSeenFilterState, action) => {
  switch (action.type) {
    case SET_LAST_SEEN:
      return {
        ...state,
        lastSeen: action.data.date,
        lastSeenHumanReadable: action.data.humanReadableValue,
      };
    case CLEAR_LAST_SEEN:
      return {
        ...state,
        lastSeen: '',
        lastSeenHumanReadable: '',
      };
    default:
      return state;
  }
};

export default lastSeenFilterReducer;
