import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Typography, Form, Input, Button, Row, Col, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useAxios from '../hooks/useAxios';
import NavBar from '../components/NavBar';
import StatusNotification from '../components/StatusNotification';
import LoadingIcon from '../components/LoadingIcon';

const { Title } = Typography;
const { Content } = Layout;

const ForgotPassword = ({ history }) => {
  const [loading, axiosFetch] = useAxios();
  const apiURL = '/api/users/password/reset';

  const onFinish = (email) => {
    axiosFetch({
      method: 'post',
      url: apiURL,
      requestConfig: email,
    })
      .then((res) => {
        StatusNotification(
          'success',
          'Reset success',
          'Please Check Your Email To Reset Your Password'
        );
        history.push('/password/success');
      })
      .catch((err) => {
        StatusNotification('error', 'Problem Reseting Password', err);
      });
  };

  return (
    <>
      <Layout className='layout-default layout-signin'>
        <NavBar />
        <Content>
          <Row
            type='flex'
            justify='center'
            align='middle'
            style={{ minHeight: '70vh', flexFlow: 'Column' }}
          >
            <Title className='mb-15' style={{ textAlign: 'center' }}>
              Password Reset
            </Title>
            <Form
              name='normal_login'
              className='login-form'
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name='email'
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Your email please ... 😃',
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  placeholder='email'
                  allowClear='true'
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  type='primary'
                  htmlType='submit'
                >
                  {loading ? <LoadingIcon /> : 'Reset Password'}
                </Button>

                <Col justify='center' align='middle' span={24}>
                  <Space />
                  <Link to='/signin' id='validating'>
                    Return to Sign In
                  </Link>
                </Col>
              </Form.Item>
            </Form>
          </Row>
        </Content>
      </Layout>
    </>
  );
};
export default ForgotPassword;
