import React from 'react';
import { Menu, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { axiosVanila } from '../../apis/backendAPI';
import { useHistory } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import scrollWithOffset from '../../utils/scrollWithOffset';
import { LogoutOutlined } from '@ant-design/icons'; // Import the icon

const RightMenu = ({ mode, showDrawer }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userDetails = useSelector((state) => state.user.userDetails);

  const apiURL = '/api/users/';
  let menuItems;

  if (isAuthenticated) {
    menuItems = [
      {
        label: `Hi, ${userDetails?.name}`,
        key: 'greeting',
        disabled: true, // Make it non-clickable
      },
      {
        label: <LogoutOutlined style={{ fontSize: 24 }} />, // Use the icon here
        key: 'signout',
      },
    ];
  } else {
    menuItems = [
      {
        label: 'Sign In',
        key: 'signin',
      },
      {
        label: (
          <Link to='/signup' scroll={(el) => scrollWithOffset(el, -480)}>
            <Button type='primary'>Try for free</Button>
          </Link>
        ),
        key: 'signup',
      },
    ];
  }

  const onMenuItemClick = async ({ key }) => {
    if (key === 'signin') history.push('/signin');
    else if (key === 'signup') history.push('/signup');
    else if (key === 'signout') {
      try {
        await axiosVanila(apiURL + 'signout', {
          withCredentials: true,
        });

        dispatch({ type: 'LOGOUT_USER' });
        dispatch({ type: 'REMOVE_THIRTY_DAYS_CHART_DATA' });
        history.push('/');
      } catch (error) {
        history.push('/');
      }
    }
    if (mode === 'inline') {
      showDrawer();
    }
  };

  return (
    <Menu
      mode={mode}
      style={{
        display: 'flex',
        justifyContent: 'end',
        color: '#7A7774',
        fontSize: 16,
        fontWeight: 600,
      }}
      onClick={onMenuItemClick}
      items={menuItems}
    />
  );
};

export default RightMenu;