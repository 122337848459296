/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  DashboardOutlined,
  ContactsOutlined,
  TabletOutlined,
  CreditCardOutlined,
  SendOutlined,
  BlockOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import GlobalColors from '../../assets/colors/GlobalColors';
import { HappyProvider } from '@ant-design/happy-work-theme';
import Leaderboard from '../event/Leaderboard';

function Sidenav({ color, isShowSide }) {
  const { pathname } = useLocation();
  const page = pathname.replace('/', '');

  const isLiveEventInProgress = useSelector((state) => state.surveysData.isLiveEventInProgress);
  const isQuizInProgress = useSelector((state) => state.surveysData.isQuizInProgress);
  const teamDetails = useSelector((state) => state.user.teamDetails);

  let menuItems = [
    {
      label: (
        <NavLink to='/dashboard'>
          <span
            className='icon'
            style={{
              background: page === 'dashboard' ? color : '',
            }}
          >
            <DashboardOutlined style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Dashboard</span>}
        </NavLink>
      ),
      key: 'dashboard',
    },
    {
      label: (
        <NavLink to='/surveys'>
          <span
            className='icon'
            style={{
              background: page === 'surveys' ? color : page === 'surveys/create' ? color : '',
            }}
          >
            <TabletOutlined style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Activity</span>}
        </NavLink>
      ),
      key: 'surveys',
    },
    {
      label: (
        <NavLink to='/broadcasts'>
          <span
            className='icon'
            style={{
              background: page === 'broadcasts' ? color : '',
            }}
          >
            <SendOutlined style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Broadcast</span>}
        </NavLink>
      ),
      key: 'broadcast',
    },
    {
      label: (
        <NavLink to='/contacts'>
          <span
            className='icon'
            style={{
              background: page === 'contacts' ? color : '',
            }}
          >
            <ContactsOutlined style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Contacts</span>}
        </NavLink>
      ),
      key: 'contacts',
    },
  ];

  if (teamDetails?.role === 'owner') {
    menuItems.push({
      label: (
        <NavLink to='/integrations'>
          <span
            className='icon'
            style={{
              background: page === 'integrations' ? color : '',
            }}
          >
            <BlockOutlined  style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Integrations</span>}
        </NavLink>
      ),
      key: 'integrations',
    });
    menuItems.push({
      label: (
        <NavLink to='/settings'>
          <span
            className='icon'
            style={{
              background: page === 'settings' ? color : '',
            }}
          >
            <SettingOutlined style={{ fontSize: 24 }} />
          </span>
          {isShowSide && <span className='label'>Settings</span>}
        </NavLink>
      ),
      key: 'settings',
    });
  }
  let menuItemsInline = [
    {
      label: (
        <NavLink to='/dashboard'>
          <DashboardOutlined style={{ fontSize: 24, color: GlobalColors.lightPurple }} />{' '}
        </NavLink>
      ),
      key: 'dashboard',
    },
    {
      label: (
        <NavLink to='/surveys'>
          <TabletOutlined style={{ fontSize: 24, color: GlobalColors.lightPurple }} />
        </NavLink>
      ),
      key: 'surveys',
    },
    {
      label: (
        <NavLink to='/broadcasts'>
          <SendOutlined style={{ fontSize: 24, color: GlobalColors.lightPurple }} />
        </NavLink>
      ),
      key: 'broadcast',
    },
    {
      label: (
        <NavLink to='/contacts'>
          <ContactsOutlined style={{ fontSize: 24, color: GlobalColors.lightPurple }} />
        </NavLink>
      ),
      key: 'contacts',
    },
    {
      label: (
        <NavLink to='/subscription'>
          <CreditCardOutlined style={{ fontSize: 24, color: GlobalColors.lightPurple }} />
        </NavLink>
      ),
      key: 'subscription',
    },
  ];

  const openHowToVideos = () => {
    setTimeout(() => {
      window.open(
        'https://www.youtube.com/playlist?list=PLkRaS73J2FxnQgBhOo6VSHiJq-mtozIu4',
        '_blank'
      );
    }, 700);
  };

  const isShowInline = isQuizInProgress === false && isLiveEventInProgress === false;
  return (
    <>
      {isShowSide ? (
        <>
          {' '}
          <Menu mode='inline' items={menuItems} style={{ marginTop: 58 }} />
        </>
      ) : (
        <>
          {' '}
          {isShowInline && (
            <Menu
              mode='horizontal'
              items={menuItemsInline}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          )}{' '}
          {isQuizInProgress && <Leaderboard />}
        </>
      )}

      {isShowInline && (
        <div className='aside-footer'>
          <div
            className='footer-box'
            style={{
              background: color,
            }}
          >
            <h6>Need Help?</h6>
            <p>Please check our videos</p>
            <HappyProvider>
              <Button
                id='howToVideos'
                // className='ant-btn-sm ant-btn-block'
                onClick={openHowToVideos}
                block
              >
                How To Videos
              </Button>
            </HappyProvider>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidenav;
