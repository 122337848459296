const determineFileType = (mimeType) => {
  const type = mimeType.split('/')[0];
  if (type === 'image') {
    return 'image';
  } else if (type === 'video') {
    return 'video';
  } else if (mimeType === 'application/pdf') {
    return 'document';
  }
  return 'unknown';
};

export default determineFileType;