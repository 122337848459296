import React, { useState } from 'react';
import features from '../assets/videos/features.mp4';
import LoadingIcon from './LoadingIcon';
import GlobalColors from '../assets/colors/GlobalColors';

const FeatureVideo = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          <LoadingIcon color={GlobalColors.darkGray} lineHeight={8} fontSize={28} />
        </div>
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        onLoadedData={() => setIsLoading(false)}
        style={{
          width: '100%',
          height: 'auto',
          display: isLoading ? 'none' : 'block',
        }}
      >
        <source src={features} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default FeatureVideo;
