import {
  SET_TEMPLATE_INPUT_VALUES,
  SET_SELECTED_TEMPLATE,
  SET_TEMPLATE_MEDIA_URL,
  RESET_TEMPLATE,
  CREATE_TEMPLATE_INPUT_DATA,
  FETCH_TEMPLATES,
  ADD_NEW_TEMPLATE,
  SET_SHORT_URL_ID,
  DELETE_TEMPLATE,
  SET_WHATSAPP_MEDIA_METADATA,
  RESET_TEMPLATE_FORM,
  REMOVE_TEMPLATE_INPUT_VALUE,
} from '../actions/types';

const initialState = {
  inputValues: {},
  selectedTemplate: '',
  templateName: '',
  shortUrlId: '',
  templateParameters: [],
  templates: [],
  media: {},
  whatsAppMediaMetadata: {},
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES:
      return { ...state, templates: action.data };
    case SET_TEMPLATE_INPUT_VALUES:
      return { ...state, inputValues: { ...state.inputValues, ...action.data } };
    case REMOVE_TEMPLATE_INPUT_VALUE:
      const { keyToRemove } = action.data;
      const { [keyToRemove]: _, ...remainingInputs } = state.inputValues;
      return { ...state, inputValues: remainingInputs };
    case CREATE_TEMPLATE_INPUT_DATA:
      return {
        ...state,
        templateName: action.data.templateName,
        templateParameters: action.data.templateParameters,
      };
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.data,
      };
    case ADD_NEW_TEMPLATE:
      console.log({ addingNew: action.data });
      return {
        ...state,
        templates: [action.data, ...state.templates],
      };
    case SET_SHORT_URL_ID:
      return {
        ...state,
        shortUrlId: action.data,
      }
    case DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter((template) => template._id !== action.data),
      };
    case SET_TEMPLATE_MEDIA_URL:
      return {
        ...state,
        media: action.data,
      };
    case SET_WHATSAPP_MEDIA_METADATA:
      //when action.data.fileType === image add variable HEADER_image_0, if it is video add HEADER_video_0 if it is pdf add HEADER_document_0
      //and filehadle must be HEADER_${type}_0
      let mediaObject = {};
      if (action.data.type === 'image') {
        mediaObject.HEADER_image = 'IMAGE';
        mediaObject.HEADER_example_0 = action.data.fileHandle;
      }
      if (action.data.type === 'video') {
        mediaObject.HEADER_video = 'VIDEO';
        mediaObject.HEADER_example_0 = action.data.fileHandle;
      }
      if (action.data.type === 'pdf') {
        mediaObject.HEADER_document = 'DOCUMENT';
        mediaObject.HEADER_example_0 = action.data.fileHandle;
      }

      return {
        ...state,
        whatsAppMediaMetadata: mediaObject,
      };
    case RESET_TEMPLATE_FORM:
      return {
        ...state,
        whatsAppMediaMetadata: {},
      };
    case RESET_TEMPLATE:
      return {
        ...state,
        inputValues: {},
        selectedTemplate: '',
        templateName: '',
        shortUrlId: '',
        media: {},
        whatsAppMediaMetadata: {},
      };

    default:
      return state;
  }
};

export default templateReducer;
