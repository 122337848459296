import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Grid, Badge, Skeleton } from 'antd';
import Confetti from 'react-confetti';

import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import LoadingIcon from '../components/LoadingIcon';
import getScreenSize from '../utils/getScreenSize';

import PayfastButton from '../components/PayfastButtonEnterprise';
import Pricing from '../components/Pricing';

import Offerings from '../components/Offerings';

const basicAmount = process.env.REACT_APP_SUBSCRIPTION_BASIC;
const premiumAmount = process.env.REACT_APP_SUBSCRIPTION_PREMIUM;

const { useBreakpoint } = Grid;

const SubscriptionUpgrade = () => {
  let signature = '';
  let values = {};

  const subscriberOption = 'Standard';
  const { userId, planType, subscriptionStatus } = useSelector((state) => {
    return state.user;
  });
  const itemDescription = 'Standard subscription, with 1000 free messages';

  const screenSize = getScreenSize(useBreakpoint());
  const pfForm = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [extraDetails, setExtraDetails] = useState(undefined);

  const previousLocation = history.location.state?.from;
  const [showConfetti, setShowConfetti] = useState(previousLocation === '/subscription/complete');

  useEffect(() => {
    if (history.action === 'PUSH') setIsLoading(false);
  }, [history]);

  useEffect(() => {
    let confettiTimeout;
    if (showConfetti) {
      confettiTimeout = setTimeout(() => setShowConfetti(false), 10000);
    }
    return () => clearTimeout(confettiTimeout); // clean up on unmount
  }, [showConfetti]);

  // useEffect(() => {
  const changePlanType = async (plan) => {
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post('/api/users/subscription/update', {
        planType: plan,
        userId,
      });
      dispatch({ type: 'CHANGE_PLAN_TYPE', data: response.data.planType });

      setIsLoading(false);
      StatusNotification('success', `Subscription changed to ${response.data.planType}`);
    } catch (error) {
      StatusNotification(
        'error',
        'Error Changing Subscription',
        `${error?.response?.data}, Please contact support@surveyr.africa`
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const response = await axiosPrivate.post('/api/users/extradetails', {
        userId,
      });
      const firstName = response.data.name;
      const lastName = response.data.lastName;
      const emailAddress = response.data.email;
      setExtraDetails({ firstName, lastName, emailAddress });
    } catch (error) {
      //if we can't get user details the app has to return
      //TODO: sentry alert CODE RED, we loosing money!!!!
      StatusNotification(
        'error',
        'Problem subscribing',
        'Support has been alerted, and will look into the issue'
      );
    }
  };

  const handleChangePlanType = () => {
    if (planType === 'basic') changePlanType('premium');
    else changePlanType('basic');
  };

  values = {
    subscriberOption,
    itemDescription,
    userId,
    planType,
  };

  return (
    <div style={{ marginTop: screenSize !== 'xs' ? 80 : 0 }}>
      {extraDetails ? (
        <Pricing
          isFront={false}
          planType={planType}
          pfForm={pfForm}
          values={values}
          extraDetails={extraDetails}
          signature={signature}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      ) : (
        <Card
          bordered={false}
          className='header-solid ant-invoice-card'
          title={[
            <>
              <Row gutter={[24, 0]} className='ant-row-flex ant-row-flex-middle'>
                <Col xs={24} md={12}></Col>
              </Row>
            </>,
          ]}
        >
          <Skeleton />
        </Card>
      )}
    </div>
  );
};

export default SubscriptionUpgrade;
