import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Image, Card, Skeleton, Col, Row, Layout, Button } from 'antd';
import usePrivateAxios from '../hooks/usePrivateAxios';
import NavBar from './NavBar';
import Footer from './layout/Footer';
import SectionHeader from './SectionHeader';

const { Text, Title } = Typography;

// Custom function to replace <Text mark> with the AntD Text component
function renderTextWithMarks(text) {
  const regex = /<Text mark>(.*?)<\/Text>/g;
  return text.split(regex).map((part, index) => {
    // Every second element in the array will be the matched group (inside the tags)
    if (index % 2 === 1) {
      return (
        <Text mark key={index} style={{ fontSize: 17 }}>
          {part}
        </Text>
      );
    }
    // The rest is regular text or other HTML that needs to be parsed safely
    return part;
  });
}

const Blog = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const { _id } = useParams();
  const axiosPrivate = usePrivateAxios();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const { data } = await axiosPrivate.get(`/api/blogs/${_id}`);
        setBlog(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog:', error);
        setLoading(false);
      }
    };
    fetchBlog();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  if (loading) {
    return (
      <Card style={{ margin: '20px' }}>
        <Skeleton active />
      </Card>
    );
  }

  const getColumnWidth = (index) => (index) => 3 && index < 6 ? 12 : 24;

  const groupedContent = () => {
    const groups = [];
    blog.content.forEach((block, index) => {
      if (block.type === 'header') {
        const nextBlock = blog.content[index + 1];
        const group = [block];
        if (nextBlock && nextBlock.type === 'paragraph') {
          group.push(nextBlock);
        }
        groups.push(group);
      } else if (block.type !== 'paragraph') {
        groups.push([block]);
      }
    });
    return groups;
  };

  const getContentStyle = (type) => {
    switch (type) {
      case 'image':
        return {
          width: '70%', // Ensure images take full width
          marginBottom: '20px', // Consistent margin for all images
        };
      case 'banner':
        return {
          width: '100%', // Ensure images take full width
          marginBottom: '20px', // Consistent margin for all images
        };
      case 'video':
        return {
          width: '100%',
        };
      case 'paragraph':
      case 'header':
        return {
          overflow: 'auto',
        };
      default:
        return {};
    }
  };

  return (
    <>
      <NavBar />
      <Layout className='blogs-layout'>
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
          <Col span={24}>
            {blog && <SectionHeader title={blog.title} isFront={false} subtitle='' />}
            <Row gutter={[16, 16]} justify='center'>
              {groupedContent().map((group, index) => {
                const colWidth = getColumnWidth(index);
                const type = group[0]?.type;
                return (
                  <Col
                    span={colWidth}
                    key={index}
                    style={type === 'banner' || type === 'image' ? { textAlign: 'center' } : {}}
                  >
                    {group.map((item, itemIndex) => {
                      const contentStyle = getContentStyle(item.type);
                      switch (item.type) {
                        case 'paragraph':
                          return (
                            <p
                              key={itemIndex}
                              style={{
                                fontSize: 17,
                                fontFamily: 'Montserrat, sans-serif',
                                fontWeight: 400,
                                ...contentStyle,
                              }}
                            >
                              {renderTextWithMarks(item.data)}
                            </p>
                          );
                        case 'header':
                          const Tag = `h${item.data.level || 1}`;
                          return React.createElement(
                            Tag,
                            { key: itemIndex, style: contentStyle },
                            renderTextWithMarks(item.data)
                          );
                        case 'image':
                        case 'banner':
                          return (
                            <Image
                              key={itemIndex}
                              src={item.data}
                              preview={false}
                              alt={item.type === 'banner' ? 'Banner image' : 'Blog image'}
                              style={contentStyle}
                            />
                          );
                        case 'video':
                          return (
                            <video key={itemIndex} controls src={item.data} style={contentStyle} />
                          );
                        default:
                          return null;
                      }
                    })}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col span={24} style={{ textAlign: 'center', marginBottom: 18 }}>
            <Button>
              <Link to='/blogs'> Back to blogs</Link>
            </Button>
          </Col>
        </Row>
        <Footer />
      </Layout>
    </>
  );
};

export default Blog;
