const generateRandomId = (length = 13) => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

function injectDateIdsIntoOptions(obj) {
  const updatedObj = JSON.parse(JSON.stringify(obj)); // Create a deep copy

  for (const key in updatedObj) {
    if (updatedObj.hasOwnProperty(key) && updatedObj[key].options) {
      updatedObj[key].options = updatedObj[key].options.map((option) => ({
        ...option,
        id: generateRandomId(),
      }));
    }
  }

  return updatedObj;
}

export default injectDateIdsIntoOptions;
