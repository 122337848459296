import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { Table, Card, Row, Col, Tooltip, Flex, Segmented, Grid } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import SurveyTableSkeleton from '../components/skeletons/SurveyTableSkeleton';
import getColumns from '../components/getColumns';
import GlobalColors from '../assets/colors/GlobalColors';
import findRankableField from '../utils/findRankableField';
import FetchDashboardData from '../components/FetchDashboardData';
import FetchLiveEventData from '../components/FetchLiveEventData';
import { generateAndDownloadPdf } from '../utils/cloudinaryServices';
import getScreenSize from '../utils/getScreenSize';

dayjs.extend(relativeTime);

const { useBreakpoint } = Grid;

const Surveys = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const axiosPrivate = usePrivateAxios();
  const screens = useBreakpoint();

  const [mappedData, setMappedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [surveyId, setSurveyId] = useState('');
  const [qrFileName, setQrFileName] = useState('');
  const [qrCodeForDownload, setQrCodeForDownload] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState('Active');
  const [botPhoneNumber, setBotPhoneNumber] = useState('');
  const [isBotPhoneNumberLoaded, setIsBotPhoneNumberLoaded] = useState(false);


  const {
    user: {
      organizationId,
      hasOwnBot,
      teamDetails: { role },
    },
  } = useSelector((state) => state);
  const { surveys: surveyData } = useSelector((state) => state.surveysData);

  const apiURL = '/api/surveys/';
  const screenSize = getScreenSize(screens);

  const toolTip = (value) => <span>{value}</span>;
  const tableRef = useRef();
  const downloadRef = useRef();
  const iconStyle = {
    fontSize: 12.5,
    color: GlobalColors.darkGray,
    padding: 0,
    lineHeight: 2.8,
    paddingRight: 8,
  };

  useEffect(() => {
    const controller = new AbortController();
    let isMounted = true;

    const getSurveys = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(apiURL + organizationId, {
          signal: controller.signal,
        });
        if (isMounted) {
          dispatch({ type: 'FETCH_SURVEYS', data: response.data });
        }
      } catch (error) {
        console.error('Failed to fetch surveys:', error);
        // StatusNotification('error', 'Failed to fetch surveys', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getSurveys();
    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [axiosPrivate, dispatch, organizationId]);

  useEffect(() => {
    const getBotPhoneNumber = async () => {
      try {
        const { data } = await axiosPrivate.get(`/api/bots/${organizationId}/${hasOwnBot}`);
        setBotPhoneNumber(data.phoneNumber);
        setIsBotPhoneNumberLoaded(true);
      } catch (error) {
        console.error('Failed to fetch bot phone number:', error);
      }
    };

    getBotPhoneNumber();
  }, [axiosPrivate, organizationId, hasOwnBot]);

  useEffect(() => {
    if (qrCodeForDownload) downloadQRCode(qrFileName);
  });

  const mapData = useMemo(() => {
    if (!surveyData || !surveyData.length || !isBotPhoneNumberLoaded || !botPhoneNumber) return [];
    return surveyData.map((s) => ({
      ...s,
      key: s._id,
      rankable: findRankableField(s.survey),
    }));
  }, [surveyData, isBotPhoneNumberLoaded, botPhoneNumber]);
  const viewSurvey = (_id, surveyName, survey, isLiveEvent) => {
    dispatch({ type: 'IS_LOADING', data: true });
    history.push({
      pathname: '/dashboard',
      isLiveEvent,
    });
    FetchDashboardData(_id, organizationId, axiosPrivate, dispatch, isLiveEvent, [surveyName])
      .then((response) => {
        if (response[2]?.status === 204)
          return StatusNotification(
            'warning',
            'No data',
            `No responses to available yet for survey [${surveyName}]`
          );
        dispatch({ type: 'SET_ACTIVE_SURVEY', data: { _id, survey, surveyName } });
        dispatch({ type: 'DASHBOARD_SET_ALL_TAGS', data: response[2]?.data?.tags });
      })
      .catch((error) => {
        StatusNotification('error', 'No responses', error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading((prevLoading) => {
          return { ...prevLoading, [_id]: false, icon: 'view' };
        });
      });
  };

  const downloadResponses = async (_id, surveyName) => {
    try {
      const response = await axiosPrivate.get(`/api/responses/export/${_id}`, {
        responseType: 'blob',
      });
      if (response.status === 204)
        return StatusNotification(
          'warning',
          'No data',
          `No responses to available yet for live event [${surveyName}]`
        );

      const url = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${surveyName}.csv`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      StatusNotification('error', 'Download Failed', 'Please try again later');
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'download' };
      });
    }
  };
  const fetchSurveyForEdit = async (_id) => {
    try {
      const response = await axiosPrivate.get('/api/surveys/edit/' + _id);
      dispatch({ type: 'PROCESS_SURVEY', data: response.data });
      history.push(`/surveys/edit`);
    } catch (error) {}
  };

  const viewInMapbox = async (_id, surveyName) => {
    try {
      // const response = await axiosPrivate.get(`/api/responses/mapbox/${_id}`);
      history.push(`/surveys/mapbox/${_id}/${surveyName}`);
    } catch (error) {
      StatusNotification('error', 'Mapbox Failed', 'Please try again later');
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'mapbox' };
      });
    }
  };

  const quiz = async (_id, surveyName, survey) => {
    try {
      const isQuiz = true;
      FetchLiveEventData(_id, organizationId, axiosPrivate, dispatch, isQuiz).then((response) => {
        if (response[2]?.status === 204)
          return StatusNotification(
            'warning',
            'No data',
            `No responses available yet for quiz [${surveyName}]`
          );
        dispatch({ type: 'SET_ACTIVE_SURVEY', data: { _id, survey, surveyName } });
        dispatch({ type: 'SET_IS_QUIZ_IN_PROGRESS', data: true });
        history.push(`/surveys/live-event/${_id}/${surveyName}`);
      });
    } catch (error) {
      StatusNotification(
        'error',
        'Quiz Failed',
        `Please try again later [${error?.response?.data?.message}]`
      );
      dispatch({ type: 'SET_IS_QUIZ_IN_PROGRESS', data: false });
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'quiz' };
      });
    }
  };
  const rank = async (_id, surveyName, survey, rankableFieldIndexNumber) => {
    try {
      const isQuiz = false;
      FetchLiveEventData(_id, organizationId, axiosPrivate, dispatch, isQuiz).then((response) => {
        if (response[2]?.status === 204)
          return StatusNotification(
            'warning',
            'No data',
            `No responses available yet to be ranked [${surveyName}]`
          );
        dispatch({ type: 'SET_ACTIVE_SURVEY', data: { _id, survey, surveyName } });
        dispatch({ type: 'SET_IS_RANKING_EVENT_IN_PROGRESS', data: true });
        history.push(
          `/public/ranking/${_id}/${surveyName}/${organizationId}/${rankableFieldIndexNumber}`
        );
      });
    } catch (error) {
      StatusNotification(
        'error',
        'Ranking Event Failed',
        `Please try again later [${error?.response?.data?.message}]`
      );
      dispatch({ type: 'SET_IS_RANKING_EVENT_IN_PROGRESS', data: false });
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'rank' };
      });
    }
  };

  const liveEvent = async (_id, surveyName, survey) => {
    try {
      const isLiveEvent = true;
      await axiosPrivate
        .patch(`/api/live-events/reset/${_id}`)
        .then(() => {
          FetchLiveEventData(_id, organizationId, axiosPrivate, dispatch, isLiveEvent).then(
            (response) => {
              if (response[2]?.status === 204)
                return StatusNotification(
                  'warning',
                  'No data',
                  `No responses available yet for survey [${surveyName}]`
                );
              dispatch({ type: 'SET_ACTIVE_SURVEY', data: { _id, survey, surveyName } });
              dispatch({ type: 'SET_IS_LIVE_EVENT_IN_PROGRESS', data: true });
              history.push(`/surveys/live-event/${_id}/${surveyName}`);
            }
          );
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      StatusNotification(
        'error',
        'Live Event Failed',
        `Please try again later [${error?.response?.data?.message}]`
      );
      dispatch({ type: 'SET_IS_LIVE_EVENT_IN_PROGRESS', data: false });
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'liveEvent' };
      });
    }
  };

  const activateStatus = async (_id, status) => {
    if (status === 'scheduled')
      try {
        setSurveyId(_id);
        const response = await axiosPrivate.put('/api/surveys/status/' + _id, {
          status: 'active',
        });
        dispatch({ type: 'ACTIVATE_STATUS', data: response.data });
      } catch (error) {
        StatusNotification('error', 'Error Activating Status', 'Please try again');
      } finally {
        setIsLoading((prevLoading) => {
          return { ...prevLoading, [_id]: false, icon: 'activate' };
        });
      }
  };
  const deleteSurvey = async (_id) => {
    try {
      const response = await axiosPrivate.delete('/api/surveys/' + _id);
      dispatch({ type: 'REMOVE_SURVEY', data: response.data });
    } catch (error) {}
  };

  const downloadReport = async (_id, surveyName, pdfmeTemplateId) => {
    try {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: true };
      });
      const response = await axiosPrivate.get(`/api/ai/thread//${organizationId}/${_id}`);
      const { threadId } = response.data;

      // Use the extracted function
      await generateAndDownloadPdf(
        axiosPrivate,
        surveyName,
        pdfmeTemplateId,
        organizationId,
        threadId
      );
    } catch (error) {
      StatusNotification('error', 'Download Failed', 'Please try again later');
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'download' };
      });
    }
  };

  const generatePDF = async (_id, surveyName, isLinkedToTemplate) => {
    return history.push(`/surveys/report/${_id}/${isLinkedToTemplate || false}`);
  };

  const summarizeResponses = async (_id, surveyName) => {
    try {
      const response = await axiosPrivate.get(`/api/ai/summarize/${_id}`);
      if (response.status === 204)
        return StatusNotification(
          'warning',
          'No data',
          `No responses to available yet for survey [${surveyName}]`
        );
      StatusNotification(
        'success',
        `Summary for surveyName [${surveyName}] `,
        `${response?.data?.response}`
      );
    } catch (error) {
      StatusNotification('error', 'Summarize Failed', 'Please try again later');
    } finally {
      setIsLoading((prevLoading) => {
        return { ...prevLoading, [_id]: false, icon: 'summarize' };
      });
    }
  };

  const filterActive = () => {
    const activeSurveys = surveyData?.filter((s) => {
      return s.status === 'active';
    });
    setMappedData(mapData(activeSurveys));
  };
  const filterScheduled = () => {
    const scheduledSurveys = surveyData?.filter((s) => s.status === 'scheduled');
    setMappedData(mapData(scheduledSurveys));
  };
  const filterCompleted = () => {
    const completedSurveys = surveyData?.filter((s) => s.status === 'completed');
    setMappedData(mapData(completedSurveys));
  };

  const handleDownload = (size, surveyName, url) => {
    setQrFileName(`${surveyName}_${size}x.png`);
    setQrCodeForDownload(
      <QRCodeCanvas
        value={url}
        size={size}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'H'}
        includeMargin={true}
      />
    );
    // setTimeout(() => {
    //   downloadQRCode(fileName);
    // }, 100);
  };

  const downloadQRCode = (fileName) => {
    setQrCodeForDownload(null);

    let canvas = downloadRef.current.querySelector('canvas');
    let image = canvas.toDataURL('image/png');
    let anchor = document.createElement('a');
    anchor.href = image;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const qrCodeSvg = (url, surveyName) => {
    const svg = (
      <div ref={tableRef}>
        <QRCodeCanvas
          value={url}
          size={screenSize === 'xs' ? 126 : 176}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'H'}
          includeMargin={true}
        />
      </div>
    );
    return (
      <>
        <Row align='middle' justify='center' style={{ width: 176 }}>
          <Col span={24} style={{ textAlign: 'center' }}>
            {svg}
          </Col>
          {/* <Col style={{ marginTop: -18, marginBottom: -8 }}>
            <DownloadOutlined style={{ ...iconStyle}} />
          </Col> */}
          <Col span={24} style={{ textAlign: 'center', marginBottom: -4 }}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={toolTip('download size')}
            >
              <span>
                <Segmented
                  size='small'
                  options={[
                    {
                      label: (
                        <Row>
                          <Col span={8}>1x</Col>
                        </Row>
                      ),
                      value: 176,
                    },
                    {
                      label: (
                        <Row justify='space-between'>
                          <Col span={8}>2x</Col>
                        </Row>
                      ),
                      value: 352,
                    },
                    {
                      label: (
                        <Row justify='space-between'>
                          <Col span={8}>3x</Col>
                        </Row>
                      ),
                      value: 538,
                    },
                    {
                      label: (
                        <Row justify='space-between'>
                          <Col span={8}>4x</Col>
                        </Row>
                      ),
                      value: 704,
                    },
                  ]}
                  defaultValue={-1}
                  onChange={(val) => handleDownload(val, surveyName, url)}
                />
              </span>
            </Tooltip>
          </Col>
        </Row>
      </>
    );
  };

  const columns = useMemo(
    () =>
      getColumns({
        screenSize,
        role,
        qrCodeSvg,
        axiosPrivate,
        activateStatus,
        fetchSurveyForEdit,
        viewSurvey,
        downloadReport,
        generatePDF,
        downloadResponses,
        summarizeResponses,
        liveEvent,
        quiz,
        rank,
        deleteSurvey,
      }),
    [screenSize, role]
  );

  const getFilteredData = (data, segment) => {
    switch (segment) {
      case 'Active':
        return data.filter((s) => s.status === 'active');
      case 'Completed':
        return data.filter((s) => s.status === 'completed');
      case 'Scheduled':
        return data.filter((s) => s.status === 'scheduled');
      default:
        return data;
    }
  };

  const filteredSurveys = useMemo(() => {
    return getFilteredData(mapData, selectedSegment);
  }, [mapData, selectedSegment]);

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  return (
    <>
      <div className='tabled' style={{ marginBottom: 10, marginTop: screenSize !== 'xs' ? 78 : 0 }}>
        <Row>
          {role !== 'viewer' && (
            <Col xs={24} md={{ push: 1, span: 2 }}>
              <NavLink to='surveys/create'>
                <Tooltip placement='top' color={GlobalColors.mainPurple} title='Create Activity'>
                  <span className='icon' style={{ fontSize: 26 }}>
                    <PlusOutlined style={{ color: GlobalColors.mainPurple }} />
                  </span>
                </Tooltip>
              </NavLink>
            </Col>
          )}
          <Col md={{ span: 16, pull: 4 }} xs={{ span: 20, push: 2 }} style={{ marginBottom: 0 }}>
            <Flex gap='small' align='center' justify='center'>
              <Segmented
                options={['Active', 'All', 'Completed', 'Scheduled']}
                value={selectedSegment}
                onChange={handleSegmentChange}
              />
            </Flex>
          </Col>
          <Col span={24}>
            <Card bordered={false} className='criclebox tablespace mb-24' title='Activities'>
              <div className='table-responsive'>
                {!isLoading ? (
                  <Table
                    columns={columns}
                    dataSource={filteredSurveys}
                    rowKey={(record) => record._id}
                    pagination={{ size: 'small', position: ['bottomCenter'] }}
                    className='ant-border-space'
                  />
                ) : (
                  <SurveyTableSkeleton />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <div ref={downloadRef} id='qrContainer'>
        {qrCodeForDownload}
      </div>
    </>
  );
};

export default Surveys;
