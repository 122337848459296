import React from 'react';
import { Tag, Tooltip, Row, Col, Image } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import SurveyActions from './SurveyActions';
import GlobalColors from '../assets/colors/GlobalColors';
import StatusActive from '../assets/images/status-active.svg';
import StatusCompleted from '../assets/images/status-completed.svg';
import StatusScheduled from '../assets/images/status-scheduled.svg';
import svgIcon from '../assets/images/svgIcon.svg';

const getColumns = ({
  axiosPrivate,
  screenSize,
  role,
  qrCodeSvg,
  activateStatus,
  fetchSurveyForEdit,
  viewSurvey,
  downloadReport,
  generatePDF,
  downloadResponses,
  summarizeResponses,
  liveEvent,
  quiz,
  rank,
  deleteSurvey,
}) => [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    width: '4%',
    align: 'center',
    render: (_, __, index) => <span>{index + 1}</span>,
    responsive: ['lg'],
  },
  {
    title: 'Survey Name',
    dataIndex: 'name',
    key: 'name',
    width: screenSize === 'xs' ? '12%' : '18%',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (name) => <span>{name}</span>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: screenSize === 'xs' ? '8%' : '10%',
    render: (status, record) => {
      let icon = <CheckCircleOutlined />;
      let color = 'grey';
      let mobileStatusIcon = StatusCompleted;

      if (status === 'scheduled') {
        icon = <ClockCircleOutlined />;
        color = 'warning';
        mobileStatusIcon = StatusScheduled;
      } else if (status === 'active') {
        color = 'success';
        mobileStatusIcon = StatusActive;
      }

      return (
        <Row justify='center'>
          <Col span={24}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={status === 'scheduled' ? 'Activate' : null}
            >
              <span onClick={() => status === 'scheduled' && activateStatus(record._id, status)}>
                {screenSize === 'xs' ? (
                  <Image height={12} src={mobileStatusIcon} preview={false} />
                ) : (
                  <Tag
                    icon={icon}
                    color={color}
                    style={{
                      width: '92px',
                      cursor: status === 'scheduled' ? 'pointer' : 'default',
                    }}
                  >
                    {status}
                  </Tag>
                )}
              </span>
            </Tooltip>
          </Col>
        </Row>
      );
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: screenSize === 'xs' ? '8%' : '10%',
    render: (type) => {
      let color = 'processing';
      if (type === 'quiz') color = 'purple';
      if (type === 'live') color = 'red';
      return <Tag color={color}>{type}</Tag>;
    },
  },
  {
    title: 'Expires',
    dataIndex: 'end',
    key: 'expires',
    width: '10%',
    responsive: ['lg'],
    render: (end) => <span>{dayjs(end).fromNow()}</span>,
  },
  {
    title: 'Action',
    key: 'action',
    width: '10%',
    align: 'center',
    render: (_, record) => (
      <SurveyActions
        record={record}
        role={role}
        qrCodeSvg={qrCodeSvg}
        svgIcon={svgIcon}
        axiosPrivate={axiosPrivate}
        organizationId={record.organizationId}
        fetchSurveyForEdit={fetchSurveyForEdit}
        viewSurvey={viewSurvey}
        downloadReport={downloadReport}
        generatePDF={generatePDF}
        downloadResponses={downloadResponses}
        summarizeResponses={summarizeResponses}
        liveEvent={liveEvent}
        quiz={quiz}
        rank={rank}
        deleteSurvey={deleteSurvey}
      />
    ),
  },
];

export default getColumns;
