/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import { Typography, Select, Row, Col } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import lineChartConfigs from './configs/lineChart';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(weekday);

const { options: lineOptions, series: lineSeries } = lineChartConfigs;

function LineChart() {
  const [lineChartSeriesData, setLineChartSeriesData] = useState([]);
  const [lineChartOptions, setLineChartOptions] = useState({});

  const chartsData = useSelector((state) => state.dashboard.chartsData);

  useEffect(() => {
    setLineChartSeriesData([
      { ...lineSeries[0], data: chartsData?.lineChart?.week?.series || [] },
    ]);
    setLineChartOptions({
      ...lineOptions,
      xaxis: {
        ...lineOptions.xaxis,
        categories: chartsData?.lineChart?.week?.categories || [],
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    let range = 'week';

    switch (value) {
      case 'past_day':
        range = 'hour';
        break;

      case 'past_week':
        range = 'week';
        break;

      case 'past_month':
        range = 'month';
        break;

      case 'past_3_months':
        range = 'threeMonths';
        break;

      case 'past_6_months':
        range = 'sixMonths';
        break;

      default:
        break;
    }
    setLineChartSeriesData([
      { ...lineSeries[0], data: chartsData?.lineChart?.[range]?.series || [] },
    ]);
    setLineChartOptions({
      ...lineOptions,
      xaxis: {
        ...lineOptions.xaxis,
        categories: chartsData?.lineChart?.[range]?.categories || [],
      },
    });
  };

  return (
    <>
      <div style={{paddingBottom: 18}}>
        <Row gutter={[8, 0]} justify='space-between'>
          {/* <Col>
            <div>
              <Title level={5}>Active Users</Title>
              <Paragraph className='lastweek'>
                than last week <span className='bnb2'>+30%</span>
              </Paragraph>
            </div>
          </Col> */}
          <Col xs={{ span: 24, order: 3 }}>
            <div>
              <Select
                defaultValue='past_week'
                onChange={handleChange}
                style={{
                  width: 180,
                }}
                options={[
                  {
                    value: 'past_day',
                    label: 'Past day',
                  },
                  {
                    value: 'past_week',
                    label: 'Past week',
                  },
                  {
                    value: 'past_month',
                    label: 'Past month',
                  },
                  {
                    value: 'past_3_months',
                    label: 'Past three months',
                  },
                  {
                    value: 'past_6_months',
                    label: 'Past six months',
                  },
                ]}
              />
            </div>
          </Col>
          {/* <Col>
            <div className='sales'>
              <ul>
                <li>{<MinusOutlined />} Responses</li>
                <li>{<MinusOutlined />} Time of day</li>
              </ul>
            </div>
          </Col> */}
        </Row>
      </div>

      <ReactApexChart
        className='full-width'
        options={lineChartOptions}
        series={lineChartSeriesData}
        type='area'
        height={150}
        width={'100%'}
      />
    </>
  );
}

export default LineChart;
