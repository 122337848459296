import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { FormProvider } from 'rc-field-form';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Button,
  Typography,
  Collapse,
  Tag,
  Input,
  DatePicker,
  Grid,
  Checkbox,
  Alert,
} from 'antd';
import TemplateForm from './TemplateForm';
import SectionHeader from './SectionHeader';
import GlobalColors from '../assets/colors/GlobalColors';
import StatusNotification from './StatusNotification';
import usePrivateAxios from '../hooks/usePrivateAxios';
import LoadingIcon from './LoadingIcon';
import TemplateCard from './TemplateCard';
import getScreenSize from '../utils/getScreenSize';
import LastSeenFilter from './LastSeenFilter';
import TagFilter from './TagFilter';
import SubscriptionExpired from './SubscriptionExpired';
import FetchOrganizationCounters from './FetchOrganizationCounters';
import { HappyProvider } from '@ant-design/happy-work-theme';

const { Panel } = Collapse;
const { useBreakpoint } = Grid;
const { Text } = Typography;

const tagComplete = <Tag color='#87d068'>completed</Tag>;
const tagDraft = <Tag color='#9E9E9E'>draft</Tag>;

const HandleBroadcast = ({ action }) => {
  const [isLoading, setIsLoading] = useState({
    getBatchSize: false,
    handleSubmit: false,
  });
  const [broadcastName, setBroadcastName] = useState('');
  const [broadcastNameError, setBroadcastNameError] = useState('');
  const [startingDateTime, setStartingDateTime] = useState(null);
  const [startingDateTimeError, setStartingDateTimeError] = useState('');
  const [filterError, setFilterError] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [wasRejected, setWasRejected] = useState(false);
  const [isLoyal, setIsLoyal] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [open, setOpen] = useState(['broadcast']);
  const [isDraft, setIsDraft] = useState(true);
  const [broadcastType, setBroadcastType] = useState('sms');

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const dispatch = useDispatch();
  const history = useHistory();
  const axiosPrivate = usePrivateAxios();
  const apiURL = '/api/broadcasts/start';

  const {
    user: { organizationId, counters, userId },
    broadcastData: { batchSize },
    tagsFilter: { andTags, notTags },
    lastSeenFilter: { lastSeen },
    templatesData: { templateParameters, selectedTemplate, shortUrlId },
  } = useSelector((state) => state);

  useEffect(() => {
    getBatchSize();
  }, [isSubscribed, isContact, lastSeen, andTags, notTags]);

  const generatePayload = () => {
    return {
      organizationId,
      options: {
        ...(isSubscribed && { isSubscribed }),
        ...(isContact && { isContact }),
        ...(lastSeen && { lastSeen }),
        andTags: andTags,
        notTags: notTags,
      },
    };
  };

  useEffect(() => {
    if (filterError) {
      setOpen(['segmantation']);
    }
  }, [filterError]);

  const getBatchSize = async () => {
    try {
      setIsLoading((prevLoading) => ({ ...prevLoading, getBatchSize: true }));
      const payload = generatePayload();
      const response = await axiosPrivate.post('/api/broadcasts/batch-size', payload);
      dispatch({ type: 'FETCH_BATCH_SIZE', data: response.data });
      setIsLoading((prevLoading) => ({ ...prevLoading, getBatchSize: false }));
      FetchOrganizationCounters(axiosPrivate, dispatch, organizationId);
    } catch (error) {
      // StatusNotification('error', 'Error when fetching batch size');
      setIsLoading((prevLoading) => ({ ...prevLoading, getBatchSize: false }));
    }
  };

  const handleSubmit = async () => {
    let isValid = true;

    if (broadcastName.length < 5) {
      setBroadcastNameError('Broadcast name must be at least 5 characters long!');
      isValid = false;
    }

    if (!startingDateTime) {
      setStartingDateTimeError('Please select the starting date and time');
      isValid = false;
    }

    if (!isSubscribed && !isContact) {
      setFilterError('Atleast one of isSubscribed or isContact must be selected');
      isValid = false;
    }

    if (!broadcastType) return StatusNotification('warning', 'Please select a broadcast type');

    if (isLoading.handleSubmit) return;

    if (isValid) {
      const payload = generatePayload();

      payload.broadcastName = broadcastName;
      payload.startingDateTime = startingDateTime;
      payload.organizationId = organizationId;
      payload.templateId = selectedTemplate?._id;
      payload.templateName = selectedTemplate?.name;
      payload.templateParameters = templateParameters;
      payload.broadcastType = broadcastType;
      payload.shortUrlId = shortUrlId;

      try {
        setIsLoading((prevLoading) => ({ ...prevLoading, handleSubmit: true }));

        const response = await axiosPrivate.post(apiURL, payload);
        StatusNotification(
          'success',
          'Broadcast submitted successfully',
          'Keep refreshing the table to get progress status'
        );
        dispatch({ type: 'CREATE_BROADCAST_ID', data: response.data.id });
        handleReset();
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          handleSubmit: false,
        }));

        history.push({
          pathname: '/broadcasts',
          state: { from: { pathname: '/broadcasts/create' } },
        });
      } catch (error) {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          handleSubmit: false,
        }));
        if (error?.response?.status === 402) {
          StatusNotification('error', `${error?.response?.data?.message}`);
        } else
          StatusNotification(
            'error',
            'An error occurred while submitting the broadcast',
            `${error?.response?.data?.message}`
          );
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleBroadcastNameChange = (e) => {
    setBroadcastName(e.target.value);
    if (e.target.value.length >= 5) {
      setBroadcastNameError('');
    }
  };

  const handleIsSubscribed = (e) => {
    setIsSubscribed(e.target.checked);
    setFilterError('');
    dispatch({ type: 'RESET_TAGS' });
    dispatch({ type: 'CLEAR_LAST_SEEN' });
  };

  const handleTopUp = () => {
    setTimeout(() => {
      history.push({
        pathname: '/subscription',
        state: { from: { pathname: '/broadcasts/create' } },
      });
    }, 700);
  };
  const handleIsContact = (e) => {
    setIsContact(e.target.checked);
    setFilterError('');
    dispatch({ type: 'RESET_TAGS' });
    dispatch({ type: 'CLEAR_LAST_SEEN' });
  };

  const handleDateChange = (date) => {
    setStartingDateTime(date);
    if (date) {
      setStartingDateTimeError('');
    }
  };
  const handleReset = () => {
    setBroadcastName('');
    setStartingDateTime(null);
    setIsSubscribed(false);
    setWasRejected(false);
    setIsLoyal(false);
    setIsContact(false);
    getBatchSize();
    dispatch({ type: 'CREATE_BROADCAST_CLEAR' });
    dispatch({ type: 'RESET_TAGS' });
    dispatch({ type: 'CLEAR_LAST_SEEN' });
  };

  const cannotSubmit = !isContact && !isSubscribed;
  const currentCredit = counters?.topUpCredit + counters?.monthlyFreeBundles;
  const hasEnoughCredit = currentCredit >= batchSize;

  return (
    <Row gutter={[8, 0]} style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}>
      <Col span={24}>
        <Row>
          <Col xs={24} md={12}>
            <SectionHeader
              title={action === 'create' ? 'Create Broadcast' : 'Edit Broadcast'}
              isFront={false}
              subtitle=''
              style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={24}>
            <Row>
              <Col xs={24} md={12}>
                <Input
                  value={broadcastName}
                  onChange={handleBroadcastNameChange}
                  minLength={5}
                  maxLength={26}
                  showCount={true}
                  placeholder='Broadcast Name'
                  className='field-item form-field'
                />
                {broadcastNameError && <div style={{ color: 'red' }}>{broadcastNameError}</div>}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row>
              <Col xs={24} md={12}>
                <DatePicker
                  value={startingDateTime}
                  onChange={handleDateChange}
                  showTime={{ format: 'HH:mm', minuteStep: 15, hourStep: 3 }}
                  size='large'
                  status={startingDateTimeError ? 'error' : 'validating'}
                  style={{ width: '100%' }}
                  placeholder='Choose date and time'
                />
                {startingDateTimeError && (
                  <div style={{ color: 'red' }}>{startingDateTimeError}</div>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={[8, 16]} style={{ marginTop: 14 }}>
          <Col xs={24} md={12}>
            <FormProvider onFormFinish={(name, { values, forms }) => {}}>
              <Collapse
                accordion
                activeKey={open}
                onChange={(e) => {
                  setOpen((prev) => [e]);
                }}
              >
                <Panel
                  header='Broadcast Templates'
                  key='broadcast'
                  extra={isDraft ? tagDraft : tagComplete}
                >
                  <TemplateForm
                    setOpen={setOpen}
                    setIsDraft={setIsDraft}
                    setBroadcastType={setBroadcastType}
                  />
                </Panel>
              </Collapse>
            </FormProvider>
          </Col>
          <Col xs={24} md={12}>
            <Row justify='end'>
              <Col
                span={12}
                style={{
                  maxHeight: 'calc(100vh - 200px)',
                  overflowY: 'auto',
                  padding: '10px',
                  boxSizing: 'border-box',
                }}
              >
                {screenSize !== 'xs' && selectedTemplate && <TemplateCard />}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <Collapse accordion activeKey={open} onChange={(e) => setOpen((prev) => [e])}>
              <Panel
                header='Segmentation'
                key='segmantation'
                extra={
                  <>
                    {'Batch Size '}
                    <Tag color={GlobalColors.lightPurple}>
                      {isLoading.getBatchSize ? <LoadingIcon /> : batchSize}
                    </Tag>
                  </>
                }
              >
                <Row gutter={[8, 0]}>
                  <Col span={12}>
                    <Row justify='space-between'>
                      <Col xs={24} md={12}>
                        <Checkbox checked={isSubscribed} onChange={handleIsSubscribed}>
                          Is Subscribed
                        </Checkbox>
                      </Col>
                      <Col xs={24} md={12}>
                        <Checkbox checked={isContact} onChange={handleIsContact}>
                          Is Contact
                        </Checkbox>
                      </Col>
                    </Row>
                    {filterError && <div style={{ color: 'red', marginTop: 8 }}>{filterError}</div>}
                  </Col>
                  <Col span={12}>
                    <LastSeenFilter />
                  </Col>
                  {(isSubscribed || isContact) && (
                    <>
                      <Col span={12} style={{ marginTop: 14 }}>
                        <span
                          style={{
                            fontSize: 12,
                            backgroundColor: GlobalColors.whitePurple,
                            borderRadius: 4,
                            padding: 2,
                          }}
                        >
                          <strong>Included Tags</strong>
                        </span>{' '}
                        <TagFilter operator={'AND'} />
                      </Col>
                      <Col span={12} style={{ marginTop: 14 }}>
                        <span
                          style={{
                            fontSize: 12,
                            backgroundColor: GlobalColors.whitePurple,
                            borderRadius: 4,
                            padding: 2,
                          }}
                        >
                          <strong>Excluded Tags</strong>
                        </span>{' '}
                        <TagFilter operator={'NOT'} />
                      </Col>
                    </>
                  )}
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </Col>
      {!hasEnoughCredit && (
        <Col xs={24} md={12} style={{ marginTop: 8 }}>
          <Alert
            message={`Insufficient credit [ ${currentCredit} ], consider topping up or reducing batch size`}
            type='info'
            showIcon
            action={
              <HappyProvider>
                <Button onClick={handleTopUp}>Top Up</Button>
              </HappyProvider>
            }
          />
        </Col>
      )}
      {hasEnoughCredit && (
        <Col xs={24} md={12} style={{ marginTop: 20, marginBottom: 20 }}>
          <Row gutter={[8, 12]}>
            <Col xs={24} md={12}>
              <Button
                type='primary'
                style={{ width: '100%' }}
                onClick={handleSubmit}
                disabled={isDraft || batchSize === 0 || cannotSubmit}
              >
                {isLoading.handleSubmit ? <LoadingIcon /> : 'Submit'}
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Button style={{ width: '100%' }} onClick={handleReset}>
                {'Reset'}
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default HandleBroadcast;
