import React, { useState } from 'react';
import { Row, Col, Typography, Popover, Tooltip, Popconfirm } from 'antd';
import {
  CopyOutlined,
  CheckOutlined,
  ClearOutlined,
  EditFilled,
  EyeFilled,
  FilePdfOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  RobotOutlined,
  VideoCameraOutlined,
  QuestionCircleOutlined,
  OrderedListOutlined,
  DeleteFilled,
} from '@ant-design/icons';
import SurveyActionsDropdown from './SurveyActionsDropdown';
import GlobalColors from '../assets/colors/GlobalColors';
import findRankableField from '../utils/findRankableField';
import StatusNotification from '../components/StatusNotification';

const { Paragraph } = Typography;
const SurveyActions = ({
  record,
  role,
  botPhoneNumber,
  organizationId,
  axiosPrivate,
  qrCodeSvg,
  svgIcon,
  fetchSurveyForEdit,
  viewSurvey,
  downloadReport,
  generatePDF,
  downloadResponses,
  summarizeResponses,
  liveEvent,
  quiz,
  rank,
  deleteSurvey,
}) => {
  const {
    _id,
    name,
    status,
    survey,
    isResponsesAvailable,
    isLinkedToTemplate,
    isMapped,
    isLiveEvent,
    isQuiz,
    pdfmeTemplateId,
  } = record;

  console.log({ record });

  const iconStyle = {
    fontSize: 12.5,
    color: GlobalColors.darkGray,
    padding: 0,
    lineHeight: 2.8,
    paddingRight: 8,
  };

  const [resetConfirmVisible, setResetConfirmVisible] = useState(false);
  const [currentReset, setCurrentReset] = useState({ id: null, name: null });
  const [confirmLoading, setConfirmLoading] = useState(false);

  const { isRankable, rankableFieldIndexNumber } = findRankableField(survey);

  const handleResetClick = (_id, name) => {
    setCurrentReset({ id: _id, name: name });
    setResetConfirmVisible(true);
  };

  const handleCancelReset = () => {
    setResetConfirmVisible(false);
    setCurrentReset({ id: null, name: null });
  };

  const handleConfirmReset = async () => {
    if (!currentReset.id) return;

    setConfirmLoading(true);
    await resetSurvey(currentReset.id, currentReset.name);
    setConfirmLoading(false);

    setResetConfirmVisible(false);
    setCurrentReset({ id: null, name: null });
  };

  const resetSurvey = async (_id, surveyName) => {
    try {
      const response = await axiosPrivate.post('/api/responses/reset', {
        surveyId: _id,
        removeAll: false,
        tags: [surveyName],
      });
      StatusNotification('success', 'Reset Successful', 'All responses have been reset');
    } catch (error) {}
  };
  const getDropdownMenuItems = () => {
    let items = [];

    if (status !== 'completed' && role !== 'viewer') {
      items.push({
        key: 'edit',
        label: 'Edit',
        icon: <EditFilled />,
      });
    }

    if ((status === 'active' || status === 'completed') && isResponsesAvailable) {
      items.push({
        key: 'view',
        label: 'View',
        icon: <EyeFilled />,
      });
    }

    if (status !== 'scheduled' && isResponsesAvailable) {
      if (isMapped) {
        items.push({
          key: 'download',
          label: 'Download Report',
          icon: <FilePdfOutlined />,
          children: [
            {
              key: 'downloadReport',
              label: 'Download Mapped Report',
            },
            {
              key: 'reMap',
              label: 'Re-Map with Latest Responses',
            },
          ],
        });
      } else if (role !== 'viewer') {
        items.push({
          key: 'mapReport',
          label: 'Map Report',
          icon: <DatabaseOutlined />,
        });
      }
    }

    if (status !== 'scheduled' && isResponsesAvailable) {
      items.push({
        key: 'responses',
        label: 'Download CSV',
        icon: <DownloadOutlined />,
      });
    }

    if (status !== 'scheduled' && isResponsesAvailable) {
      items.push({
        key: 'summarize',
        label: 'Summarize with AI',
        icon: <RobotOutlined />,
      });
    }

    if (status !== 'scheduled' && status !== 'completed' && isLiveEvent && !isQuiz) {
      items.push({
        key: 'liveEvent',
        label: 'Live Event Interaction',
        icon: <VideoCameraOutlined />,
      });
    }

    if (status !== 'scheduled' && status !== 'completed' && isLiveEvent && isQuiz) {
      items.push({
        key: 'quiz',
        label: 'Quiz',
        icon: <QuestionCircleOutlined />,
      });
    }

    if (status !== 'scheduled' && status !== 'completed' && isRankable) {
      items.push({
        key: 'rank',
        label: 'Ranking Event',
        icon: <OrderedListOutlined />,
      });
    }

    if (status === 'scheduled' && role !== 'viewer') {
      items.push({
        key: 'delete',
        label: 'Delete',
        icon: <DeleteFilled />,
        danger: true,
      });
    }

    return items;
  };

  const handleDropdownItemClick = (key) => {
    switch (key) {
      case 'edit':
        fetchSurveyForEdit(_id);
        break;
      case 'view':
        viewSurvey(_id, name, survey, isLiveEvent);
        break;
      case 'downloadReport':
        downloadReport(_id, name, pdfmeTemplateId);
        break;
      case 'reMap':
      case 'mapReport':
        generatePDF(_id, name, isLinkedToTemplate, isMapped);
        break;
      case 'responses':
        downloadResponses(_id, name);
        break;
      case 'summarize':
        summarizeResponses(_id, name);
        break;
      case 'liveEvent':
        liveEvent(_id, name, survey);
        break;
      case 'quiz':
        quiz(_id, name, survey);
        break;
      case 'rank':
        rank(_id, name, survey, rankableFieldIndexNumber);
        break;
      case 'reset':
        handleResetClick(_id, name);
        break;
      case 'delete':
        deleteSurvey(_id);
        break;
      default:
        console.log(`No handler for key: ${key}`);
    }
  };

  const dropdownItems = getDropdownMenuItems();

  return (
    <Row
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      {status !== 'scheduled' && status !== 'completed' && (
        <Col md={{ span: 6 }} xs={{ span: 8 }} style={{ textAlign: 'center' }}>
          <Paragraph
            style={{ marginBottom: 0 }}
            copyable={{
              icon: [
                <CopyOutlined key='copy-icon' style={iconStyle} />,
                <CheckOutlined
                  key='copied-icon'
                  style={{
                    ...iconStyle,
                    color: GlobalColors.whatsappLime,
                  }}
                />,
              ],
              text: `https://api.whatsapp.com/send?phone=${botPhoneNumber}&text=Press-Send-To-Start-The-Survey_${organizationId}_${_id}`,
              tooltips: ['copy link', 'copied'],
            }}
          />
        </Col>
      )}
      {status === 'active' && (
        <Col md={{ span: 6 }} xs={{ span: 8 }} style={{ textAlign: 'center' }}>
          <Popover
            overlayInnerStyle={{ padding: 0 }}
            content={qrCodeSvg(
              `https://api.whatsapp.com/send?phone=${botPhoneNumber}&text=Press-Send-To-Start-The-Survey_${organizationId}_${_id}`,
              name
            )}
          >
            <img src={svgIcon} style={{ width: 12, color: 'gray' }} alt='' />
          </Popover>
        </Col>
      )}
      {status === 'active' && isResponsesAvailable && (
        <Col md={{ span: 6 }} xs={{ span: 8 }} style={{ textAlign: 'center' }}>
          <Tooltip placement='top' color={GlobalColors.mainPurple} title='Reset Survey'>
            <Popconfirm
              title='Are you sure you want to reset this survey?'
              open={resetConfirmVisible}
              onConfirm={handleConfirmReset}
              onCancel={handleCancelReset}
              okType='danger'
              okButtonProps={{ loading: confirmLoading }}
            >
              <ClearOutlined onClick={() => handleResetClick(_id, name)} />
            </Popconfirm>
          </Tooltip>
        </Col>
      )}
      <Col md={{ span: 6 }} xs={{ span: 24 }} style={{ textAlign: 'center' }}>
        <SurveyActionsDropdown items={dropdownItems} onClick={handleDropdownItemClick} />
      </Col>
    </Row>
  );
};

export default SurveyActions;
