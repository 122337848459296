import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Upload, Button, Row, Col, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import StatusNotification from './StatusNotification';
import usePrivateAxios from '../hooks/usePrivateAxios';

const { Text } = Typography;

const WhatsAppMedia = ({ fileName, organizationId, usage, planType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileType, setFileType] = useState('');
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();

  const handleUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axiosPrivate.post(`/api/templates/whatsapp-media`, formData, {
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round((loaded / total) * 100) }, file);
        },
      });

      const fileHandle = response.data.fileHandle;

      dispatch({
        type: 'SET_WHATSAPP_MEDIA_METADATA',
        data: { fileHandle, type: fileType },
      });

      onSuccess(response.data, file);
      setIsLoading(false);
      setIsUploaded(true);
      StatusNotification('success', 'File successfully uploaded to the WhatsApp media library');
    } catch (error) {
      onError(error);
      setIsLoading(false);
      StatusNotification('error', 'Error uploading file', error?.response?.data?.message);
    }
  };

  const props = {
    name: 'file',
    customRequest: handleUpload,
    beforeUpload: (file) => {
      setFileType(file.type.split('/')[0]);
      const fileExtension = file.type.split('/')[1];
      const acceptedFormats = ['jpeg', 'jpg', 'png'];

      console.log({file})

      if (fileType === 'video' && fileExtension !== 'mp4') {
        StatusNotification('error', 'Only mp4 videos are supported');
        return Upload.LIST_IGNORE;
      }

      if (fileType === 'video') {
        const isLt32M = file.size / 1024 / 1024 < 32;
        if (!isLt32M) {
          StatusNotification('error', 'Video must be smaller than 32MB!');
          return Upload.LIST_IGNORE;
        }
      }

      if (fileType !== 'image' && file.type !== 'application/pdf' && fileType !== 'video') {
        StatusNotification('error', `${fileType} is not a supported format`);
        return Upload.LIST_IGNORE;
      }

      if (fileType === 'image') {
        const isLt4M = file.size / 1024 / 1024 < 4;

        if (!acceptedFormats.includes(fileExtension)) {
          StatusNotification('error', 'Only .jpg, .jpeg, and .png images are allowed');
          return Upload.LIST_IGNORE;
        }
        if (!isLt4M) {
          StatusNotification('error', 'Image must be smaller than 4MB!');
          return Upload.LIST_IGNORE;
        }
      }

      if (file.type === 'application/pdf') {
        const isLt12M = file.size / 1024 / 1024 < 12;
        if (!isLt12M) {
          StatusNotification('error', 'PDF must be smaller than 12MB!');
          return Upload.LIST_IGNORE;
        }
      }

      setIsLoading(true);
      return true;
    },
    onRemove: () => {
      setIsLoading(false);
      setIsUploaded(false);
    },
  };

  return (
    <Row>
      <Col span={24} style={{ backgroundColor: 'Background', borderRadius: 6 }}>
        <Row>
          {/* <Col span={24} style={{ paddingLeft: 4 }}>
            <Text strong style={{ fontSize: 12 }}>
              {'WhatsApp Media'}
            </Text>
          </Col> */}
          <Col span={24} style={{ paddingLeft: 4, paddingBottom: 8 }}>
            <Upload {...props} style={{ cursor: 'pointer', width: '100%', marginBottom: 14 }}>
              <Button icon={<UploadOutlined />} disabled={isLoading || isUploaded}>
                {isLoading ? 'Uploading...' : isUploaded ? 'Uploaded' : `Upload WhatsApp Media`}
              </Button>
            </Upload>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default WhatsAppMedia;
