import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const CountrySelect = ({ onChange }) => {
  const countries = ['South Africa', 'Eswatini', 'Zimbabwe', 'Botswana', 'Zambia', 'Mozambique', 'Tanzania', 'Kenya', 'Uganda', 'Ethiopia', 'Nigeria', 'Ghana', 'Other'];

  return (
    <Select placeholder='Select country' style={{ width: '100%' }} onChange={onChange} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
      {countries.map((country) => (
        <Option key={country} value={country}>
          {country}
        </Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
