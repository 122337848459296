import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form, { Field } from 'rc-field-form';
import {
  Card,
  Row,
  Col,
  Select,
  Input,
  Button,
  Typography,
  Tag,
  Segmented,
  Grid,
  Tooltip,
  Collapse,
  Alert,
} from 'antd';

import GlobalColors from '../assets/colors/GlobalColors';
import TemplateButtons from './TemplateButtons';
import getScreenSize from '../utils/getScreenSize';
import WhatsAppMedia from './WhatsAppMedia';
import LoadingIcon from './LoadingIcon';
import StatusNotification from './StatusNotification';
import SectionHeader from './SectionHeader';
import ValidatePlaceholderInputs from './ValidatePlaceholderInputs';
import usePrivateAxios from '../hooks/usePrivateAxios';

import formatFormTemplateValues from '../utils/formatFormTemplateValues';
import TemplateCard from './TemplateCard';

const { Option } = Select;
const { TextArea } = Input;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
const { Panel } = Collapse;

const tagComplete = <Tag color='#87d068'>completed</Tag>;
const tagDraft = <Tag color='#9E9E9E'>draft</Tag>;

const HandleTemplate = () => {
  const [templateName, setTemplateName] = useState('');
  const [templateNameError, setTemplateNameError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [headerType, setHeaderType] = useState('none');
  const [headerText, setHeaderText] = useState('');
  const [bodyText, setBodyText] = useState('');
  const [footerText, setFooterText] = useState('');
  const [headerTextError, setHeaderTextError] = useState('');
  const [bodyTextError, setBodyTextError] = useState('');
  const [footerTextError, setFooterTextError] = useState('');
  const [open, setOpen] = useState(['header']);
  const [isDraft, setIsDraft] = useState(true);
  const [templateInputData, setTemplateInputData] = useState({});
  const [buttonsData, setButtonsData] = useState({});
  const [currentPlaceholders, setCurrentPlaceholders] = useState([]);
  const [selectedType, setSelectedType] = useState('SMS');
  const [dynamicUrlInput, setDynamicUrlInput] = useState('');
  const [isDynamicUrlShortened, setIsDynamicUrlShortened] = useState(false);
  const [smsCharacterLimit, setSmsCharacterLimit] = useState(480);

  const { organizationId, hasOwnBot } = useSelector((state) => state.user);
  const whatsAppMediaMetadata = useSelector((state) => state.templatesData.whatsAppMediaMetadata);
  const selectedTemplate = useSelector((state) => state.templatesData.selectedTemplate);
  const inputValues = useSelector((state) => state.templatesData.inputValues);

  const [form] = Form.useForm();

  const axiosPrivate = usePrivateAxios();
  const dispatch = useDispatch();
  const history = useHistory();
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const handleSubmit = () => {
    let isValid = true;

    if (templateName.length < 5) {
      setTemplateNameError('Template name must be at least 5 characters long!');
      isValid = false;
    }

    if (!bodyText) {
      setBodyTextError('Body text is required!');
      isValid = false;
    }

    if (isLoading) return;

    if (isValid) {
      setIsLoading(true);
      const data = {
        templateName,
        headerType,
        headerText,
        bodyText,
        footerText,
      };
      setTemplateInputData(data);

      setIsDraft(false);
      setIsLoading(false);
    }
  };

  const validateInput = () => {
    // Custom validation logic goes here
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
    if (e.target.value.length >= 5) {
      setTemplateNameError('');
    }
  };

  const handleHeaderTextChange = (newValue) => {
    const placeholderRegex = /{/g;
    const placeholders = newValue.match(placeholderRegex) || [];

    if (placeholders.length > 2 && newValue.includes('{') && !headerText.includes(newValue)) {
      setHeaderTextError('Only one variable placeholder is allowed in the header.');
      if (placeholders.length < 1) setHeaderTextError('');
      return;
    } else {
      setHeaderTextError(''); // Clear error if the condition is no longer met
    }

    setHeaderText(newValue);
    if (newValue.length < 60) {
      setHeaderTextError('');
    }
  };

  const handleBodyTextChange = (newValue) => {
    const newPlaceholders = extractPlaceholders(newValue).map(
      (ph) => `BODY_${parseInt(ph.match(/\d+/)[0], 10) - 1}`
    );
    const oldPlaceholders = Object.keys(inputValues).filter((key) => key.startsWith('BODY_'));

    setBodyText(newValue);
    setCurrentPlaceholders(newPlaceholders);

    if (selectedType === 'SMS' && newValue.includes('{{url}}') && !dynamicUrlInput?.payload) {
      setDynamicUrlInput('');
      setIsDynamicUrlShortened(false);
    }

    if (selectedType === 'SMS' && dynamicUrlInput?.payload) {
      setSmsCharacterLimit(480 - dynamicUrlInput.length?.payload);
    }

    const removedPlaceholders = oldPlaceholders.filter((ph) => {
      return !newPlaceholders.includes(ph);
    });

    removedPlaceholders.forEach((placeholder) => {
      dispatch({
        type: 'REMOVE_TEMPLATE_INPUT_VALUE',
        data: { keyToRemove: placeholder },
      });
    });
  };

  const extractPlaceholders = (text) => {
    const placeholderRegex = /{{\d+}}/g;
    const matches = text.match(placeholderRegex) || [];
    return [...new Set(matches)];
  };

  const handleFooterTextChange = (e) => {
    const newValue = e.target.value;
    const placeholderRegex = /{/g;
    const placeholders = newValue.match(placeholderRegex) || [];

    if (placeholders.length > 0 && newValue.includes('{') && !footerText.includes(newValue)) {
      setFooterTextError('Placeholder not allowed in the footer.');
      if (placeholders.length < 1) setFooterTextError('');
      return;
    } else {
      setFooterTextError('');
    }

    setFooterText(newValue);
    if (newValue.length < 60) {
      setFooterTextError('');
    }
  };

  const handleTemplateType = (value) => {
    setSelectedType(value);
  };

  const handleValuesChange = (_, allValues) => {
    if (allValues?.templateName) {
      const fieldValue = allValues?.templateName;
      setTemplateName(allValues?.templateName);
      form.setFieldsValue({
        templateName: fieldValue?.toLowerCase().replaceAll(' ', '_'),
      });
    }
    if (allValues?.HEADER_text) handleHeaderTextChange(allValues?.HEADER_text);
    if (allValues?.BODY_text) handleBodyTextChange(allValues?.BODY_text);

    const payload = formatFormTemplateValues({
      ...allValues,
      ...whatsAppMediaMetadata,
    });

    for (const [key, value] of Object.entries(allValues)) {
      if (key.includes('_example_')) {
        const [type, exampleText, index] = key.split('_');
        dispatch({
          type: 'SET_TEMPLATE_INPUT_VALUES',
          data: { [`${type}_${index}`]: value },
        });
      }
    }

    dispatch({ type: 'SET_SELECTED_TEMPLATE', data: payload });
  };

  const onFinish = async (values) => {
    const payload = formatFormTemplateValues({
      ...values,
      ...whatsAppMediaMetadata,
    });

    payload.type = selectedType.toLowerCase();
    payload.language = 'en_US';
    payload.category = 'MARKETING';
    payload.organizationId = organizationId;
    payload.name = organizationId.toLowerCase() + '_' + values?.templateName;

    if (!payload?.name) return;

    try {
      setIsLoading(true);

      const response = await axiosPrivate.post('/api/templates/create', payload);

      StatusNotification('success', 'Template uploaded and stored successfully');
      dispatch({ type: 'ADD_NEW_TEMPLATE', data: response.data });
      handleReset();

      history.push({
        pathname: '/templates',
        state: { from: { pathname: '/templates/create' } },
      });
    } catch (error) {
      StatusNotification('error', 'An error occurred while uploading the template');
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    dispatch({ type: 'RESET_TEMPLATE' });
    localStorage.removeItem('templateData');
    setCurrentPlaceholders([]);
    form.resetFields();
    setSmsCharacterLimit(480);
    setDynamicUrlInput('');
  };

  console.log({ smsCharacterLimit, dynamicUrlInput });
  return (
    <Row gutter={[8, 0]} style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}>
      <Form form={form} onFinish={onFinish} layout='vertical' onValuesChange={handleValuesChange}>
        <Col xs={24} md={12}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <SectionHeader
                title={hasOwnBot ? 'Create WhatsApp or sms Template' : 'Create SMS Template'}
                isFront={false}
                subtitle=''
                style={{ marginTop: screenSize !== 'xs' ? 48 : 0 }}
              />
            </Col>
            <Col span={24}>
              <Field
                key={1}
                name='templateName'
                rules={[
                  {
                    required: true,
                    message: 'TemplateName is required!',
                  },
                ]}
              >
                {(control, meta) => (
                  <>
                    <Input
                      {...control}
                      minLength={5}
                      maxLength={60}
                      showCount={true}
                      placeholder='Template Name'
                    />
                    {meta.errors.length > 0 && <div style={{ color: 'red' }}>{meta.errors[0]}</div>}
                  </>
                )}
              </Field>
            </Col>
            <Col span={24}>
              <Row>
                {hasOwnBot && (
                  <Col span={24}>
                    <Tag color={GlobalColors.lightPurple} style={{ fontSize: 12.5 }}>
                      Choose Template Type
                    </Tag>
                  </Col>
                )}
                {hasOwnBot && (
                  <Col span={24}>
                    <Segmented
                      options={hasOwnBot ? [('SMS', 'WhatsApp')] : ['SMS']}
                      defaultValue={selectedType}
                      value={selectedType}
                      onChange={handleTemplateType}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            {selectedType === 'WhatsApp' && (
              <Col span={24}>
                <Collapse
                  accordion
                  activeKey={open}
                  onChange={(e) => {
                    setOpen((prev) => [e]);
                  }}
                >
                  <Panel
                    header='Add title or choose media type for header'
                    key='header'
                    extra={isDraft ? tagDraft : tagComplete}
                  >
                    <Row gutter={[8, 8]}>
                      <Col xs={24} md={4}>
                        <Select value={headerType} placeholder='None' onChange={setHeaderType}>
                          <Option value='none'>None</Option>
                          <Option value='text'>Text</Option>
                          <Option value='media'>Media</Option>
                        </Select>
                      </Col>
                      {headerType === 'text' && (
                        <Col xs={24} md={20}>
                          <Field
                            key={1}
                            name='HEADER_text'
                            rules={[
                              {
                                required: true,
                                message: 'HeaderText is required!',
                              },
                            ]}
                          >
                            {(control, meta) => {
                              return (
                                <>
                                  <Input
                                    {...control}
                                    placeholder='Enter header text, Only one {{1}} variable allowed'
                                    showCount
                                    maxLength={60}
                                    style={meta.errors.length > 0 ? {} : { marginBottom: 18 }}
                                  />
                                  {meta.errors.length > 0 && (
                                    <div style={{ color: 'red' }}>{meta.errors[0]}</div>
                                  )}
                                </>
                              );
                            }}
                          </Field>
                          {headerText && (
                            <ValidatePlaceholderInputs
                              type='HEADER'
                              text={headerText}
                              form={form}
                            />
                          )}
                          {headerTextError && <div style={{ color: 'red' }}>{headerTextError}</div>}
                        </Col>
                      )}
                      {headerType === 'media' && (
                        <Col xs={24} md={20} style={{ textAlign: 'center' }}>
                          {templateName ? (
                            <WhatsAppMedia usage={'WhatsApp'} fileName={templateName} />
                          ) : (
                            StatusNotification(
                              'error',
                              'Template name required',
                              'You must enter template name first before uploading media'
                            )
                          )}
                        </Col>
                      )}
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            )}
            <Col span={24}>
              <Field
                key={3}
                name='BODY_text'
                rules={[{ required: true, message: 'BodyText is required!' }]}
              >
                {(control, meta) => {
                  return (
                    <>
                      <TextArea
                        {...control}
                        placeholder='Enter body text in English (US), Multiple {{1}} variables allowed'
                        rows={4}
                        showCount
                        maxLength={selectedType === 'WhatsApp' ? 1024 : smsCharacterLimit}
                        style={meta.errors.length > 0 ? {} : { marginBottom: 24 }}
                      />
                      {meta.errors.length > 0 && (
                        <div style={{ color: 'red' }}>{meta.errors[0]}</div>
                      )}
                    </>
                  );
                }}
              </Field>
              {bodyText && (
                <ValidatePlaceholderInputs
                  type='BODY'
                  text={bodyText}
                  form={form}
                  placeholderType={selectedType}
                  setDynamicUrlInput={setDynamicUrlInput}
                />
              )}
              {selectedType === 'SMS' && (
                <Alert
                  message='Minimum 160, Maximum 480 characters. [displaying as a single SMS on a cellphone]'
                  type='info'
                />
              )}
            </Col>
            {selectedType === 'WhatsApp' && (
              <Col span={24} style={{ marginTop: 14 }}>
                <Collapse accordion>
                  <Panel
                    header='Footer'
                    key='footer'
                    extra={<Tag color={GlobalColors.lightPurple}>optional</Tag>}
                  >
                    <Field key={4} name='FOOTER_text'>
                      {(control, meta) => {
                        return (
                          <>
                            <Input
                              {...control}
                              placeholder='Enter text in English (US)'
                              showCount
                              maxLength={60}
                            />
                            {meta.errors.length > 0 && (
                              <div style={{ color: 'red' }}>{meta.errors[0]}</div>
                            )}
                          </>
                        );
                      }}
                    </Field>
                  </Panel>
                </Collapse>
              </Col>
            )}
            {selectedType === 'WhatsApp' && (
              <Col span={24}>
                <Collapse accordion>
                  <Panel
                    header='Buttons'
                    key='buttons'
                    extra={<Tag color={GlobalColors.lightPurple}>optional</Tag>}
                  >
                    <TemplateButtons
                      screenSize={screenSize}
                      form={form}
                      handleValuesChange={handleValuesChange}
                    />
                  </Panel>
                </Collapse>
              </Col>
            )}
            <Col span={24}>
              <Col span={24} style={{ marginTop: 20, marginBottom: 20 }}>
                <Row gutter={[8, 12]}>
                  <Col md={12} xs={24}>
                    <Button type='primary' block htmlType='submit'>
                      {isLoading ? <LoadingIcon /> : 'Submit'}
                    </Button>
                  </Col>
                  <Col md={12} xs={24}>
                    <Button block onClick={handleReset}>
                      {'Reset'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={7}>
          {selectedTemplate?.components?.length > 0 && <TemplateCard />}
        </Col>
      </Form>
    </Row>
  );
};

export default HandleTemplate;
