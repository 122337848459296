import React from 'react'
import { Card, List, Tag } from 'antd'
import { CheckCircleFilled, StopOutlined } from '@ant-design/icons'
import GlobalColors from '../assets/colors/GlobalColors'

const avatarIcon = (
  <CheckCircleFilled
    style={{ fontSize: 16, color: GlobalColors.whitePurple }}
  />
)

const freeSubscriptionData = [
  {
    title: 'Includes',
  },

  {
    avatar: avatarIcon,
    title: 'Bulk SMS R0.28 per message',
  },

  {
    avatar: avatarIcon,
    title: 'List Segmentation',
  },
  {
    avatar: avatarIcon,
    title: 'Broadcast Analytics',
  },
  {
    avatar: avatarIcon,
    title: '20 Free Message Bundle at Signup',
  },
  {
    avatar: avatarIcon,
    title: 'CSV Contacts Import',
  },
  {
    avatar: avatarIcon,
    title: 'Contacts tagging',
  },
  {
    avatar: avatarIcon,
    title: 'Email Support',
  },
];
const basicSubscriptionData = [
  {
    title: 'Everything in Free, Plus',
  },
  {
    avatar: avatarIcon,
    title: 'WhatsApp Bulk Messaging',
  },
  {
    avatar: avatarIcon,
    title: 'WhatsApp Templates',
  },
  {
    avatar: avatarIcon,
    title: '500 Free WhatsApp Messages p/m',
  },
  {
    avatar: avatarIcon,
    title: '1 AI Chatbot',
  },
  {
    avatar: avatarIcon,
    title: '3 Active Survey',
  },
  {
    avatar: avatarIcon,
    title: 'Dashboard Analytics',
  },
  {
    avatar: avatarIcon,
    title: 'AI Responses Summary',
  },
  {
    avatar: avatarIcon,
    title: 'Response Data Export',
  },
  {
    avatar: avatarIcon,
    title: 'Chat With Responses',
    beta: true,
  },
  {
    avatar: avatarIcon,
    title: 'Export Responses',
  },
];
const premiumSubscriptionData = [
  {
    title: 'Everything in Basic, Plus',
  },
  {
    avatar: avatarIcon,
    title: '1000 Free WhatsApp Messages p/m',
  },
  {
    avatar: avatarIcon,
    title: 'Unlimited AI Chatbots',
  },
  {
    avatar: avatarIcon,
    title: '8 Active Surveys',
  },
  {
    avatar: avatarIcon,
    title: 'Advanced Survey Template',
  },
  {
    avatar: avatarIcon,
    title: 'Survey Logical Branching',
  },
  {
    avatar: avatarIcon,
    title: 'Sentiment Analysis',
  },
  {
    avatar: avatarIcon,
    title: 'Dashboard Zip Download',
  },
  {
    avatar: avatarIcon,
    title: 'Live Events Mode',
    new: true,
  },
  {
    avatar: avatarIcon,
    title: 'PDF Reports Download',
  },
  {
    avatar: avatarIcon,
    title: 'Chat GPT Integration',
  },
  {
    avatar: avatarIcon,
    title: 'Smart AI Reporting',
  },
]

const enterpriseSubscriptionData = [
  {
    title: 'Everything in Premium, Plus',
  },
  {
    avatar: avatarIcon,
    title: 'Unlimited Active Surveys',
  },
  {
    avatar: avatarIcon,
    title: 'Customized CRM Integrations',
  },
  {
    avatar: avatarIcon,
    title: 'LLM Integration',
    new: true,
  },
  {
    avatar: avatarIcon,
    title: 'Customized Reporting',
  },
  {
    avatar: avatarIcon,
    title: 'Dedicated Account Manager',
  },
  {
    avatar: avatarIcon,
    title: 'Customized AI Chatbot',
  },
  {
    avatar: avatarIcon,
    title: '3000 Free Responses p/m',
  },
  {
    avatar: avatarIcon,
    title: 'Customized Colors',
  },
]

const Offerings = ({ planType, isFront }) => {
  let dataSource
  if (planType === 'free') dataSource = freeSubscriptionData
  else if (planType === 'basic') dataSource = basicSubscriptionData
  else if (planType === 'premium') dataSource = premiumSubscriptionData
  else dataSource = enterpriseSubscriptionData

  return (
    <Card
      style={{
        borderRadius: '9px',
        height: '100%',
        backgroundColor: planType === 'premium' ? 'white' : 'whitesmoke',
      }}
    >
      <List
        size="small"
        itemLayout="horizontal"
        className="invoice-list"
        dataSource={dataSource}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              avatar={item.avatar}
              title={item.title}
              style={{ color: GlobalColors.darkGray }}
            />
            {item.beta && (
              <Tag
                color={GlobalColors.whitePurple}
                style={{ marginLeft: '10px', borderRadius: 3, color: 'black' }}
              >
                beta
              </Tag>
            )}
            {item.new && (
              <Tag
                color={GlobalColors.whitePurple}
                style={{ marginLeft: '10px', borderRadius: 3, color: 'black' }}
              >
                new
              </Tag>
            )}
          </List.Item>
        )}
      />
    </Card>
  )
}

export default Offerings
