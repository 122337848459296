const extractValidationError = (data) => {
  const path = data?.path;

  // Extract the question number from the path
  const questionNumberMatch = path?.match(/\[(\d+)\]/);
  let questionNumber = questionNumberMatch ? parseInt(questionNumberMatch[1]) + 1 : '';

  const errorMessage = data.message;

  return `Error on Question ${questionNumber}: ${errorMessage}`;
};

export default extractValidationError;