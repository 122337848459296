import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Typography, Button } from 'antd';
import { Database, MessageCircle } from 'lucide-react';
import usePrivateAxios from '../hooks/usePrivateAxios';
import ChatGPTModal from '../components/integrationModals/ChatGPTModal';
import StatusNotification from '../components/StatusNotification';
// Import other modal components as needed

const { Title, Text } = Typography;

const integrationDefaults = [
  {
    icon: <MessageCircle size={40} color='#10A37F' />,
    title: 'OpenAI ChatGPT',
    description: 'Enhance your chatbot with advanced AI capabilities powered by OpenAI',
    id: 'chatgpt',
  },
  {
    icon: <Database size={40} color='#4B0082' />,
    title: 'Custom Knowledge',
    description:
      'Enhance your chatbot with tailored information from your own website, documents, or database.',
    id: 'knowledgebase',
  },
];

const IntegrationCard = ({ icon, title, description, connected, id, onUpdate }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const privateAxios = usePrivateAxios();
  let { organizationId } = useSelector((state) => state.user);

  if (organizationId === 'UwtbDE') {
    organizationId = 'surveyr';
    console.log(organizationId);
  }

  const handleModalClose = () => setModalVisible(false);

  const handleSaveInstructions = async (instructions) => {
    try {
      if (connected) {
        // Update existing integration
        await privateAxios.patch(`/api/integrations/${organizationId}/${id}`, {
          customInstructions: instructions,
          isEnabled: true,
        });
      } else {
        // Create new integration
        await privateAxios.post('/api/integrations', {
          organizationId,
          integrationType: id,
          isEnabled: true,
          customInstructions: instructions,
        });
      }
      StatusNotification(
        'success',
        `${connected ? 'Update' : 'Connection'} Successful`,
        `Successfully ${connected ? 'updated' : 'connected'} ${title}`
      );
      onUpdate();
    } catch (error) {
      console.error(`Error saving ${id} integration:`, error);
      StatusNotification(
        'error',
        `${connected ? 'Update' : 'Connection'} Failed`,
        `Failed to ${connected ? 'update' : 'connect'} ${title}. Please try again.`
      );
    }
    setModalVisible(false);
  };

  const renderModal = () => {
    switch (id) {
      case 'chatgpt':
        return (
          <ChatGPTModal
            visible={modalVisible}
            onClose={handleModalClose}
            onSave={handleSaveInstructions}
          />
        );
      case 'knowledgebase':
        // Return CustomKnowledgeModal when implemented
        return null;
      default:
        return null;
    }
  };

  return (
    <Col xs={24} sm={12} md={8} lg={8}>
      <div style={{ height: '100%' }} className='integrationBlockStyle'>
        <div style={{ marginBottom: '10px' }}>{icon}</div>
        <Title level={4}>{title}</Title>
        <Text type='secondary'>{description}</Text>
        <div style={{ marginTop: '20px' }}>
          <Button
            type={connected ? 'default' : 'primary'}
            block
            onClick={() => setModalVisible(true)}
          >
            {connected ? 'Manage' : 'Connect'}
          </Button>
        </div>
        <Text
          type={connected ? 'success' : 'secondary'}
          style={{ display: 'block', marginTop: '10px' }}
        >
          {connected ? 'Connected' : 'Not Connected'}
        </Text>
      </div>
      {renderModal()}
    </Col>
  );
};

const Integrations = () => {
  const [integrations, setIntegrations] = useState([]);
  const privateAxios = usePrivateAxios();
  let { organizationId } = useSelector((state) => state.user);

    if (organizationId === 'UwtbDE') {
      organizationId = 'surveyr';
      console.log(organizationId);
    }


  const fetchIntegrations = async () => {
    try {
      const response = await privateAxios.get(`/api/integrations/${organizationId}`);
      const dbIntegrations = response.data;

      const mergedIntegrations = integrationDefaults.map((defaultInt) => {
        const dbInt = dbIntegrations.find((i) => i.integrationType === defaultInt.id);
        return {
          ...defaultInt,
          connected: dbInt ? dbInt.isEnabled : false,
          customInstructions: dbInt ? dbInt.customInstructions : '',
        };
      });

      setIntegrations(mergedIntegrations);
    } catch (error) {
      console.error('Error fetching integrations:', error);
      StatusNotification(
        'error',
        'Failed to Load Integrations',
        'There was an error loading your integrations. Please refresh the page or try again later.'
      );
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, [organizationId]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Title level={2}>Integrations</Title>
        <Text>Connect your chatbot with various services to extend its capabilities.</Text>
      </Col>
      {integrations.map((integration, index) => (
        <IntegrationCard key={index} {...integration} onUpdate={fetchIntegrations} />
      ))}
    </Row>
  );
};

export default Integrations;
