import React from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

const ClosingMessageInput = () => {
  const dispatch = useDispatch();
  const closingMessage = useSelector((state) => state.surveysData.create.media.closingMessage);

  const handleChange = (event) => {
    const { value } = event.target;
    dispatch({ type: 'CREATE_SURVEY_CLOSE_MEDIA_MESSAGE', data: value });
  };

  return <Input.TextArea rows={3} maxLength={400} showCount={true} placeholder='Caption message to accompany bonus content' onChange={handleChange} value={closingMessage} />;
};

export default ClosingMessageInput;