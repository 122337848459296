import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../apis/backendAPI';
import useRefreshToken from './useRefreshToken';

const usePrivateAxios = () => {
  const refresh = useRefreshToken();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    console.error('Logging out user due to token expiration or other errors.');
    dispatch({ type: 'LOGOUT_USER' });
    history.push('/signin');
  };

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${user.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        // Check both 401 and 403 errors but ensure we do not enter an infinite loop
        if (
          (error?.response?.status === 401 || error?.response?.status === 403) &&
          !prevRequest?.sent
        ) {
          if (prevRequest.url.includes('/api/refresh')) {
            // Directly checking if it's a refresh token request
            handleLogout(); // Direct logout if refresh token request itself fails
            return Promise.reject(error);
          }
          try {
            prevRequest.sent = true; // Mark the request to prevent future retries
            const newAccessToken = await refresh();
            if (newAccessToken) {
              prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
              return axiosInstance(prevRequest);
            }
          } catch (refreshError) {
            handleLogout();
            return Promise.reject(refreshError); // Ensure the promise is properly rejected
          }
        }
        // Non-auth related errors are simply passed on
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [refresh, user, dispatch, history]);

  return axiosInstance;
};

export default usePrivateAxios;
