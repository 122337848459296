import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Table, Row, Col, Space, Tag, Typography } from 'antd';
import { SyncOutlined, DownloadOutlined } from '@ant-design/icons';
import Papa from 'papaparse';

const { Title } = Typography;
const BroadcastStatusList = ({ action }) => {
  const { broadcastRespondents } = useSelector((state) => state.broadcastData);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const filteredRespondents = broadcastRespondents.filter((respondent) => {
    return (
      respondent.broadcastMessagesStatus &&
      respondent.broadcastMessagesStatus.some(
        (status) =>
          status.statusUpdates && status.statusUpdates[action] && status.statusUpdates[action].value
      )
    );
  });

  const columns = [
    {
      title: '#',
      key: 'counter',
      width: '10%',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '90%',
      align: 'left',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const downloadCsv = () => {
    const csvData = filteredRespondents.map((respondent) => {
      return { name: respondent.name };
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `broadcast-respondents-${action}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className='tabled' style={{ marginBottom: 10, marginTop: 78 }}>
        <Title level={3} className='sectionHeader'>
          {action}
        </Title>
        <Col span={24}>
          <Card
            bordered={false}
            className='criclebox tablespace mb-24'
            title={
              <Row justify='space-between'>
                <Col>{action.charAt(0).toUpperCase() + action.slice(1)} Respondents</Col>
                <Col>
                  <Space>
                    <span className='icon' style={{ cursor: 'pointer' }} onClick={downloadCsv}>
                      <DownloadOutlined />
                    </span>
                  </Space>
                </Col>
              </Row>
            }
          >
            <Table
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={filteredRespondents}
              className='ant-border-space'
              pagination={{
                position: ['bottomCenter'],
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </Card>
        </Col>
      </div>
    </>
  );
};

export default BroadcastStatusList;
