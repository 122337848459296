import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Row } from 'antd';

import useAxios from '../hooks/useAxios';
import StatusNotification from '../components/StatusNotification';
import LoadingIcon from '../components/LoadingIcon';
import NavBar from '../components/NavBar';

const UpdatePassword = ({ history }) => {
  const [loading, axiosFetch] = useAxios();

  let { token, userId } = useParams();

  const apiURL = '/api/users/password/new/';

  const onFinish = (values) => {
    axiosFetch({
      method: 'post',
      url: apiURL + `${userId}/${token}`,
      requestConfig: { password: values.password },
    })
      .then((response) => {
        StatusNotification(
          'success',
          'Password Now Reset',
          `You can now login with your new password`
        );
        history.push('/login');
      })
      .catch((err) => {
        StatusNotification('error', 'Problem Reseting', err);
        history.push('/password/forgot');
      });
  };

  return (
    <>
      <NavBar />
      <Row
        type='flex'
        justify='center'
        align='middle'
        style={{ minHeight: '100vh', flexFlow: 'Column' }}
      >
        <Form
          name='normal_login'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name='password'
            label='Password'
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name='confirm'
            label='Confirm Password'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Passwords do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
              {loading ? <LoadingIcon /> : 'Reset Password'}
            </Button>
          </Form.Item>
        </Form>
      </Row>
    </>
  );
};
export default UpdatePassword;
