import React from 'react'
import HighlightCurlyBraces from './HighlightCurlyBraces'

const ReplacePlaceholders = ({ text, type, inputValues, className }) => {
  if (!text) return null;

  // Replace {{url}} placeholder
  let replacedText = text;
  if (text.includes('{{url}}')) {
    const urlReplacement = inputValues[`${type}_url_0`];
    replacedText = replacedText.replace(
      '{{url}}',
      urlReplacement ? `{{${urlReplacement}}}` : '{{url}}'
    );
  }

  // Replace other placeholders {{1}}, {{2}}, etc.
  replacedText = replacedText.replace(/{{\d+}}/g, (match) => {
    const index = parseInt(match.replace(/[{{}}]/g, ''), 10) - 1;
    const replacement = inputValues[`${type}_${index}`];
    return replacement ? `{{${replacement}}}` : match;
  });

  const lines = replacedText.split('\n').filter((message) => message.trim().length > 0);

  const jsxLines = lines.map((line, index) => (
    <React.Fragment key={index}>
      <HighlightCurlyBraces text={line} />
      {index !== lines.length - 1 && <br />}
    </React.Fragment>
  ));

  return <span className={className}>{jsxLines}</span>;
};

export default ReplacePlaceholders;

