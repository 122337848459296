/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, Redirect, useParams } from 'react-router-dom';
import { Layout, Button, Row, Col, Typography, Form, Input, Card } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { HappyProvider } from '@ant-design/happy-work-theme';

import usePrivateAxios from '../hooks/usePrivateAxios';

import { signInUser } from '../store/actions/authorizeUser';
import NavBar from '../components/NavBar';
import useAxios from '../hooks/useAxios';
import StatusNotification from '../components/StatusNotification';
import LoadingIcon from '../components/LoadingIcon';
import FetchDashboardData from '../components/FetchDashboardData';
import SectionHeader from '../components/SectionHeader';
import FetchOrganizationCounters from '../components/FetchOrganizationCounters';

const SignIn = ({ history, signInUser }) => {
  const [loading, axiosFetch] = useAxios();
  const axiosPrivate = usePrivateAxios();
  let { token, userId } = useParams();

  const {
    user: { isAuthenticated, organizationId, planType, userId: loggedInUserId },
    surveysData: {
      activeSurvey: { surveyId, surveyName },
    },
  } = useSelector((state) => state);

  const apiURL = '/api/users/';
  const exchangeUrl = '/api/exchange-rates';
  const allResponsesURL = `/api/responses/${organizationId}/all`;

  const { state } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId && token)
      axiosFetch({
        method: 'post',
        url: apiURL + `signup/verify/${userId}/${token}`,
        requestConfig: {},
      })
        .then((response) => {
          if (response.type)
            // eslint-disable-next-line default-case
            switch (response.type) {
              case 'active':
                StatusNotification(
                  'success',
                  'Account Activated',
                  `Welcome To Surveyr 🙌🏾, you can now login`
                );
                history.push('/dashboard');
                break;
              case 'confirmed':
                StatusNotification(
                  'success',
                  'Already Confirmed',
                  `You have already activated your email`
                );
                history.push('/dashboard');
                break;
            }
        })
        .catch((err) => {
          //TODO:
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, token]);

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const { data } = await axiosPrivate.get(exchangeUrl);
      const roundedRate = parseFloat(data.rate.toFixed(2));

      dispatch({ type: 'SET_EXCHANGE_RATE', data: roundedRate });
    } catch (error) {
      console.error('Error fetching exchange rate', error);
    }
  };

  if (isAuthenticated) {
    const getLimitedChartData = () => {

      const isLiveEvent = false;
      FetchDashboardData(surveyId, organizationId, axiosPrivate, dispatch, isLiveEvent, [
        surveyName,
      ])
        .then(() => {})
        .catch(() => {
          // StatusNotification(
          //   'error',
          //   'Data Fetching Error',
          //   `Logout then login and try again. Contact us if issue persist`
          // );
        });
    };

    getLimitedChartData();
    FetchOrganizationCounters(axiosPrivate, dispatch, organizationId);

    return <Redirect to={state?.from || '/'} />;
  }

  const onFinish = async (values) => {
    axiosFetch({
      method: 'post',
      url: apiURL + 'signin',
      requestConfig: values,
    })
      .then((response) => {
        if (response.accessToken) signInUser(response.accessToken);
      })
      .catch((error) => {
        console.log({ error });
        StatusNotification('error', 'Problem Signing in', 'Sign up if you do not have an account');
        if (error?.includes('activation')) history.push('/resend');
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Layout className='layout-default layout-signin'>
        <NavBar />
        <Row justify='center'>
          <Col span={24}>
            <SectionHeader title='Sign In' isFront={false} subtitle='' />
          </Col>
          <Col span={24}>
            <Card
              className='card-signup header-solid ant-card h-full '
              // title={
              //   <Title className='mb-15' style={{ textAlign: 'center' }}>
              //     Sign Up
              //   </Title>
              // }
              bordered='false'
            >
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout='vertical'
                className='row-col'
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      style={{ marginTop: 12 }}
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                      ]}
                    >
                      <Input placeholder='Email' />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                      ]}
                    >
                      <Input.Password
                        style={{ borderRadius: 7, height: 48 }}
                        placeholder='Password'
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item>
                      <HappyProvider>
                        <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                          {loading ? <LoadingIcon /> : 'Sign In'}
                        </Button>
                      </HappyProvider>
                    </Form.Item>
                    <Row justify='space-between'>
                      <Col>
                        <p className='text-muted font-semibold'>
                          <Link to='/signup' className='text-dark font-bold'>
                            Sign Up
                          </Link>
                        </p>
                      </Col>
                      <Col>
                        <p className='text-muted font-semibold'>
                          <Link to='/password/forgot' className='text-dark font-bold'>
                            Forgot Password
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
};
// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.user.isAuthenticated,
//     organizationId: state.user.organizationId,
//     accessToken: state.user.accessToken,
//     surveyId: state.surveysData.surveyId,
//   };
// };
export default connect(null, {
  signInUser,
})(SignIn);
