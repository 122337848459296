
const shortenUrl = async (longUrl, axios) => {
  if (!longUrl) return;
  console.log(longUrl);

  try {
    const response = await axios.post('/api/short', { longUrl });

    if (response.data && response.data.isSuccess) {
      return { isSuccess: true, payload: response.data.payload, urlId: response.data.urlId };
    } else {
      return { isSuccess: false, payload: null, error: response.data.error };
    }
  } catch (error) {
    console.log(error);
    return { isSuccess: false, payload: null, error: error?.response?.data };
  }
};

export default shortenUrl;
