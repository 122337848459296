const formatFormTemplateValues = (formValues) => {
  let transformed = {
    name: formValues['teplateName'],
    components: [],
  }

  // Header Component text
  if (formValues['HEADER_text']) {
    let headerComponent = {
      type: 'HEADER',
      format: 'TEXT',
      text: formValues['HEADER_text'],
    }
    // Add example only if it exists and is not null
    if (formValues['HEADER_example_0']) {
      headerComponent.example = {
        header_text: [formValues['HEADER_example_0']],
      }
    }
    transformed.components.push(headerComponent)
  }

  // Header Component text
  if (formValues['HEADER_document']) {
    transformed.components.push({
      type: 'HEADER',
      format: 'DOCUMENT',
      example: {
        header_handle: [formValues['HEADER_example_0']],
      },
    })
  }
  // Header Component text
  if (formValues['HEADER_image']) {
    transformed.components.push({
      type: 'HEADER',
      format: 'IMAGE',
      example: {
        header_handle: [formValues['HEADER_example_0']],
      },
    })
  }
  // Header Component text
  if (formValues['HEADER_video']) {
    transformed.components.push({
      type: 'HEADER',
      format: 'VIDEO',
      example: {
        header_handle: [formValues['HEADER_example_0']],
      },
    })
  }

  // Body Component
  if (formValues['BODY_text']) {
    const bodyExamples = Object.keys(formValues)
      .filter((key) => key.startsWith('BODY_example_'))
      .map((key) => formValues[key])

    let bodyComponent = {
      type: 'BODY',
      text: formValues['BODY_text'],
    }
    // Add example only if it exists and is not an empty array
    if (bodyExamples.length > 0) {
      bodyComponent.example = {
        body_text: [bodyExamples],
      }
    }
    transformed.components.push(bodyComponent)
  }

  // Footer Component
  if (formValues['FOOTER_text']) {
    transformed.components.push({
      type: 'FOOTER',
      text: formValues['FOOTER_text'],
    })
  }

  // Buttons Component
  const buttonsComponent = { type: 'BUTTONS', buttons: [] }

  // Handle QUICK_REPLY buttons
  Object.keys(formValues).forEach((key) => {
    if (key.startsWith('BUTTONS_QUICK_REPLY_text_') && formValues[key]) {
      buttonsComponent.buttons.push({
        type: 'QUICK_REPLY',
        text: formValues[key],
      })
    }
  })

  // Handle URL buttons, including those with examples
  Object.keys(formValues).forEach((key) => {
    if (key.startsWith('BUTTONS_URL_text_')) {
      const index = key.split('_')[3] // Extract the index from the key
      const button = {
        type: 'URL',
        text: formValues[key],
        url: formValues[`BUTTONS_URL_${index}`],
      }

      // Check for and include example if present
      if (formValues[`URL_EXAMPLE_${index}`]) {
        button.example = [formValues[`URL_EXAMPLE_${index}`]]
        button.url = `https://link.surveyr.africa/{{1}}`
      }

      buttonsComponent.buttons.push(button)
    }
  })

  // Only add the buttons component if it has buttons
  if (buttonsComponent.buttons.length > 0) {
    transformed.components.push(buttonsComponent)
  }

  return transformed
}

export default formatFormTemplateValues
