import React from 'react';
import { Select } from 'antd';

const TagsDropdown = ({ tags }) => {
  const tagOptions = tags.map((tag) => ({
    value: tag,
    label: tag,
  }));

  return (
    <Select
      mode='multiple'
      showSearch
      style={{ width: '100%' }} // Adjust width as needed
      placeholder='Select tags'
      optionFilterProp='value' // Filtering based on the value of the option
      filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      notFoundContent='No tags found'
    >
      {tagOptions.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TagsDropdown;
