import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const HowDidYouHearSelect = ({ onChange }) => {
  const options = ['Google', 'Social Media', 'Word of Mouth', 'Advertisement', 'Online Article', 'Event', 'Other'];

  const handleSearch = (value) => {
    // Implement your search logic here if needed
    // For example, filter the options based on the input value
    const filteredOptions = options.filter((option) => option.toLowerCase().includes(value.toLowerCase()));
    return filteredOptions;
  };

  return (
    <Select placeholder='How did you hear about us?' style={{ width: '100%' }} onChange={onChange} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onSearch={handleSearch} allowClear>
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default HowDidYouHearSelect;
