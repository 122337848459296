import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, Row, Col, Grid, Segmented } from 'antd';
import Form, { Field } from 'rc-field-form';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import GetPlaceholderInputs from './GetPlaceholderInputs';
import TemplateCard from './TemplateCard';
import getScreenSize from '../utils/getScreenSize';
import reformatValues from '../utils/reformatValues';
import isObjectEmpty from '../utils/isObjectEmpty';

const { Option } = Select;
const { useBreakpoint } = Grid;

const TemplateForm = ({ setOpen, setIsDraft, setBroadcastType }) => {
  const [form] = Form.useForm();
  const [inputValues, setInputValues] = useState({});
  const [templateNotSelectedError, setTemplateNotSelectedError] = useState(false);
  const [selectedType, setSelectedType] = useState('SMS'); // Added state for segmented control

  const axiosPrivate = usePrivateAxios();
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);
  const dispatch = useDispatch();

  const {
    templatesData: {
      templates,
      selectedTemplate,
      media: { templateMediaUrl, mediaType },
    },
    user: { hasOwnBot },
    broadcastData: {
      create: { isDraft },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (isDraft) handleReset();
  }, [isDraft]);

  const handleReset = () => {
    form.resetFields();
    setInputValues({});
    setIsDraft(true);
    dispatch({ type: 'RESET_TEMPLATE' });
  };

  const onTemplateSelect = (templateName) => {
    handleReset();
    const selectedTemplate = templates.find((template) => template.name === templateName);
    dispatch({ type: 'SET_SELECTED_TEMPLATE', data: selectedTemplate });
    form.setFieldsValue({ template: templateName });
    setTemplateNotSelectedError(false);
  };

  const handleInputChange = (type, index, value) => {
    console.log({ type, index, value });
    setInputValues({ ...inputValues, [`${type}_${index}`]: value });
    dispatch({
      type: 'SET_TEMPLATE_INPUT_VALUES',
      data: { ...inputValues, [`${type}_${index}`]: value },
    });
  };

  const onFinish = async (values) => {
    const hasHeaderImage = selectedTemplate?.components.some(
      (component) => component.type === 'HEADER' && component.format === 'IMAGE'
    );

    for (const [key, value] of Object.entries(values)) {
      if (key === 'template') continue;
      const [type, index] = key.split('_');
      handleInputChange(type, parseInt(index, 10), value);
    }

    if (!values.template) {
      setTemplateNotSelectedError(true);
      return;
    }

    const buttonComponent = selectedTemplate?.components?.find(
      (component) => component.type === 'BUTTONS'
    );

    if (!isObjectEmpty(buttonComponent)) {
      const quickReplyButtons = Object.entries(values).filter(([key]) => {
        const [type, buttonType, index] = key.split('_');
        return type === 'BUTTONS' && buttonType === 'button' && !/Stop|Mute/i.test(values[key]);
      });

      const quickReplyPayload = quickReplyButtons.map(([key, value]) => {
        const index = key.split('_').pop();
        const name = buttonComponent.buttons[index].text;
        return { name, responseText: value };
      });

      if (quickReplyPayload.length > 0)
        try {
          const response = await axiosPrivate.post('/api/templates/buttons', {
            templateId: selectedTemplate._id,
            quickReplyButtons: quickReplyPayload,
          });

          if (response.status === 201) {
            // Handle successful update
          }
        } catch (error) {
          // Handle error
        }
    }

    const templateParameters = reformatValues(values, mediaType, templateMediaUrl);
    console.log({ templateParameters });
    const templateName = values.template;

    setIsDraft(false);
    setOpen(null);
    dispatch({
      type: 'CREATE_TEMPLATE_INPUT_DATA',
      data: { templateParameters, templateName },
    });
  };

  const handleBroadcastType = (value) => {
    setSelectedType(value);
    setBroadcastType(value?.toLowerCase());
  };

  // Filter templates based on the selected messaging type
  const filteredTemplates = templates?.filter((template) =>
    template.type.includes(selectedType.toLowerCase())
  );

  return (
    <Row>
      <Col span={24}>
        <Segmented
          options={hasOwnBot ? [('SMS', 'WhatsApp')] : ['SMS']}
          defaultValue={selectedType}
          value={selectedType}
          onChange={handleBroadcastType}
          style={{ marginBottom: 20 }}
        />
        <Form form={form} name='template_form' onFinish={onFinish}>
          <Field name='template' style={{ marginBottom: 20 }}>
            <Select
              placeholder='Select a template'
              onChange={onTemplateSelect}
              showSearch
              style={{ width: '100%', marginBottom: 20 }}
            >
              {filteredTemplates.map((template) => (
                <Option key={template.name} value={template.name}>
                  {template.name}
                </Option>
              ))}
            </Select>
            {templateNotSelectedError && <div style={{ color: 'red' }}>Please select template</div>}
          </Field>
          {selectedTemplate && (
            <Row gutter={16}>
              {screenSize === 'xs' && selectedTemplate && (
                <Col span={24}>
                  <TemplateCard />
                </Col>
              )}
              <Col span={24}>
                {selectedTemplate &&
                  selectedTemplate.components.map((component, index) => (
                    <div key={`component-input-${index}`}>
                      <GetPlaceholderInputs
                        component={component}
                        onInputChange={handleInputChange}
                        templateName={selectedTemplate?.name}
                        form={form}
                      />
                    </div>
                  ))}
              </Col>
            </Row>
          )}

          <div style={{ marginTop: '20px' }}>
            <Field>
              <Row justify='space-between' gutter={[8, 0]}>
                <Col span={12}>
                  <Button htmlType='submit' type='primary' block disabled={!selectedTemplate}>
                    Save
                  </Button>
                </Col>
                <Col span={12}>
                  <Button onClick={handleReset} block>
                    Reset
                  </Button>
                </Col>
              </Row>
            </Field>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default TemplateForm;
