import React, { useEffect } from 'react';
import { Row, Col, Typography, Layout } from 'antd';
import SectionHeader from '../components/SectionHeader';
import NavBar from '../components/NavBar';

const { Title, Paragraph, Text } = Typography;
const { Content } = Layout;

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });
  return (
    <div className='containerRadius'>
      <NavBar />
      <Content className='container '>
        <Row>
          <Col span={24}>
            <SectionHeader
              title='Terms of Service'
              subtitle='Policy guidance regarding how to engage with our platform'
              isFront={true}
            />
          </Col>
          <Col>
            <Row>
              <Col span={24}>
                <Title level={5}>Last updated: [03-Jan-2023]</Title>
                <Paragraph>
                  Thank you for using our SaaS Surveyr app (the "App"). By using
                  the App, you agree to be bound by these terms of service (the
                  "Terms"). If you do not agree to these Terms, do not use the
                  App.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Use of the App</Title>
                <Paragraph>
                  <Text>
                    The App is intended for use by individuals who are 18 years
                    of age or older. By using the App, you represent and warrant
                    that you are at least 18 years old.
                  </Text>
                  <Text>
                    You may use the App only for lawful purposes and in
                    accordance with these Terms. You may not use the App:
                  </Text>{' '}
                  In any way that violates any applicable federal, state, local,
                  or international law or regulation; To transmit, or procure
                  the sending of, any advertising or promotional material
                  without our prior written consent; or To engage in any other
                  conduct that restricts or inhibits anyone's use or enjoyment
                  of the App, or which, as determined by us, may harm the App or
                  users of the App or expose them to liability. We reserve the
                  right to terminate or restrict your access to the App at any
                  time, for any or no reason, with or without notice.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Account Creation and Security</Title>
                <Paragraph>
                  To use the App, you must create an account and provide certain
                  personal information such as your name, email address, and
                  phone number. You are responsible for maintaining the
                  confidentiality of your account and password and for
                  restricting access to your device. You agree to accept
                  responsibility for all activities that occur under your
                  account or password. We reserve the right to terminate or
                  restrict your access to the App if we believe that you have
                  violated these Terms or any applicable law.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Intellectual Property</Title>
                <Paragraph>
                  The App and its content and features, including but not
                  limited to text, graphics, images, and software, are the
                  property of [Codegarden PTY LTD 2019/488172/07] or its
                  licensors and are protected by copyright and other
                  intellectual property laws. You may not use any content or
                  feature of the App for any commercial purpose without the
                  prior written consent of [Codegarden PTY LTD 2019/488172/07].
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Disclaimer of Warranties</Title>
                <Paragraph>
                  THE APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE
                  DO NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED OR
                  ERROR-FREE, AND WE WILL NOT BE LIABLE FOR ANY INTERRUPTIONS OR
                  ERRORS. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                  INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                  NON-INFRINGEMENT.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Limitation of Liability</Title>
                <Paragraph>
                  WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
                  REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS
                  OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
                  FROM: YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE
                  THE APP; ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE APP,
                  INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE, OR
                  ILLEGAL CONDUCT; ANY CONTENT OBTAINED FROM THE APP; OR
                  UNAUTHORIZED ACCESS, USE, OR
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default TermsOfService;
