import React, { useState, useEffect, useCallback } from 'react';
import { Input, Badge, Skeleton } from 'antd';
import { debounce } from 'lodash';
import axios from 'axios';
import { motion, AnimatePresence, usePresence } from 'framer-motion';
import '../../assets/styles/leaderboard.css'; // Make sure the path to your CSS file is correct
import GlobalColors from '../../assets/colors/GlobalColors';
import formatScore from '../../utils/formatScore';

const colorStart = GlobalColors.darkPurple;
const colorEnd = GlobalColors.lightPurple;

const interpolateColor = (index, length) => {
  // A simple linear interpolation between colorStart and colorEnd based on index
  const ratio = index / length;
  return `rgb(${Math.round(
    parseInt(colorStart.substring(1, 3), 16) * (1 - ratio) +
      parseInt(colorEnd.substring(1, 3), 16) * ratio
  )},${Math.round(
    parseInt(colorStart.substring(3, 5), 16) * (1 - ratio) +
      parseInt(colorEnd.substring(3, 5), 16) * ratio
  )},${Math.round(
    parseInt(colorStart.substring(5, 7), 16) * (1 - ratio) +
      parseInt(colorEnd.substring(5, 7), 16) * ratio
  )})`;
};

const LeaderboardItem = ({ player, index, length, isAnimating, type }) => {
  const [isPresent, safeToRemove] = usePresence();

  // Check if the color interpolation is working correctly
  const color = interpolateColor(index, length);

  const itemVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: { delay: isAnimating ? index * 0.1 : 0 },
    },
    out: { opacity: 0 },
  };

  const animations = {
    initial: 'out',
    animate: 'in',
    exit: 'out',
    layout: true,
    transition: { type: 'spring', stiffness: 500, damping: 50, mass: 1 },
    style: {
      color: color,
      position: isPresent ? 'static' : 'absolute',
    },
    variants: {
      in: { opacity: 1, scaleY: 1 },
    },
  };

  return (
    <motion.div
      className='leaderboard-item'
      variants={itemVariants}
      initial='hidden'
      animate='show'
      exit='hidden'
      {...animations}
    >
      <span className='rank'>{index + 1}</span>
      <span className='name'>{player.name}</span>
      <span className='score'>{formatScore(player.score)}</span>
    </motion.div>
  );
};

const Leaderboard = ({
  type,
  visibility,
  surveyId,
  setIsStillActive,
  rankableFieldIndexNumber,
  screenSize,
}) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [filteredLeaderboard, setFilteredLeaderboard] = useState([]);
  const [isPollingActive, setIsPollingActive] = useState(true);
  const [showSearch, setShowSearch] = useState(false);
  const [total, setTotal] = useState(18);
  const [loading, setLoading] = useState(true); // State for loading

  const sumTotal = (object) => {
    const totalSum = object.reduce((acc, obj) => {
      return acc + (obj.score || 0); // Adds obj.total to acc, defaults to 0 if undefined
    }, 0); // Initialize accumulator to 0

    return formatScore(totalSum);
  };

  const urlLink =
    type === 'ranking'
      ? `/api/leaderboard/ranking/${surveyId}/${rankableFieldIndexNumber}`
      : `/api/leaderboard/${surveyId}`;

  useEffect(() => {
    let intervalId;
    console.log(urlLink);
    const fetchLeaderboardData = async () => {
      try {
        setLoading(true); // Set loading to true when fetching data
        const response = await axios.get(`/api/leaderboard/${surveyId}`);

        console.log({ type, response });
        setLeaderboard(response.data);
        setFilteredLeaderboard(response.data);
        setTotal(sumTotal(response.data));
        setLoading(false); // Set loading to false when data fetching is complete
      } catch (error) {
        const status = error?.response?.status;
        console.error('Error fetching leaderboard data:', status);
        if (status === 409) {
          setIsStillActive(false);
          setIsPollingActive(false);
        }
        setLoading(false); // Set loading to false on error
      }
    };

    // fetchLeaderboardData(); // Initial fetch regardless of polling state

    if (isPollingActive) {
      intervalId = setInterval(fetchLeaderboardData, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPollingActive]); // Include isPollingActive in the dependency array

  const debouncedSearch = useCallback(
    debounce((value) => {
      const filteredData = leaderboard.filter((player) =>
        player.name.toLowerCase().includes(value)
      );
      setFilteredLeaderboard(filteredData);
      if (value.trim() !== '') {
        setIsPollingActive(false);
      } else {
        setIsPollingActive(true);
      }
    }, 300),
    [leaderboard]
  ); // Adjust debounce time as needed

  const handleSearch = (e) => {
    debouncedSearch(e.target.value.toLowerCase());
  };

  return (
    <>
      {loading && filteredLeaderboard.length < 0 ? (
        <>
          <Skeleton active paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          {type === 'ranking' ? (
            <Badge color={GlobalColors.mainPurple} count={total} offset={[8, 28]}>
              <div className='leaderboard' style={{ marginTop: screenSize !== 'xs' ? 30 : 0 }}>
                <div className='leaderboard-heading'>
                  <span className='rank'>Rank</span>
                  <span className='name'>Name</span>
                  <span className='score'>Amount</span>
                </div>
                <AnimatePresence>
                  {filteredLeaderboard.length > 0 &&
                    filteredLeaderboard.map((player, index) => (
                      <LeaderboardItem
                        key={player._id}
                        player={player}
                        index={index}
                        length={filteredLeaderboard.length}
                        isAnimating={!showSearch}
                        type={type}
                      />
                    ))}
                </AnimatePresence>
              </div>
            </Badge>
          ) : (
            <div className='leaderboard' style={{ marginTop: screenSize !== 'xs' ? 30 : 0 }}>
              <div className='leaderboard-heading'>
                <span className='rank'>Rank</span>
                <span className='name'>Name</span>
                <span className='score'>Score</span>
              </div>
              <AnimatePresence>
                {filteredLeaderboard.length > 0 &&
                  filteredLeaderboard.map((player, index) => (
                    <LeaderboardItem
                      key={player._id}
                      player={player}
                      index={index}
                      length={filteredLeaderboard.length}
                      isAnimating={!showSearch} // Pass a prop to control animations
                      type={type}
                    />
                  ))}
              </AnimatePresence>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Leaderboard;
