import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Space, Form, Checkbox, Tooltip, Row, Col, Select } from 'antd';
import { PlusOutlined, MinusCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import Emojis from '../seed/Emojis';
import { MAX_OPTIONS } from '../constants';
import Media from './Media';

const { Option } = Select;
const OptionType = 'OPTION';

const DraggableOption = ({
  id,
  index,
  moveItem,
  children,
  isQuiz,
  isCorrect,
  onCheckboxChange,
  textOrEmoji,
  description,
  explanation,
  jump,
  media,
  minus,
}) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: OptionType,
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: OptionType,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity, marginBottom: 8 }}>
      <Space
        align='center'
        style={{
          width: '100%',
          backgroundColor: isQuiz ? (isCorrect ? '#74b9ff' : '#fab1a0') : '#f0f2ff',
          paddingTop: 8,
          paddingLeft: 12,
          paddingBottom: 8,
          borderRadius: 4,
        }}
      >
        <MenuOutlined style={{ cursor: 'move', marginBottom: 8 }} />
        <Row>
          <Col xs={24} md={12}>
            {textOrEmoji}
          </Col>
          <Col xs={24} md={12}>
            {description}
          </Col>
          <Col xs={24} md={12}>
            {explanation}
          </Col>
          <Col xs={24} md={12}>
            {jump}
          </Col>
          <Col xs={24} md={12}>
            {media}
          </Col>
        </Row>
        {isQuiz && (
          <Tooltip placement='top' title='Mark as correct answer'>
            <Checkbox checked={isCorrect} onChange={onCheckboxChange} style={{ marginBottom: 8 }} />
          </Tooltip>
        )}
        <span style={{ marginRight: 12 }}> {minus}</span>
      </Space>
    </div>
  );
};

const SurveyFormAdvanced = ({
  question,
  surveyNumber,
  updateQuestionText,
  removeQuestion,
  setOpen,
  typeCounter,
  setTypeCounter,
  numberOfQuestions,
  openEndeds,
}) => {
  const [options, setOptions] = useState({});
  const [useEmoji, setUseEmoji] = useState(false);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);
  const [numberOfOptions, setNumberOfOptions] = useState(0);
  const [isOpenEnded, setIsOpenEnded] = useState(false);
  const [isValidate, setIsValidate] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { create } = useSelector((state) => state.surveysData);
  const { surveyInputData, completed, emojis, isQuiz } = create;
  const { organizationId } = useSelector((state) => state.user);

  useEffect(() => {
    let currentForm = surveyInputData[surveyNumber];
    if (currentForm) {
      setUseEmoji(emojis[surveyNumber] || false);
      setIsOpenEnded(openEndeds[surveyNumber] || false); // Use openEndeds here
      form.setFieldsValue(currentForm);
      const optionsObject = {};
      if (currentForm.options && Array.isArray(currentForm.options)) {
        currentForm.options.forEach((option, index) => {
          console.log({ option });
          optionsObject[option.id] = { ...option, index };
        });
      }
      console.log({ advancedForm: optionsObject });
      setOptions(optionsObject);
      setNumberOfOptions(Object.keys(optionsObject).length);
      setCorrectOptionIndex(currentForm.correctAnswerOption);
    }
  }, [surveyInputData, surveyNumber, emojis, openEndeds, form]);

  useEffect(() => {
    form.setFieldsValue({
      mainQuestion: question?.text,
      options: Object.values(options).map((o) => ({
        value: o.value,
        description: o.description,
        emojiType: o.emojiType,
        isExplanationEnabled: o.isExplanationEnabled,
        explanation: o.explanation,
        isJumpEnabled: o.isJumpEnabled,
        jumpTo: o.jumpTo,
        isOptionMediaEnabled: o.isOptionMediaEnabled,
        optionMediaUrl: o.optionMediaUrl,
      })),
    });
  }, [question, options, form]);

  useEffect(() => {
    if (numberOfOptions > 0) {
      form.validateFields(['options']);
    }
  }, [numberOfOptions, form]);

  const onFinish = (values) => {
    console.log({ values });
    let formattedOptions;
    if (isOpenEnded) {
      formattedOptions = [];
    } else {
      formattedOptions = Object.values(options).map((option) => ({
        id: option.id,
        value: useEmoji ? Emojis[option.emojiType] : option.value,
        description: option.description,
        isExplanationEnabled: option.isExplanationEnabled || false,
        explanation: option.explanation,
        isJumpEnabled: option.isJumpEnabled || false,
        jumpTo: option.jumpTo,
        isOptionMediaEnabled: option.isOptionMediaEnabled || false,
        optionMediaUrl: option.optionMediaUrl,
        ...(useEmoji && { emojiType: option.emojiType }),
        ...(isQuiz && { isCorrect: option.isCorrect }),
      }));
    }

    const formattedValues = {
      mainQuestion: values.mainQuestion,
      options: formattedOptions,
      type: isOpenEnded ? 'openEnded' : 'optionList',
      subHeading: `${isOpenEnded ? 'Open Ended' : 'Question'} ${surveyNumber}`,
      useEmoji: useEmoji,
      isValidateInput: isValidate ? values.isValidateInput : undefined,
    };

    if (isQuiz && !isOpenEnded) {
      formattedValues.correctAnswerOption = formattedOptions.findIndex((o) => o.isCorrect);
    }

    console.log({ formattedValues });
    let currentTypeValue = typeCounter[formattedValues.type];
    formattedValues.subHeading = `${
      formattedValues.type === 'optionList' ? 'Question' : formattedValues.type
    } ${currentTypeValue}`;

    setTypeCounter((prevState) => ({
      ...prevState,
      [formattedValues.type]: prevState[formattedValues.type] + 1,
    }));

    dispatch({ type: 'CREATE_SURVEY_SET_EMOJIS', data: { ...emojis, [surveyNumber]: useEmoji } });
    dispatch({
      type: 'CREATE_SURVEY_SET_OPEN_ENDEDS',
      data: { ...openEndeds, [surveyNumber]: isOpenEnded },
    });
    dispatch({
      type: 'CREATE_SURVEY_INPUT_DATA',
      data: { ...surveyInputData, [surveyNumber]: formattedValues },
    });
    dispatch({ type: 'CREATE_SURVEY_COMPLETED', data: { ...completed, [surveyNumber]: true } });

    setOpen([surveyNumber + 1]);
    console.log({ surveyInputData });
  };

  const moveOption = useCallback(
    (dragIndex, hoverIndex) => {
      setOptions((prevOptions) => {
        const optionIds = Object.keys(prevOptions);
        const dragId = optionIds[dragIndex];
        const hoverId = optionIds[hoverIndex];

        // Create a new array of keys with the dragged item moved
        const newOptionIds = [...optionIds];
        newOptionIds.splice(dragIndex, 1);
        newOptionIds.splice(hoverIndex, 0, dragId);

        // Create a new options object with the updated order
        const newOptions = {};
        newOptionIds.forEach((id, index) => {
          newOptions[id] = { ...prevOptions[id], index };
        });

        // Update correct option index if this is a quiz question
        if (isQuiz) {
          let newCorrectOptionIndex = correctOptionIndex;
          if (correctOptionIndex === dragIndex) {
            newCorrectOptionIndex = hoverIndex;
          } else if (dragIndex < correctOptionIndex && hoverIndex >= correctOptionIndex) {
            newCorrectOptionIndex--;
          } else if (dragIndex > correctOptionIndex && hoverIndex <= correctOptionIndex) {
            newCorrectOptionIndex++;
          }
          setCorrectOptionIndex(newCorrectOptionIndex);
        }

        return newOptions;
      });
    },
    [isQuiz, correctOptionIndex]
  );

  const handleQuestionTextChange = (e) => {
    const text = e.target.value;
    updateQuestionText(question.id, text);
  };

  const setCorrectAnswer = (optionIndex) => {
    setCorrectOptionIndex(optionIndex);
    setOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      Object.keys(newOptions).forEach((key) => {
        newOptions[key] = { ...newOptions[key], isCorrect: newOptions[key].index === optionIndex };
      });
      return newOptions;
    });
  };

  const addOption = () => {
    const newOptionId = Date.now().toString();
    const newOption = {
      id: newOptionId,
      index: numberOfOptions,
      value: '',
      description: '',
      isExplanationEnabled: false,
      explanation: '',
      isJumpEnabled: false,
      jumpTo: undefined,
      isOptionMediaEnabled: false,
      optionMediaUrl: undefined,
    };

    setOptions((prevOptions) => ({
      ...prevOptions,
      [newOptionId]: newOption,
    }));
    setNumberOfOptions((prev) => prev + 1);
  };

  const removeOption = (optionId) => {
    setOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      delete newOptions[optionId];
      // Re-index remaining options
      Object.values(newOptions).forEach((option, index) => {
        option.index = index;
      });
      return newOptions;
    });
    setNumberOfOptions((prev) => prev - 1);

    if (isQuiz) {
      const removedIndex = Object.values(options).findIndex((o) => o.id === optionId);
      if (removedIndex < correctOptionIndex) {
        setCorrectOptionIndex((prev) => prev - 1);
      } else if (removedIndex === correctOptionIndex) {
        setCorrectOptionIndex(null);
      }
    }
  };

  const updateOptionText = (optionId, value, field = 'value') => {
    setOptions((prevOptions) => {
      const updatedOption = {
        ...prevOptions[optionId],
        [field]: field === 'emojiType' ? Emojis[value] : value,
        ...(field === 'emojiType' ? { value: Emojis[value] } : {}),
      };

      // Update form fields
      const optionIndex = Object.values(prevOptions).findIndex((opt) => opt.id === optionId);
      form.setFieldsValue({
        options: {
          ...form.getFieldValue('options'),
          [optionIndex]: {
            ...form.getFieldValue(['options', optionIndex]),
            [field]: field === 'emojiType' ? Emojis[value] : value,
            ...(field === 'emojiType' ? { value: Emojis[value] } : {}),
          },
        },
      });

      return {
        ...prevOptions,
        [optionId]: updatedOption,
      };
    });
  };

  const handleUseEmojiChange = (e) => {
    const newUseEmoji = e.target.checked;
    setUseEmoji(newUseEmoji);
    setOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      Object.keys(newOptions).forEach((key) => {
        newOptions[key] = {
          ...newOptions[key],
          value: newUseEmoji ? '' : newOptions[key].value,
          emojiType: newUseEmoji ? newOptions[key].emojiType : undefined,
        };
      });
      return newOptions;
    });
  };

  const handleOpenEndedChange = (e) => {
    setIsOpenEnded(e.target.checked);
    if (e.target.checked) {
      setOptions({});
      setNumberOfOptions(0);
    }
  };

  const handleIsValidateChange = (e) => {
    setIsValidate(e.target.checked);
  };

  const handleExplanation = (optionId) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionId]: {
        ...prevOptions[optionId],
        isExplanationEnabled: !prevOptions[optionId].isExplanationEnabled,
      },
    }));

    // Update form fields
    const optionIndex = options[optionId].index;
    const currentOption = form.getFieldValue(['options', optionIndex]);
    form.setFieldsValue({
      options: {
        ...form.getFieldValue('options'),
        [optionIndex]: {
          ...currentOption,
          isExplanationEnabled: !currentOption.isExplanationEnabled,
        },
      },
    });
  };

  const handleJump = (optionId) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionId]: {
        ...prevOptions[optionId],
        isJumpEnabled: !prevOptions[optionId].isJumpEnabled,
      },
    }));
  };

  const handleOptionMedia = (optionId) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      [optionId]: {
        ...prevOptions[optionId],
        isOptionMediaEnabled: !prevOptions[optionId].isOptionMediaEnabled,
      },
    }));
  };

  const resetOptions = () => {
    setOptions({});
    setCorrectOptionIndex(null);
    setNumberOfOptions(0);
    setIsOpenEnded(false);
    setIsValidate(false);
    form.resetFields();
  };
  return (
    <Form form={form} onFinish={onFinish} name={surveyNumber} key={`form-${numberOfOptions}`}>
      {isQuiz && (
        <div style={{ marginBottom: '16px' }}>
          This is a quiz question. Please mark the correct answer.
        </div>
      )}
      <Space direction='vertical' style={{ width: '100%' }}>
        <Form.Item
          name='mainQuestion'
          rules={[
            { required: true, message: 'Please enter the question text' },
            { min: 5, message: 'Minimum 5 characters required' },
            { max: 500, message: 'Main question, maximum length is 500 characters' },
          ]}
        >
          <Input
            showCount
            maxLength={500}
            placeholder='Enter question text'
            onChange={handleQuestionTextChange}
          />
        </Form.Item>
        <Checkbox checked={useEmoji} onChange={handleUseEmojiChange}>
          Use Emojis <span style={{ fontSize: 20 }}>😎</span>
        </Checkbox>
        <Checkbox checked={isOpenEnded} onChange={handleOpenEndedChange}>
          Open Ended Question
        </Checkbox>
        {isOpenEnded && (
          <Checkbox checked={isValidate} onChange={handleIsValidateChange}>
            Validate Input
          </Checkbox>
        )}
        {isValidate && isOpenEnded && (
          <Form.Item name='isValidateInput' label='Select Validation Type'>
            <Select placeholder='Choose validation type' style={{ width: '100%' }}>
              <Option value='email'>Email</Option>
              <Option value='number'>Number</Option>
              <Option value='id_number'>ID Number</Option>
            </Select>
          </Form.Item>
        )}
        {!isOpenEnded &&
          Object.values(options).map((option, optionIndex) => (
            <DraggableOption
              key={option.id}
              id={option.id}
              index={optionIndex}
              moveItem={moveOption}
              isQuiz={isQuiz}
              isCorrect={option.isCorrect}
              onCheckboxChange={() => setCorrectAnswer(optionIndex)}
              textOrEmoji={
                useEmoji ? (
                  <Form.Item
                    name={['options', optionIndex, 'emojiType']}
                    rules={[{ required: true, message: 'Emoji is required' }]}
                  >
                    <Select
                      value={option.emojiType}
                      placeholder={`Emoji ${String.fromCharCode(97 + optionIndex)}`}
                      allowClear
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option?.value?.toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(value) => updateOptionText(option.id, value, 'emojiType')}
                      style={{ width: 214, marginTop: 4 }}
                    >
                      {Object.entries(Emojis).map((emoji, index) => (
                        <Option value={emoji[0]} key={index}>
                          {emoji[1]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={['options', optionIndex, 'value']}
                    rules={[
                      { required: true, message: 'Option text is required' },
                      { max: 20, message: 'Maximum 20 characters allowed' },
                    ]}
                  >
                    <Input
                      showCount
                      maxLength={20}
                      onChange={(e) => updateOptionText(option.id, e.target.value, 'value')}
                      placeholder={`Option ${String.fromCharCode(97 + optionIndex)}`}
                      style={{ width: 214, marginBottom: -14 }}
                    />
                  </Form.Item>
                )
              }
              description={
                <Form.Item
                  name={['options', optionIndex, 'description']}
                  rules={[{ max: 60, message: 'Maximum 60 characters allowed' }]}
                >
                  <Input
                    placeholder='Description'
                    showCount
                    maxLength={60}
                    onChange={(e) => updateOptionText(option.id, e.target.value, 'description')}
                    style={{ width: 214, marginBottom: -14 }}
                  />
                </Form.Item>
              }
              explanation={
                <>
                  <Form.Item
                    name={['options', optionIndex, 'isExplanationEnabled']}
                    valuePropName='checked'
                  >
                    <Checkbox
                      checked={option.isExplanationEnabled}
                      onChange={() => handleExplanation(option.id)}
                    >
                      Enable Explanation
                    </Checkbox>
                  </Form.Item>
                  {option.isExplanationEnabled && (
                    <Form.Item
                      name={['options', optionIndex, 'explanation']}
                      rules={[{ max: 500, message: 'Maximum 500 characters allowed' }]}
                    >
                      <Input.TextArea
                        placeholder='Explanation'
                        showCount
                        maxLength={500}
                        style={{ width: 214 }}
                        onChange={(e) => updateOptionText(option.id, e.target.value, 'explanation')}
                      />
                    </Form.Item>
                  )}
                </>
              }
              jump={
                <>
                  {numberOfQuestions > 2 && (
                    <Form.Item
                      name={['options', optionIndex, 'isJumpEnabled']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={() => handleJump(option.id)}>Jump</Checkbox>
                    </Form.Item>
                  )}
                  {option.isJumpEnabled && (
                    <Form.Item name={['options', optionIndex, 'jumpTo']}>
                      <Select
                        style={{ width: 214 }}
                        onChange={(value) => updateOptionText(option.id, value, 'jumpTo')}
                      >
                        {Array.from({ length: numberOfQuestions - surveyNumber }, (_, index) => {
                          const jump = surveyNumber + index + 1;
                          return (
                            <Option key={jump} value={jump}>
                              {jump}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  )}
                </>
              }
              media={
                <>
                  {option.isExplanationEnabled && (
                    <Form.Item
                      name={['options', optionIndex, 'isOptionMediaEnabled']}
                      valuePropName='checked'
                    >
                      <Checkbox onChange={() => handleOptionMedia(option.id)}>
                        Enable Media
                      </Checkbox>
                    </Form.Item>
                  )}
                  {option.isOptionMediaEnabled && (
                    <Form.Item
                      name={['options', optionIndex, 'optionMediaUrl']}
                      rules={[{ required: true, message: 'Please upload a media file!' }]}
                    >
                      <Media
                        fileName={`${surveyNumber}_${optionIndex}`}
                        organizationId={organizationId}
                        usage='Option'
                        form={form}
                        fieldName={['options', optionIndex]}
                        key={`media-${option.id}`}
                      />
                    </Form.Item>
                  )}
                </>
              }
              minus={
                <MinusCircleOutlined
                  style={{ fontSize: 20, marginBottom: 8 }}
                  onClick={() => removeOption(option.id)}
                />
              }
            />
          ))}
        {!isOpenEnded && numberOfOptions < MAX_OPTIONS && (
          <Button type='dashed' onClick={addOption} icon={<PlusOutlined />} block>
            Add Option
          </Button>
        )}
        {(numberOfOptions >= 2 || isOpenEnded) && (
          <Row justify='space-between' gutter={[8, 12]} style={{ backgroundColor: '#f5f5f5' }}>
            <Col span={12}>
              <Button htmlType='submit' type='primary' block>
                Save
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={resetOptions} block>
                Reset
              </Button>
            </Col>
          </Row>
        )}
      </Space>
    </Form>
  );
};

export default SurveyFormAdvanced;
