import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import { Row, Col, Grid, Tooltip, Typography } from 'antd';
import { StopFilled, BackwardOutlined, ForwardOutlined } from '@ant-design/icons';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import { SyncOutlined } from '@ant-design/icons';
import StatusNotification from '../StatusNotification';
import GlobalColors from '../../assets/colors/GlobalColors';
import BarChart from './BarChart';
import barChart from './configs/barChart';
import getScreenSize from '../../utils/getScreenSize';
import isObjectEmpty from '../../utils/isObjectEmpty';
import FetchDashboardData from '../FetchDashboardData';
import Leaderboard from './Leaderboard';

const { useBreakpoint } = Grid;
const { Title } = Typography;
const { options: barChartOptions } = barChart;

const toolTip = (value) => <span>{value}</span>;

//This chart will be using pieChart data is it is already formated
const LiveEvent = () => {
  const [barChartSeriesData, setBarChartSeriesData] = useState([]);
  const [barChartOptionsData, setBarChartOptionsData] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isStillActive, setIsStillActive] = useState(true);

  const [mainQuestion, setMainQuestion] = useState('');
  const [barChartTotal, setBarChartTotal] = useState(0);

  const { id: surveyId, surveyName } = useParams();
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();
  const history = useHistory();
  const screenSize = getScreenSize(useBreakpoint());

  const {
    dashboard: {
      chartsData: { pieChart },
    },
    surveysData: { activeSurveyQuestions, isQuizInProgress },
    user: { organizationId },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pieChart?._id === surveyId && pieChart?.data) {
      const questionKeys = Object.keys(pieChart.data);
      if (currentQuestionIndex >= questionKeys.length || currentQuestionIndex < 0) return;

      const selectedKey = questionKeys[currentQuestionIndex];
      const value = pieChart.data[selectedKey];

      if (!value) return;

      setBarChartSeriesData([{ name: 'responses', data: value?.series, color: '#fff' }]);
      setBarChartOptionsData({
        ...barChartOptions,
        xaxis: {
          ...barChartOptions.xaxis,
          categories: filterEmojis(value?.categories),
        },
      });
      setBarChartTotal(value?.series?.reduce((acc, val) => acc + val, 0));
    } else {
      console.log('set to nothing');
      setBarChartSeriesData([{ name: 'responses', data: [], color: '#fff' }]);
    }
    setMainQuestion(activeSurveyQuestions[currentQuestionIndex]?.replace(/\*/g, ''));
  }, [pieChart, currentQuestionIndex]);

  useEffect(() => {
    const fetchAndUpdate = () => {
      const isLiveEvent = true;
      FetchDashboardData(surveyId, organizationId, axiosPrivate, dispatch, isLiveEvent, [
        surveyName,
      ])
        .then((response) => {
          if (response[2]?.status === 204) {
            StatusNotification(
              'warning',
              'No data',
              `No responses available yet for [${surveyName}]`
            );
          } else {
            // dispatch({ type: 'SET_ACTIVE_SURVEY', data: {surveyId, } });
          }
        })
        .catch((error) => {
          // StatusNotification(
          //   "error",
          //   "Live Event Failed",
          //   "Please try again later",
          // );
        });
    };

    fetchAndUpdate();
    const intervalId = setInterval(fetchAndUpdate, 3000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [surveyId, surveyName, organizationId, axiosPrivate, dispatch]);

  const filterEmojis = (strings) => strings.map((str) => str.split('_')[0]);

  const exitLiveEvent = () => {
    try {
      dispatch({ type: 'SET_IS_LIVE_EVENT_IN_PROGRESS', data: false });
      dispatch({ type: 'SET_IS_QUIZ_IN_PROGRESS', data: false });
      history.push('/surveys');
    } catch (error) {}
  };
  const goToNextQuestion = async () => {
    const nextIndex = currentQuestionIndex + 1;
    const totalQuestions = Object.keys(activeSurveyQuestions).length;

    if (nextIndex < totalQuestions) {
      try {
        await axiosPrivate.patch(`/api/live-events/trigger-question/${surveyId}`, {
          controllerPosition: nextIndex,
        });
        setCurrentQuestionIndex(nextIndex);
      } catch (error) {
        console.error(
          'Failed to update controller position:',
          error.response?.data || error.message
        );
        StatusNotification(
          'error',
          'Update Failed',
          'Unable to proceed to the next question. Please try again.'
        );
      }
    } else {
    }
  };

  const goToPreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const hasNextQuestion = currentQuestionIndex < Object.keys(activeSurveyQuestions).length - 1;
  const isFirstQuestion = currentQuestionIndex === 0;

  return (
    <div style={{ backgroundColor: GlobalColors.lightGray, height: '100vh' }}>
      <Row
        gutter={screenSize !== 'xs' ? [8, 8] : [8, 8]}
        // style={screenSize !== 'xs' ? { height: '80vh', borderRadius: 9 } : {}}
      >
        {isQuizInProgress && (
          <Col
            xs={24}
            md={7}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 8,
              // backgroundColor: isQuizInProgress ? '#EDEDED' : 'white',
            }}
          >
            (
            <Leaderboard
              visibility={'private'}
              surveyId={surveyId}
              setIsStillActive={setIsStillActive}
              screenSize={screenSize}
            />
            )
          </Col>
        )}
        <Col style={{ marginLeft: 18 }}>
          <Row justify={'space-between'}>
            <Col span={24} order={1} style={screenSize !== 'xs' ? { height: '5%' } : {}}>
              <Title level={screenSize !== 'xs' ? 1 : 2} className='sectionHeader'>
                {surveyName}
              </Title>
            </Col>
            <Col
              xs={{ span: 24, order: 3 }}
              md={{ span: 14, order: 2 }}
              // style={screenSize === "xs" ? { paddingTop: 64 } : {}}
            >
              <BarChart barChartSeries={barChartSeriesData} barChartOptions={barChartOptionsData} />
            </Col>
            <Col
              xs={{ span: 24, order: 2 }}
              md={{ span: 10, order: 3 }}
              style={{ textAlign: 'center' }}
            >
              <Row justify='space-between'>
                <Col span={24}>
                  <Title level={screenSize !== 'xs' ? 2 : 4} className='sectionHeaderInternal'>
                    {mainQuestion}
                  </Title>
                  <Title level={4} className='sectionHeaderInternal'>
                    Total Responses: {barChartTotal}
                  </Title>
                </Col>
                <Col span={24}>
                  <Row align='bottom' style={screenSize !== 'xs' ? { minHeight: '29.2vh' } : {}}>
                    {!isFirstQuestion && (
                      <Col span={12}>
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={toolTip('Previous Question')}
                        >
                          <BackwardOutlined
                            onClick={goToPreviousQuestion}
                            style={{ fontSize: 68, color: GlobalColors.darkGray }}
                          />
                        </Tooltip>
                      </Col>
                    )}
                    {hasNextQuestion && (
                      <Col span={12}>
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={toolTip('Next Question')}
                        >
                          <ForwardOutlined
                            onClick={goToNextQuestion}
                            style={{ fontSize: 68, color: GlobalColors.darkGray }}
                          />
                        </Tooltip>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              span={24}
              order={4}
              style={screenSize !== 'xs' ? { height: '5%' } : { paddingTop: 6, height: '5%' }}
            >
              <Tooltip
                placement='top'
                color={GlobalColors.mainPurple}
                title={toolTip('Exit Live Event')}
              >
                <StopFilled
                  onClick={exitLiveEvent}
                  style={{ fontSize: 28, color: GlobalColors.darkGray, marginTop: 46 }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LiveEvent;
