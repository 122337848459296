import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Tag,
  Typography,
  Grid,
  Space,
  Tooltip,
  Table,
  Image,
  Popconfirm,
} from 'antd';
import GlobalColors from '../assets/colors/GlobalColors';
import usePrivateAxios from '../hooks/usePrivateAxios';
import LoadingIcon from '../components/LoadingIcon';
import StatusNotification from '../components/StatusNotification';
import {
  CheckCircleOutlined,
  PlusOutlined,
  EyeFilled,
  SyncOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import getScreenSize from '../utils/getScreenSize';

import StatusCompleted from '../assets/images/status-completed.svg';
import TemplateCard from '../components/TemplateCard';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const Templates = () => {
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();
  const screens = useBreakpoint();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState({});
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const apiURL = '/api/templates/';

  const screenSize = getScreenSize(screens);
  const toolTip = (value) => <span>{value}</span>;

  const { organizationId } = useSelector((state) => state.user);
  const { selectedTemplate, templates } = useSelector((state) => state.templatesData);

  useEffect(() => {
    dispatch({ type: 'RESET_TEMPLATE' });
    localStorage.removeItem('templateData');
  }, [dispatch]);

  const refreshTemplates = async () => {
    setIsRefreshing(true);
    try {
      const response = await axiosPrivate.get(apiURL + organizationId);
      dispatch({ type: 'FETCH_TEMPLATES', data: response.data });
    } catch (error) {
      StatusNotification(
        'error',
        'Refresh Failed',
        error?.response?.data?.message || 'An error occurred while refreshing the templates.'
      );
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleView = (record) => {
    const selectedTemplate = templates.find((template) => template.name === record.name);
    dispatch({ type: 'SET_SELECTED_TEMPLATE', data: selectedTemplate });
  };

  const handleDelete = async (templateId) => {
    setIsLoading({ [templateId]: 'delete' });
    try {
      const response = await axiosPrivate.delete(apiURL + organizationId + '/' + templateId);
      if (response.status === 200) {
        dispatch({ type: 'DELETE_TEMPLATE', data: templateId });
      } else {
        StatusNotification(
          'error',
          'Delete Failed',
          'Failed to delete the template. Please try again.'
        );
      }
    } catch (error) {
      StatusNotification(
        'error',
        'Delete Failed',
        error?.response?.data?.message || 'An error occurred while deleting the template.'
      );
    } finally {
      setIsLoading({});
    }
  };

  let icon = <CheckCircleOutlined />;
  let tagStyle = { width: '92px' };
  let mobileStatusIcon = StatusCompleted;

  const columns = [
    {
      title: '#',
      key: 'counter',
      width: '4%',
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      align: 'center',
    },
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
      width: '18%',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '8%',
      render: (type) => <Tag color={type === 'sms' ? 'blue' : 'green'}>{type.toLowerCase()}</Tag>,
      sorter: (a, b) => a.type.localeCompare(b.type),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '32%',
      key: 'status',
      render: (status, record) => (
        <Row justify='center'>
          <Col span={24} style={{ textAlign: screenSize === 'xs' ? 'center' : 'left' }}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={status === 'scheduled' ? toolTip('activate') : null}
            >
              <span>
                {screenSize === 'xs' ? (
                  <Image height={12} src={mobileStatusIcon} preview={false} />
                ) : (
                  <Tag
                    icon={icon}
                    color={
                      status === 'PENDING' ? 'warning' : status === 'APPROVED' ? 'grey' : 'default'
                    }
                    style={status === 'PENDING' ? { ...tagStyle, cursor: 'pointer' } : { tagStyle }}
                  >
                    {status.toLowerCase()}
                  </Tag>
                )}
              </span>
            </Tooltip>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Action',
      align: 'center',
      key: 'action',
      width: '32%',
      dataIndex: 'action',
      render: (text, record) => (
        <Space>
          <Tooltip placement='top' color={GlobalColors.mainPurple} title='View Templates'>
            {isLoading[record._id] === 'view' ? (
              <LoadingIcon color={GlobalColors.darkGray} />
            ) : (
              <EyeFilled onClick={() => handleView(record)} />
            )}
          </Tooltip>
          <Tooltip placement='top' color={GlobalColors.mainPurple} title='Delete Template'>
            <Popconfirm
              title='Are you sure you want to delete?'
              onConfirm={() => handleDelete(record._id)}
              okText='Yes'
              cancelText='No'
            >
              {isLoading[record._id] === 'delete' ? (
                <LoadingIcon color={GlobalColors.darkGray} />
              ) : (
                <DeleteOutlined />
              )}
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  console.log({ templates });
  return (
    <>
      <div className='tabled' style={{ marginBottom: 10, marginTop: screenSize !== 'xs' ? 78 : 0 }}>
        <Row>
          <Col span={14}>
            <Row>
              <Col push={1} span={24}>
                <Row justify='space-between'>
                  <Col span={12}>
                    <NavLink to='templates/create'>
                      <Tooltip
                        placement='top'
                        color={GlobalColors.mainPurple}
                        title={toolTip('Create Template')}
                      >
                        <span className='icon' style={{ fontSize: 26 }}>
                          <PlusOutlined style={{ color: GlobalColors.mainPurple }} />
                        </span>
                      </Tooltip>
                    </NavLink>
                  </Col>
                  <Col span={12} style={{ textAlign: 'end' }} pull={2}></Col>
                </Row>
              </Col>
              <Col span={24}>
                <Card
                  bordered={false}
                  className='criclebox tablespace mb-24'
                  title={
                    <Row justify='space-between'>
                      <Col style={{ fontSize: 24, color: GlobalColors.darkGray }}>Templates</Col>
                      <Col>
                        <span
                          className='icon'
                          onClick={refreshTemplates}
                          style={{ cursor: 'pointer' }}
                        >
                          <SyncOutlined
                            spin={isRefreshing}
                            style={{
                              fontSize: 20,
                              color: GlobalColors.darkGray,
                            }}
                          />
                        </span>
                      </Col>
                    </Row>
                  }
                >
                  <Table
                    rowKey={(record) => record._id}
                    columns={columns}
                    dataSource={templates}
                    pagination={{
                      position: ['bottomCenter'],
                      size: 'small',
                      current: currentPage,
                      pageSize: pageSize,
                      onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                      },
                    }}
                    className='ant-border-space'
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={8}>{screenSize !== 'xs' && selectedTemplate && <TemplateCard />}</Col>
        </Row>
      </div>
    </>
  );
};

export default Templates;
