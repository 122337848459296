import React, { useRef } from 'react';
import usePrivateAxios from '../hooks/usePrivateAxios';
import verifySignature from '../payfast/verifySignature';
import LoadingIcon from './LoadingIcon';
import { Button } from 'antd';
import { HappyProvider } from '@ant-design/happy-work-theme';
const PAYFAST_BASE_URL = process.env.REACT_APP_PAYFAST_ONSITE_URL;
const PAYFAST_RETURN_PAGE = '/subscription/complete';
const PAYFAST_CANCEL_PAGE = '/subscription/cancel';
const PAYFAST_NOTIFY_PAGE = '/api/pfnotify';

const apiURL = '/api/pfnotify/total';
const PayfastButtonBasic = ({
  amount,
  discountAmount,
  exchangeRate,
  currency,
  values,
  nextBillingDate,
  extraDetails,
  signature,
  setIsLoading,
  isLoading,
  isYearly,
  btn,
}) => {
  const pfForm = useRef();
  const privateAxios = usePrivateAxios();
  const { userId } = values;
  const { firstName = '', lastName = '', emailAddress = '' } = extraDetails;

  signature = signature || '';

  let payfastAmount = amount;
  let payfastDiscountAmount = discountAmount;
  if (currency === 'USD') {
    payfastAmount = amount * exchangeRate;
    payfastDiscountAmount = discountAmount * exchangeRate;
  }
  const handleSubmit = () => {
    setIsLoading(true);
    const formData = new FormData(pfForm.current);

    signature = verifySignature(formData);
    privateAxios.post(apiURL, { checkoutTotal: payfastAmount });
  };
  return (
    <div>
      <form ref={pfForm} action={PAYFAST_BASE_URL} method='POST'>
        <input type='hidden' name='merchant_id' value={process.env.REACT_APP_PAYFAST_MERCHANT_ID} />
        <input
          type='hidden'
          name='merchant_key'
          value={process.env.REACT_APP_PAYFAST_MERCHANT_KEY}
        />
        <input
          type='hidden'
          name='return_url'
          value={`${process.env.REACT_APP_BASE_URL_PAYFAST}${PAYFAST_RETURN_PAGE}`}
        />
        <input
          type='hidden'
          name='cancel_url'
          value={`${process.env.REACT_APP_BASE_URL_PAYFAST}${PAYFAST_CANCEL_PAGE}`}
        />
        <input
          type='hidden'
          name='notify_url'
          value={`${process.env.REACT_APP_BASE_URL}${PAYFAST_NOTIFY_PAGE}`}
        />
        <input type='hidden' name='name_first' value={firstName} />
        <input type='hidden' name='billing_date' value={nextBillingDate} />
        <input type='hidden' name='name_last' value={lastName} />
        <input type='hidden' name='email_address' value={emailAddress} />

        {isYearly ? (
          <>
            <input type='hidden' name='custom_str3' value={payfastDiscountAmount} />
            <input type='hidden' name='item_description' value={'yearly basic plan'} />
          </>
        ) : (
          <>
            {' '}
            <input type='hidden' name='subscription_type' value='1' />
            <input type='hidden' name='frequency' value={'3'} />
            <input type='hidden' name='recurring_amount' value={payfastAmount} />
            <input type='hidden' name='cycles' value='0' />
            <input type='hidden' name='item_description' value={'monthly basic plan'} />
          </>
        )}

        <input type='hidden' name='amount' value={payfastAmount} />
        <input type='hidden' name='item_name' value='basic plan' />

        <input type='hidden' name='custom_str1' value={userId} />
        <input type='hidden' name='custom_str2' value='basic' />

        <input type='hidden' name='passphrase' value={process.env.REACT_APP_PAYFAST_PASSPHRASE} />
        <input type='hidden' name='signature' value={signature} />
        <HappyProvider>
          <Button type='primary' onClick={handleSubmit} htmlType='submit' block>
            {isLoading && btn === 'basic' ? <LoadingIcon /> : 'Subscribe'}
          </Button>
        </HappyProvider>
      </form>
    </div>
  );
};

export default PayfastButtonBasic;
