import axios from 'axios';
import { generate } from '@pdfme/generator';
import { text, barcodes, image } from '@pdfme/schemas';

const downloadFromCloudinary = async (pdfUrl) => {
  try {
    const response = await axios.get(pdfUrl, { responseType: 'blob' });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(response.data);
    });
  } catch (error) {
    throw new Error(`Error downloading from Cloudinary: ${error.message}`);
  }
};

const processImages = async (schemas, inputs) => {
  try {
    for (const schema of schemas) {
      for (const [key, value] of Object.entries(schema)) {
        if (value.type === 'image') {
          // Fetch the image URL from inputs
          const imageUrl = inputs[0][key];
          if (imageUrl) {
            // Download image and convert it to base64
            const imageBase64 = await downloadFromCloudinary(imageUrl);
            // Replace the URL in inputs with base64 string
            inputs[0][key] = imageBase64;
          }
        }
      }
    }
    return inputs; // Return the modified inputs
  } catch (error) {
    console.error('Error in processImages:', error);
    throw error; // Rethrow the error for the caller to handle
  }
};

const generateAndDownloadPdf = async (
  axiosPrivate,
  surveyName,
  pdfmeTemplateId,
  organizationId,
  threadId
) => {
  try {
    const pdfResponse = await axiosPrivate.get(
      `/api/pdfme/download/${pdfmeTemplateId}/${organizationId}/${threadId}`
    );
    const { schemas, inputs, pdfUrl } = pdfResponse.data;

    const plugins = { text, qrcode: barcodes.qrcode, image };
    const pdfBlob = await downloadFromCloudinary(pdfUrl);
    const processedInputs = await processImages(schemas, inputs);

    const template = { schemas, basePdf: pdfBlob };
    const pdf = await generate({ template, plugins, inputs: processedInputs });
    const blob = new Blob([pdf], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${surveyName}-report.pdf`;
    link.click();
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error in generateAndDownloadPdf:', error.message);
    throw error; // Rethrow the error for the caller to handle
  }
};

export { generateAndDownloadPdf };
