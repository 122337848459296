/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import GlobalColors from './assets/colors/GlobalColors';

import App from './App';
import StatusNotification from './components/StatusNotification';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const theme = {
  token: {
    colorPrimary: GlobalColors.mainPurple,
    colorPrimaryHover: GlobalColors.lightPurple,
  },
  components: {
    Slider: {
      trackBackgroundColor: GlobalColors.lightPurple,
      handleColor: GlobalColors.lightPurple,
      trackBg: GlobalColors.lightPurple,
      trackHoverBg: GlobalColors.whitePurple,
      dotActiveBorderColor: GlobalColors.lightPurple,
      handleActiveColor: GlobalColors.lightPurple,
      colorPrimaryBorderHover: GlobalColors.lightPurple,
      handleLineWidth: 4,
    },
    Segmented: {
      itemSelectedBg: GlobalColors.lightPurple,
    },
    statistic: {
      // contentFontSize: 20,
      titleFontSize: 18,
    },
    Form: {
      // itemMarginBottom: 0,
      verticalLabelPadding: '0 0 0px',
    },
    Input: {
      addonBg: GlobalColors.lightPurple,
    },
    Alert: {
      colorInfo: GlobalColors.lightPurple,
      colorInfoBorder: GlobalColors.lightGray,
    },
    Button: {
      borderColorDisabled: GlobalColors.darkGray,
      colorBgContainerDisabled: GlobalColors.lightGray,
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={({ error, errorInfo }) =>
        StatusNotification('error', 'Something went wrong', ' Support have been notified', {
          errorInfo,
          options: {
            level: 'error',
            tags: { section: 'Sentry ErrorBountry' },
            extra: { additionalInfo: error?.message },
          },
        })
      }
      showDialog
    >
      <BrowserRouter>
        <ConfigProvider theme={theme}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
