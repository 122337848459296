import React, { useEffect } from 'react';
import { Row, Col, Typography, Layout } from 'antd';
import SectionHeader from '../components/SectionHeader';
import NavBar from '../components/NavBar';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  });
  return (
    <div className='containerRadius'>
      <NavBar />
      <Content className='container '>
        <Row>
          <Col span={24}>
            <SectionHeader
              title='Privacy Policy'
              subtitle='All the information relating to how your data is handled'
              isFront={true}
            />
          </Col>
          <Col>
            <Row>
              <Col span={24}>
                <Title level={5}>Last updated: [03-Jan-2023]</Title>
                <Paragraph>
                  Thank you for using our SaaS Surveyr app (the "App"). We take
                  the privacy of our users seriously and are committed to
                  protecting your personal information. This Privacy Policy
                  explains how we collect, use, and share information about you
                  when you use the App. By using the App, you agree to the
                  collection, use, and sharing of your information as described
                  in this Privacy Policy. If you do not agree with our policies
                  and practices, do not use the App.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Information We Collect</Title>
                <Paragraph>
                  We collect information about you when you use the App. This
                  includes: Information you provide to us: When you create an
                  account, we may ask for personal information such as your
                  name, email address, and phone number. Information we collect
                  automatically: When you use the App, we may collect
                  information about your device and how you use the App. This
                  may include your IP address, device type, operating system,
                  and information about your browsing and messaging activity
                  within the App.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>How We Use Your Information</Title>
                <Paragraph>
                  We use the information we collect about you to: Provide,
                  maintain, and improve the App: We use your information to
                  provide, maintain, and improve the App and its features.
                  Communicate with you: We may use your information to send you
                  updates about the App or respond to your inquiries. Protect
                  against and prevent fraud, unauthorized transactions, claims,
                  and other liabilities: We use your information to help us
                  prevent fraud and to protect the App and its users.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Sharing Your Information</Title>
                <Paragraph>
                  We may share your information with third parties in the
                  following circumstances: With your consent: We may share your
                  information with third parties if you have given us your
                  consent to do so. To protect our rights: We may share your
                  information if we believe it is necessary to enforce our
                  policies, terms of use, or to protect the rights, property, or
                  safety of the App, its users, or others. In connection with a
                  merger, sale, or other transfer of assets: If we are involved
                  in a merger, acquisition, or sale of assets, we may share your
                  information with the other party.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Your Choices and Rights</Title>
                <Paragraph>
                  You have the following choices and rights regarding your
                  information: Access, update, or delete your information: You
                  can access, update, or delete your account information by
                  logging into your account and making the changes. Object to
                  the processing of your information: You can object to the
                  processing of your information by contacting us. Withdraw your
                  consent: If you have given us your consent to process your
                  information, you can withdraw your consent at any time by
                  contacting us. Complain to a supervisory authority: If you
                  have a concern about our privacy practices, you can report it
                  to a supervisory authority in your jurisdiction.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Data Retention and Security</Title>
                <Paragraph>
                  We retain your information for as long as your account is
                  active or as needed to provide you with the App. We will
                  delete your information upon request or as required by law. We
                  have implemented security measures to protect your information
                  from unauthorized access, use, or disclosure. However, no
                  method of transmission over the internet or electronic storage
                  is completely secure, and we cannot guarantee the absolute
                  security of your information.
                </Paragraph>
              </Col>
              <Col span={24}>
                <Title level={5}>Changes to This Privacy Policy</Title>
                <Paragraph>
                  Changes to This Privacy Policy We may update this Privacy
                  Policy from time to time to reflect changes to our practices
                  or for other operational, legal, or regulatory reasons.
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default PrivacyPolicy;
