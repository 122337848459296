/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactApexChart from 'react-apexcharts';
import html2canvas from 'html2canvas';
import { Typography, Select, Row, Col, Form, Tag, Grid, Tooltip, Popconfirm, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import weekday from 'dayjs/plugin/weekday';
import pieChartConfigs from './configs/pieChart';
import GlobalColors from '../../assets/colors/GlobalColors';
import getScreenSize from '../../utils/getScreenSize';
import StatusNotification from '../StatusNotification';
import JSZip from 'jszip';
import isObjectEmpty from '../../utils/isObjectEmpty';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(weekday);

const { options: pieOptions, series: pieSeries } = pieChartConfigs;
const { useBreakpoint } = Grid;

function PieChart() {
  const { Title, Paragraph, Text } = Typography;
  const [pieChartSeriesData, setPieChartSeriesData] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectValue, setSelectValue] = useState(undefined);
  const [mainQuestion, setMainQuestion] = useState('');
  const [surveyDetails, setSurveyDetails] = useState({});
  const [splitEmojiText, setSplitEmojiText] = useState([]);
  const [searchedTag, setSearchedTag] = useState([]);
  // const [allTags, setAllTags] = useState([]);
  const [counter, setCounter] = useState(0);
  const [chartsForDownload, setChartsForDownload] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [hasRan, setHasRan] = useState(false);
  let zip = null;
  const axiosPrivate = usePrivateAxios();

  const dispatch = useDispatch();

  const [pieChartTotal, setPieChartTotal] = useState();

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const iconStyle = {
    fontSize: 18,
    color: GlobalColors.darkGray,
    padding: 0,
    // lineHeight: 2.8,
    paddingRight: 8,
    paddingLeft: 8,
  };

  const toolTip = (value) => <span>{value}</span>;

  const {
    user: { organizationId },
    dashboard: {
      chartsData: { pieChart },
      allTags,
    },
    surveysData: {
      activeSurvey: { surveyId, surveyName },
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pieChart && pieChart?.data) {
      setSurveyDetails({
        surveyName: pieChart?.surveyName,
        _id: pieChart?._id,
      });

      setSelectOptions(
        Object.keys(pieChart?.data).map((key) => ({
          value: key,
          label: key,
        }))
      );
      setSelectValue(selectOptions[0]?.label);

      let value;
      const entries = Object.entries(pieChart?.data ?? {});
      if (!isObjectEmpty(entries)) {
        value = entries[0][1];
      } else {
        value = undefined; // or a default value
      }
      setPieChartSeriesData(value?.series);

      const { categories, tooltip } = mapCategories(value?.categories);
      setPieChartOptions({
        ...pieOptions,
        labels: categories,
        tooltip: tooltip,
      });
      setMainQuestion(value?.mainQuestion.replace(/\*/g, ''));
      setPieChartTotal(value?.series?.reduce((acc, val) => acc + val, 0));
      dispatch({ type: 'IS_LOADING', data: false });
    }
    if (surveyName) {
      setSearchedTag([surveyName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieChart]);

  const mapCategories = (value) => {
    let categories = value;
    let tooltip = {
      background: 'rgba(255, 255, 255, 0.2)',
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `<div style="text-align: center; padding: 8px; background: rgba(255, 255, 255, 0.3);">${categories[seriesIndex]} :   [${series[seriesIndex]}]</div>`;
      },
    };

    if (Array.isArray(categories) && categories.length > 0 && categories[0]?.includes('_')) {
      categories = categories.map((category) => {
        return category?.split('_')[0];
      });

      const splitEmoji = value.map((category) => {
        return category.split('_')[1];
      });
      setSplitEmojiText(splitEmoji);
      tooltip = {
        // background: 'rgba(255, 255, 255, 0.2)',
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `<div style="text-align: center; padding: 8px; background: rgba(255, 255, 255, 0.3);">${categories[seriesIndex]} : ${splitEmoji[seriesIndex]} : [${series[seriesIndex]}]</div>`;
        },
      };
    }

    return { categories, tooltip };
  };

  const handleConfirm = () => {
    window.location.reload();
  };

  const handleOk = () => {
    setConfirmLoading(true);
    handleDownload();
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const downloadZip = () => {
    setModalOpen(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (chartsForDownload.length > 0 && surveyDetails._id && !hasRan) {
      setIsDownloading(true);
      setHasRan(true);
      axiosPrivate
        .get(`/api/responses/export/${surveyDetails._id}`, {
          responseType: 'blob',
        })
        .then((response) => {
          if (response.status === 204)
            return StatusNotification('warning', 'No data', 'No responses to download');

          // setCsvBlob(response.data);
          generateZip(response.data);
        })
        .catch((error) => {
          StatusNotification('error', 'Download Failed', 'Please try again later');
        })
        .finally(() => {
          setIsDownloading(false);
        });
    }
  });

  const generateZip = async (csvBlob) => {
    const charts = document.querySelectorAll('.pie-for-download');
    if (!charts.length) return;

    zip = new JSZip();
    const promises = [];

    const response = await axiosPrivate.get(`/api/ai/summarize/${surveyDetails._id}`);

    if (response?.data?.response) zip.file('summary.txt', response.data.response);

    zip.file(`${surveyDetails.surveyName}.csv`, csvBlob);

    charts.forEach((chart, index) => {
      promises.push(
        html2canvas(chart, { scale: 2 }).then((canvas) => {
          const imageUrl = canvas.toDataURL('image/png');
          zip.file(`Question-${++index}.png`, imageUrl.split(',')[1], {
            base64: true,
          });
        })
      );
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = window.URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${surveyDetails.surveyName}.zip`;
        if (counter === 1) {
          link.click();
          const parent = document.getElementById('chartsContainer');
          if (parent) parent.remove();
          setModalOpen(false);
          setConfirmLoading(false);
        } else handleDownload();
      });
    });
  };

  const handleDownload = () => {
    if (pieChart && pieChart?.data && surveyDetails._id) {
      const arrChartsForDownload = selectOptions.map((option, index) => {
        for (const [key, value] of Object.entries(pieChart?.data)) {
          if (key === option.value) {
            const { categories, tooltip } = mapCategories(value.categories);
            const mainQ = value.mainQuestion.replace(/\*/g, '');
            return (
              <Row className='pie-for-download' key={index}>
                <Col
                  span={24}
                  style={{
                    textAlign: 'center',
                    fontSize: 13,
                    color: GlobalColors.darkGray,
                  }}
                >
                  {mainQ}
                </Col>
                <Col span={24}>
                  <ReactApexChart
                    className='full-width '
                    options={{
                      ...pieOptions,
                      labels: categories,
                      tooltip: tooltip,
                    }}
                    series={value.series}
                    type='pie'
                    width={'100%'}
                  />
                </Col>
              </Row>
            );
          }
        }
        return null;
      });

      setChartsForDownload(arrChartsForDownload);
      setCounter((prev) => prev + 1);
    } else {
      StatusNotification('error', 'Download Failed', 'Please try again later');
    }
  };

  const handleChange = (selected) => {
    setSplitEmojiText([]);
    if (pieChart) {
      for (const [key, value] of Object.entries(pieChart?.data)) {
        if (key === selected) {
          const { categories, tooltip } = mapCategories(value.categories);
          setMainQuestion(value.mainQuestion.replace(/\*/g, ''));
          setPieChartSeriesData(value.series);
          setPieChartOptions({
            ...pieOptions,
            labels: categories,
            tooltip: tooltip,
          });
        }
      }
    }
  };

  const handleTagChange = (value) => {
    if (!value.includes(surveyName)) {
      value.push(surveyName);
    }
    setSearchedTag(value);
    dispatch({ type: 'DASHBOARD_SET_SEARCHED_TAGS', data: value });
  };

  return (
    <>
      <Modal
        title='Pie Chart Download'
        open={modalOpen}
        onOk={handleOk}
        okText='Download'
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Downloading chart data, Wait for the zip file </p>
      </Modal>
      <div>
        <Row gutter={[8, 0]} justify='space-between'>
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 6 }}
            style={screenSize === 'xs' ? { textAlign: 'center' } : {}}
          >
            <div>
              <Form>
                <Form.Item valuePropName='option'>
                  <Select
                    size='middle'
                    placeholder={surveyDetails.surveyName ? 'Question 1' : 'Loading...'}
                    defaultActiveFirstOption
                    style={{
                      width: 120,
                    }}
                    onChange={handleChange}
                  >
                    {selectOptions.map((question, index) => (
                      <Select.Option key={index} value={question.value} />
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col xs={24} md={{ span: 10, order: 2 }}>
            <Row gutter={[]}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Title level={5} style={{ color: GlobalColors.darkGray }}>
                  {surveyDetails.surveyName}
                </Title>
              </Col>
              <Col span={24} style={{ textAlign: 'center', fontSize: 9 }}>
                Survey Name
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            md={{ span: 6, order: 3 }}
            style={screenSize === 'xs' ? { textAlign: 'center' } : { textAlign: 'right' }}
          >
            <Select
              size='middle'
              mode='tags'
              style={{
                width: '100%',
              }}
              placeholder='Select tags'
              className='table-search'
              value={searchedTag}
              onChange={handleTagChange}
            >
              {allTags?.map((tag) => (
                <Select.Option key={tag} value={tag} disabled={tag === surveyName}>
                  {tag}
                </Select.Option>
              ))}
            </Select>
            <div>
              {/* <Title level={5} style={{ color: GlobalColors.darkGray }}>
                Responses <Tag color={GlobalColors.lightPurple}>{pieChartTotal}</Tag>
              </Title> */}
            </div>
          </Col>
          <Col md={{ order: 3 }}>
            {surveyDetails.surveyName && (
              <Tooltip
                placement='top'
                color={GlobalColors.mainPurple}
                title={toolTip('download ZIP')}
              >
                {' '}
                {counter < 1 ? (
                  <DownloadOutlined style={iconStyle} onClick={downloadZip} />
                ) : (
                  <Popconfirm
                    icon={null}
                    placement='rightTop'
                    title={
                      <Paragraph style={{ color: GlobalColors.darkGray }}>
                        For a second donwload, Page will <Text strong>refresh</Text>
                      </Paragraph>
                    }
                    description='Are you sure to delete this task?'
                    onConfirm={handleConfirm}
                    okText='Yes'
                    cancelText='No'
                  >
                    <DownloadOutlined style={iconStyle} />
                  </Popconfirm>
                )}{' '}
              </Tooltip>
            )}
          </Col>
          <Col
            span={24}
            order={3}
            style={{
              textAlign: 'center',
              fontSize: 13,
              color: GlobalColors.darkGray,
            }}
          >
            {mainQuestion}
          </Col>
        </Row>
      </div>

      <ReactApexChart
        className='full-width pie-chart'
        options={pieChartOptions}
        series={pieChartSeriesData}
        type='pie'
        width={'100%'}
      />
      <div id='chartsContainer'>{chartsForDownload.map((chart, index) => chart)}</div>
    </>
  );
}

export default PieChart;
