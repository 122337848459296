import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const IndustrySelect = ({ onChange }) => {

  const industries = ['Information Technology (IT)', 'Finance and Banking', 'Healthcare and Pharmaceuticals', 'Education and E-learning', 'Retail and E-commerce', 'Manufacturing and Engineering', 'Hospitality and Tourism', 'Media and Entertainment', 'Professional Services (Legal, Consulting, Accounting, etc.)', 'Real Estate and Construction', 'Non-profit and Charity', 'Automotive', 'Food and Beverage', 'Energy and Utilities', 'Transportation and Logistics', 'Government and Public Sector', 'Agriculture and Farming', 'Telecommunications', 'Fitness and Wellness', 'Fashion and Apparel'];

  return (
    <Select placeholder='Select industry' style={{ width: '100%' }} onChange={onChange} showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} allowClear>
      {industries.map((industry) => (
        <Option key={industry} value={industry}>
          {industry}
        </Option>
      ))}
    </Select>
  );
};

export default IndustrySelect;