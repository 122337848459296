import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Typography, Button, Space, Grid, Image } from 'antd';
import { HappyProvider } from '@ant-design/happy-work-theme';
import { SmileOutlined } from '@ant-design/icons';
import getScreenSize from '../utils/getScreenSize';
import { ReactComponent as LandingFred } from '../assets/images/landing-fred.svg';

import GlobalColors from '../assets/colors/GlobalColors';
import TrustBar from './TrustBar';

const { Title, Text, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const Hero = () => {
  const screens = useBreakpoint();
  const history = useHistory();

  const screenSize = getScreenSize(screens);

  const handleTryForFree = () => {
    setTimeout(() => {
      history.push('/signup');
    }, 700);
  };

  return (
    <div id='hero' className='hero container'>
      <Row className='heroRow' style={{  height: '100vh' }}>
        <Col span={24}>
          <Row>
            <Col
              xs={[{ span: 24 }, { order: 2 }]}
              md={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <LandingFred className='landingFredStyle' />
            </Col>
            <Col
              xs={[{ span: 24 }, { order: 1 }]}
              md={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Row justify='start' align='middle'>
                <Col>
                  <Paragraph
                    className='heroText heroCol gradientText'
                    style={{ color: '#25d366', marginBottom: 0 }}
                  >
                    Supercharge your conversations with Surveyr
                  </Paragraph>
                </Col>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Row justify='middle' align='middle'>
                    <Space direction='vertical' size='small'>
                      <Col span={24}>
                        <Title level={screenSize === 'xs' ? 3 : 2} style={{ color: '#7A7774' }}>
                          A{' '}
                          <Text className='markedText' mark>
                            WhatsApp
                          </Text>{' '}
                          driven platform for engaging customer chats,{' '}
                          <Text className='markedText' mark>
                            capturing leads,
                          </Text>{' '}
                          collecting valuable feedback and{' '}
                          <Text className='markedText' mark>
                            boosting live events
                          </Text>
                          .
                        </Title>
                      </Col>
                      <Col xs={{ span: 12, push: 6 }}>
                        <HappyProvider>
                          <Button type='primary' onClick={handleTryForFree}>
                            Try Surveyr for free <SmileOutlined style={{ paddingLeft: 4 }} />
                          </Button>
                        </HappyProvider>
                      </Col>
                    </Space>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            maxHeight: '18vh',
          }}
        >
          <TrustBar />
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
};

export default Hero;
