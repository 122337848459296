import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import usePrivateAxios from '../hooks/usePrivateAxios';

const TagFilter = ({ operator }) => {
  const axiosPrivate = usePrivateAxios();
  const apiURL = '/api/broadcasts/tags/';

  const dispatch = useDispatch();

  const {
    user: { organizationId },
    tagsFilter: { allTags, andTags, notTags },
  } = useSelector((state) => state);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axiosPrivate.get(`${apiURL}${organizationId}`);
        dispatch({ type: 'FETCH_TAGS', data: response.data });
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, [axiosPrivate, dispatch, organizationId]);

  const handleChange = (value) => {
    const actionType = operator === 'AND' ? 'SET_SELECTED_AND_TAGS' : 'SET_SELECTED_NOT_TAGS';
    dispatch({ type: actionType, data: value });
  };

  // Dynamically determine options based on operator
  let options = allTags;
  if (operator === 'AND') {
    options = allTags?.filter((tag) => !notTags.includes(tag));
  } else if (operator === 'NOT') {
    options = allTags?.filter((tag) => !andTags.includes(tag));
  }
  options = options?.map((tag) => ({ value: tag }));

  return (
    <Select
      mode='tags'
      size='middle'
      placeholder={`Filter tags (${operator})`}
      onChange={handleChange}
      style={{ width: '100%' }}
      options={options}
    />
  );
};

export default TagFilter;
