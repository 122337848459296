const GlobalColors = {
  whatsappLime: '#25d366',
  darkPurple: '#533b73',
  mainPurple: '#6a4c93',
  // lightPurple: '#9d89b8',
  lightPurple: '#bf95e4',
  whitePurple: '#dad2e4',
  darkGray: '#7a7774',
  lightGray: '#dfdfdf',
  whiteGray: '#ededed',
  delivered: '#3498db',
  sent: '#79bbe7',
  failed: '#fc5c65',
  read: '#2ecc71',
  stats: '#f1c40f',
  gold: '#f1c40f',
};

export default GlobalColors;
