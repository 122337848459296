import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Redirect, NavLink } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Tag,
  Table,
  Grid,
  Space,
  Tooltip,
  Input,
  Modal,
  Radio,
  Select,
} from 'antd';
import { PlusOutlined, SyncOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import GlobalColors from '../assets/colors/GlobalColors';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import getScreenSize from '../utils/getScreenSize';
import TagsDropdown from '../components/TagsDropdown';

const { useBreakpoint } = Grid;

const Contacts = () => {
  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();
  const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchedTag, setSearchedTag] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isBulkEditModalVisible, setIsBulkEditModalVisible] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [bulkEditAction, setBulkEditAction] = useState('add'); // or 'remove'
  const [bulkEditTags, setBulkEditTags] = useState([]);
  const [searchedName, setSearchedName] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentDataSource, setCurrentDataSource] = useState([]);

  const apiURL = '/api/contacts/';

  const {
    organizationId,
    teamDetails: { role },
  } = useSelector((state) => state.user);
  const { contacts, failedContacts, optedOutContacts } = useSelector((state) => state.contactsData);

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const toolTip = (value) => <span>{value}</span>;

  useEffect(() => {
    const getContacts = async () => {
      try {
        const response = await axiosPrivate.get(apiURL + organizationId);
        dispatch({ type: 'FETCH_CONTACTS', data: response.data });
      } catch (error) {
        return <Redirect to={state?.from || '/signin'} />;
      }
    };
    getContacts();
  }, []);

  useEffect(() => {
    const tags = new Set();
    contacts.forEach((contact) =>
      contact.profilePerOrganization.forEach(
        ({ profile }) => profile.tags && profile.tags.forEach((tag) => tags.add(tag))
      )
    );
    setAllTags([...tags]);
  }, [contacts]);

  useEffect(() => {
    // Set the initial data source to filteredContacts
    setCurrentDataSource(filteredContacts);
  }, [filteredContacts]);

  useEffect(() => {
    let filtered = contacts;

    if (searchedTag && searchedTag.length > 0) {
      filtered = filtered.filter((contact) =>
        contact.profilePerOrganization.some(
          ({ profile }) => profile.tags && searchedTag.every((tag) => profile.tags.includes(tag))
        )
      );
    }

    if (searchedName) {
      const lowercasedName = searchedName.toLowerCase();
      filtered = filtered.filter((contact) => contact.name.toLowerCase().includes(lowercasedName));
    }

    setFilteredContacts(filtered);
  }, [searchedTag, searchedName, contacts]);

  const handleFilteredContactsClick = () => {
    setCurrentDataSource(filteredContacts);
  };

  const handleFailedContactsClick = () => {
    setCurrentDataSource(failedContacts);
  };
  const handleOptedOutContactsClick = () => {
    setCurrentDataSource(optedOutContacts);
  };

  const refreshContacts = async () => {
    setIsRefreshing(true);
    try {
      const response = await axiosPrivate.get(apiURL + organizationId);
      dispatch({ type: 'FETCH_CONTACTS', data: response.data });
    } catch (error) {
      StatusNotification(
        'error',
        'Refresh Failed',
        error?.response?.data?.message || 'An error occurred while refreshing the contacts.'
      );
    } finally {
      setIsRefreshing(false);
    }
  };
  const handleBulkEditCancel = () => {
    setIsBulkEditModalVisible(false);
  };

  const handleBulkEditOk = async () => {
    try {
      const response = await axiosPrivate.patch(`/api/respondents/bulk-edit/${organizationId}`, {
        respondentsToUpdate: selectedRowKeys,
        action: bulkEditAction,
        tags: bulkEditTags.split(',').map((tag) => tag.trim()), // Convert comma-separated string to array
      });

      StatusNotification('success', 'Bulk Edit Successful', response.data.message);
      setIsBulkEditModalVisible(false);
      setSelectedRowKeys([]); // Clear selection
      refreshContacts();
    } catch (error) {
      StatusNotification('error', 'Bulk Edit Failed', error?.response?.data?.message);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: '#',
      key: 'counter',
      width: '4%',
      responsive: ['lg'],
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '16%',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Uploaded Contact',
      dataIndex: 'profilePerOrganization',
      key: 'isContact',
      align: 'center',
      responsive: ['lg'],
      sorter: (a, b) =>
        a.profilePerOrganization[0].profile.isContact -
        b.profilePerOrganization[0].profile.isContact,
      render: (profilePerOrganization) =>
        profilePerOrganization &&
        profilePerOrganization.length > 0 &&
        profilePerOrganization[0].profile.isContact
          ? 'true'
          : null,
    },
    {
      title: 'Subscribed',
      dataIndex: 'profilePerOrganization',
      key: 'isSubscribed',
      align: 'center',
      responsive: ['lg'],
      sorter: (a, b) =>
        a.profilePerOrganization[0].profile.isSubscribed.value -
        b.profilePerOrganization[0].profile.isSubscribed.value,
      render: (profilePerOrganization) =>
        profilePerOrganization &&
        profilePerOrganization.length > 0 &&
        profilePerOrganization[0].profile.isSubscribed.value
          ? 'true'
          : null,
    },
    {
      title: 'Date',
      dataIndex: 'startingDateTime',
      key: 'createdAt',
      align: 'center',
      width: '8%',
      render: (date, record) => dayjs(record.createdAt).fromNow(),
      sorter: (a, b) => {
        if (a.createdAt && b.createdAt) {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Tags',
      dataIndex: 'profilePerOrganization',
      width: '30%',
      key: 'tags',
      align: 'center',
      responsive: ['lg'],
      sorter: (a, b) =>
        a.profilePerOrganization[0].profile.tags.length -
        b.profilePerOrganization[0].profile.tags.length,
      render: (profilePerOrganization) => {
        const tags = profilePerOrganization?.[0]?.profile?.tags || [];

        return tags.length > 0 ? <TagsDropdown tags={tags} /> : null;
      },
    },
  ];

  return (
    <>
      <Modal
        title='Bulk Edit Tags'
        open={isBulkEditModalVisible}
        onOk={handleBulkEditOk}
        onCancel={handleBulkEditCancel}
      >
        <Radio.Group onChange={(e) => setBulkEditAction(e.target.value)} value={bulkEditAction}>
          <Radio value={'add'}>Add Tags</Radio>
          <Radio value={'remove'}>Remove Tags</Radio>
        </Radio.Group>
        <Input
          type='text'
          placeholder='Enter tags, separated by commas'
          value={bulkEditTags}
          onChange={(e) => setBulkEditTags(e.target.value)}
        />
      </Modal>

      <div style={{ marginBottom: 10, marginTop: screenSize !== 'xs' ? 78 : 0 }}>
        <Row>
          {role !== 'viewer' && (
            <Col push={1} span={24}>
              <Row justify='space-between'>
                <Col span={12}>
                  <NavLink to='/contacts/upload'>
                    <span className='icon' style={{ fontSize: 26, cursor: 'pointer' }}>
                      <Tooltip
                        placement='top'
                        color={GlobalColors.mainPurple}
                        title={toolTip('Add Contacts')}
                      >
                        <PlusOutlined style={{ color: GlobalColors.mainPurple }} />
                      </Tooltip>
                    </span>
                  </NavLink>
                </Col>
                <Col pull={2} span={12} style={{ textAlign: 'right' }}>
                  <span
                    className='icon'
                    style={{
                      fontSize: 26,
                      cursor: selectedRowKeys.length > 0 ? 'pointer' : 'not-allowed',
                    }}
                    onClick={() => {
                      if (selectedRowKeys.length > 0) {
                        setIsBulkEditModalVisible(true);
                      }
                    }}
                  >
                    <Tooltip
                      placement='top'
                      color={GlobalColors.mainPurple}
                      title={toolTip('Bulk Edit Contacts')}
                    >
                      <EditOutlined
                        style={{
                          color: selectedRowKeys.length > 0 ? GlobalColors.mainPurple : '#ccc',
                        }}
                      />
                    </Tooltip>
                  </span>
                </Col>
              </Row>
            </Col>
          )}
          <Col span={24}>
            <Card
              bordered={false}
              className='criclebox tablespace mb-24'
              style={{ padding: 12 }}
              title={
                <Row justify='space-between'>
                  <Col xs={24} md={22}>
                    <Row justify='space-between'>
                      <Col xs={24} md={6}>
                        <UserOutlined
                          style={{
                            fontSize: 24,
                            color: GlobalColors.lightPurple,
                            paddingRight: 8,
                          }}
                        />
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={toolTip('Active')}
                        >
                          <Tag
                            color={GlobalColors.lightPurple}
                            onClick={handleFilteredContactsClick}
                            style={{ cursor: 'pointer' }}
                          >
                            {filteredContacts.length}
                          </Tag>
                        </Tooltip>
                        {''}
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={toolTip('Failed Broadcast')}
                        >
                          <Tag
                            color={GlobalColors.failed}
                            style={{ cursor: 'pointer' }}
                            onClick={handleFailedContactsClick}
                          >
                            {failedContacts.length}
                          </Tag>
                        </Tooltip>
                        <Tooltip
                          placement='top'
                          color={GlobalColors.mainPurple}
                          title={toolTip('Opted Out')}
                        >
                          <Tag
                            color={GlobalColors.mainPurple}
                            style={{ cursor: 'pointer' }}
                            onClick={handleOptedOutContactsClick}
                          >
                            {optedOutContacts.length}
                          </Tag>
                        </Tooltip>
                      </Col>
                      <Col xs={24} md={18}>
                        <Row gutter={[8, 0]} justify='space-between'>
                          <Col xs={24} md={12}>
                            <Input
                              type='text'
                              placeholder='Search by name'
                              className='field-item form-field'
                              style={{ lineHeight: 10, height: 32 }}
                              value={searchedName}
                              onChange={(e) => setSearchedName(e.target.value)}
                            />
                          </Col>
                          <Col xs={24} md={12}>
                            <Select
                              mode='tags'
                              size='middle'
                              style={{
                                width: '100%',
                                borderRadius: '6px',
                              }}
                              placeholder='Select tags'
                              className='table-search'
                              value={searchedTag}
                              onChange={(value) => setSearchedTag(value)}
                            >
                              {allTags.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                  {tag}
                                </Select.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <span className='icon' onClick={refreshContacts} style={{ cursor: 'pointer' }}>
                      <SyncOutlined spin={isRefreshing} style={{ fontSize: 20 }} />
                    </span>
                  </Col>
                </Row>
              }
            >
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={currentDataSource}
                pagination={{
                  size: 'small',
                  position: ['bottomCenter'],
                  current: currentPage,
                  pageSize: pageSize,
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                  },
                }}
                rowSelection={screenSize !== 'xs' ? rowSelection : undefined}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Contacts;
