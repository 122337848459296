import React from 'react';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';

const SurveyActionsDropdown = ({ items, onClick }) => {
  return (
    <Dropdown
      menu={{
        items,
        onClick: (info) => {
          console.log(info);
          return onClick(info.key);
        }, // Pass only the key to onClick
      }}
      trigger={['click']}
    >
      <button
        onClick={(e) => e.preventDefault()}
        style={{
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          color: GlobalColors.mainPurple,
          padding: 0,
          display: 'flex',
          alignItems: 'center',
        }}
        aria-label='More actions'
      >
        {items.length > 0 && <MoreOutlined style={{ fontSize: 20 }} />}
      </button>
    </Dropdown>
  );
};

export default SurveyActionsDropdown;
