import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Typography, Space } from 'antd';
import { MailTwoTone } from '@ant-design/icons';
import NavBar from '../components/NavBar';

const { Paragraph, Text } = Typography;

const RegistrationSuccess = () => {
  return (
    <>
      <NavBar />
      <div className='container'>
        <Row
          type='flex'
          justify='center'
          align='middle'
          style={{ minHeight: '100vh', flexFlow: 'Column' }}
        >
          <Col xs={24} md={8}></Col>
          <Col xs={24} md={8} justify='center' align='middle'>
            <MailTwoTone twoToneColor='#966dad' style={{ fontSize: '80px' }} />
            <h2>Almost done!</h2>
            <Paragraph>
              <Text strong>
                Please check your email account to complete the registration process and to use
                Surveyr
              </Text>
            </Paragraph>
            <Paragraph>
              The confirmation email can take up to 30 minutes. If you have not received the
              confirmation email after 30 minutes, please check your spam folder, make sure you have
              entered the correct email address and, if needed, resend the confirmation email with
              the link below.
            </Paragraph>
            <Space />
            <Paragraph>
              <Text type='secondary'>
                If you have still not received the confirmation email even after resending it and
                waiting for another 30 minutes, please contact our support : support@surveyr.africa
              </Text>
            </Paragraph>
            <Link to='/resend'>Resend confirmation email</Link>
          </Col>
          <Col span={8}></Col>
        </Row>
      </div>
    </>
  );
};

export default RegistrationSuccess;
