import React from 'react'
import { Row, Col, Result, Button } from 'antd'
import { CreditCardTwoTone } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import GlobalColors from '../assets/colors/GlobalColors' //
import { HappyProvider } from '@ant-design/happy-work-theme'

const SubscriptionExpired = () => {
  const unAuthorizedText = 'This feature requires the Basic Plan.'
  const history = useHistory()

  const handleChangePlan = () => {
    setTimeout(() => {
      history.push('/subscription')
    }, 700)
  }

  return (
    <Row justify="center">
      <Col xs={24} md={12}>
        <Result
          status="warning"
          title={
            <span
              style={{
                fontFamily: 'Alfa Slab One',
                color: GlobalColors.darkGray,
                fontWeight: 100,
              }}
            >
              {unAuthorizedText}
            </span>
          }
          subTitle="Please upgrade from your Free Plan to continue."
          icon={<CreditCardTwoTone twoToneColor={GlobalColors.lightPurple} />}
          extra={
            <HappyProvider>
              <Button type="primary" onClick={handleChangePlan}>
                Change Plan
              </Button>
            </HappyProvider>
          }
        />
      </Col>
    </Row>
  )
}

export default SubscriptionExpired
