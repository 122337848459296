import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Grid, Image, Typography, Tag } from 'antd';
import ReplacePlaceholders from './ReplacePlaceholders';
import getScreenSize from '../utils/getScreenSize';
import urlIcon from '../assets/images/whatsapp-url-icon.png';
import btnIcon from '../assets/images/whatsapp-btn-icon.png';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const TemplateCard = () => {
  const { selectedTemplate, inputValues, templateMediaUrl } = useSelector(
    (state) => state.templatesData || {}
  );

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const hasButtons =
    selectedTemplate &&
    selectedTemplate.components.some((comp) => {
      return comp.buttons;
    });
  const cardStyle =
    screenSize === 'xs'
      ? { width: '100%', padding: '20px', marginBottom: '10px' }
      : {
          position: 'fixed',
          top: '120px',
          right: '20px',
          width: 260,
          zIndex: 10,
          paddingTop: '6px',
          paddingLeft: '10px',
          paddingRight: '10px',
        };

  return (
    <Card
      style={cardStyle}
      className='template-card  '
      // size="small"
      cover={
        selectedTemplate &&
        selectedTemplate.components.some(
          (comp) => comp.type === 'HEADER' && comp.example && comp.example.header_handle
        ) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {' '}
            <Image
              alt='example'
              height={160}
              width={160}
              preview={false}
              src={
                templateMediaUrl
                  ? templateMediaUrl
                  : selectedTemplate &&
                    selectedTemplate.components.find((comp) => comp.type === 'HEADER').example
                      .header_handle[0]
              }
            />
          </div>
        )
      }
    >
      {selectedTemplate &&
        selectedTemplate.components.map((component, index) => (
          <div
            key={`component-${index}`}
            style={
              component.type === 'FOOTER' && hasButtons
                ? { borderBottom: '1px solid #e9eae7', paddingBottom: 5 }
                : component.type === 'HEADER'
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            <ReplacePlaceholders
              text={component.text}
              type={component.type}
              inputValues={inputValues}
              className={component.type === 'FOOTER' ? 'footer-text' : ''}
            />
            {component.type === 'BODY' && (
              <Tag color='blue' style={{ marginTop: '5px', float: 'right' }}>
                {` ${component.text.length}`}
              </Tag>
            )}
          </div>
        ))}
      <Row>
        <Col span={24}>
          {hasButtons &&
            selectedTemplate &&
            selectedTemplate?.components
              .filter((comp) => comp.buttons)
              .flatMap((comp) => comp.buttons)
              .map((button, index, arr) => (
                <div
                  key={`button-${index}`}
                  style={{
                    backgroundColor: 'white',
                    textAlign: 'center',
                    padding: '5px 0',
                    marginTop: '6px',
                    fontSize: '12px',
                    color: '#3DB1F5',
                    fontWeight: 'bold',
                    borderBottom: index === arr.length - 1 ? 'none' : '1px solid #e9eae7',
                  }}
                  className='card-button-text'
                >
                  {button.text && (
                    <img
                      src={button.type === 'URL' ? urlIcon : btnIcon}
                      alt='icon'
                      style={{
                        height: '12px',
                        width: '12px',
                        marginRight: '4px',
                        marginTop: '-3px',
                      }}
                    />
                  )}
                  {button.text}
                </div>
              ))}
        </Col>
      </Row>
    </Card>
  );
};

export default TemplateCard;
