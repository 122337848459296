/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Row, Col } from 'antd';
import { HeartTwoTone } from '@ant-design/icons';
import GlobalColors from '../../assets/colors/GlobalColors';
import {Link} from 'react-router-dom';

function Footer() {
  return (
    <div
      id='about'
      className='container containerRadius'
      // style={{ backgroundColor: '#dfdfdf' }}
    >
      <Row className='just' style={{ padding: 18 }}>
        <Col xs={24} md={12} lg={12}>
          <div className='copyright'>
            © 2023, Made with
            {<HeartTwoTone twoToneColor={GlobalColors.mainPurple} />} by
            <a href='https://www.codegarden.co.za' style={{color: GlobalColors.darkGray}}>
              Codegarden.
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className='footer-menu'>
            <ul>
              <li className='nav-item'>
                <Link to='/contact' className='nav-link text-muted'>
                  Support
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/blogs'>
                  Blogs
              </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Footer;
