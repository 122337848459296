import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Typography, Grid, QRCode, Badge } from 'antd';
import { SmileTwoTone, HeartFilled } from '@ant-design/icons';
import NavBar from '../NavBar';
import getScreenSize from '../../utils/getScreenSize';
import Leaderboard from './Leaderboard';
import GlobalColors from '../../assets/colors/GlobalColors';
import WordCloud from './WordCloud';

const { useBreakpoint } = Grid;
const { Title } = Typography;

const LiveEventPublic = ({ type }) => {
  const { id: surveyId, surveyName, organizationId, rankableFieldIndexNumber } = useParams();
  const [isStillActive, setIsStillActive] = useState(true);
  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const qrValue = `${process.env.REACT_APP_WHATSAPP_API_URL}Press-Send-To-Start-The-Survey_${organizationId}_${surveyId}`;

  const colStyle = { textAlign: 'center', ...(screenSize !== 'xs' && { height: '5%' }) };
  const titleLevel = screenSize !== 'xs' ? 1 : 2;
  const titleSuffix = isStillActive ? null : (
    <>
      {' is no longer active '}
      <SmileTwoTone twoToneColor={GlobalColors.lightPurple} />
    </>
  );

  return (
    <div style={{ marginTop: -36 }}>
      <NavBar />
      <Row>
        <Col span={24} style={colStyle}>
          <Title level={titleLevel} className='sectionHeader'>
            {surveyName}
            {titleSuffix}
          </Title>
        </Col>
        {isStillActive && (
          <>
            {' '}
            <Col xs={24} md={4} order={screenSize === 'xs' ? 2 : 0}>
              <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <HeartFilled style={{ color: GlobalColors.failed }} />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  <QRCode value={qrValue} size={256} />
                  <Title level={3} className='sectionHeader'>
                    Scan To Participate 🔥
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              md={12}
              order={screenSize === 'xs' ? 1 : 0}
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <Leaderboard
                visibility={'public'}
                type={type}
                surveyId={surveyId}
                rankableFieldIndexNumber={rankableFieldIndexNumber}
                setIsStillActive={setIsStillActive}
              />
            </Col>
            {type === 'ranking' && (
              <Col
                xs={24}
                md={8}
                order={screenSize === 'xs' ? 1 : 0}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={2} className='sectionHeader' style={{ marginLeft: 10 }}>
                      Pledges 🙏🏾
                    </Title>
                  </Col>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <WordCloud
                      setIsStillActive={setIsStillActive}
                      surveyId={surveyId}
                      wordCloudAnswerIndex={5}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default LiveEventPublic;
