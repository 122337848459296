import React, { useState } from 'react';
import { Modal, Input, Button, Segmented } from 'antd';
import { useDispatch } from 'react-redux';
import usePrivateAxios from '../hooks/usePrivateAxios';
import StatusNotification from '../components/StatusNotification';
import Media from '../components/Media';

const GenerateSurveys = ({ surveyName, visible, onCancel, organizationId, formType }) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('text');
  const [imageUrls, setImageUrls] = useState([]);

  const dispatch = useDispatch();
  const axiosPrivate = usePrivateAxios();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSegmentChange = (value) => {
    setSelectedType(value);
  };

  const handleOk = () => {
    setIsLoading(true);
    generateSurvey();
  };

  const generateSurvey = async () => {
    try {
      const payload = {
        prompt: inputValue,
        type: selectedType,
        options: {},
      };

      if (selectedType === 'vision') {
        payload.options.imageUrls = imageUrls;
      }

      const { data } = await axiosPrivate.post(`/api/ai/generate/survey`, payload);
      dispatch({ type: 'RECEIVE_AI_GENERATED_SURVEY', data });
      StatusNotification(
        'success',
        'Response from chatGPT',
        `Review the surveys questions generated by AI, make any necessary edits, and ensure to save them to finalize the draft versions.`
      );
    } catch (error) {
      console.error('Error generating survey:', error);
      StatusNotification('error', 'Generating with AI Failed', 'Please try again later');
    } finally {
      setIsLoading(false);
      onCancel();
    }
  };

  const handleImageUpload = (url) => {
    setImageUrls((prevUrls) => [...prevUrls, { image_url: { url } }]);
  };

  return (
    <Modal
      open={visible}
      title='Generate with AI'
      onCancel={onCancel}
      footer={[
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key='ok' type='primary' onClick={handleOk} loading={isLoading}>
          OK
        </Button>,
      ]}
    >
      <Segmented
        options={formType === 'basic' ? [] : ['text', 'vision']}
        defaultChecked
        value={selectedType}
        onChange={handleSegmentChange}
      />
      {selectedType === 'text' ? (
        <Input.TextArea
          value={inputValue}
          onChange={handleInputChange}
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      ) : (
        <>
          <Input.TextArea
            value={inputValue}
            onChange={handleInputChange}
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
          <Media
            setImageUrl={handleImageUpload}
            fileName={surveyName}
            organizationId={organizationId}
            usage={'gpt-vision'}
          />
        </>
      )}
    </Modal>
  );
};

export default GenerateSurveys;
