import * as yup from 'yup';
import { MAX_QUESTIONS, MAX_OPTIONS } from '../constants';

const createSurveySchema = (formType, isQuiz) => {
  let schema = yup.object().shape({
    survey: yup
      .array()
      .min(1, 'A minimum of 1 survey must be submitted')
      .max(MAX_QUESTIONS, `A maximum of ${MAX_QUESTIONS} surveys are allowed at a time`)
      .of(
        yup.object().shape({
          id: yup.string().trim().required(),
          mainQuestion: yup.string().trim().min(4).max(500).required(),
          subHeading: yup
            .string()
            .trim()
            .min(1)
            .max(24, 'subHeading has a maximum length of 24')
            .required(),
          correctAnswerOption: isQuiz ? yup.number().required() : yup.number().notRequired(),
          isValidateInput: yup
            .string()
            .trim()
            .oneOf(['email', 'number', 'id_number'])
            .notRequired(),
          options: yup.array().when('type', {
            is: 'optionList',
            then: yup
              .array()
              .min(2, 'A minimum of 2 options must be submitted')
              .max(MAX_OPTIONS, `A maximum of ${MAX_OPTIONS} options are allowed at a time`)
              .of(
                yup.object().shape({
                  id: yup.string().trim().required(),
                  value: yup
                    .string()
                    .trim()
                    .min(1)
                    .max(20, 'value has a maximum length of 20 characters')
                    .test(
                      'len',
                      'value cannot have underscores as part of the name',
                      function (value) {
                        return !value?.includes('_');
                      }
                    ),
                  emojiType: yup
                    .string()
                    .trim()
                    .max(50, 'emojitype has a maximum length of 50 characters'),
                  description: yup
                    .string()
                    .trim()
                    .max(60, 'description has a maximum length of 60 characters')
                    .when('emojiType', {
                      is: (emojiType) => !!emojiType,
                      then: yup
                        .string()
                        .required('Description is required when emojiType is provided'),
                      otherwise: yup.string().trim().notRequired(),
                    }),
                  isExplanationEnabled:
                    formType === 'basic' ? yup.boolean().notRequired() : yup.boolean().required(),
                  explanation: yup
                    .string()
                    .trim()
                    .max(500, 'explanation has a maximum length of 500 characters')
                    .when('isExplanationEnabled', {
                      is: true,
                      then: yup
                        .string()
                        .trim()
                        .required('explanation is required when isExplanationEnabled is true'),
                      otherwise: yup.string().trim().notRequired(),
                    }),
                  isJumpEnabled:
                    formType === 'basic' ? yup.boolean().notRequired() : yup.boolean().required(),
                  jumpTo: yup.number().when('isJumpEnabled', {
                    is: true,
                    then: yup.number().required('jumpTo is required when isJumpEnabled is true'),
                    otherwise: yup.number().notRequired(),
                  }),
                  isOptionMediaEnabled:
                    formType === 'basic' ? yup.boolean().notRequired() : yup.boolean().required(),
                  optionMediaUrl: yup.string().when('isOptionMediaEnabled', {
                    is: true,
                    then: yup
                      .string()
                      .required('optionMediaUrl is required when isOptionMediaEnabled is true'),
                    otherwise: yup.string().notRequired(),
                  }),
                })
              ),
            otherwise: yup.array().notRequired(),
          }),
          type: yup.mixed().oneOf(['optionList', 'openEnded', 'wordCloud']).required(),
        })
      ),
  });

  return schema;
};

const validateObject = (object, formType, isQuiz) => {
  const surveySchema = createSurveySchema(formType, isQuiz);

  return new Promise((resolve, reject) => {
    surveySchema
      .validate(object)
      .then((v) => {
        resolve(v);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default validateObject;
