import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Typography, List } from 'antd';

const { Title } = Typography;

const BroadcastStatusStats = () => {
  const selectedBroadcastShortStats =
    useSelector((state) => state.broadcastData.selectedBroadcastShortStats) || {};

  const totalClicks = selectedBroadcastShortStats.totalClicks || 0;
  const countries = selectedBroadcastShortStats.country || [];
  const cities = selectedBroadcastShortStats.city || [];
  const os = selectedBroadcastShortStats.os || [];

  const stats = [
    { task: 'Total Clicks', data: [totalClicks] },
    { task: 'Countries', data: countries.map((country) => country.countryName) },
    { task: 'Cities', data: cities.map((city) => city.name) },
    { task: 'Operating Systems', data: os.map((osItem) => osItem.os) },
  ];

  return (
    <div className='tabled' style={{ marginBottom: 10, marginTop: 78 }}>
      <Title level={3} className='sectionHeader' style={{ textAlign: 'center' }}>
        SMS link statistics
      </Title>
      <Row className='rowgap-vbox' gutter={[24, 0]}>
        {stats.map((stat, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
            <Card bordered={false} className='criclebox'>
              <div className='number'>
                <Row align='middle' gutter={[24, 0]}>
                  <Col xs={24}>
                    <span>{stat.task.toLocaleUpperCase()}</span>
                    <List
                      dataSource={stat.data}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text>{item}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default BroadcastStatusStats;
