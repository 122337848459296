import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Button, Drawer } from "antd";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import { MenuOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Logo from "../../assets/images/surveyr-logo-small.png";
const NavBar = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(!visible);
  };

  const { isAuthenticated } = useSelector((state) => state.user);

  // If you do not want to auto-close the mobile drawer when a path is selected
  // Delete or comment out the code block below
  // From here
  let { pathname: location } = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);
  // Upto here

  return (
    <nav className="navbar container" style={{ marginRight: 0 }}>
      <Layout>
        <Layout.Header className="nav-header">
          <div className="logo">
            <h3 className="brand-font">
              <HashLink to="/#top" smooth="true">
                <img src={Logo} alt="" />
              </HashLink>
            </h3>
          </div>
          <div className="navbar-menu">
            <div className="leftMenu">
              {!isAuthenticated && <LeftMenu mode={"horizontal"} />}
            </div>
            <Button
              className="menuButton"
              type="text"
              onClick={showDrawer}
              style={{
                boxShadow: "none",
                borderRadius: "0px",
                fontSize: 26,
                color: "#7A7774",
              }}
            >
              <MenuOutlined />
            </Button>
            <div className="rightMenu">
              <RightMenu mode={"horizontal"} />
            </div>

            <Drawer
              title={
                <Link to="/">
                  <img src={Logo} alt="" style={{ width: 152 }} />
                </Link>
              }
              placement="right"
              closable={true}
              size={118}
              onClose={showDrawer}
              open={visible}
              style={{ zIndex: 99999 }}
              extra={
                <Button
                  onClick={showDrawer}
                  style={{ border: "1px solid #6A4C93" }}
                >
                  Close
                </Button>
              }
            >
              {!isAuthenticated && (
                <LeftMenu mode={"inline"} showDrawer={showDrawer} />
              )}
              <RightMenu mode={"inline"} showDrawer={showDrawer} />
            </Drawer>
          </div>
        </Layout.Header>
      </Layout>
    </nav>
  );
};

export default NavBar;
