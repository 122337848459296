import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Menu } from 'antd';
import scrollWithOffset from '../../utils/scrollWithOffset';

const LeftMenu = ({ mode, showDrawer }) => {
  const onMenuItemClick = (item) => {
    if (mode === 'inline') {
      showDrawer();
    }
  };

  // //makes sure the scroll stops/starts exactly where the components starts.
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  //   const yOffset = -80;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  // };

  let menuItems = [
    {
      label: (
        <span>
          Testimonials
          <Link to='/#testimonials' smooth='true' scroll={(el) => scrollWithOffset(el, -80)} />
        </span>
      ),
      key: '#testimonials',
    },
    {
      label: (
        <span>
          Features
          <Link to='/#features' smooth='true' scroll={(el) => scrollWithOffset(el, -80)} />
        </span>
      ),
      key: '#features',
    },
    {
      label: (
        <span>
          Pricing
          <Link to='/#pricing' smooth='true' scroll={(el) => scrollWithOffset(el, -80)} />
        </span>
      ),
      key: '#pricing',
    },
    {
      label: (
        <span>
          Use Cases
          <Link to='/#use-cases' smooth='true' scroll={(el) => scrollWithOffset(el, -80)} />
        </span>
      ),
      key: '#use-cases',
    },
    // {
    //   label: (
    //     <span>
    //       Blogs
    //       <Link to='/blogs' />
    //     </span>
    //   ),
    //   key: 'blogs',
    // },
    {
      label: (
        <span>
          Contact Us
          <Link to='/contact' smooth='true' scroll={(el) => scrollWithOffset(el, -80)} />
        </span>
      ),
      key: '#contact',
    },
  ];

  return (
    <>
      <Menu
        mode={mode}
        onClick={onMenuItemClick}
        items={menuItems}
        style={{ color: '#7A7774', fontSize: 16, fontWeight: 600 }}
      />
      <Link to='/#features' />
    </>
  );
};

export default LeftMenu;
