import React, { useState, useEffect } from "react";
import { Tour as AntTour, Image } from "antd";
import tourYouTube from "../assets/images/tour-youtube.png";
import surveyrIcon from "../assets/images/surveyr-logo-icon.png";

const Tour = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasShownTour = localStorage.getItem("hasShownTour");
    if (!hasShownTour) {
      const timer = setTimeout(() => {
        setOpen(true);
        localStorage.setItem("hasShownTour", "true");
      }, 3000);

      // After showing the tour, set 'hasShownTour' in localStorage to prevent it from showing again

      return () => clearTimeout(timer);
    }
  }, []);

  const steps = [
    {
      title: "Help Center",
      description:
        "For assistance, please visit our Help Center. On the bottom left corner of the screen",
      cover: (
        <Image alt="tour.png" src={tourYouTube} preview={false} width={60} />
      ),
      target: () => null,
    },
    {
      title: "AI Chatbot",
      description:
        "You can always ask our AI chatbot for help. Click the chat icon on the bottom right corner of the screen",
      cover: (
        <Image alt="surveyr.png" src={surveyrIcon} preview={false} width={60} />
      ),
      target: () => null,
    },
    {
      title: "Start here",
      placement: "top",
      description: "To get started, watch this video.",
      target: () => document.querySelector("#startingPoint"),
    },
  ];

  return (
    <AntTour
      open={open}
      steps={steps}
      onClose={() => setOpen(false)}
      // Additional Tour configuration as needed
    />
  );
};

export default Tour;
