import { SIGN_IN_USER, SIGN_UP_USER, LOGOUT_USER, REFRESH_TOKEN } from './types';
import axios from '../../apis/backendAPI';
import { jwtDecode } from 'jwt-decode';

const apiURL = '/api/users/';

export const signInUser = (accessToken) => async (dispatch) => {
  // if (data.codeStatus == 'success')
  const {
    userId,
    organizationId,
    hasOwnBot,
    discount,
    registrationDate,
    isSubscribedToMarketing,
    userDetails,
    teamDetails,
  } = jwtDecode(accessToken);

  console.log({ userDetails });
  const data = {
    userId,
    organizationId,
    hasOwnBot,
    discount,
    accessToken,
    planType: userDetails?.planType,
    subscriptionStatus: userDetails?.subscriptionStatus,
    registrationDate,
    isSubscribedToMarketing,
    userDetails,
    teamDetails,
  };
  dispatch({
    type: SIGN_IN_USER,
    data,
  });
};

export const signUpUser = (data) => async (dispatch) => {
  dispatch({
    type: SIGN_UP_USER,
    data,
  });
};

export const refreshUserToken = (accessToken) => async (dispatch) => {
  const { userId, organizationId, planType, subscriptionStatus, registrationDate } =
    jwtDecode(accessToken);
  const payload = {
    userId,
    organizationId,
    accessToken,
  };
  dispatch({
    type: REFRESH_TOKEN,
    payload,
  });
};

export const signOutUser = () => async (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  dispatch({ type: 'REMOVE_THIRTY_DAYS_CHART_DATA' });
};

export const verifyUser = (userId, token) => {
  return new Promise((resolve, reject) => {
    return axios('post', apiURL + `signup/verify/${userId}/${token}`, {})
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(message);
      });
  });
};

export const resendVerificationEmail = (email) => {
  return new Promise((resolve, reject) => {
    return axios('post', apiURL + 'signup/resend', email)
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(message);
      });
  });
};

export const resetPassword = (email) => {
  return new Promise((resolve, reject) => {
    return axios('post', apiURL + 'password/reset', email)
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(message);
      });
  });
};

export const updatePassword = (userId, token, password) => {
  return new Promise((resolve, reject) => {
    return axios('post', apiURL + `password/new/${userId}/${token}`, {
      password,
    })
      .then((data) => {
        resolve(data);
      })
      .catch(({ message }) => {
        reject(message);
      });
  });
};
