function recalculateSubHeadingNumbering(validated) {
  let openEndedCounter = 0;
  let optionListCounter = 0;

  const sanitizedSurvey = validated.survey.map((item) => {
    if (item.type === 'openEnded') {
      openEndedCounter++;
      return {
        ...item,
        subHeading: `openEnded ${openEndedCounter}`,
      };
    } else if (item.type === 'optionList') {
      optionListCounter++;
      return {
        ...item,
        subHeading: `Question ${optionListCounter}`,
      };
    } else {
      return item;
    }
  });

  return {
    ...validated,
    survey: sanitizedSurvey,
  };
}

export default recalculateSubHeadingNumbering;