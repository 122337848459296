import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const HighlightCurlyBraces = ({ text }) => {

  if (!text) return null;

  const parts = text.split(/({{.*?}})/g);

  return (
    <Typography>
      {parts.map((part, index) => {
        // If the part starts with {{ and ends with }}, it should be highlighted
        if (part.startsWith('{{') && part.endsWith('}}')) {
          return (
            <Text key={index} strong mark style={{ color: 'purple' }}>
              {part}
            </Text>
          );
        }
        return part;
      })}
    </Typography>
  );
};

export default HighlightCurlyBraces;
