import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers';

// const initialState = {};
const middleware = [thunk]; //array, for other middleware like logger

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

//to enable persistReducer, remove rootReducer & initialstate from createStore
const store = createStore(
  persistedReducer,
  // rootReducer,
  // initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

//uncomment persistor, for persistance and comment out store
const persistor = persistStore(store);
export { store, persistor };
// export {store}
