/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Alert, Space, Button, Row, Col, Grid, ConfigProvider } from 'antd';
import { SmileTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

import Sidenav from './Sidenav';
import NavBar from '../NavBar';
import Footer from './Footer';
import GlobalColors from '../../assets/colors/GlobalColors';
import getScreenSize from '../../utils/getScreenSize';
import Leaderboard from '../event/Leaderboard';
import usePrivateAxios from '../../hooks/usePrivateAxios';
import StatusNotification from '../StatusNotification';

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

function Main({ children }) {
  const [sidenavType, setSidenavType] = useState('white');

  const registrationDate = useSelector((state) => state.user.registrationDate);
  const subscriptionStatus = useSelector((state) => state.user.subscriptionStatus);
  const { userId, organizationId } = useSelector((state) => state.user);
  const { accountDeletionScheduled } = useSelector((state) => state.user.userDetails);
  const isLiveEventInProgress = useSelector((state) => state.surveysData.isLiveEventInProgress);
  const isQuizInProgress = useSelector((state) => state.surveysData.isQuizInProgress);

  const screens = useBreakpoint();
  const screenSize = getScreenSize(screens);

  const axiosPrivate = usePrivateAxios();
  const apiUrl = '/api/users/cancel-deletion';

  const today = dayjs(dayjs().format('YYYY-MM-DD'));
  const regday = dayjs(registrationDate).format('YYYY-MM-DD');
  const daysLeft = 7 - today.diff(regday, 'days');
  const deletionDaysLeft = accountDeletionScheduled
    ? dayjs(accountDeletionScheduled).diff(today, 'days')
    : null;

  const dispatch = useDispatch();

  const handleCancelDeletion = async () => {
    try {
      const response = await axiosPrivate.post(apiUrl, {
        userId,
        organizationId,
      });
      if (response.status === 200) {
        dispatch({ type: 'CANCEL_ACCOUNT_DELETION' });
      }
    } catch (error) {
      StatusNotification('error', 'Error cancelling deletion', 'Please try again later');
    }
  };

  let { pathname } = useLocation();
  pathname = pathname.replace('/', '');

  const normalStyle = `layout-dashboard ${pathname === 'profile' ? 'layout-profile' : ''} ${
    pathname === 'rtl' ? 'layout-dashboard-rtl' : ''
  }`;

  const liveEvent = isLiveEventInProgress || isQuizInProgress;

  const showShakeEffect = (node) => {
    if (!node) return;

    const seq = [0, -15, 15, -5, 5, 0];
    const itv = 10;
    let steps = 0;
    let intervalId = null;

    function loop() {
      cancelAnimationFrame(node.effectTimeout);
      node.effectTimeout = requestAnimationFrame(() => {
        const currentStep = Math.floor(steps / itv);
        const current = seq[currentStep];
        const next = seq[currentStep + 1];
        if (currentStep >= seq.length - 1) {
          node.style.transform = '';
          node.style.transition = '';
          steps = 0; // Reset steps to restart the animation
          return;
        }

        const angle = current + ((next - current) / itv) * (steps % itv);
        node.style.transform = `rotate(${angle}deg)`;
        node.style.transition = 'none';
        steps++;
        loop();
      });
    }

    // Stop the previous interval if any
    clearInterval(intervalId);

    // Start a new interval
    intervalId = setInterval(loop, 14000); // Restart the loop every 14 seconds
    loop(); // Start immediately
  };

  const Wrapper = ({ name }) => {
    const buttonRef = useRef(null);

    useEffect(() => {
      const node = buttonRef.current;
      showShakeEffect(node);

      // Cleanup function to clear interval and animation frame
      return () => {
        if (node) {
          clearInterval(node.effectInterval);
          cancelAnimationFrame(node.effectTimeout);
        }
      };
    }, []);

    return (
      <Button ref={buttonRef} type='primary'>
        {name}
      </Button>
    );
  };

  return (
    <>
      {!isLiveEventInProgress && <NavBar />}
      <Layout
        className={!liveEvent ? normalStyle : ''}
        style={!liveEvent ? { backgroundColor: '#EDEDED' } : {}}
      >
        {!liveEvent && (
          <Sider
            breakpoint='lg'
            collapsedWidth='0'
            trigger={null}
            width={250}
            theme='light'
            className={`sider-primary ant-layout-sider-primary ${
              sidenavType === '#EDEDED' ? 'active-route' : ''
            }`}
            style={
              isQuizInProgress
                ? { marginTop: 10, marginLeft: 0, background: '#EDEDED' }
                : { background: '#EDEDED' }
            }
          >
            <Sidenav color='#c095e4' isShowSide={true} screenSize={screenSize} />
          </Sider>
        )}
        <Layout>
          <Content className='content-ant'>
            {subscriptionStatus === 'trial' && (
              <Alert
                style={{
                  top: 10,
                  borderRadius: 9,
                  // height: 48,
                  border: 'none',
                  marginBottom: 40,
                }}
                message={
                  <Row align='center'>
                    <Col
                      span={24}
                      style={{
                        color: '#7A7774',
                        textAlign: 'center',
                      }}
                    >
                      {daysLeft > 0 ? (
                        <span>
                          {daysLeft} days left on your <strong>full feature</strong> free access
                          trial
                        </span>
                      ) : (
                        <>
                          Your 7-day full access trial has ended. Continue with limited features or{' '}
                          <strong>upgrade to a paid plan</strong> for the full experience.{' '}
                          <SmileTwoTone twoToneColor={GlobalColors.mainPurple} />
                        </>
                      )}
                    </Col>
                  </Row>
                }
                type={daysLeft > 0 ? 'success' : 'error'}
                closable={daysLeft < 0}
                action={
                  <Space>
                    <Link to={'/subscription'}>
                      <Wrapper name='Buy Plan' />
                    </Link>
                  </Space>
                }
              />
            )}
            {accountDeletionScheduled && (
              <Alert
                style={{
                  top: 10,
                  borderRadius: 9,
                  border: 'none',
                  marginBottom: 40,
                }}
                message={
                  <Row align='center'>
                    <Col
                      span={24}
                      style={{
                        color: '#7A7774',
                        textAlign: 'center',
                      }}
                    >
                      {deletionDaysLeft > 0 ? (
                        <span>
                          {deletionDaysLeft} days left until your account is{' '}
                          <strong>deleted</strong>
                        </span>
                      ) : (
                        <>
                          Your account is scheduled for deletion today. Please{' '}
                          <strong>cancel deletion</strong> if you want to keep your account.{' '}
                          <SmileTwoTone twoToneColor={GlobalColors.mainPurple} />
                        </>
                      )}
                    </Col>
                  </Row>
                }
                type={deletionDaysLeft > 0 ? 'error' : 'warning'}
                closable={deletionDaysLeft <= 0}
                action={
                  <Space>
                    <Button type='primary' onClick={handleCancelDeletion}>
                      Cancel Deletion
                    </Button>
                  </Space>
                }
              />
            )}
            {screenSize === 'xs' && (
              <Row style={{ paddingTop: 20 }}>
                <Col xs={24} md={12}>
                  <Sidenav color={GlobalColors.lightPurple} isShowSide={false} />
                </Col>
              </Row>
            )}
            {children}
          </Content>
          {!liveEvent && <Footer />}
        </Layout>
      </Layout>
    </>
  );
}

export default Main;
