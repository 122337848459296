// PieChartSkeleton.jsx
import React from 'react';
import { Skeleton, Row, Col } from 'antd';

const PieChartSkeleton = () => {
  return (
    <div style={{ padding: '24px' }}>
      <Row justify='space-between' align='middle' style={{ marginBottom: '24px' }}>
        <Col span={6}>
          <Skeleton.Input active style={{ width: 120 }} />
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Skeleton.Input active style={{ width: 200 }} />
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Skeleton.Input active style={{ width: 120 }} />
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          marginTop: '44px',
        }}
      >
        <Skeleton.Avatar active size={400} shape='circle' />
      </div>
      <Row justify='center' style={{ marginTop: '24px' }}>
        <Col span={12} style={{ textAlign: 'center' }}>
          <Skeleton.Button active style={{ width: 80, marginRight: 20 }} />
          <Skeleton.Button active style={{ width: 80 }} />
        </Col>
      </Row>
    </div>
  );
};

export default PieChartSkeleton;
