import { FETCH_CONTACTS } from '../actions/types';

const initialState = {
  contacts: [],
  failedContacts: [],
  optedOutContacts: [],
};

const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTACTS:
      return {
        ...state,
        contacts: action.data.filteredContacts,
        failedContacts: action.data.failedContacts,
        optedOutContacts: action.data.optedOutContacts
      };

    default:
      return state;
  }
};

export default contactsReducer;
