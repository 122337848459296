import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Layout, Tag, Image, Skeleton } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import usePrivateAxios from '../hooks/usePrivateAxios';
import NavBar from './NavBar';
import Footer from './layout/Footer';
import SectionHeader from './SectionHeader';
import GlobalColors from '../assets/colors/GlobalColors';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const axiosPrivate = usePrivateAxios();
  const history = useHistory();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const { data } = await axiosPrivate.get('/api/blogs');
        setBlogs(data);
      } catch (error) {
        console.error('Failed to fetch blogs:', error);
        // Handle errors here, e.g., show notification, redirect, etc.
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [axiosPrivate]);

  const handleCardClick = (id) => {
    history.push(`/blogs/${id}`);
  };

  return (
    <Layout className='layout-default layout-signin'>
      <NavBar />
      <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
        <Col span={24}>
          <SectionHeader title='Blogs' isFront={false} subtitle='' />
          <Row gutter={[16, 16]} justify='center'>
            {loading &&
              Array.from({ length: 2 }).map((_, index) => (
                <Col key={index} xs={24} sm={12} md={8} lg={6}>
                  <Skeleton loading={loading} active avatar>
                    <Card
                      hoverable
                      className='blogStyle'
                      cover={<Image preview={false} />}
                      actions={[
                        <Tag color={GlobalColors.darkGray} style={{ fontSize: 15, padding: 4 }} />,
                        <Tag
                          color={GlobalColors.darkPurple}
                          style={{ fontSize: 15, padding: 4 }}
                        />,
                      ]}
                    >
                      <Card.Meta title='' description='' />
                    </Card>
                  </Skeleton>
                </Col>
              ))}
            {!loading &&
              blogs.map((blog) => (
                <Col key={blog._id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    className='blogStyle'
                    cover={
                      <Image
                        preview={false}
                        alt={blog?.title}
                        src={blog?.image}
                        onClick={() => handleCardClick(blog._id)}
                      />
                    }
                    actions={[
                      <Tag color={GlobalColors.darkGray} style={{ fontSize: 15, padding: 4 }}>
                        {dayjs(blog.createdAt).fromNow()}
                      </Tag>,
                      <Link to={`/blogs/${blog?._id}`}>
                        <Tag color={GlobalColors.darkPurple} style={{ fontSize: 15, padding: 4 }}>
                          {blog.categories[0]}
                        </Tag>
                      </Link>,
                    ]}
                  >
                    <Card.Meta
                      title={blog?.title}
                      description={`${blog?.description.substring(0, 100)}...`}
                    />
                    <Link to={`/blogs/${blog?._id}`} className='read-more-link'>
                      read more
                    </Link>
                  </Card>
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
      <Footer />
    </Layout>
  );
};

export default BlogList;
