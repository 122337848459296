import { CommentOutlined, CustomerServiceOutlined, VideoCameraOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { FloatButton, Switch } from 'antd';
import GlobalColors from '../assets/colors/GlobalColors';
const Floater = () => {
  const openHowToVideos = () => {
    window.open(
      'https://www.youtube.com/playlist?list=PLkRaS73J2FxnQgBhOo6VSHiJq-mtozIu4',
      '_blank'
    );
  };

  const handleWhatsAppClick = () => {
    window.open('https://api.whatsapp.com/send?phone=4915225859870&text=Hello', '_blank');
  };

  return (
    <>
      <FloatButton.Group
        trigger='click'
        type='primary'
        style={{
          right: 24,
        }}
        icon={<CustomerServiceOutlined />}
      >
        <FloatButton
          icon={
            <CommentOutlined
              style={{ color: GlobalColors.mainPurple }}
              onClick={handleWhatsAppClick}
            />
          }
        />
        <FloatButton
          icon={
            <VideoCameraOutlined
              style={{ color: GlobalColors.mainPurple }}
              onClick={openHowToVideos}
            />
          }
        />
      </FloatButton.Group>
    </>
  );
};
export default Floater;
