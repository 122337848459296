import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';

const GettingStartedVideo = ({ screenSize }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        onClick={showModal}
        id={screenSize !== 'xs' ? 'startingPoint' : 'NotStartingPoint'}
        type='default'
        icon={<VideoCameraOutlined style={{ fontSize: 18, cursor: 'pointer' }} />}
      >
        Watch Getting Started Video
      </Button>
      <Modal
        title='Creating a survey in 4 minutes or less!'
        open={visible}
        onCancel={handleCancel}
        footer={null}
        width='60%'
        style={{ padding: 0 }} // Removes the padding around the iframe
      >
        <iframe
          width='100%'
          height='380'
          src='https://www.youtube.com/embed/_etu6OJ_kcI'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen'
        ></iframe>
      </Modal>
    </>
  );
};

export default GettingStartedVideo;
