import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Slider } from 'antd';
import { MAX_QUESTIONS } from '../constants';

const marks = {};
for (let i = 1; i <= MAX_QUESTIONS; i++) {
  marks[i] = String(i);
}

const QuestionerSlider = () => {
  const dispatch = useDispatch();
  const { completed, numberOfQuestions, surveyInputData } = useSelector(
    (state) => state.surveysData.create
  );

  const handleChange = (value) => {
    const nonEmptyQuestions = Object.values(surveyInputData).filter(
      (q) => q.mainQuestion.trim() !== ''
    ).length;

    // Prevent decreasing below the number of non-empty questions
    if (value < nonEmptyQuestions) {
      value = nonEmptyQuestions;
    }

    dispatch({ type: 'CREATE_SURVEY_NUMBER_OF_QUESTIONS', data: value });

    if (value < numberOfQuestions) {
      // Remove excess questions
      const newSurveyInputData = {};
      const newCompleted = {};
      for (let i = 1; i <= value; i++) {
        newSurveyInputData[i] = surveyInputData[i];
        newCompleted[i] = completed[i];
      }
      dispatch({
        type: 'CREATE_SURVEY_INPUT_DATA',
        data: newSurveyInputData,
      });
      dispatch({ type: 'CREATE_SURVEY_COMPLETED', data: newCompleted });
    } else if (value > numberOfQuestions) {
      // Add new questions
      const newSurveyInputData = { ...surveyInputData };
      const newCompleted = { ...completed };
      for (let i = numberOfQuestions + 1; i <= value; i++) {
        newSurveyInputData[i] = {
          mainQuestion: '',
          options: [],
          type: 'optionList',
          subHeading: `Question ${i}`,
        };
        newCompleted[i] = false;
      }
      dispatch({
        type: 'CREATE_SURVEY_INPUT_DATA',
        data: newSurveyInputData,
      });
      dispatch({ type: 'CREATE_SURVEY_COMPLETED', data: newCompleted });
    }
  };

  return (
    <Slider
      step={1}
      defaultValue={2}
      value={numberOfQuestions}
      min={1}
      max={MAX_QUESTIONS}
      onChange={handleChange}
      style={{ paddingRight: 18 }}
    />
  );
};

export default QuestionerSlider;
