import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import {
  List,
  Skeleton,
  Collapse,
  Divider,
  Tag,
  Row,
  Col,
  Typography,
  Card,
  Popover,
  Tooltip,
} from 'antd';
import {
  PictureOutlined,
  FileTextOutlined,
  AudioOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import GlobalColors from '../assets/colors/GlobalColors';
import usePrivateAxios from '../hooks/usePrivateAxios';
import { useSelector } from 'react-redux';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import LoadingIcon from './LoadingIcon';
import FreeTextResponsesSkeleton from './skeletons/FreeTextResponsesSkeleton';

const { Text, Title } = Typography;
const toolTip = (value) => <span>{value}</span>;

const sentimentColors = {
  positive: '#a3edc1',
  neutral: '#fce89f',
  negative: '#fec2c6',
};

const FreeTextResponses = ({ screenSize, organizationId, surveyId }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const axiosPrivate = usePrivateAxios();
  const { searchedTags } = useSelector((state) => state.dashboard);

  const fetchTextData = async () => {
    if (!surveyId || !organizationId || searchedTags.length === 0) return;
    
    setIsLoading(true);
    try {
      const response = await axiosPrivate.post(`/api/responses/open-ended`, {
        surveyId,
        organizationId,
        tags: searchedTags,
      });

      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error.response.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTextData();
  }, [searchedTags]);

  // Function to create and download zip
  const downloadZip = async () => {
    setIsLoading(true);
    const zip = new JSZip();
    const csvContent = [];

    // Collect unique questions
    const uniqueQuestions = new Set();
    data.forEach((group) => {
      uniqueQuestions.add(group.question);
    });

    // Create CSV header
    const csvHeader = ['Respondent Name', ...Array.from(uniqueQuestions)];
    csvContent.push(csvHeader.join(','));

    // Collect responses in a structured format
    const responsesByRespondent = {};

    data.forEach((group) => {
      group.responses.forEach((response) => {
        if (!responsesByRespondent[response.name]) {
          responsesByRespondent[response.name] = { name: response.name };
        }
        responsesByRespondent[response.name][group.question] = response.answer;
      });
    });

    Object.values(responsesByRespondent).forEach((respondent) => {
      const row = [respondent.name];
      uniqueQuestions.forEach((question) => {
        row.push(respondent[question] || '');
      });
      csvContent.push(row.join(','));
    });

    // Add CSV file to zip
    const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
    zip.file('responses.csv', csvBlob);

    // Add media files to zip
    const mediaFolder = zip.folder('media');
    for (const group of data) {
      for (const response of group.responses) {
        if (response.type === 'audio' || response.type === 'image') {
          const mediaBlob = await fetch(response.mediaUrl).then((r) => r.blob());
          const mediaFileName = `${response.name}_${response.mediaUrl.split('/').pop()}`;
          mediaFolder.file(mediaFileName, mediaBlob);
        }
      }
    }

    // Generate zip and trigger download
    const zipFileName = searchedTags.join('_') + '.zip';
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, zipFileName);
    });

    setIsLoading(false);
  };

  const renderResponseContent = (item) => {
    if (item.type === 'audio') {
      return (
        <audio controls style={{ width: '100%' }}>
          <source src={item.mediaUrl} type='audio/ogg' />
          <source src={item.mediaUrl} type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
      );
    } else if (item.type === 'image') {
      return (
        <Popover
          overlayInnerStyle={{ padding: 0 }}
          content={
            <img src={item.mediaUrl} alt='response' style={{ maxWidth: 180, borderRadius: 9 }} />
          }
        >
          <PictureOutlined style={{ cursor: 'pointer' }} />
        </Popover>
      );
    } else {
      return <FileTextOutlined />;
    }
  };

  return isLoading ? (
    <FreeTextResponsesSkeleton />
  ) : (
    <div style={{ padding: '0 16px' }}>
      <Row justify='space-between' style={{ paddingBottom: 16 }}>
        {data.length > 0 && (
          <Col xs={24} md={6}>
            <Tag color={GlobalColors.lightPurple}>Free Text Responses</Tag>
          </Col>
        )}
        {data.length > 0 && (
          <Col push={2} xs={24} md={6}>
            <Tooltip
              placement='top'
              color={GlobalColors.mainPurple}
              title={toolTip('Download All Responses')}
            >
              <DownloadOutlined style={{ cursor: 'pointer', fontSize: 18 }} onClick={downloadZip} />
            </Tooltip>
          </Col>
        )}
      </Row>
      {data.length > 0 ? (
        <div
          id='scrollableDiv'
          style={{
            height: 'calc(100vh - 300px)',
            overflow: 'auto',
            borderRadius: 7,
          }}
        >
          <InfiniteScroll
            dataLength={data.length}
            next={fetchTextData}
            hasMore={false} // Adjust as per your requirement
            loader={<Skeleton paragraph={{ rows: 1 }} active />}
            endMessage={<Divider plain></Divider>}
            scrollableTarget='scrollableDiv'
          >
            {data.map((group, index) => {
              // Check if any responses have audio or image
              const hasAudio = group.responses.some((response) => response.type === 'audio');
              const hasImage = group.responses.some((response) => response.type === 'image');

              return (
                <Collapse
                  accordion
                  key={index}
                  style={{
                    width: '100%',
                    marginBottom: 8,
                    padding: 0,
                  }}
                >
                  <Collapse.Panel
                    header={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <span>{group.question}</span>
                        <div>
                          {hasAudio && <AudioOutlined style={{ marginLeft: 8 }} />}
                          {hasImage && <PictureOutlined style={{ marginLeft: 8 }} />}
                        </div>
                      </div>
                    }
                    key={`panel-${index}`}
                  >
                    <List
                      size='small'
                      dataSource={group.responses}
                      renderItem={(item) => (
                        <List.Item key={item.respondentId} style={{ paddingTop: 8 }}>
                          <Row style={{ width: '100%' }}>
                            <Col span={4} style={{ marginRight: 2 }}>
                              <Tag color={GlobalColors.darkGray}>{item.name}</Tag>
                            </Col>
                            {item.type !== 'image' && (
                              <Col span={20}>
                                {item.answer.length > 100
                                  ? `${item.answer.substring(0, 100)}...`
                                  : item.answer}
                              </Col>
                            )}
                            {item.mediaUrl && (
                              <Col span={item.type !== 'image' ? 24 : 20} style={{ paddingTop: 8 }}>
                                {renderResponseContent(item)}
                              </Col>
                            )}
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Collapse.Panel>
                </Collapse>
              );
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <Row justify='center' align='middle' style={{ height: '55vh' }}>
          <Col>
            <Title level={4} style={{ color: GlobalColors.darkGray }}>
              No free text responses available
            </Title>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FreeTextResponses;
