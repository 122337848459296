function canResend(document, role) {
  const { bullMQ, whatsApp, status } = document;

  // Viewers cannot resend
  if (role === 'viewer') {
    return false;
  }
  // Allow resend if status is 'failed' and nothing has been dispatched
  if (status === 'failed' && bullMQ.dispatched === 0) {
    return true;
  }

  // Do not allow resend if the status is one of these, regardless of WhatsApp activity
  if (['active', 'completed', 'stalled'].includes(status)) {
    return false;
  }

  // At this point, status is neither 'failed', 'completed', 'stalled', nor 'active'
  // Allow resend if there's no WhatsApp activity
  return !Object.values(whatsApp).some((value) => value > 0);
}

export default canResend;
