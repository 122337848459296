import React from 'react';
import { Row, Col, Typography, Grid } from 'antd';
import getScreenSize from '../utils/getScreenSize';

const { Title } = Typography;
const { useBreakpoint } = Grid;

const SectionHeader = ({ title, subtitle, isFront }) => {
    const screens = useBreakpoint();
    const screenSize = getScreenSize(screens);
  return (
    <Row justify='center'>
      <Col x={24} md={24} style={{ textAlign: 'center' }}>
        <Title level={screenSize === 'xs' ? 3 : 1} className='sectionHeader'>
          {title}
        </Title>
      </Col>
      <Col x={24} md={24} style={isFront ? { textAlign: 'center' } : {}}>
        <Title level={5} className={isFront ? 'sectionLead' : 'sectionLeadInternal'}>
          {subtitle}
        </Title>
      </Col>
    </Row>
  );
};

export default SectionHeader;
