
function checkLocalStoreStatus() {
  let test = 'test';
  try {
    // try setting an item
    localStorage.setItem('test', test);
    localStorage.removeItem('test');
  } catch (e) {
    // browser specific checks if local storage was exceeded
    if (
      e.name === 'QUATA_EXCEEDED_ERR' || // Chrome
      e.name === 'NS_ERROR_DOM_QUATA_REACHED' //Firefox/Safari
    ) {
      // local storage is full
      return 'full';
    } else {
      try {
        if (localStorage.remainingSpace === 0) {
          // IE
          // local storage is full
          return 'full';
        }
      } catch (e) {
        // localStorage.remainingSpace doesn't exist
      }

      // local storage might not be available
      return false;
    }
  }
  return true;
}

export default checkLocalStoreStatus;
