import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import StrengthBar from 'react-password-strength-bar';
import { Layout, Button, Typography, Card, Form, Input, Checkbox, Spin, Row, Col } from 'antd';
import StatusNotification from '../components/StatusNotification';
import makeId from '../utils/makeId';
import useAxios from '../hooks/useAxios';
import NavBar from '../components/NavBar';
import SectionHeader from '../components/SectionHeader';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { HappyProvider } from '@ant-design/happy-work-theme';
import CountrySelect from '../components/CountrySelect';
import IndustrySelect from '../components/IndustrySelect';
import HowDidYouHearSelect from '../components/HowDidYouHearSelect';

const { Text } = Typography;
const loadingIcon = <LoadingOutlined style={{ fontSize: 24, color: 'gray' }} spin />;

const SignUp = ({ history }) => {
  const [typedPassword, setTypedPassword] = useState('');
  const [loading, axiosFetch] = useAxios();
  const [country, setCountry] = useState('');
  const [industry, setIndustry] = useState('');
  const [howDidYouHear, setHowDidYouHear] = useState('');
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const apiURL = '/api/users/signup';

  const onPasswordChange = (e) => {
    setTypedPassword(e.target.value);
  };

  const onCountryChange = (value) => {
    setCountry(value);
  };

  const onIndustryChange = (value) => {
    setIndustry(value);
  };

  const onHowDidYouHearChange = (value) => {
    setHowDidYouHear(value);
  };

  const onValuesChange = (changedValues, allValues) => {
    delete allValues.password;
    delete allValues.confirmPassword;

    localStorage.setItem('formValues', JSON.stringify(allValues));
  };

  useEffect(() => {
    form.setFieldsValue(JSON.parse(localStorage.getItem('formValues')));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const onFinish = ({ name, lastName, email, organizationName, password }) => {
    const id = `${makeId(7)}`;

    const userObject = {
      user: { name, lastName, email, password },
      organization: {
        id,
        name: organizationName,
        contactPerson: {
          name,
          email,
        },
        country,
        industry,
        howDidYouHear,
      },
    };

    dispatch({ type: 'RESET_REDUX_STATE' });
    localStorage.removeItem('hasConcent');

    axiosFetch({
      method: 'post',
      url: apiURL,
      requestConfig: userObject,
    })
      .then((response) => {
        localStorage.removeItem('formValues');
        form.resetFields();

        StatusNotification(
          'success',
          'Registration success',
          'Confirmation code sent to your email'
        );
        history.push('/success');
      })
      .catch((err) => {
        StatusNotification('error', 'Problem Signing Up', err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // Handle failure
  };

  return (
    <>
      <Layout className='layout-default layout-signin'>
        <NavBar />

        <Row justify='center'>
          <Col span={24}>
            <SectionHeader title='Sign Up' isFront={false} subtitle='' />
          </Col>
          <Col span={24}>
            <Card className='card-signup header-solid ant-card h-full ' bordered={false}>
              <Form
                name='basic'
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className='row-col'
                form={form}
                onValuesChange={onValuesChange}
              >
                <Form.Item
                  style={{ marginTop: 12 }}
                  name='name'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input placeholder='Name' />
                </Form.Item>
                <Form.Item
                  style={{ marginTop: 12 }}
                  lastName='lastName'
                  rules={[{ required: true, message: 'Please input your username!' }]}
                >
                  <Input placeholder='Last Name' />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    { required: true, message: 'Please input your email!' },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                  name='organizationName'
                  rules={[{ required: true, message: 'Organization Name is needed' }]}
                >
                  <Input placeholder='Organization Name' />
                </Form.Item>
                <Form.Item>
                  <CountrySelect onChange={onCountryChange} />
                </Form.Item>
                <Form.Item>
                  <IndustrySelect onChange={onIndustryChange} />
                </Form.Item>
                <Form.Item>
                  <HowDidYouHearSelect onChange={onHowDidYouHearChange} />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    { required: true, message: 'Please input your password!' },
                    { min: 6, message: 'Minimum of 6 characters required' },
                  ]}
                  onChange={onPasswordChange}
                  hasFeedback
                >
                  <Input.Password placeholder='Password' style={{ borderRadius: 7, height: 48 }} />
                </Form.Item>
                <StrengthBar
                  password={typedPassword}
                  minLength={6}
                  barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#52c41a']}
                />
                <Form.Item
                  name='confirmPassword'
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('The two passwords that you entered do not match!')
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder='Confirm Password'
                    style={{ borderRadius: 7, height: 48 }}
                  />
                </Form.Item>
                <Form.Item
                  name='terms'
                  valuePropName='checked'
                  rules={[
                    {
                      required: true,
                      message: 'You must agree to the terms before using the platform',
                    },
                  ]}
                >
                  <Checkbox>
                    I agree the{' '}
                    <Link to='/terms'>
                      {' '}
                      <Text strong>Terms and Conditions</Text>
                    </Link>
                  </Checkbox>
                </Form.Item>
                <Form.Item>
                  <HappyProvider>
                    <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
                      {loading ? <Spin indicator={loadingIcon} /> : 'Sign Up'}
                    </Button>
                  </HappyProvider>
                </Form.Item>
              </Form>
              <p className='text-muted text-center font-semibold'>
                Already have an account?{' '}
                <Link to='/signin' className='text-dark font-bold'>
                  Sign In
                </Link>
              </p>
            </Card>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default SignUp;
