import React from 'react';
import { Collapse, Typography } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

const FAQ = () => {
  const faqData = [
    {
      question: 'What is Surveyr?',
      answer:
        'Surveyr is an advanced survey management platform designed to help businesses gather, analyze, and act on customer feedback efficiently. It provides tools for creating surveys, distributing them across multiple channels, and analyzing the responses to drive business decisions.',
    },
    {
      question: 'How can Surveyr help improve my customer engagement?',
      answer:
        'Surveyr allows you to create customized surveys that can be distributed via email, SMS, and social media. This multi-channel approach ensures higher response rates and better engagement. Additionally, our platform offers real-time analytics and insights, helping you understand customer needs and improve your products or services.',
    },
    {
      question: 'Can I integrate Surveyr with other business tools?',
      answer:
        'Yes, Surveyr can be integrated with various CRM systems, marketing automation tools, and e-commerce platforms. This integration helps streamline your workflow, ensuring that customer feedback is seamlessly incorporated into your business processes.',
    },
    {
      question: 'What types of surveys can I create with Surveyr?',
      answer:
        'With Surveyr, you can create a wide range of surveys including customer satisfaction surveys, product feedback surveys, employee engagement surveys, and more. Our platform offers various question types and templates to suit your specific needs.',
    },
    {
      question: 'How secure is the data collected through Surveyr?',
      answer:
        'Data security is a top priority at Surveyr. We employ industry-standard encryption protocols to protect your data during transmission and storage. Additionally, we comply with major data protection regulations to ensure your data is handled securely and responsibly.',
    },
    {
      question: 'What support options are available if I need help using Surveyr?',
      answer:
        'Surveyr offers comprehensive support through various channels. You can access our detailed help center, join our community forums, or contact our customer support team via email or live chat for assistance. We also provide onboarding and training sessions to help you get the most out of our platform.',
    },
  ];

  return (
    <div style={{  margin: '0 auto', padding: '20px' }}>
      <Title
        level={2}
        style={{ textAlign: 'center', marginBottom: '40px' }}
        className='typographyBrown'
      >
        Frequently Asked Questions
      </Title>
      <Collapse
        accordion
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
      >
        {faqData.map((faq, index) => (
          <Panel header={<strong>{faq.question}</strong>} key={index}>
            <Paragraph>{faq.answer}</Paragraph>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
