import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal, Button, Row, Col, Input, Image, Typography, Form } from 'antd';
import { useExitIntent } from 'use-exit-intent';
import usePrivateAxios from '../hooks/usePrivateAxios';
import { HappyProvider } from '@ant-design/happy-work-theme';
import Sentimeter from '../assets/images/sentimeter.png';
import Chillbot from '../assets/images/chillbot.png';
import GlobalColors from '../assets/colors/GlobalColors';
import StatusNotification from './StatusNotification';
import LoadingIcon from './LoadingIcon';

const { Title } = Typography;
const modalShownKey = 'exit-intent-modal-shown';
const intents = {
  cookie: {
    daysToExpire: 30,
    key: 'use-exit-intent',
  },
  desktop: {
    triggerOnIdle: true,
    useBeforeUnload: false,
    triggerOnMouseLeave: true,
    delayInSecondsToTrigger: 12,
  },
  mobile: {
    triggerOnIdle: true,
    delayInSecondsToTrigger: 15,
  },
};
const ExitIntentModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { registerHandler, isTriggered } = useExitIntent(intents);
  const [form] = Form.useForm();
  const axiosPrivate = usePrivateAxios();
  const dispatch = useDispatch();
  const location = useLocation();

  const { isSusbscribedToMarketing } = useSelector((state) => state.user);

  useEffect(() => {
    const modalShown = localStorage.getItem(modalShownKey);

    if (
      !isSusbscribedToMarketing &&
      location.pathname !== '/signup' &&
      location.pathname !== '/public' &&
      modalShown !== 'true' // Assuming modalShown is stored as a string 'true' or 'false'
    ) {
      registerHandler({
        id: 'openModal',
        handler: () => setIsModalVisible(true),
      });
    }
  }, [registerHandler, isSusbscribedToMarketing, location.pathname, modalShownKey]); // Added modalShownKey to dependencies

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    localStorage.setItem(modalShownKey, 'true');
  };

  const handleSubmit = async (values) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
      };
      setIsLoading(true);
      await axiosPrivate.post('/api/emaillist', payload);

      dispatch({ type: 'SET_USER_MARKETING_EMAIL_STATUS', data: true });
      StatusNotification(
        'success',
        'Subscription successful!',
        'Be on the lookout for your discount email, which will come through shortly 🥳'
      );
      handleOk();
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.status === 409) {
        dispatch({ type: 'SET_USER_MARKETING_EMAIL_STATUS', data: true });
        StatusNotification(
          'warning',
          'Already Subscribed',
          'You are already subscribed to our marketing list.'
        );
        handleOk();
      } else {
        dispatch({ type: 'SET_USER_MARKETING_EMAIL_STATUS', data: false });
        console.error('Subscription error:', error);
        StatusNotification('error', 'Error subscribing', 'Please try again later');
        handleOk();
      }
    }
  };

  return (
    <>
      {isModalVisible && (
        <Modal
          title={null}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          closable={false}
          centered
          width={600}
          styles={{
            body: {
              display: 'flex',
              borderRadius: 9,
            },
          }}
        >
          <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <Row
              gutter={[4, 0]}
              align='stretch'
              justify='center'
              style={{ height: '100%', display: 'flex', flexFlow: 'row nowrap' }}
            >
              <Col
                span={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  height: '100%',
                  backgroundColor: '#FFCF77',
                }}
              >
                <Row gutter={[18, 8]}>
                  <Col span={24} style={{ height: '100%' }}>
                    <Title level={3}>Subscribe to get 20% off</Title>
                  </Col>
                  <Col span={24}>
                    <Image src={Chillbot} preview={false} />
                  </Col>
                </Row>
              </Col>
              <Col
                span={18}
                style={{
                  backgroundColor: GlobalColors.whitePurple,
                  padding: 8,
                }}
              >
                <Row align='middle' justify='center' gutter={[0, 8]}>
                  <Col span={24}>
                    <Image src={Sentimeter} preview={false} />
                  </Col>
                  <Col span={18} style={{ textAlign: 'center' }}>
                    <Title level={3}>Increase engagement, through feedback collection</Title>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name='name' // Name input
                      rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                      <Input placeholder='Enter your name' />
                    </Form.Item>
                    <Form.Item
                      name='email'
                      rules={[
                        { required: true, message: 'Please input your email!' },
                        { type: 'email', message: 'Please enter a valid email!' },
                      ]}
                    >
                      <Input placeholder='Enter your email' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {isLoading ? (
                      <LoadingIcon />
                    ) : (
                      <HappyProvider>
                        <Button type='primary' htmlType='submit' block>
                          Subscribe
                        </Button>
                      </HappyProvider>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ExitIntentModal;
