const FetchLiveEventData = (
  surveyId,
  organizationId,
  axiosPrivate,
  dispatch,
  isLiveEvent,
) => {
  return new Promise((resolve, reject) => {
    if (!surveyId) {
      reject(new Error("Survey ID is required"));
      return;
    }
    const liveEventDataURL = `/api/responses/limited/${organizationId}/${surveyId}/${isLiveEvent}`;
    axiosPrivate
      .get(liveEventDataURL)
      .then((response) => {
        const chartsData = response?.data;
        if (chartsData) {
          dispatch({
            type: "LOAD_LIVE_EVENT_DATA",
            payload: chartsData,
          });
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default FetchLiveEventData;
