const ChartColors = [
  '#8b73ab',
  '#fbe0e0',
  '#fff0d4',
  '#525e75',
  '#78938a',
  '#ffc8dd',
  '#a2d2ff',
];

export default ChartColors;